import * as React from 'react';
import { Container, ThumbnailItem } from './thumbnail.styles';
import { BrandTag } from '../brand-tag/brand-tag.component';
import useAppContext from '../../../hooks/use-app-context';
import { useMemo, useRef } from 'react';

export type ThumbnailProps = {
  imageUrl?: string;
  showBrand?: boolean;
  size?: string;
  iconSize?: string;
  brandSource?: 'user' | 'brandedContainer';
  brandedContainerBrand?: string;
};

export const Thumbnail = ({
  imageUrl,
  showBrand = true,
  iconSize = '21px',
  size = '43px',
  brandSource,
  brandedContainerBrand,
}: ThumbnailProps) => {
  const {
    state: { selectedBrand, brands },
  } = useAppContext();
  const RETRY_DELAY = 800;
  const retryTimeout = useRef<NodeJS.Timeout>();

  const retryImageLoad = (
    err: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const target = err.target as React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >;
    if (target && imageUrl) {
      target.onError = undefined;
      retryTimeout.current = setTimeout(() => {
        if (target) {
          target.src = imageUrl;
          target.onError = retryImageLoad;
          retryTimeout.current = undefined;
        }
      }, RETRY_DELAY);
    }
  };

  React.useEffect(() => {
    return () => {
      if (retryTimeout.current) {
        clearTimeout(retryTimeout.current);
      }
    };
  }, []);

  const brandedContainerBrandValue = useMemo(
    () => brands.find((brand) => brand.name === brandedContainerBrand),
    [brands, brandedContainerBrand]
  );

  return (
    <Container size={size} className="branded-container-thumbnail">
      <ThumbnailItem>
        {imageUrl ? (
          <img src={imageUrl} alt="" onError={retryImageLoad} />
        ) : (
          <span style={{ fontSize: iconSize, color: '#D4D4D4' }}>
            <i className="fal fa-image"></i>
          </span>
        )}
      </ThumbnailItem>
      {showBrand && brandSource === 'user' && selectedBrand && (
        <BrandTag brand={selectedBrand.shortcut} color={selectedBrand.color} />
      )}
      {showBrand &&
        brandSource === 'brandedContainer' &&
        brandedContainerBrandValue && (
          <BrandTag
            brand={brandedContainerBrandValue.shortcut}
            color={brandedContainerBrandValue.color}
          />
        )}
    </Container>
  );
};

import styled, { css } from 'styled-components';
import { Button, Modal } from 'antd';

export const StyledModal = styled(Modal)<{ hasCustomHeightStyle?: boolean }>`
  & > .ant-modal-content {
    background-color: #ffffff;
    max-width: 500px;

    ${(props) =>
      props.hasCustomHeightStyle &&
      css`
        min-height: 392px;
      `}

    & > .ant-modal-header {
      border: none;
      padding-bottom: 0;
    }

    & > .ant-modal-body {
      padding: 10px 24px 0 24px;

      ${(props) =>
        props.hasCustomHeightStyle &&
        css`
          min-height: 300px;
        `}
    }

    & > .ant-modal-footer {
      background-color: #f3f3f3;
      display: flex;
      justify-content: center;
      border: none;
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .modal-title {
    font-size: 14px;
    font-weight: 500;
  }

  & > .modal-subtitle {
    font-size: 12px;
    font-weight: 300;
  }
`;

export const ModalCtaButton = styled(Button)`
  width: 140px;
  height: 40px;
`;

export const EventDetails = styled.div`
  text-align: center;
  padding-bottom: 15px;

  &,
  .ant-btn {
    font-size: 13px;
    font-weight: 300;
  }
`;

import { ValidationRule } from './use-update-form';

export const validationRules: ValidationRule[] = [
  {
    name: 'title',
    isValid: (title: string) => (title ? title.trim().length > 0 : true),
    message: 'Title must contain at least one character.',
  },
  {
    name: 'additionalKeywords',
    isValid: (keywords: string[]) =>
      keywords
        ? keywords.length < 11 &&
          keywords.every((keyword) => keyword.length < 101)
        : true,
    message:
      'There can be max 10 additional keywords, each of can contain maximum of 100 characters',
  },
  {
    name: 'description',
    isValid: (description: string) =>
      description ? description.length < 10001 : true,
    message: 'Description can contain up to 10 000 characters',
  },
  {
    name: 'shortDescription',
    isValid: (shortDescription: string) =>
      shortDescription ? shortDescription.length < 10001 : true,
    message: 'Short description can contain up to 10 000 characters',
  },
];

import React from 'react';
import { useParams } from 'react-router';
import type { EventDetailsAction } from '../../../event/drawer-content/shared/types';
import Drawer from '../../../event/drawer/drawer';

type EventDetailsDrawerProps = {
  handleClose: () => void;
  actions?: EventDetailsAction[];
};

const EventDetailsDrawer = ({
  handleClose,
  actions,
}: EventDetailsDrawerProps) => {
  if (!actions?.length) {
    handleClose();
    return null;
  }

  const { id } = useParams() as { id: string };

  return (
    <Drawer
      id={id}
      width={1050}
      handleClose={handleClose}
      actions={actions}
      isNestedDrawer={true}
    />
  );
};

export default EventDetailsDrawer;

import {
  BuildFiltersQueryConfig,
  FilterDefinition,
  SubqueryFilterDefinition,
  FilterQueryType,
  MatchFilterDefinition,
  StandardFilterDefinition,
} from 'yggdrasil-shared/database/persistable-filters';
import { EventFilters } from '../../context/event-filters.context';
import { SearchOption } from '../../components/common/search-with-option/search-with-option.component';
import { ValueDefinition } from 'yggdrasil-shared/database/persistable-filters';
import { LabeledValue } from 'antd/lib/select';

const getValueFromTermArray = (filter: FilterDefinition) =>
  (filter.props.default! as ValueDefinition).value;

const getMinValue = (filter: FilterDefinition) =>
  (filter.props.min! as ValueDefinition).value;

const getMaxValue = (filter: FilterDefinition) =>
  (filter.props.max! as ValueDefinition).value;

const getRangeValue = (filter: FilterDefinition) => ({
  min: (filter.props.min! as ValueDefinition).value,
  max: (filter.props.max! as ValueDefinition).value,
});

/**
 * @note "value" has been added - required by antd v4
 */

const getLabeledValue = (filter: FilterDefinition): LabeledValue[] =>
  (filter?.props?.default as ValueDefinition<string[]>)?.value?.map((val) => ({
    key: val.toString(),
    label: val.toString(),
    value: val.toString(),
  })) ?? [];

const extractTitles = (filter: SubqueryFilterDefinition) =>
  Object.keys(filter.config.filtersConfig).map((key) => ({
    value: getValueFromTermArray(filter.config.filtersConfig[key]),
    option:
      (filter.config.filtersConfig[key] as StandardFilterDefinition).query
        .type === FilterQueryType.MustNot
        ? SearchOption.Negative
        : (filter.config.filtersConfig[key] as MatchFilterDefinition).fuzziness
        ? SearchOption.Fuzzy
        : SearchOption.Exact,
  }));

export const getInitialEventFilters = ({
  filtersConfig,
}: BuildFiltersQueryConfig): Partial<EventFilters> => {
  let filters: Partial<EventFilters> = {};

  if (filtersConfig.sourceEventId) {
    filters.sourceEventIds = getValueFromTermArray(filtersConfig.sourceEventId);
  }

  if (filtersConfig.genre) {
    filters.genres = getValueFromTermArray(filtersConfig.genre);
  }

  if (filtersConfig.relevantTextFields) {
    filters.fullTextSearchValues = extractTitles(
      filtersConfig.relevantTextFields as SubqueryFilterDefinition
    );
  }

  if (filtersConfig.title) {
    filters.titleSearchValues = extractTitles(
      filtersConfig.title as SubqueryFilterDefinition
    );
  }

  if (filtersConfig.priceMin) {
    filters.minPrice = getMinValue(filtersConfig.priceMin);
  }

  if (filtersConfig.priceMax) {
    filters.maxPrice = getMaxValue(filtersConfig.priceMax);
  }

  if (filtersConfig.startDateTime) {
    filters.startDateTime = getRangeValue(filtersConfig.startDateTime);
  }

  if (filtersConfig.saleStartDateTime) {
    filters.saleStartDateTime = getRangeValue(filtersConfig.saleStartDateTime);
  }

  if (filtersConfig.locationId) {
    filters.locationIDs = getValueFromTermArray(filtersConfig.locationId);
  }

  if (filtersConfig.ticketTypes) {
    filters.ticketSpecials = getValueFromTermArray(filtersConfig.ticketTypes);
  }

  if (filtersConfig.location) {
    filters.locations = getLabeledValue(filtersConfig.location);
  }

  if (filtersConfig.organizerName) {
    filters.organizerNames = getValueFromTermArray(filtersConfig.organizerName);
  }

  if (
    filtersConfig.organizers ||
    filtersConfig.organizerName ||
    filtersConfig.organizerId
  ) {
    filters.organizers = [
      ...(filtersConfig.organizers
        ? getLabeledValue(filtersConfig.organizers)
        : []),
      ...(filtersConfig.organizerName
        ? getLabeledValue(filtersConfig.organizerName)
        : []),
      ...(filtersConfig.organizerId
        ? getLabeledValue(filtersConfig.organizerId)
        : []),
    ];
  }

  return filters;
};

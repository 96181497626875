import * as React from 'react';
import type { AuthClientEvent } from '@react-keycloak/core/lib';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import {
  keycloakClient,
  keycloakInitConfig,
  logoutFromKeycloak,
} from '../../../context/keycloak.context';
import { setUserData, UserData } from '../../../utils/token';
import { AuthContext, AuthStateStore } from '../../../context/auth.context';
import { AuthInfoLoader } from '../auth-info-loader/auth-info-loader.component';
import { AppContext, AppStoreApi } from '../../../context/app.context';

type KeycloakWrapperProps = {
  children: React.ReactNode;
};

export const KeycloakWrapper = ({ children }: KeycloakWrapperProps) => {
  const { state, changeLoginState } = React.useContext(
    AuthContext
  ) as AuthStateStore;
  const { dispatch } = React.useContext(AppContext) as AppStoreApi;

  const updateUserData = async () => {
    const userInfo: UserData = await keycloakClient.loadUserInfo();
    setUserData({
      accessToken: keycloakClient.token,
      name: userInfo.name,
      email: userInfo.email,
    });
    dispatch({
      type: 'SET_USER',
      user: {
        accessToken: keycloakClient.token,
        name: userInfo.name,
        email: userInfo.email,
      },
    });
  };

  const keycloakEventHandler = async (
    changeLoginState: any,
    eventType: AuthClientEvent
  ) => {
    switch (eventType) {
      case 'onAuthLogout':
        setUserData({});
        break;
      case 'onAuthRefreshError':
        changeLoginState('loggingOut');
        logoutFromKeycloak();
        break;
      case 'onAuthSuccess':
        updateUserData();
        changeLoginState('loggedIn');
        break;
      case 'onAuthRefreshSuccess':
        updateUserData();
        break;
      default:
        break;
    }
  };

  return state.loginState === 'loggingOut' ? (
    <AuthInfoLoader />
  ) : (
    <ReactKeycloakProvider
      authClient={keycloakClient}
      initOptions={keycloakInitConfig}
      onEvent={(eventType: AuthClientEvent) => {
        keycloakEventHandler(changeLoginState, eventType);
      }}
      LoadingComponent={<AuthInfoLoader />}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

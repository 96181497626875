import React, { ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Input } from 'antd';
import { FormStepProps } from './shared';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { DescriptionContentSwitcher } from '../description-content-switcher/description-content-switcher.component';
import { LastUpdatedDate } from './last-updated-date.component';

type PressTextProps = FormStepProps & {
  warningComponent: ReactElement | null;
};

export const PressTextStep = ({
  onChange,
  isFormDisabled = false,
  values: { pressText, pressTextSource, dataLastUpdated },
  warningComponent,
}: PressTextProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeDescriptionContent = (value: string) => {
    const route = location.pathname.replace('press-text', value);

    navigate(route);
  };

  return (
    <React.Fragment>
      <DescriptionContentSwitcher
        onChange={onChangeDescriptionContent}
        defaultValue="press-text"
      />
      {warningComponent}
      {/*
      // @ts-ignore */}
      <Form.Item label="Press text source">
        <Input
          type="text"
          name="pressTextSource"
          placeholder="Enter text source..."
          value={pressTextSource || ''}
          onChange={onChange}
          disabled={isFormDisabled}
        />
        {dataLastUpdated.pressTextSource && (
          <LastUpdatedDate date={dataLastUpdated.pressTextSource} />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Press text">
        <Input.TextArea
          autoSize={{
            minRows: 11,
            maxRows: 11,
          }}
          name="pressText"
          value={pressText || ''}
          style={{ resize: 'none' }}
          onChange={onChange}
          placeholder="Enter press text..."
          disabled={isFormDisabled}
        />
        {dataLastUpdated.pressText && (
          <LastUpdatedDate date={dataLastUpdated.pressText} />
        )}
      </Form.Item>
    </React.Fragment>
  );
};

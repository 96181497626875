import React from 'react';
import { Tag } from './brand-tag.styles';

type BrandTagProps = {
  brand: string;
  color?: string;
  disabled?: boolean;
};

export const BrandTag = ({ brand, color, disabled }: BrandTagProps) => {
  return (
    <Tag className="brand-tag" color={color} disabled={disabled}>
      {brand}
    </Tag>
  );
};

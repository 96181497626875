import React, { ChangeEvent, useCallback } from 'react';
import { brandCustom } from 'yggdrasil-shared/domain/brand-custom';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { Form } from '@ant-design/compatible';
import { CloseOutlined } from '@ant-design/icons';
import type { MetaFields } from 'yggdrasil-shared/domain/brand-custom';
import { BrandName } from 'yggdrasil-shared/domain/dictionary';
import { StyledButton } from '../description-step/description-step.styles';
import { LastUpdatedDate } from '../last-updated-date.component';
import { FormStepProps } from '../shared';
import { LabelWithTooltip } from '../../../common/label-with-tooltip/label-with-tooltip';

export type FormItemLockerComponentProps = {
  name: keyof MetaFields;
  label: string;
  placeholder: string;
  onChange: FormStepProps['onChange'];
  activeBrandTab: BrandName;
  value?: string | null;
  isFormDisabled?: FormStepProps['isFormDisabled'];
  dataLastUpdated?: string | null;
  tooltipMessage?: string;
};

export const MetaFormItem = ({
  name,
  label,
  placeholder,
  onChange,
  value,
  isFormDisabled = false,
  activeBrandTab,
  dataLastUpdated,
  tooltipMessage,
}: FormItemLockerComponentProps) => {
  const onReset = useCallback(() => {
    onChange({
      target: {
        name,
        value: brandCustom.getDefaultMetaByKey(activeBrandTab, name),
      },
    } as ChangeEvent<HTMLInputElement>);
  }, [activeBrandTab, onChange, name]);

  return (
    <>
      {/*
      // @ts-ignore */}
      <Form.Item
        colon={false}
        label={
          tooltipMessage ? (
            <LabelWithTooltip label={label} tooltipMessage={tooltipMessage} />
          ) : (
            <span>{label}:</span>
          )
        }
      >
        <div style={{ display: 'flex' }}>
          <Input
            type="text"
            style={{ width: 'calc(100% - 100px)' }}
            name={name}
            placeholder={placeholder}
            value={value || ''}
            onChange={onChange}
            disabled={
              isFormDisabled || !brandCustom.isMetaEnabled(activeBrandTab)
            }
          />
          <StyledButton
            onClick={onReset}
            disabled={
              isFormDisabled || !brandCustom.isMetaEnabled(activeBrandTab)
            }
            icon={<CloseOutlined />}
          >
            Reset
          </StyledButton>
        </div>
        {dataLastUpdated && <LastUpdatedDate date={dataLastUpdated} />}
      </Form.Item>
    </>
  );
};

import React from 'react';
import { LimitedContainer } from '../limited-container/limited-container.component';
import {
  LastModifiedItem,
  LastModifiedItemProps,
} from '../last-modified-item/last-modified-item.component';
import { routes } from '../../../route-urls';
import { StyledLastModifiedList } from './last-modified-list.styles';

type LastModifiedListProps = {
  brandedContainerId: string;
  activityLog: LastModifiedItemProps[];
  totalActivities: number;
};

export const LastModifiedList = ({
  brandedContainerId,
  activityLog,
  totalActivities,
}: LastModifiedListProps) => {
  return (
    <StyledLastModifiedList>
      <LimitedContainer
        total={totalActivities}
        offset={3}
        title="LAST MODIFIED"
        redirectPath={routes.setBrandedContainerData(
          brandedContainerId,
          'last-modified'
        )}
      >
        {activityLog.length ? (
          activityLog
            .slice(0, 3)
            .map((activity, index) => (
              <LastModifiedItem key={`activity-log-${index}`} {...activity} />
            ))
        ) : (
          <span className="no-activity">There is no activity yet.</span>
        )}
      </LimitedContainer>
    </StyledLastModifiedList>
  );
};

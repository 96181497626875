import { Route, Routes } from 'react-router';
import React from 'react';
import DrawerWrapper from '../../components/event/drawer-wrapper/drawer-wrapper.component';

const EventDrawer = () => {
  return (
    <Routes>
      <Route path={`set-event-data/:id/*`} element={<DrawerWrapper />} />
    </Routes>
  );
};

export default EventDrawer;

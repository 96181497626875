import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  & > .switch-label {
    margin-left: 8px;
    font-size: 12px;
  }
`;

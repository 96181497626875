import styled from 'styled-components';

export const Container = styled.div.attrs(
  ({ smallMode }: { smallMode: boolean }) => ({
    smallMode,
  })
)`
  display: inline-block !important;
  width: auto !important;
  max-width: ${(props) => (props.smallMode ? '340px !important' : 'none')};

  & > .inner-container {
    display: flex;
    margin-left: -4px;
    margin-right: -4px;

    & > .event-title-container {
      margin-left: 15px;
    }
  }

  .title-wrapper {
    display: inline-flex;
    font-size: 11px;
    align-items: center;
  }

  .tag {
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${(props) => (props.smallMode ? '120px' : '250px')};
  }
`;

export const TitleWrapper = styled.div.attrs(
  ({ smallMode }: { smallMode: boolean }) => ({
    smallMode,
  })
)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 11px;

  .title {
    font-size: 13px;
    max-width: ${(props) => (props.smallMode ? '125px' : '150px')};
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > .event-details {
    margin-bottom: 5px;
  }

  & > .text-with-icon {
    & .icon {
      margin-right: 8px;
    }
  }
`;

import { Artist } from '../../../resolver.types';
import React from 'react';
import { getName } from '../../../utils/artist';
import { Link } from 'react-router-dom';
import { routes } from '../../../route-urls';

export type ArtistsWithSameNameExistsErrorProps = {
  artists: Artist[];
};

const ArtistsWithSameNameExistsWarning = ({
  artists,
}: ArtistsWithSameNameExistsErrorProps) => {
  return (
    <div style={{ paddingRight: '5px' }}>
      <div>Following artist(s) with the similar name already exist:</div>
      <ul style={{ paddingLeft: '20px' }}>
        {artists.map((artist) => (
          <li key={artist.id}>
            <Link
              style={{ textDecoration: 'underline' }}
              to={routes.setArtistData(artist.id)}
            >
              {getName(artist)}
            </Link>
          </li>
        ))}
      </ul>
      <div>
        Please make sure that you provide enough information to make them
        distinguishable.
      </div>
    </div>
  );
};

export default ArtistsWithSameNameExistsWarning;

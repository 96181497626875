import React from 'react';
import {
  StyledLimitedContainer,
  ContainerTitle,
  ItemsContainer,
  LinkContainer,
  ShowAllLink,
} from './limited-container.styles';

type LimitedContainerProps = {
  total: number;
  offset: number;
  title: string;
  redirectPath: string;
  children: React.ReactNode;
};

export const LimitedContainer = ({
  total,
  offset,
  title,
  redirectPath,
  children,
}: LimitedContainerProps) => {
  const displayShowAll = React.useMemo(() => total > offset, [total, offset]);

  return (
    <StyledLimitedContainer className="limited-container">
      <ContainerTitle>{title}</ContainerTitle>
      <ItemsContainer>{children}</ItemsContainer>
      {displayShowAll && (
        <LinkContainer>
          <ShowAllLink to={redirectPath}>Show all</ShowAllLink>
        </LinkContainer>
      )}
    </StyledLimitedContainer>
  );
};

import { Button, List as AntdList } from 'antd';
import React from 'react';
import { ListContainer, ListItem } from './list.styles';
import { routes } from '../../../route-urls';
import { Link } from 'react-router-dom';
import Title from '../title/title.component';
import { BrandedContainer } from '../../../resolver.types';

export type ListProps = {
  brandedContainers: BrandedContainer[];
  total: number;
  onShowAll: () => any;
  loading?: boolean;
};

export const List = ({
  brandedContainers,
  loading,
  total,
  onShowAll,
}: ListProps) => {
  return (
    <ListContainer>
      <AntdList<BrandedContainer>
        className="branded-containers-list"
        itemLayout="horizontal"
        dataSource={brandedContainers}
        bordered={false}
        renderItem={(item) => {
          return (
            <ListItem>
              <Link to={routes.setBrandedContainerData(item.id)}>
                <Title
                  style={{
                    width: '100%',
                  }}
                  thumbnailProps={{
                    brandSource: 'brandedContainer',
                  }}
                  isRegularSize={false}
                  brandedContainer={item}
                />
              </Link>
            </ListItem>
          );
        }}
      />
      {total > brandedContainers.length && (
        <Button
          shape="round"
          size="small"
          loading={loading}
          onClick={onShowAll}
          className="show-all"
          type="primary"
        >
          {loading ? 'Loading...' : `Show all (${total})`}
        </Button>
      )}
    </ListContainer>
  );
};

import { MouseEventHandler } from 'react';
import Icon from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

export type ResetButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const StyledButton = styled(Button)`
  &.ant-btn {
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 10px;
    position: absolute;
    top: 0;
    left: -8px;
    z-index: 3;
  }
`;

export const ResetButton = ({ onClick }: ResetButtonProps) => {
  return (
    <Tooltip
      arrowPointAtCenter
      placement="topLeft"
      title="Reset to initial state"
    >
      <StyledButton
        className="reset-button"
        onClick={onClick}
        type="primary"
        shape="round"
      >
        <Icon component={() => <i className="fal fa-sync" />} />
      </StyledButton>
    </Tooltip>
  );
};

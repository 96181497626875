import { useMutation } from '@apollo/client';
import {
  AddBrandedContainerCommentDocument,
  GetBrandedContainerCommentsDocument,
  GetBrandedContainerDocument,
} from '../../../resolver.types';

export const useAddBrandedContainerComment = (
  id: string,
  afterSubmit?: () => void
) => {
  const [mutate] = useMutation(AddBrandedContainerCommentDocument, {
    refetchQueries: [
      {
        query: GetBrandedContainerCommentsDocument,
        variables: { id },
      },
      {
        query: GetBrandedContainerDocument,
        variables: { id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const addComment = async ({
    content,
    issuer,
    version,
  }: {
    content: string;
    issuer: string;
    version: number;
  }) => {
    if (!content.trim()) {
      return;
    }

    await mutate({
      variables: {
        input: {
          id,
          version,
          comment: {
            content,
            issuer,
          },
        },
      },
    });

    if (afterSubmit) {
      afterSubmit();
    }
  };

  return { addComment };
};

import venueImg from '../assets/images/venue.svg';
import campaignImg from '../assets/images/campaign.svg';
import eventSeriesImg from '../assets/images/event-series.svg';
import artistImg from '../assets/images/artist.svg';
import genreImg from '../assets/images/genre.svg';
import placeImg from '../assets/images/place.svg';

export const promoteItems = [
  {
    name: 'artists',
    key: 'main-artist',
    label: 'Artist Information',
    imgUrl: artistImg,
    singleValue: false,
    placeholder: 'Please select an artist...',
    cardTitle: 'Artist',
    disabled: false,
  },
  {
    name: 'genres',
    key: 'genres',
    label: 'Genre Information',
    imgUrl: genreImg,
    singleValue: false,
    placeholder: 'Please add genre...',
    cardTitle: 'Genre',
    disabled: false,
  },
  {
    name: 'venue',
    key: 'venue',
    label: 'Venue Information',
    imgUrl: venueImg,
    singleValue: false,
    placeholder: 'Please add venue...',
    cardTitle: 'Venue',
    disabled: false,
  },
  {
    name: 'campaign',
    key: 'campaign',
    label: 'Campaign Information',
    imgUrl: campaignImg,
    singleValue: true,
    placeholder: 'Please add campaign...',
    cardTitle: 'Campaign',
    disabled: false,
  },
  {
    name: 'eventSeries',
    key: 'event-series',
    label: 'Event series Information',
    imgUrl: eventSeriesImg,
    singleValue: true,
    placeholder: 'Please add event series...',
    cardTitle: 'Event series',
    disabled: false,
  },
  {
    name: 'place',
    key: 'place',
    label: 'Place Information',
    imgUrl: placeImg,
    singleValue: false,
    placeholder: 'Please add place...',
    cardTitle: 'Place',
    disabled: false,
  },
  {
    name: 'workOfArt',
    key: 'workOfArt',
    label: 'Work of Art Information',
    imgUrl: venueImg,
    singleValue: true,
    placeholder: 'Please add work of art...',
    cardTitle: 'Work of Art',
    disabled: false,
  },
];

export type BrandedContainerNavigationItem = {
  icon: string;
  label: string;
  link: string;
  reselectBrand: boolean;
  total?: number;
};

export const brandedContainerNavigationData: BrandedContainerNavigationItem[] =
  [
    {
      icon: 'fa-align-left',
      label: 'Description',
      link: 'description',
      reselectBrand: false,
    },
    {
      icon: 'fa-address-card',
      label: 'Information',
      link: 'information',
      reselectBrand: true,
    },
    { icon: 'fa-images', label: 'Media', link: 'media', reselectBrand: false },
    {
      icon: 'fa-basketball-ball',
      label: 'Events',
      link: 'bc-events',
      reselectBrand: false,
    },
    {
      icon: 'fa-copyright',
      label: 'Branded Containers',
      link: 'branded-containers',
      reselectBrand: false,
    },
    {
      icon: 'fa-eye',
      label: 'Display Settings',
      link: 'display-settings',
      reselectBrand: true,
    },
    {
      icon: 'fa-comment-alt-dots',
      label: 'Comments',
      link: 'comments',
      reselectBrand: false,
    },
    {
      icon: 'fa-history',
      label: 'Last modified',
      link: 'last-modified',
      reselectBrand: true,
    },
  ];

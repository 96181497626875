import React from 'react';
import { useFiltersContext } from '../../../hooks';
import { Form } from '@ant-design/compatible';
import { Select } from 'antd';
import styled from 'styled-components';

const CustomSelect = styled(Select)`
  & .ant-select-selector {
    background-color: #f0f0f0 !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`;

interface TopicTypeMethodSelectProps {
  label: string;
  selectFieldName: string;
}

const TopicTypeMethodSelect: React.FC<TopicTypeMethodSelectProps> = ({
  label,
  selectFieldName,
}) => {
  const methods = [
    { label: 'Includes', value: 'includes' },
    { label: 'Exact', value: 'exact' },
  ];

  const { getFieldDecorator } = useFiltersContext();

  const decorator = getFieldDecorator(selectFieldName, {
    preserve: true,
    initialValue: methods[0].value,
  });

  return (
    <>
      {/*
    // @ts-ignore */}
      <Form.Item
        colon={false}
        label={label}
        className="compatible-row"
        style={{ minWidth: '100px' }}
      >
        {decorator(
          <CustomSelect>
            {methods.map((method) => (
              <Select.Option
                name={method.label}
                key={method.value}
                value={method.value}
              >
                {method.label}
              </Select.Option>
            ))}
          </CustomSelect>
        )}
      </Form.Item>
    </>
  );
};

export default TopicTypeMethodSelect;

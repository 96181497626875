import styled from 'styled-components';
import { Button } from 'antd';

export const SearchContainer = styled.div`
  padding: 15px 20px;

  i {
    color: #a4a4a4;
  }
`;

export const ButtonGroup = styled(Button.Group)`
  width: 100%;

  &.ant-btn-group button {
    width: 50%;
    border-radius: 0;
    border: none;
    background: #f3f3f3;

    &:first-child {
      border-right: 2px solid #e3e3e3;
    }
  }
`;

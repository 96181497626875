import { InputProps, InputRef } from 'antd/lib/input';
import {
  Artist,
  ArtistsByNameDocument,
  ArtistsByNameQueryResult,
  ArtistsByNameQueryVariables,
} from '../../../resolver.types';
import { LoadingOutlined } from '@ant-design/icons';
import { Input, message } from 'antd';
import React, {
  ChangeEventHandler,
  forwardRef,
  MutableRefObject,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { useLazyQuery } from '@apollo/react-hooks';
import usePrevious from '../../../hooks/use-previous';
import isEqual from 'lodash.isequal';

export type NameInputProps = InputProps & {
  onArtistsFoundChange?: (artists: Artist[]) => any;
  currentArtistID?: string;
  onLoadingChange?: (loading: boolean) => any;
};

const NameInput = forwardRef(
  (
    {
      onArtistsFoundChange,
      onChange,
      suffix,
      currentArtistID,
      onLoadingChange,
      ...inputProps
    }: NameInputProps,
    ref
  ) => {
    const inputRef = useRef<InputRef>();

    const [loading, setLoading] = useState(false);

    const [fetchArtists, { data, error }] = useLazyQuery<
      ArtistsByNameQueryResult['data'],
      ArtistsByNameQueryVariables
    >(ArtistsByNameDocument, {
      fetchPolicy: 'network-only',
    });

    /**
     * @note Changed to useMemo from useCallback
     */

    const debouncedFetchArtists = useMemo(
      () =>
        debounce((name: string) => {
          if (name) {
            setLoading(true);
            fetchArtists({
              variables: {
                name,
              },
            });
          } else {
            setArtists([]);
            setLoading(false);
          }
        }, 600),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const [artists, setArtists] = useState<Artist[]>([]);
    const previousArtists = usePrevious(artists);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      debouncedFetchArtists(event.target.value);

      setLoading(true);

      if (onChange) {
        onChange(event);
      }
    };

    useEffect(() => {
      if (data?.artistsByName) {
        const filteredArtists = currentArtistID
          ? data.artistsByName.items.filter(
              (artist) => (artist as Artist).id !== currentArtistID
            )
          : data.artistsByName.items;
        setArtists(filteredArtists as Artist[]);

        setLoading(false);
      }
    }, [currentArtistID, data]);

    useEffect(() => {
      if (onArtistsFoundChange && !isEqual(artists, previousArtists)) {
        onArtistsFoundChange(artists);
      }
    }, [artists, onArtistsFoundChange, previousArtists]);

    useEffect(() => {
      if (error) {
        message.error(error.message);
        setLoading(false);
      }
    }, [error]);

    useEffect(() => {
      if (onLoadingChange) {
        onLoadingChange(loading);
      }
    }, [loading, onLoadingChange]);

    useEffect(() => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(inputRef);
      } else {
        (ref as any).current = inputRef;
      }
    }, [inputRef, ref]);

    return (
      <Input
        autoComplete="off"
        suffix={loading ? <LoadingOutlined /> : suffix ? suffix : <span />}
        ref={inputRef as MutableRefObject<InputRef>}
        {...inputProps}
        onChange={handleChange}
      />
    );
  }
);

export default NameInput;

import { useLazyQuery } from '@apollo/react-hooks';
import { useMemo, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import {
  Artist,
  SearchByTopicQueryVariables,
  SearchByTopicQueryResult,
  SearchByTopicDocument,
  TopicData,
  Venue,
  Place,
} from '../../../resolver.types';
import unionWith from 'lodash.unionwith';
import isEqual from 'lodash.isequal';
import { getName as getArtistName } from '../../../utils/artist';
import { getName as getVenueName } from '../../../utils/venue';
import { getName as getPlaceName } from '../../../utils/place';
import { TopicFilter } from './types';
import usePrevious from '../../../hooks/use-previous';

type TopicDataTuple = [
  string,
  string | string[] | Artist[] | Venue[] | Place[]
];

export type TopicSuggestionsHookConfig = {
  search: string | null;
  brand?: string;
  requiredSearchLength?: number;
  value: TopicFilter[];
};

const ignoredKeys = ['artists', 'venue', 'place', '__typename'];

const isEntity = <T>(
  value: any,
  topicType: string,
  topicMatch: string
): value is T[] => {
  return topicType === topicMatch;
};

const hasSearchValue = (search: string) => {
  const hasSearchForEntity = <V extends string, K extends Record<V, unknown>>(
    valuesArray: K[],
    searchKey: V
  ) => {
    return Boolean(
      valuesArray.find((item) =>
        String(item[searchKey]).toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  return ([topicType, value]: TopicDataTuple): boolean => {
    if (isEntity<Artist>(value, topicType, 'artistsEntities')) {
      return hasSearchForEntity(value, 'name');
    }

    if (isEntity<Venue>(value, topicType, 'venueEntities')) {
      return hasSearchForEntity(value, 'name');
    }

    if (isEntity<Place>(value, topicType, 'placeEntities')) {
      return hasSearchForEntity(value, 'unique_city_name');
    }

    if (Array.isArray(value)) {
      return Boolean(
        value.find((val) => val.toLowerCase().includes(search.toLowerCase()))
      );
    }

    return value.toLowerCase().includes(search.toLowerCase());
  };
};

const createSuggestion = (search: string, dropdownValue: TopicFilter[]) => {
  const createEntitySuggestion = <
    V extends string,
    K extends Record<V, unknown>
  >({
    valuesArray,
    searchKey,
    nameGetter,
    originKey,
  }: {
    valuesArray: K[];
    searchKey: V;
    nameGetter: (entity: K) => string;
    originKey: keyof TopicData;
  }) => {
    return valuesArray
      .filter(
        (item) =>
          String(item[searchKey])
            .toLowerCase()
            .includes(search.toLowerCase()) &&
          !dropdownValue.find((val) => val.label === item[searchKey])
      )
      .map((entity) => {
        const name = nameGetter(entity);

        return {
          key: {
            value: entity[searchKey],
            type: originKey,
            label: name,
          },
          label: name,
          value: name,
        };
      });
  };

  return ([topicType, value]: TopicDataTuple): TopicFilter[] => {
    if (isEntity<Artist>(value, topicType, 'artistsEntities')) {
      return createEntitySuggestion({
        valuesArray: value,
        searchKey: 'name',
        originKey: 'artists',
        nameGetter: getArtistName,
      });
    }

    if (isEntity<Venue>(value, topicType, 'venueEntities')) {
      return createEntitySuggestion({
        valuesArray: value,
        searchKey: 'name',
        originKey: 'venue',
        nameGetter: getVenueName,
      });
    }

    if (isEntity<Place>(value, topicType, 'placeEntities')) {
      return createEntitySuggestion({
        valuesArray: value,
        searchKey: 'unique_city_name',
        originKey: 'place',
        nameGetter: getPlaceName,
      });
    }

    const valueToMap = Array.isArray(value) ? value : [value];

    return valueToMap
      .filter(
        (val) =>
          val.toLowerCase().includes(search.toLowerCase()) &&
          !dropdownValue.find((dropdownVal) => dropdownVal.label === val)
      )
      .map((val) => ({
        key: {
          value: val,
          type: topicType as keyof TopicData,
          label: val,
        },
        label: val,
        value: val,
      }));
  };
};

const useTopicSuggestions = ({
  search,
  brand,
  requiredSearchLength,
  value,
}: TopicSuggestionsHookConfig) => {
  const [fetchSuggestions, { data, loading: queryLoading }] = useLazyQuery<
    SearchByTopicQueryResult['data'],
    SearchByTopicQueryVariables
  >(SearchByTopicDocument, {
    fetchPolicy: 'no-cache',
  });

  const [choices, setChoices] = useState<TopicFilter[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchedPhrase, setSearchedPhrase] = useState('');
  const prevBrand = usePrevious(brand);

  /**
   * @note Changed to useMemo from useCallback
   */

  const getSuggestions = useMemo(
    () =>
      debounce((search: string | null) => {
        if (
          !search ||
          (requiredSearchLength && search.length < requiredSearchLength)
        ) {
          setChoices([]);
          setLoading(false);
          setSearchedPhrase('');

          return;
        }

        setSearchedPhrase(search);
        fetchSuggestions({
          variables: {
            search,
            brand,
          },
        });
      }, 500),
    [requiredSearchLength, brand, fetchSuggestions]
  );

  useEffect(() => {
    const items = data?.searchByTopic?.items ?? [];

    const suggestions: TopicFilter[] = items
      .map((container) => {
        const data = Object.entries(container!.topic.data) as TopicDataTuple[];

        return data
          .filter(
            ([topicType, value]) =>
              !ignoredKeys.includes(topicType) &&
              (Array.isArray(value) ? value.length > 0 : Boolean(value))
          )
          .filter(hasSearchValue(search!))
          .flatMap(createSuggestion(search!, value));
      })
      .flat()
      .filter(Boolean);

    setChoices(unionWith<TopicFilter>(suggestions, isEqual));
  }, [data, search, value]);

  useEffect(() => {
    setLoading(true);

    getSuggestions(search);
  }, [search, getSuggestions]);

  useEffect(() => {
    setLoading(queryLoading);
  }, [queryLoading]);

  useEffect(() => {
    if (searchedPhrase && searchedPhrase !== search) {
      setChoices([]);
    }
  }, [searchedPhrase, search]);

  useEffect(() => {
    if (prevBrand && brand) {
      setChoices([]);
      setSearchedPhrase('');
      setLoading(false);
    }
  }, [prevBrand, brand, search, getSuggestions]);

  return {
    loading,
    choices,
  };
};

export default useTopicSuggestions;

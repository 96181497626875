import React from 'react';
import styled from 'styled-components';
import { SpinLoader } from '../../common/spin-loader/spin-loader.component';
import {
  AuthContext,
  AuthStateStore,
  KeycloakLogginState,
} from '../../../context/auth.context';

const StyledSpinLoader = styled(SpinLoader)`
  height: calc(100vh);
`;

const generateMessage = (
  loginState: KeycloakLogginState
): string | undefined => {
  switch (loginState) {
    case 'loggedIn':
      return 'Loading data...';
    case 'loggingOut':
      return 'Logging out...';
    case 'loggedOut':
    default:
      return 'Logging in...';
  }
};

export const AuthInfoLoader = () => {
  const { state } = React.useContext(AuthContext) as AuthStateStore;

  return (
    <StyledSpinLoader loadingMessage={generateMessage(state.loginState)} />
  );
};

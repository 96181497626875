import styled from 'styled-components';

export const Warning = styled.div`
  .ant-alert-message {
    color: #bd6b26;
  }

  .ant-alert {
    background-color: #f5e5de;
    border: 1px solid #ecddd2;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Button, Upload } from 'antd';
import {
  BrandedContainerImageAspectRatio,
  File,
} from '../../../../resolver.types';
import { UploadImageWrapper } from './upload-image-wrapper.component';

type ReplaceMediaImageButtonProps = {
  onReplace: (file: File) => Promise<string | void>;
  confirmThumbnailGeneration?: (url: string) => Promise<void>;
  aspectRatio: BrandedContainerImageAspectRatio;
  disabled?: boolean;
  handleImageLoading?: (status: boolean) => void;
};

export const ReplaceMediaImageButton = ({
  onReplace,
  aspectRatio,
  disabled,
  handleImageLoading,
  confirmThumbnailGeneration,
}: ReplaceMediaImageButtonProps) => {
  return (
    <UploadImageWrapper
      onUpload={onReplace}
      confirmThumbnailGeneration={confirmThumbnailGeneration}
      aspectRatio={aspectRatio}
      disabled={disabled}
      handleImageLoading={handleImageLoading}
      uploadComponent={({ customRequest, onChange }) => (
        <Upload
          name="file"
          multiple
          customRequest={customRequest}
          onChange={onChange}
          accept="image/*"
          showUploadList={false}
          disabled={disabled}
        >
          <ReplaceButton>
            <span className="icon">
              <i className="fal fa-upload"></i>
            </span>
            Replace Image
          </ReplaceButton>
        </Upload>
      )}
    />
  );
};

const ReplaceButton = styled(Button)`
  &.ant-btn {
    padding: 0 5px;
    height: 28px;

    & .icon {
      margin-right: 5px;
      font-size: 14px;
    }
  }
`;

import * as React from 'react';
import { Select } from 'antd';
import { BrandStateSelect } from './state-switcher.styles';
import { useMutation, useLazyQuery, ApolloError } from '@apollo/client';
import {
  confirmStateChangeModal,
  stateChangeErrorModal,
  getBrandedContainerStates,
  stateChangeValidationErrorModal,
} from './helpers';
import {
  UpdateBrandedContainerStateDocument,
  GetBrandedContainerStateDocument,
  GetBrandedContainerDocument,
  BrandedContainerState,
} from '../../../resolver.types';
import { BrandedContainer } from '../../../resolver.types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { routes } from '../../../route-urls';
import { InputError } from 'yggdrasil-shared/domain/error';
import { validateBcData } from './validation-frontend-process';

export type StateSwitcherProps = {
  brandedContainerId?: string;
  brandedContainer?: BrandedContainer;
  refetchQueriesOnUpdate?: boolean;
  disabled?: boolean;
  onStateChange?: () => void;
};

export const StateSwitcher = ({
  brandedContainerId,
  brandedContainer,
  refetchQueriesOnUpdate = true,
  disabled = false,
  onStateChange,
}: StateSwitcherProps) => {
  const navigate = useNavigate();

  const [fetchBrandedContainer, { loading, data }] = useLazyQuery(
    GetBrandedContainerStateDocument,
    {
      variables: {
        id: brandedContainerId,
      },
    }
  );

  const [fetchBrandedContainerDocument] = useLazyQuery(
    GetBrandedContainerDocument,
    {
      variables: {
        id: brandedContainerId || brandedContainer!.id,
      },
      fetchPolicy: 'network-only',
    }
  );

  const [updateState] = useMutation(UpdateBrandedContainerStateDocument);
  const [state, setState] = useState<BrandedContainerState | null>(null);

  const getBrandedContainer = (): BrandedContainer =>
    data ? data.brandedContainer : brandedContainer;

  useEffect(() => {
    if (data && data.brandedContainer) {
      setState(data.brandedContainer.state);
    }
  }, [data]);

  useEffect(() => {
    if (brandedContainer) {
      setState(brandedContainer.state);
    } else {
      fetchBrandedContainer();
    }
  }, [brandedContainer, fetchBrandedContainer]);

  const states = useMemo(
    () => (state ? getBrandedContainerStates(state) : []),
    [state]
  );

  const onOk = (stateTarget: string) => async () => {
    const bc = getBrandedContainer();
    if (stateTarget === BrandedContainerState.Deleted) {
      navigate(routes.deleteBrandedContainer(bc.id, bc.version));
    } else {
      try {
        await updateState({
          variables: {
            input: {
              state: stateTarget,
              id: bc.id,
              version: bc.version,
            },
          },
          refetchQueries: refetchQueriesOnUpdate
            ? [
                {
                  query: GetBrandedContainerStateDocument,
                  variables: { id: bc.id },
                },
                {
                  query: GetBrandedContainerDocument,
                  variables: { id: bc.id },
                },
              ]
            : undefined,
          awaitRefetchQueries: true,
        });
        setState(stateTarget as BrandedContainerState);
        if (onStateChange) onStateChange();
      } catch (e) {
        const apolloError = e as ApolloError;
        const [error] = apolloError.graphQLErrors;
        stateChangeErrorModal(error as InputError);
      }
    }
  };

  const onChange = async (stateTarget: any) => {
    let emptyWarningFields: string[] = [];

    if (stateTarget === BrandedContainerState.Published) {
      const data = await fetchBrandedContainerDocument();
      const bcData = data.data.brandedContainer;
      const {
        emptyWarningFields: tempEmptyWarningFields,
        emptyValidationErrorFields,
      } = validateBcData(bcData);

      if (emptyValidationErrorFields.length > 0) {
        stateChangeValidationErrorModal({
          emptyValidationErrorFields,
        });

        return;
      }

      emptyWarningFields = tempEmptyWarningFields;
    }

    confirmStateChangeModal({
      state: state || getBrandedContainer().state,
      stateTarget: stateTarget,
      callback: onOk(stateTarget),
      emptyWarningFields: emptyWarningFields,
    });
  };

  const disableEventPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  return (
    <>
      {state && (
        <div onClick={disableEventPropagation} style={{ width: 'fit-content' }}>
          <BrandStateSelect
            dropdownMatchSelectWidth={false}
            disabled={
              loading ||
              getBrandedContainer().state === BrandedContainerState.Deleted ||
              disabled
            }
            onChange={onChange}
            value={state}
            optionLabelProp="children"
          >
            {states.map((state) => (
              <Select.Option key={state.value}>
                <span
                  className="option-icon"
                  style={{
                    marginRight: '10px',
                    color: state.color,
                  }}
                >
                  <i className={`fal ${state.iconClass}`}></i>
                </span>
                {state.value}
              </Select.Option>
            ))}
          </BrandStateSelect>
        </div>
      )}
    </>
  );
};

import React from 'react';
import styled from 'styled-components';
import {
  BrandedContainerImageAspectRatio,
  BrandedContainerImageCropBoundaryInput,
  File,
} from '../../../../resolver.types';
import { ImageCropper, RectShape } from './image-cropper.component';
import { PreviewCanvas } from './preview-canvas.component';
import { SelectAspectRatio } from './select-aspect-ratio.component';

type ImageCropContainerProps = {
  imgUrl: string;
  imgMimetype: string;
  selectedAspectRatio: BrandedContainerImageAspectRatio;
  onSelectAspectRatio: (aspectRatio: BrandedContainerImageAspectRatio) => void;
  onDelete?: () => Promise<void>;
  onReplace?: (file: File) => Promise<string | void>;
  confirmThumbnailGeneration?: (url: string) => Promise<void>;
  handleImageLoading?: (status: boolean) => void;
  onSelect?: (
    fileDataURL: string,
    cropShape: RectShape,
    mimetype: string
  ) => void;
  initialShape?: BrandedContainerImageCropBoundaryInput;
  disabled?: boolean;
  copyrightFormItem: React.ReactNode;
  isThumbnailGenerating: boolean;
  cropSupported: boolean;
};

export const ImageCropContainer = ({
  imgUrl,
  imgMimetype,
  selectedAspectRatio,
  onSelectAspectRatio,
  onDelete,
  onReplace,
  handleImageLoading,
  confirmThumbnailGeneration,
  onSelect: selectCallback,
  initialShape,
  disabled = false,
  copyrightFormItem,
  isThumbnailGenerating,
  cropSupported,
}: ImageCropContainerProps) => {
  const previewContextFlexibleRef = React.useRef<HTMLCanvasElement | null>(
    null
  );
  const previewContext280Ref = React.useRef<HTMLCanvasElement>(null);
  const previewContext150Ref = React.useRef<HTMLCanvasElement>(null);
  const previewContext75Ref = React.useRef<HTMLCanvasElement>(null);
  const previewContext50Ref = React.useRef<HTMLCanvasElement>(null);

  const [isCropEnabled, setIsCropEnabled] = React.useState(true);

  const setCropEnabled = React.useCallback(
    (cropEnabled: boolean) => {
      setIsCropEnabled(cropEnabled);
    },
    [setIsCropEnabled]
  );

  const selectedCrop = React.useRef(
    initialShape ?? {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }
  );

  const onSelect = () => {
    const fileDataURL = previewContextFlexibleRef.current!.toDataURL(
      'image/webp',
      0.8
    );

    if (selectCallback) {
      selectCallback(fileDataURL, selectedCrop.current, imgMimetype);
    }
  };

  const onCrop = React.useCallback((crop: RectShape) => {
    selectedCrop.current = crop;
  }, []);

  return (
    <StyledImageCropContainer>
      <ImageCropper
        initialShape={initialShape}
        imgUrl={imgUrl}
        flexibleCanvas={previewContextFlexibleRef}
        previewCanvases={[
          previewContext280Ref,
          previewContext150Ref,
          previewContext75Ref,
          previewContext50Ref,
        ]}
        selectedAspectRatio={selectedAspectRatio}
        onCrop={onCrop}
        setCropEnabled={setCropEnabled}
        disabled={disabled}
        cropSupported={cropSupported}
      />
      <SelectAspectRatio
        defaultAspectRatio={selectedAspectRatio}
        onChange={onSelectAspectRatio}
        onDelete={onDelete}
        onSelect={onSelect}
        onReplace={onReplace}
        confirmThumbnailGeneration={confirmThumbnailGeneration}
        handleImageLoading={handleImageLoading}
        disabled={disabled || !isCropEnabled || isThumbnailGenerating}
        cropSupported={cropSupported}
      />
      {copyrightFormItem}
      {cropSupported && (
        <>
          <span className="preview-title">Preview</span>
          <CropPreviewContainer>
            <PreviewCanvas
              canvasRef={previewContext280Ref}
              size={280}
              aspectRatio={selectedAspectRatio}
            />
            <PreviewCanvas
              canvasRef={previewContext150Ref}
              size={150}
              aspectRatio={selectedAspectRatio}
            />
            <PreviewCanvas
              canvasRef={previewContext75Ref}
              size={75}
              aspectRatio={selectedAspectRatio}
            />
            <PreviewCanvas
              canvasRef={previewContext50Ref}
              size={50}
              aspectRatio={selectedAspectRatio}
            />
          </CropPreviewContainer>
        </>
      )}
    </StyledImageCropContainer>
  );
};

const StyledImageCropContainer = styled.div`
  width: 640px;
`;

const CropPreviewContainer = styled.div`
  display: flex;
  align-items: flex-start;

  & > .preview-canvas {
    margin-left: 18px;
  }

  & > .preview-canvas:first-child {
    margin-left: 0;
  }
`;

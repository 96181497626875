import { useCallback, useEffect, useState } from 'react';
import { VersionMismatchError } from 'yggdrasil-shared/domain/error';

const useVersionMismatchError = () => {
  const [versionMismatchError, setVersionMismatchError] =
    useState<VersionMismatchError | null>(null);
  const removeMismatchError = useCallback(() => {
    setVersionMismatchError(null);
  }, []);

  const [mismatchErrorVisible, setMismatchErrorVisible] = useState(false);
  const toggleMismatchErrorVisible = useCallback(() => {
    setMismatchErrorVisible(!mismatchErrorVisible);
  }, [mismatchErrorVisible]);

  useEffect(() => {
    setMismatchErrorVisible(Boolean(versionMismatchError));
  }, [versionMismatchError]);

  return {
    versionMismatchError,
    setVersionMismatchError,
    mismatchErrorVisible,
    setMismatchErrorVisible,
    toggleMismatchErrorVisible,
    removeMismatchError,
  };
};

export default useVersionMismatchError;

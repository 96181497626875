import { BrandedContainerMissingContent } from '../../../../../resolver.types';

export type MissingContentLabelsMap = {
  [Key in BrandedContainerMissingContent]?: string;
};

const labelsMap: MissingContentLabelsMap = {
  [BrandedContainerMissingContent.EditorialLongTextTooOld]:
    'Editorial Long Text older than one year',
  [BrandedContainerMissingContent.EditorialShortTextTooOld]:
    'Editorial Short Text older than one year',
};

export default labelsMap;

import { Route, Routes, useNavigate, useParams } from 'react-router';
import React, { useCallback } from 'react';
import { routes } from '../../route-urls';
import DrawerWrapper from '../../components/branded-container/drawer-wrapper/drawer-wrapper';

const ContainerForArtist = () => {
  const navigate = useNavigate();
  const { artistID } = useParams();

  const handleClose = useCallback(() => {
    navigate(routes.brandedContainersForArtist(artistID!));
  }, [artistID, navigate]);

  return (
    <Routes>
      <Route path={`:id/*`} element={<DrawerWrapper onClose={handleClose} />} />
    </Routes>
  );
};

export default ContainerForArtist;

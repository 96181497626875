import { FilterInputProps } from '../../../types';
import React, { useEffect } from 'react';
import { useFiltersContext } from '../../../hooks';
import RangeInput from '../../../../common/range-input/range-input.component';

export type RangeValue = [number | undefined, number | undefined];
export type RangeProps = FilterInputProps<RangeValue> & {
  suffix?: string;
  defaultOpen?: boolean;
  onChange?: (value: RangeValue) => any;
  max?: number;
  separator?: string;
  onlyIntegers?: boolean;
  onlyAbsolute?: boolean;
  disabled?: boolean;
  tooltipText?: string;
};

export const Range = ({
  name,
  width,
  onChange,
  initialValue,
  ...props
}: RangeProps) => {
  const { getFieldValue, getFieldDecorator } = useFiltersContext();
  const value = (getFieldValue(name) ?? initialValue) as RangeValue;

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <>
      {getFieldDecorator(name, { initialValue, preserve: true })(
        <RangeInput {...props} />
      )}
    </>
  );
};

import { Except } from 'type-fest';
import { CreateArtistInput } from './create-artist';
import { Artist } from '../../types/artist';
import { UpdateArtistDocument } from '../../resolver.types';

export const UPDATE_ARTIST = UpdateArtistDocument;

export type UpdateArtistInput = Except<CreateArtistInput, 'issuer'> & {
  id: string;
};

export type UpdateArtistVariables = {
  input: UpdateArtistInput;
};

export type UpdateArtistResult = {
  updateArtist: Artist;
};

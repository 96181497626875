import * as React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Slider } from 'antd';
import {
  SliderContainer,
  TitleContainer,
} from '../../layout/drawer/title.styles';
import { TopicData } from '../../../resolver.types';
import { Tags } from '../../topic/tags/tags';

type BrandedContainerTitleProps = {
  brandedContainerTitle: string;
  onChangeRelevance: (relevance: number) => void;
  relevanceValue?: number;
  topic: TopicData;
  disabled?: boolean;
};

export const BrandedContainerTitle = ({
  brandedContainerTitle,
  relevanceValue = 1,
  onChangeRelevance,
  topic,
  disabled,
}: BrandedContainerTitleProps) => {
  const [relevance, setRelevance] = React.useState(relevanceValue);

  const onSliderChange = (value: any) => {
    setRelevance(value);
    onChangeRelevance(value);
  };

  return (
    <TitleContainer className="title">
      <div className="title-and-topic">
        <div
          style={{ marginRight: '10px' }}
          className="title branded-container-title"
        >
          {brandedContainerTitle}
        </div>
        <Tags topic={topic} limit={7} />
      </div>
      <div className="relevance">
        <Dropdown
          overlay={
            <SliderContainer>
              <Slider
                disabled={disabled}
                min={1}
                max={10}
                value={relevance}
                onChange={onSliderChange}
              />
            </SliderContainer>
          }
        >
          <Button className="relevance-button">
            Importance {relevance}/10 <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </TitleContainer>
  );
};

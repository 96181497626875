import { FilterInputProps } from '../../../types';
import React, { FC } from 'react';
import BaseTopicDropdown from '../../../../topic/topic-dropdown/topic-dropdown.component';
import { useFiltersContext } from '../../../hooks';
import { TopicFilter } from '../../../../topic/topic-dropdown/types';

export type TopicDropdownProps = FilterInputProps<TopicFilter[]>;

const defaultValue: TopicFilter[] = [];

export const TopicDropdown: FC<TopicDropdownProps> = ({
  initialValue = defaultValue,
  name,
  ...props
}) => {
  const { getFieldDecorator } = useFiltersContext();
  const decorator = getFieldDecorator(name, {
    initialValue,
  });

  return <>{decorator(<BaseTopicDropdown name={name} {...props} />)}</>;
};

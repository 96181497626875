import React, { useCallback } from 'react';
import { FormStepProps } from './shared';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { RegionRange } from '../region-range/region-range.component';
import styled from 'styled-components';
import { RegionSwitch } from '../region-switch/region-switch.component';
import { ArtistDropdown } from '../../artist/dropdown/dropdown.component';
import { VenueDropdown } from '../../venue/dropdown/dropdown.component';
import { PlaceDropdown } from '../../place/dropdown/dropdown.component';
import { GenresDropdown } from '../genres-dropdown/genres-dropdown.component';
import { BrandedContainerRegionFeature } from '../../../resolver.types';

export type InformationStepProps = FormStepProps & {
  topicArtists?: string[];
  topicVenues?: string[];
  topicPlaces?: string[];
  topicGenres?: string[];
  regionFeatures?: BrandedContainerRegionFeature[] | null;
  suggestedGenres?: string[];
};

export const InformationStep = ({
  values: {
    genres,
    isInternational,
    isRegional,
    artistIDs,
    venueIDs,
    placeIDs,
    regionRadius,
  },
  onChange,
  isFormDisabled = false,
  topicArtists = [],
  topicVenues = [],
  topicPlaces = [],
  topicGenres = [],
  regionFeatures,
  suggestedGenres = [],
}: InformationStepProps) => {
  const onSelect = (name: string) => (items: string | string[]) => {
    onChange({ target: { name, value: items } } as any);
  };

  const onRangeChange = useCallback(
    (value: number) => {
      onChange({
        target: {
          name: 'regionRadius',
          value: parseInt(value.toString(), 10),
        },
      } as any);
    },
    [onChange]
  );

  return (
    <React.Fragment>
      {/*
      // @ts-ignore */}
      <Form.Item label="Artists">
        <ArtistDropdown
          disabled={isFormDisabled}
          disabledIDs={topicArtists}
          onChange={onSelect('artistIDs')}
          value={artistIDs || []}
          closeOnSelect={false}
        />
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Genres">
        <GenresDropdown
          disabled={isFormDisabled}
          onChange={onSelect('genres')}
          value={genres ?? []}
          suggestedGenres={suggestedGenres}
          maxTagCount={5}
          fieldHeight="40px"
          disabledValues={topicGenres}
        />
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Venues">
        <VenueDropdown
          disabled={isFormDisabled}
          disabledIDs={topicVenues}
          onChange={onSelect('venueIDs')}
          value={venueIDs || []}
          closeOnSelect={false}
        />
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Places">
        <PlaceDropdown
          disabled={isFormDisabled}
          disabledIDs={topicPlaces}
          onChange={onSelect('placeIDs')}
          value={placeIDs || []}
          closeOnSelect={false}
        />
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Region">
        <RegionSelectContainer>
          <RegionSwitch
            label="Regional"
            value={Boolean(isRegional)}
            name="isRegional"
            onChange={onChange}
            disabled={isFormDisabled}
          />
          <RegionSwitch
            label="International"
            value={Boolean(isInternational)}
            name="isInternational"
            onChange={onChange}
            disabled={isFormDisabled}
          />
        </RegionSelectContainer>
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Region range">
        <RegionRange
          range={regionRadius ?? 30}
          disabled={isFormDisabled}
          onChange={onRangeChange}
          features={regionFeatures}
        />
      </Form.Item>
    </React.Fragment>
  );
};

const RegionSelectContainer = styled.div`
  display: flex;

  & > .switch-container {
    margin-left: 15px;
  }

  & > .switch-container:first-child {
    margin-left: 0;
  }
`;

import {
  ChoicesMapper,
  CityOrZipProps,
} from '../../../../common/city-or-zip/city-or-zip.component';
import React, { FC, useCallback } from 'react';
import { Except } from 'type-fest';
import CityOrZip from '../../common/city-or-zip/city-or-zip.component';

export type LocationProps = Except<CityOrZipProps, 'choicesMapper' | 'mode'>;

/**
 * @note "value" has been added - required by antd v4
 */

const EventCityOrZip: FC<LocationProps> = (props) => {
  const mapper: ChoicesMapper = useCallback(
    (locationData) => ({
      key: locationData.searchString.toString(),
      value: locationData.searchString.toString(),
      label: locationData.searchString.toString(),
    }),
    []
  );

  return (
    <CityOrZip
      maxTagWidth="110px"
      maxTagCount={1}
      {...props}
      mode="tags"
      choicesMapper={mapper}
    />
  );
};

export default EventCityOrZip;

import React, { useCallback, useState } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import {
  ButtonElement,
  ButtonsContainer,
} from './events-filter-buttons.styles';
import {
  FiltersButtons,
  FiltersButtonsContainer,
  FiltersWrapper,
} from '../../filters/filters.styles';
import { EventsFilters } from '../events-filters/events-filters.component';
import { isSuggestedEventsTotalOverMax } from '../suggested-events-count/suggested-events-count.component';
import {
  EventFiltersContext,
  EventFiltersStoreApi,
} from '../../../context/event-filters.context';
import usePrevious from '../../../hooks/use-previous';

type EventsFilterButtonsProps = {
  onApply: () => void;
  onReset: () => void;
  onActionClick: () => void;
  suggestedEventsTotal: number;
};

export const EventsFilterButtons = ({
  onApply,
  onActionClick,
  onReset,
  suggestedEventsTotal,
}: EventsFilterButtonsProps) => {
  const [showFilters, setShowFilters] = useState(false);
  const toggleShowAll = useCallback(() => {
    setShowFilters(!showFilters);
    onActionClick();
  }, [showFilters, onActionClick]);

  const {
    state: { filtersCount, isDirty, loading, isLock },
  } = React.useContext(EventFiltersContext) as EventFiltersStoreApi;

  const prevLoading = usePrevious(loading);

  React.useEffect(() => {
    if (!suggestedEventsTotal && prevLoading === undefined && !loading) {
      return setShowFilters(true);
    }

    if (suggestedEventsTotal && prevLoading && !loading) {
      return setShowFilters(false);
    }
  }, [loading, prevLoading, suggestedEventsTotal]);

  const buttons = (
    <>
      <Button
        className="reset-filter"
        onClick={onReset}
        disabled={loading || filtersCount === 0}
      >
        <CloseOutlined />
        <span>Reset filter</span>
      </Button>
      <Button
        className="save-filter"
        onClick={onApply}
        type="primary"
        disabled={
          isLock || !isDirty || loading || (filtersCount === 0 && loading)
        }
      >
        <CheckOutlined />
        <span>Save filter</span>
      </Button>
    </>
  );

  return (
    <>
      <ButtonsContainer className="events-filter-buttons">
        <FiltersWrapper className="filters-wrapper">
          <ButtonElement onClick={toggleShowAll} type="link">
            {showFilters ? (
              <UpOutlined style={{ fontSize: '12px' }} />
            ) : (
              <DownOutlined style={{ fontSize: '12px' }} />
            )}
            {showFilters ? 'Hide filter' : 'Show filter'}
          </ButtonElement>
        </FiltersWrapper>
      </ButtonsContainer>
      <EventsFilters
        open={showFilters}
        showSuggestedEventsInfo={isSuggestedEventsTotalOverMax(
          suggestedEventsTotal
        )}
      />
      <FiltersButtonsContainer>
        {showFilters && <FiltersButtons>{buttons}</FiltersButtons>}
      </FiltersButtonsContainer>
    </>
  );
};

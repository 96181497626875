import React from 'react';
import { Modal } from 'antd';
import { InputError } from 'yggdrasil-shared/domain/error';
import { BrandedContainerState } from '../../../resolver.types';
import { makeUrlClickable } from '../../common/links-manipulations/make-url-clickable';

export type State = {
  value: BrandedContainerState;
  iconClass: string;
  color: string;
};

export const stateData: State[] = [
  {
    value: BrandedContainerState.Draft,
    iconClass: 'fa-file-alt',
    color: '#8d8d8d',
  },
  {
    value: BrandedContainerState.Published,
    iconClass: 'fa-check-circle',
    color: '#44C474',
  },
  {
    value: BrandedContainerState.Archived,
    iconClass: 'fa-archive',
    color: '#8d8d8d',
  },
  {
    value: BrandedContainerState.Deleted,
    iconClass: 'fa-trash-alt',
    color: '#8d8d8d',
  },
];

const brandedContainerStatePossibilities: {
  [key: string]: BrandedContainerState[];
} = {
  draft: [
    BrandedContainerState.Draft,
    BrandedContainerState.Published,
    BrandedContainerState.Deleted,
  ],
  published: [
    BrandedContainerState.Published,
    BrandedContainerState.Archived,
    BrandedContainerState.Draft,
  ],
  archived: [
    BrandedContainerState.Draft,
    BrandedContainerState.Archived,
    BrandedContainerState.Deleted,
  ],
  deleted: [],
};

export const getBrandedContainerStates = (state: string) =>
  stateData.filter((s) =>
    brandedContainerStatePossibilities[state].includes(s.value)
  );

export const confirmStateChangeModal = ({
  state,
  stateTarget,
  callback,
  emptyWarningFields,
}: {
  state: string;
  stateTarget: string;
  callback: () => void;
  emptyWarningFields: string[];
}) => {
  const content = (
    <div>
      <p>
        Do you want to change branded container state from {state} to{' '}
        {stateTarget}?
      </p>

      {emptyWarningFields.length > 0 &&
        stateTarget === BrandedContainerState.Published && (
          <div>
            <p>Following fields are empty:</p>
            <ul>
              {emptyWarningFields.map((field, index) => (
                <li key={index}>{field}</li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );

  Modal.confirm({
    title: 'Branded Container state change.',
    content,
    onOk: callback,
    onCancel: () => {},
  });
};

export const stateChangeValidationErrorModal = ({
  emptyValidationErrorFields,
}: {
  emptyValidationErrorFields: string[];
}) => {
  const content = (
    <div>
      <p>Unable to publish branded container.</p>

      {emptyValidationErrorFields.length > 0 && (
        <div>
          <p>
            {`Following ${
              emptyValidationErrorFields.length > 1 ? 'fields' : 'field'
            } need${
              emptyValidationErrorFields.length > 1 ? '' : 's'
            } to be provided:`}
          </p>
          <ul>
            {emptyValidationErrorFields.map((field, index) => (
              <li key={index}>{field}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  Modal.error({
    title: 'Branded Container state change rejection.',
    content,
  });
};

export const stateChangeErrorModal = (error: InputError) =>
  Modal.error({
    title: 'Branded Container state change rejection.',
    content: (
      <>
        <p style={{ whiteSpace: 'pre-line' }}>
          {makeUrlClickable(error.message)}
        </p>
        {'details' in error && (
          <ul>
            {Object.values(error.details!).map(
              ({ message, context: { key } }: any) => (
                <li key={key}>{message}</li>
              )
            )}
          </ul>
        )}
      </>
    ),
  });

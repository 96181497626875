import React from 'react';
import { brandCustom } from 'yggdrasil-shared/domain/brand-custom';
import { AppContext, AppStoreApi } from '../../../context/app.context';
import {
  SourceButtonsContainer,
  ContentLabel,
} from './description-content-switcher.styles';

export type TextSource = 'press-text' | 'description' | 'meta' | 'translations';

type DescriptionContentSwitcherProps = {
  defaultValue?: TextSource;
  onChange: (source: TextSource) => void;
};

export const DescriptionContentSwitcher = ({
  defaultValue = 'description',
  onChange: cb,
}: DescriptionContentSwitcherProps) => {
  const {
    state: { activeBrandTab },
  } = React.useContext(AppContext) as AppStoreApi;

  const [value, setValue] = React.useState<TextSource>(defaultValue);

  const onChange = ({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValue(value as TextSource);
      cb(value as TextSource);
    }
  };

  return (
    <SourceButtonsContainer className="text-source-buttons">
      <ContentLabel>
        <span className="label-text">Editorial Text</span>
        <input
          type="radio"
          onChange={onChange}
          checked={value === 'description'}
          value="description"
          name="text-source"
        />
        <span className="active-border"></span>
      </ContentLabel>
      <ContentLabel>
        <span className="label-text">Press Text</span>
        <input
          type="radio"
          name="text-source"
          value="press-text"
          onChange={onChange}
          checked={value === 'press-text'}
        />
        <span className="active-border"></span>
      </ContentLabel>
      {brandCustom.isMetaEnabled(activeBrandTab) && (
        <ContentLabel>
          <span className="label-text">Meta Text</span>
          <input
            type="radio"
            name="text-source"
            value="meta"
            onChange={onChange}
            checked={value === 'meta'}
          />
          <span className="active-border"></span>
        </ContentLabel>
      )}
      {brandCustom.isTranslationsEnabled(activeBrandTab) && (
        <ContentLabel>
          <span className="label-text">Translations</span>
          <input
            type="radio"
            name="text-source"
            value="translations"
            onChange={onChange}
            checked={value === 'translations'}
          />
          <span className="active-border"></span>
        </ContentLabel>
      )}
    </SourceButtonsContainer>
  );
};

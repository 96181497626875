import styled from 'styled-components';

export const StyledLastModifiedList = styled.div`
  & .last-modified-item {
    margin-top: 15px;
  }

  & .no-activity {
    font-size: 11px;
  }
`;

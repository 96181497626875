import { FormStepProps } from '../shared/types';
import React, { useCallback } from 'react';
import { defaultBrandedContainersColumns } from '../../../../hooks/use-related-branded-containers-columns';
import { DataList } from '../../../common/data-list/data-list.component';
import {
  BrandedContainer,
  GetBrandedContainersDocument,
} from '../../../../resolver.types';
import { routes } from '../../../../route-urls';
import { Routes, Route, useNavigate } from 'react-router';
import BrandedContainerDetailsDrawer from '../../../branded-container/form-steps/branded-containers-step/branded-containers-details-drawer.component';

const BrandedContainersStep = ({ event, disabled }: FormStepProps) => {
  const navigate = useNavigate();

  const queryVars = React.useMemo(
    () => ({
      filters: {
        eventIDs: [event.id],
      },
    }),
    [event]
  );

  const closeDetailsOpen = useCallback(() => {
    navigate(routes.setEventData(event.id, 'branded-containers'));
  }, [event.id, navigate]);

  const goToDetailView = React.useCallback(
    (relatedBC: BrandedContainer) => {
      navigate(routes.eventRelatedBcsDetailView(event.id, relatedBC.id));
    },
    [event.id, navigate]
  );

  const openBrandedContainerDetails = React.useCallback(
    (record: BrandedContainer) => {
      goToDetailView(record);
    },
    [goToDetailView]
  );

  const columns = defaultBrandedContainersColumns({
    onClick: openBrandedContainerDetails,
    disabled,
    showBrand: true,
  });

  return (
    <>
      <Routes>
        <Route
          path={`:id/*`}
          element={
            <BrandedContainerDetailsDrawer
              handleClose={closeDetailsOpen}
              width={960}
              noActions
            />
          }
        />
      </Routes>
      <DataList
        queryVars={queryVars}
        fetchPolicy="cache-first"
        columns={columns}
        query={GetBrandedContainersDocument}
        dataType="event_related_branded_containers"
      />
    </>
  );
};

export default BrandedContainersStep;

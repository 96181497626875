import * as React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button } from 'antd';
import { StyledMenuLink, StyledMenu } from './topbar-new-button.styles';
import { routes } from '../../../../route-urls';

export const TopbarNewButton = () => {
  const menu = (
    <StyledMenu>
      <Menu.Item key="menu-item-0">
        <StyledMenuLink to="/branded-container/select-promotion-items">
          Branded Container
        </StyledMenuLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="menu-item-1">
        <StyledMenuLink to={`/artists/${routes.artistForm()}`}>
          Artist
        </StyledMenuLink>
      </Menu.Item>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <Button type="primary" icon={<PlusOutlined />}>
        New
      </Button>
    </Dropdown>
  );
};

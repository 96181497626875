import styled from 'styled-components';
import { Select } from 'antd';

export const StyledDropdown = styled(Select).attrs(
  ({ $color }: { $color: string }) => ({
    $color,
  })
)`
  &.ant-select {
    width: 52px;
    font-size: 11px;
  }

  & .ant-select-selection--single
    background-color: ${(props) => props.$color};
    color: #ffffff;
    border: none;
    height: 25px;
  }

  & .ant-select-selector {
    background-color: ${(props) => props.$color} !important;
    color: #ffffff !important;
    border: none !important;
    height: 25px !important;
    padding: 0 7px !important;
  }

  & .ant-select-selection-selected-value {
    line-height: 25px;
  }

  & .ant-select-selection-item {
    line-height: 25px !important;
    padding-right: 0px !important;
  }

  & .ant-select-arrow {
    right: 7px;
    font-size: 10px;
    color: #ffffff;
  }
`;

import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > .title-and-topic {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 4px;

    & > .title {
      font-size: 15px;
      overflow: hidden;
    }

    & > .tag {
      margin-left: 8px;
    }
  }

  & > .relevance {
    user-select: none;

    & > button {
      border: none;
      background: inherit;
      font-size: 11px;
      outline: none;
      box-shadow: none;
      margin: 0;
      padding: 0;
      height: 11px;
    }
  }

  .sub-title {
    font-size: 12px;
    font-weight: 200;
  }
`;

export const SliderContainer = styled.div`
  width: 200px;
  padding: 2px 10px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 4px 12px 0 rgba(178, 178, 178, 0.5);

  & > .ant-slider {
    width: calc(100% - 10px);
  }
`;

export const TopicTypeTag = styled.div`
  background: linear-gradient(270deg, #fe9350 0%, #ff645a 100%);
  padding: 2px 12px;
  font-size: 10px;
  color: #fff;
  border-radius: 15px;
  font-weight: 500;
  user-select: none;
`;

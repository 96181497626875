import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Badge, Button, Checkbox, Input, Select } from 'antd';
import { BaseOptionType, LabeledValue, OptionProps } from 'antd/lib/select';
import React, { ClassicComponentClass, ReactNode } from 'react';
import { Highlight } from '../../../../common/highlight/hightlight.component';
import { ResetButton } from '../../../common/reset-button/reset-button.component';
import { FilterInputProps } from '../../../types';
import { MultiSelectHookConfig, useMultiSelect } from './hooks';
import { ButtonGroup, SearchContainer } from './multiselect.styles';

import { StyledSelect } from '../../../../common/search-results/search-results.styles';

export type MultiSelectProps = FilterInputProps<LabeledValue[]> & {
  choices: LabeledValue[];
  perPage?: number;
  defaultOpen?: MultiSelectHookConfig['defaultOpen'];
  onOpenChange?: MultiSelectHookConfig['onOpenChange'];
  showSearch?: boolean;
  showReset?: boolean;
  allowClear?: boolean;
  showSelectedOnBottom?: boolean;
  maxTagCount?: number;
  maxTagTextLength?: number;
  onChange?: MultiSelectHookConfig['onChange'];
  onSelectChange?: (
    value: any,
    option: BaseOptionType | BaseOptionType[]
  ) => void;
  maxTagWidth?: string;
};

// Workaround for Typescript invalid treating of "label" props
const Option = Select.Option as unknown as ClassicComponentClass<
  OptionProps & {
    label?: ReactNode;
  }
>;

export const Multiselect = ({
  name,
  initialValue,
  choices,
  placeholder,
  width,
  label,
  perPage = 5,
  defaultOpen = false,
  onOpenChange,
  showSearch = true,
  showReset = true,
  allowClear = false,
  showSelectedOnBottom = false,
  maxTagCount = 2,
  maxTagTextLength,
  onChange,
  onSelectChange,
  maxTagWidth,
}: MultiSelectProps) => {
  const {
    selectedChoices,
    handleDeselect,
    setContainerRef,
    onInputClick,
    onInputChange,
    decorator,
    pagination,
    open,
    toggleOpen,
    value,
    search,
    resetValue,
    selectRef,
    showResetButton,
  } = useMultiSelect({
    name,
    initialValue,
    perPage,
    choices,
    defaultOpen,
    onOpenChange,
    showSelectedOnBottom,
    onChange,
  });
  const {
    pagedItems,
    maxPages,
    isDisabled: isPaginationDisabled,
    nextPage,
    page,
    prevPage,
  } = pagination;

  return (
    <>
      {/*
      // @ts-ignore */}
      <Form.Item
        colon={false}
        label={label}
        className="multiselect compatible-row"
        style={{ width, minWidth: '100px' }}
      >
        {showReset && showResetButton && <ResetButton onClick={resetValue} />}
        <span
          className="multiselect-wrapper"
          ref={setContainerRef}
          onClick={toggleOpen}
        >
          {decorator(
            <StyledSelect
              $maxTagWidth={maxTagWidth}
              $antSelectorStyle="border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important;"
              onChange={onSelectChange}
              allowClear={allowClear}
              labelInValue
              optionLabelProp="label"
              ref={selectRef}
              open={open}
              /**
               * @note Disabled due to causing breaking change on antd v4
               */
              // @ts-ignore
              // onDropdownVisibleChange={setOpen}
              dropdownMatchSelectWidth={false}
              showArrow
              notFoundContent={value.length ? '' : 'Nothing found.'}
              /**
               * @note Disabled getInputElement which is restricted for prod in antd v4
               */
              // @ts-ignore
              // getInputElement={() => (
              //   <Input
              //     style={{
              //       border: 'none',
              //       boxShadow: 'none',
              //       background: 'transparent',
              //     }}
              //     readOnly
              //   />
              // )}
              maxTagCount={maxTagCount}
              maxTagTextLength={maxTagTextLength}
              dropdownRender={(menu) => (
                <>
                  {showSearch && (
                    <SearchContainer>
                      <Input
                        allowClear
                        className="search-input"
                        onClick={onInputClick}
                        value={search}
                        onChange={onInputChange}
                        placeholder="Search..."
                        suffix={<SearchOutlined />}
                      />
                    </SearchContainer>
                  )}
                  {menu}
                  {!isPaginationDisabled && (
                    <ButtonGroup>
                      <Button
                        onClick={prevPage}
                        disabled={isPaginationDisabled || page <= 1}
                      >
                        <ArrowLeftOutlined />
                      </Button>
                      <Button
                        onClick={nextPage}
                        disabled={isPaginationDisabled || page >= maxPages}
                      >
                        <ArrowRightOutlined />
                      </Button>
                    </ButtonGroup>
                  )}
                  <ul className="ant-select-dropdown-menu">
                    {showSelectedOnBottom &&
                      selectedChoices.map((choice) => (
                        <li
                          onClick={handleDeselect(choice)}
                          key={choice.key}
                          className="ant-select-dropdown-menu-item"
                        >
                          <Checkbox
                            checked={Boolean(
                              value.find((val) => val.key === choice.key)
                            )}
                          />
                          <span style={{ marginLeft: '10px' }}>
                            <Highlight
                              text={choice.label as string}
                              highlight={search}
                            />
                          </span>
                        </li>
                      ))}
                  </ul>
                </>
              )}
              dropdownClassName={`no-checks styled-dropdown ${
                open ? 'dropdown-open' : ''
              } ${showSearch ? 'has-custom-search' : ''}`}
              className={`detailed-tags ${value.length ? 'has-value' : ''}${
                maxTagCount && value.length - maxTagCount >= 10
                  ? ' more-than-10'
                  : ' less-than-10'
              }`}
              mode="multiple"
              placeholder={
                <>
                  <span className="placeholder-text">{placeholder}</span>
                  <Badge className="badge" count={value.length} />
                </>
              }
            >
              {pagedItems.map((choice) => (
                <Option
                  key={choice.key}
                  className="multiselect__option"
                  label={choice.label}
                  value={choice.key}
                >
                  <Checkbox
                    checked={Boolean(
                      value.find((val) => val.key === choice.key)
                    )}
                  />
                  <span style={{ marginLeft: '10px' }}>
                    <Highlight
                      text={choice.label as string}
                      highlight={search}
                    />
                  </span>
                </Option>
              ))}
            </StyledSelect>
          )}
        </span>
      </Form.Item>
    </>
  );
};

import * as React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakRole } from 'yggdrasil-shared/domain/auth';
import { isUserAuthorizedToPerformAction } from '../../../context/keycloak.context';

import { useCallback } from 'react';
import browserHistory from '../../../utils/history';

type KeycloakRoleWrapperProps = {
  children: React.ReactNode;
};

export const KeycloakRoleWrapper = ({ children }: KeycloakRoleWrapperProps) => {
  const { keycloak } = useKeycloak();

  const isAuthenticated = useCallback(() => {
    return isUserAuthorizedToPerformAction(keycloak, [
      KeycloakRole.CONTENT_MANAGER,
    ]);
  }, [keycloak]);

  if (!isAuthenticated()) {
    browserHistory.replace('/');
    /**
     * @todo a neat pop-up must be displayed with message provided in ticket (CP-749)
     */
    return (
      <span>
        To access the content system, you need additional permission. For more
        information please send a request via{' '}
        <a href="https://reservix.atlassian.net/servicedesk/customer/portal/9/group/14/create/10240?summary=Content-System">
          Service Center
        </a>
      </span>
    );
  }

  return <>{children}</>;
};

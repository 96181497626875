import styled from 'styled-components';

export const NoResultsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  .none-text {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25);
  }
  button {
    margin-bottom: 15px;
  }
`;

export const hasErrors = (
  errors: Record<string, string[] | undefined>
): boolean => {
  const mappedErrors = Object.values(errors)
    .map((error) => {
      if (!error) {
        return null;
      }

      return Object.values(error).filter((err) => err);
    })
    .filter((fieldErr) => fieldErr);

  return mappedErrors.length > 0;
};

import styled from 'styled-components';

export type ContainerProps = {
  size?: string;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${({ size = '43px' }) => size};
  height: ${({ size = '43px' }) => size};
  min-width: ${({ size = '43px' }) => size};

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
    object-fit: cover;
  }

  & > .brand-tag {
    position: absolute;
    bottom: -6px;
    left: 8px;
    cursor: default;
  }
`;

export const ThumbnailItem = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 100%;
    border-radius: 4px;
  }
`;

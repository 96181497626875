import React, { Ref } from 'react';
import { GenresDocument } from '../../../resolver.types';
import { AsyncDropdown } from '../../common/async-dropdown/async-dropdown.component';
import type { RefSelectProps } from 'antd';

type GenresDropdownProps = {
  onChange?: (value: any) => void;
  value?: string | string[];
  disabled?: boolean;
  placeholder?: string;
  styledSelection?: boolean;
  showCheckbox?: boolean;
  selectRef?: Ref<RefSelectProps>;
  showClear?: boolean;
  suggestedGenres?: string[] | null;
  maxHeight?: number;
  maxTagCount?: number;
  maxTagWidth?: string;
  width?: string;
  fieldHeight?: string;
  disabledValues?: string[];
};

export const GenresDropdown = ({
  onChange,
  value = [],
  disabled,
  placeholder = 'Please select genres...',
  styledSelection,
  showCheckbox,
  selectRef,
  showClear,
  suggestedGenres,
  maxHeight,
  maxTagCount,
  maxTagWidth,
  width,
  fieldHeight,
  disabledValues,
}: GenresDropdownProps) => {
  return (
    <AsyncDropdown
      disabled={disabled}
      query={GenresDocument}
      value={Array.isArray(value) ? value : [value]}
      onChange={onChange}
      placeholder={placeholder}
      multiple
      showClear={showClear}
      styledSelection={styledSelection}
      showCheckbox={showCheckbox}
      myRef={selectRef}
      suggestedData={suggestedGenres ?? []}
      maxHeight={maxHeight}
      maxTagCount={maxTagCount || 1}
      maxTagWidth={maxTagWidth || '110px'}
      width={width}
      fieldHeight={fieldHeight}
      disabledValues={disabledValues}
    />
  );
};

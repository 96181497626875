import React from 'react';
import { Switch } from 'antd';
import { SwitchContainer } from './region-switch.styles';

type RegionSwitchProps = {
  name: string;
  value: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
};

export const RegionSwitch = ({
  name,
  value,
  onChange,
  label,
  disabled = false,
}: RegionSwitchProps) => {
  const onSwitch = (value: boolean) => {
    onChange({ target: { name, value } } as any);
  };

  return (
    <SwitchContainer className="switch-container">
      <Switch
        size="small"
        checked={value}
        onChange={onSwitch}
        disabled={disabled}
      />
      <span className="switch-label">{label}</span>
    </SwitchContainer>
  );
};

import { FieldDecoratorResult } from '../../../types/antd';
import { CreateArtistInput } from '../../../graphql/mutations/create-artist';
import { Form } from '../../../hooks/use-form';

export type CreateFormDecorators = {
  name: FieldDecoratorResult;
  birthday: FieldDecoratorResult;
  country: FieldDecoratorResult;
  genres: FieldDecoratorResult;
};

export const getDecorators = (
  form: Form<CreateArtistInput>
): CreateFormDecorators => {
  const name = form.getFieldDecorator<CreateArtistInput>('name', {
    rules: [
      {
        required: true,
        message: 'Provide artist name',
      },
    ],
  });
  const birthday = form.getFieldDecorator<CreateArtistInput>('dateOfBirth', {
    rules: [
      {
        type: 'date',
        message: 'Provide valid birthday date.',
      },
    ],
  });
  const country = form.getFieldDecorator<CreateArtistInput>('countryOfOrigin');
  const genres = form.getFieldDecorator<CreateArtistInput>('genres');

  return {
    birthday,
    country,
    genres,
    name,
  };
};

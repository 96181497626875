import * as React from 'react';
import { ComponentType, useCallback, useEffect, useRef, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  SetPromotionItemContainer,
  SetPromotionItemModalHeader,
} from './set-promotion-item.styles';
import { TextInput } from '../../layout/content/text-input.styles';
import { StyledSelect } from '../../layout/content/select.styles';
import { routes } from '../../../route-urls';
import { useNavigate } from 'react-router-dom';
import { ModalCtaButton, StyledModal } from '../../layout/modal/modal.styles';
import { ArtistDropdown } from '../../artist/dropdown/dropdown.component';
import { VenueDropdown } from '../../venue/dropdown/dropdown.component';
import { PlaceDropdown } from '../../place/dropdown/dropdown.component';
import { StyledForm } from '../../branded-container/update-data-form/update-data-form.styles';
import { GenresDropdown } from '../../branded-container/genres-dropdown/genres-dropdown.component';
import { SimilarBrandedContainersModal } from '../similar-branded-containers/similar-branded-containers-modal.component';
import { TopicData } from '../../../pages/create-branded-container-from-topic/create-branded-container-from-topic.page';
import {
  BrandedContainer,
  GetManyByTopicQueryVariables,
} from 'yggdrasil-shared/domain/private-backend-api';
import { useLazyQuery } from '@apollo/client';
import {
  GetManyByTopicDocument,
  GetManyByTopicQueryResult,
} from '../../../resolver.types';

export type PromotionItemDetails = {
  imageUrl: string;
  value: string | string[];
  name: string;
  label: string;
  placeholder: string;
};

export type SetPromotionItemModalProps = {
  onNext: (props: { value: string | string[] }) => TopicData;
  onGoBack: () => void;
  onTopicDataSet: (topicData: TopicData) => void;
  promotionItem: PromotionItemDetails;
  promotionItemsAmount: number;
  isLoading: boolean;
};

export type PromotionItemProps = {
  onToggle?: () => any;
  onChange: (value: string | string[]) => any;
  value: string | string[];
};

const promotionItemMap: Record<string, ComponentType<PromotionItemProps>> = {
  artists: ArtistDropdown,
  genres: GenresDropdown,
  venue: VenueDropdown,
  place: PlaceDropdown,
};

export const SetPromotionItemModal = ({
  onNext,
  onTopicDataSet,
  onGoBack,
  promotionItem,
  promotionItemsAmount,
  isLoading,
}: SetPromotionItemModalProps) => {
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(true);
  const [
    isSimilarBrandedContainersModalVisible,
    setIsSimilarBrandedContainersModalVisible,
  ] = useState(false);

  useEffect(() => {
    setValue(promotionItem.value);
  }, [promotionItem.name, promotionItem.value]);

  const toggleModal = useCallback(() => {
    /**
     * @note There was a really nasty after-migration bug here!
     * Always use function argument in setState function if you want to rely on the previous state value:
     *
     * setState((prevValue) => !prevValue)
     *
     * instead of
     *
     * setState(!state)
     *
     */
    setModalVisible((prevModalVisibleValue) => !prevModalVisibleValue);
  }, [setModalVisible]);

  if (!promotionItem) {
    navigate(routes.brandedContainers());
  }

  const {
    imageUrl,
    value: defaultValue,
    name,
    label,
    placeholder,
  } = promotionItem;

  const [value, setValue] = useState<string | string[]>(defaultValue);
  const [topicData, setTopicData] = useState<TopicData>();

  const promotionItemsAmountCheckRef = useRef<number>(promotionItemsAmount);

  useEffect(() => {
    setValue(defaultValue);
  }, [name, defaultValue]);

  const onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(value);
  };

  const onSelect = (value: any) => {
    setValue(value);
  };

  const [similarBrandedContainers, setSimilarBrandedContainers] = useState<
    BrandedContainer[]
  >([]);

  const [fetchBrandedContainers] = useLazyQuery<
    GetManyByTopicQueryResult['data'],
    GetManyByTopicQueryVariables
  >(GetManyByTopicDocument, {
    fetchPolicy: 'network-only',
  });

  const fetchAndFilterBrandedContainers = async (
    topicData: TopicData,
    newCount: number,
    onTopicDataSet: (topicData: TopicData) => void
  ) => {
    const { data } = await fetchBrandedContainers({
      variables: { pagination: { start: 0, limit: 100 }, topic: topicData },
    });
    const filteredItems = data?.getManyByTopic?.items?.filter(
      (item): item is BrandedContainer => item !== null && item !== undefined
    );

    if (newCount > 0) {
      return null;
    }

    if (!filteredItems?.length) {
      onTopicDataSet(topicData);
      return null;
    }

    return filteredItems;
  };

  const onClick = async () => {
    const newCount = promotionItemsAmountCheckRef.current - 1;
    const topicData = onNext({ value });
    setTopicData(topicData);

    const filteredItems = await fetchAndFilterBrandedContainers(
      topicData,
      newCount,
      onTopicDataSet
    );

    promotionItemsAmountCheckRef.current = newCount;

    if (filteredItems === null) {
      return;
    }

    setSimilarBrandedContainers(filteredItems);
    setModalVisible(false);
    setIsSimilarBrandedContainersModalVisible(true);
  };

  const onClickBack = () => {
    promotionItemsAmountCheckRef.current += 1;
    onGoBack();
  };

  const onCancel = () => {
    navigate(routes.brandedContainers());
  };

  const handleModalGoBack = () => {
    promotionItemsAmountCheckRef.current += 1;
    setIsSimilarBrandedContainersModalVisible(false);
    setModalVisible(true);
  };

  const renderCustomPromotionItem = useCallback(() => {
    switch (name) {
      case 'artists':
        return (
          <ArtistDropdown
            value={value}
            onChange={setValue}
            onToggle={toggleModal}
            autoFocus={true}
          />
        );

      case 'genres':
        return (
          <GenresDropdown
            value={value}
            onChange={onSelect}
            maxHeight={200}
            fieldHeight="40px"
          />
        );
      case 'venue':
        return (
          <VenueDropdown value={value} onChange={setValue} autoFocus={true} />
        );
      case 'place':
        return (
          <PlaceDropdown value={value} onChange={setValue} autoFocus={true} />
        );

      default:
        return null;
    }
  }, [name, toggleModal, value]);

  return (
    <>
      <StyledModal
        maskClosable={false}
        visible={modalVisible}
        onCancel={onCancel}
        footer={
          <ModalCtaButton
            type="primary"
            onClick={onClick}
            loading={isLoading}
            disabled={
              Array.isArray(value)
                ? value.length === 0
                : value.trim().length === 0
            }
          >
            Next
          </ModalCtaButton>
        }
        title={
          <SetPromotionItemModalHeader>
            <ArrowLeftOutlined onClick={onClickBack} />
            {label}
          </SetPromotionItemModalHeader>
        }
      >
        <StyledForm>
          <SetPromotionItemContainer>
            <img src={imageUrl} alt={label} />
            {renderCustomPromotionItem()}
            {!promotionItemMap[name] && (
              <>
                {Array.isArray(value) ? (
                  <StyledSelect
                    placeholder={placeholder}
                    mode="tags"
                    style={{ width: '100%' }}
                    tokenSeparators={[',']}
                    onChange={onSelect}
                    value={value}
                  />
                ) : (
                  <TextInput
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                  />
                )}
              </>
            )}
          </SetPromotionItemContainer>
        </StyledForm>
      </StyledModal>
      <SimilarBrandedContainersModal
        onClick={onClick}
        onTopicDataSet={onTopicDataSet}
        topicData={topicData}
        onCancel={onCancel}
        visible={isSimilarBrandedContainersModalVisible}
        onGoBack={handleModalGoBack}
        similarBrandedContainers={similarBrandedContainers}
      />
    </>
  );
};

import React from 'react';
import styled from 'styled-components';
import { RawPrice } from 'yggdrasil-shared/utils/common';
import { mapCurrencySymbol } from '../../../utils/event';

export type PriceProps = {
  minPrices?: RawPrice[];
  maxPrices?: RawPrice[];
  className?: string;
  smallMode?: boolean;
};

const Container = styled.div.attrs(({ smallMode }: { smallMode: boolean }) => ({
  smallMode,
}))`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  min-width: ${(props) => (props.smallMode ? '120px' : '150px')};
  font-size: ${(props) => (props.smallMode ? '12px' : 'inherit')};

  .price-container > div {
    display: flex;
    align-items: baseline;
  }
`;

const Prices = ({
  minPrices,
  maxPrices,
  className = '',
  smallMode,
}: PriceProps) => {
  return (
    <Container smallMode={smallMode} className={`price-container ${className}`}>
      {minPrices?.length && maxPrices?.length ? (
        <>
          <div>
            <span>
              Highest: {maxPrices[0].value.toFixed(2)}{' '}
              {mapCurrencySymbol(maxPrices[0].currency)}
            </span>
          </div>
          <div>
            <small>
              Lowest: {minPrices[0].value.toFixed(2)}{' '}
              {mapCurrencySymbol(minPrices[0].currency)}
            </small>
          </div>
        </>
      ) : (
        <div>No data</div>
      )}
    </Container>
  );
};

export default Prices;

import React from 'react';
import Highlighter from 'react-highlight-words';

export type HighlightProps = {
  text: string;
  highlight: string | string[];
  className?: string;
};

export const Highlight = ({ text, highlight, className }: HighlightProps) => {
  return (
    <Highlighter
      className={className}
      highlightClassName="text--primary highlight"
      searchWords={Array.isArray(highlight) ? highlight : [highlight]}
      textToHighlight={text}
      autoEscape={true}
    />
  );
};

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  BrandedContainer,
  GetBrandedContainersDocument,
} from '../../../../../resolver.types';
import { Col, Modal } from 'antd';
import { DataList } from '../../../../common/data-list/data-list.component';
import { TopicDropdown } from '../../../../filters/inputs/branded-container/topic-dropdown/topic-dropdown.component';
import {
  allBrandedContainersColumns,
  defaultBrandedContainersColumns,
} from '../../../../../hooks/use-related-branded-containers-columns';
import { noop } from '../../../../../utils/text';
import { PlusOutlined } from '@ant-design/icons';
import { QueryParams } from '../../../../common/data-list/types';
import { FilterValues } from '../../../../filters/types';

type ViewProps = {
  loading: boolean;
  onOk: (brandedContainers: BrandedContainer[]) => Promise<void>;
  parseQuery: (
    queryVars: QueryParams<FilterValues>
  ) => QueryParams<FilterValues>;
  omitBrandedContainersIDs?: string[];
  relatedBrandedContainerIDs: string[];
  brandedContainerId: string;
  openDetails: (brandedContainer: BrandedContainer) => void;
  disabled?: boolean;
};

export const AllBrandedContainersList = ({
  loading,
  onOk,
  parseQuery,
  omitBrandedContainersIDs = [],
  relatedBrandedContainerIDs,
  brandedContainerId,
  openDetails,
  disabled,
}: ViewProps) => {
  const relatedBrandedContainersColumns = !disabled
    ? allBrandedContainersColumns({
        assignBrandedContainers: onOk,
        loading,
        onClick: openDetails,
        disabled,
        showBrand: false,
      })
    : defaultBrandedContainersColumns({
        onClick: undefined,
        disabled,
        showBrand: false,
      });

  return (
    <DataList<BrandedContainer>
      filters={
        !disabled
          ? [
              <Col key={1} xs={12}>
                <TopicDropdown
                  name="topic"
                  placeholder="Branded container topic..."
                />
              </Col>,
            ]
          : undefined
      }
      filterProps={{
        fullWidth: true,
        defaultFilters: {
          notIds: [...relatedBrandedContainerIDs, brandedContainerId],
        },
        generateInGridButtons: (
          key: number | string,
          buttons: ReactNode,
          _renderedFilters: ReactNode[]
        ) => {
          return (
            <Col
              key={key}
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'self-end',
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              <ButtonsContainer>{buttons}</ButtonsContainer>
            </Col>
          );
        },
      }}
      dataType="relatedBrandedContainers"
      query={GetBrandedContainersDocument}
      columns={relatedBrandedContainersColumns}
      parseQueryVarsBeforeQuery={parseQuery}
      useRowSelection={!disabled}
      rowSelectionActions={[
        {
          label: loading ? 'Assigning...' : 'Assign',
          icon: <PlusOutlined />,
          type: 'primary',
          resetSelection: true,
          onClick: onOk,
          loading: loading,
        },
      ]}
      selectionLabelProvider={(count: number) => (
        <span>
          <strong>{count}</strong>{' '}
          {noop(count, 'branded container', 'branded containers')} selected
        </span>
      )}
      rowSelectionColPush={0}
      rowSelectionPosition="sticky"
      dataWhichUpdateQueryParamsWhenChanged={omitBrandedContainersIDs}
    />
  );
};

export const StyledModal = styled(Modal)`
  min-width: 960px;

  .ant-modal-body {
    padding: 30px 0 0;

    & .ant-table {
      margin-top: 20px;
    }
  }
`;

const ButtonsContainer = styled.div`
  margin-bottom: 14px;

  button:nth-child(2) {
    margin-left: 10px;
  }
`;

import React from 'react';
import { Column } from '../../../common/data-list/types';
import { Event } from '../../../../resolver.types';
import { EventTitle } from '../../../event/event-title/event-title.component';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';
import TicketsAvailability from '../../../event/tickets-availability/tickets-availability.component';
import SaleState from '../../../event/sale-state/sale-state.component';

const titleColumn = (
  title: string,
  onClick?: (event: Event) => void,
  props?: { smallMode: boolean }
): Column<Event> => ({
  title,
  dataIndex: 'startDateTime',
  sortable: true,
  render: (_, record) => {
    return (
      <EventTitle
        {...record}
        title={record.title ?? ''}
        imageUrl={record.imageUrl ?? ''}
        genre={record?.genres[0].name}
        onClick={() => onClick && onClick(record)}
        smallMode={props?.smallMode}
      />
    );
  },
});

const ticketsColumn = (
  title: string,
  onClick?: (event: Event) => void
): Column<Event> => ({
  title,
  dataIndex: 'ticketsAvailability',
  render: (_, record) => {
    return (
      <TicketsAvailability
        event={record}
        onClick={() => onClick && onClick(record)}
      />
    );
  },
});

const statusColumn = (title: string): Column<Event> => ({
  title,
  dataIndex: 'status',
  render: (_, record) => {
    return <SaleState saleStatus={record.salesStatus} smallMode />;
  },
});

type EventColumns = Array<Column<Event>>;

export const defaultColumns = (
  onClick?: (event: Event) => void
): EventColumns => [
  titleColumn('Assigned events', onClick),
  statusColumn('Status'),
  ticketsColumn('Available tickets', onClick),
];

export const allEventTabColumns = (
  assignEvents: (events: Event[]) => void,
  loading?: boolean,
  onClick?: (event: Event) => void
): EventColumns => [
  titleColumn('All events', onClick, { smallMode: true }),
  statusColumn('Status'),
  ticketsColumn('Available tickets', onClick),

  {
    title: '',
    dataIndex: 'id',
    render: (_, event: Event) => {
      return (
        <AdditionalButtonsContainer>
          <Button
            disabled={loading}
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => assignEvents([event])}
          >
            Assign
          </Button>
        </AdditionalButtonsContainer>
      );
    },
  },
];

export const suggestedColumns = (
  declineEvents: (events: Event[]) => void,
  assignEvents: (events: Event[]) => void,
  loading?: boolean,
  onClick?: (event: Event) => void
): EventColumns => [
  titleColumn('Suggested events', onClick, { smallMode: true }),
  statusColumn('Status'),
  ticketsColumn('Available tickets', onClick),
  {
    title: '',
    dataIndex: 'id',
    render: (_, event: Event) => {
      return (
        <AdditionalButtonsContainer>
          <Button
            disabled={loading}
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => assignEvents([event])}
          >
            Assign
          </Button>
          <Button
            disabled={loading}
            icon={<CloseOutlined />}
            onClick={() => declineEvents([event])}
          >
            Decline
          </Button>
        </AdditionalButtonsContainer>
      );
    },
  },
];

export const declinedColumns = (
  onClick?: (event: Event) => void
): EventColumns => [
  titleColumn('Declined events', onClick),
  statusColumn('Status'),
  ticketsColumn('Available tickets', onClick),
];

const AdditionalButtonsContainer = styled.div`
  display: flex !important; /* Antd table overwrites display */
  flex-direction: column;
  width: 120px !important;

  & .ant-btn:first-child {
    margin-bottom: 8px;
  }

  & .ant-btn {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

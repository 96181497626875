import styled from 'styled-components';
import { Highlight } from '../../common/highlight/hightlight.component';
import { TopicFilter } from './types';
import React, { FC, ReactNode, useMemo } from 'react';
import { Checkbox } from 'antd';
import { camelCaseToSentence } from '../../../utils/text';

export type TopicItemProps = {
  value: TopicFilter & {
    key: string | TopicFilter['key'];
  };
  search: string;
  icon?: string | ReactNode;
  checked?: boolean;
  showCheckbox?: boolean;
  className?: string;
};

const StyledHighlight = styled(Highlight)`
  margin-left: 5px;
`;

const TopicItem: FC<TopicItemProps> = ({
  value,
  search,
  icon,
  checked,
  showCheckbox,
  className,
}) => {
  const key: TopicFilter['key'] = useMemo(
    () => (typeof value.key === 'string' ? JSON.parse(value.key) : value.key),
    [value]
  );

  return (
    <span className={className}>
      {icon}
      {showCheckbox && (
        <Checkbox style={{ marginRight: '5px' }} checked={checked} />
      )}
      <StyledHighlight text={key.label} highlight={search} />
      <span style={{ margin: '0 3px' }} className="tag">
        {camelCaseToSentence(key.type)}
      </span>
    </span>
  );
};

export default TopicItem;

import React from 'react';
import { useParams } from 'react-router';
import { BrandedContainerDrawer } from '../../../branded-container/drawer/drawer.component';
import type { BrandedContainerDetailsAction } from '../../drawer-content/shared/types';

type BrandedContainerDetailsDrawerProps = {
  handleClose: () => void;
  actions?: BrandedContainerDetailsAction[];
  noActions?: boolean;
  width?: number;
};

const BrandedContainerDetailsDrawer = ({
  handleClose,
  actions,
  noActions,
  width,
}: BrandedContainerDetailsDrawerProps) => {
  if (!noActions && !actions?.length) {
    handleClose();
    return null;
  }

  const { id } = useParams() as { id: string };

  return (
    <BrandedContainerDrawer
      id={id}
      width={width}
      onClose={handleClose}
      actions={actions}
      isNestedDrawer={true}
    />
  );
};

export default BrandedContainerDetailsDrawer;

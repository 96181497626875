import { FormStepProps } from './shared';
import React, { useCallback } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Typography } from 'antd';
import styled from 'styled-components';
import { BrandedContainerDisplayType } from '../../../resolver.types';
import { LabeledValue } from 'antd/lib/select';
import { camelCaseToSentence } from '../../../utils/text';
import { FormItemLockerComponent } from './description-step/form-item-locker.component';
import { Lockers } from '../update-data-form/use-fields-lockers';

const DisplaySettingsContainer = styled.div`
  .section-title {
    margin-bottom: 10px;

    .ant-typography {
      font-size: 16px;
    }
  }

  .ant-select-selection__rendered {
    line-height: 30px;
    font-size: 14px;
  }

  .section-content {
    margin-bottom: 40px;
  }
`;
const { Text } = Typography;

const displayTypes: LabeledValue[] = Object.values(
  BrandedContainerDisplayType
).map((type) => ({
  key: type,
  value: type,
  label: camelCaseToSentence(type),
}));

type DisplaySettingsProp = FormStepProps & {
  lockers: Lockers;
  setLockers: React.Dispatch<Partial<Lockers>>;
};

export const DisplaySettingsStep = ({
  values,
  onChange,
  isFormDisabled,
  lockers,
  setLockers,
}: DisplaySettingsProp) => {
  const handleSelectChange = useCallback(
    (name: string) => (value: any) => {
      onChange({
        target: {
          name,
          value,
        },
      } as any);
    },
    [onChange]
  );

  const handleInputChange = useCallback(
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        target: {
          name,
          value: event.target.value,
        },
      } as any);
    },
    [onChange]
  );

  return (
    <DisplaySettingsContainer>
      <section className="display-settings-section">
        <div className="section-title">
          <Text>Display Settings</Text>
        </div>
        <div className="section-content">
          {/*
          // @ts-ignore */}
          <Form.Item label="Show on main page">
            <Select<number>
              size="middle"
              disabled={isFormDisabled}
              id="showOnMainPage"
              onChange={handleSelectChange('showOnMainPage')}
              value={values.showOnMainPage ? 1 : 0}
            >
              <Select.Option value={0}>No</Select.Option>
              <Select.Option value={1}>Yes</Select.Option>
            </Select>
          </Form.Item>
          {/*
          // @ts-ignore */}
          <Form.Item label="Show on event charts">
            <Select<number>
              size="middle"
              disabled={isFormDisabled}
              id="showOnEventCharts"
              onChange={handleSelectChange('showOnEventCharts')}
              value={values.showOnEventCharts ? 1 : 0}
            >
              <Select.Option value={0}>No</Select.Option>
              <Select.Option value={1}>Yes</Select.Option>
            </Select>
          </Form.Item>
        </div>
      </section>
      <section className="display-settings-section">
        <div className="section-title">
          <Text>Format Settings</Text>
        </div>
        <div className="section-content">
          {/*
          // @ts-ignore */}
          <Form.Item label="Display event list as">
            <Select<string>
              size="middle"
              disabled={isFormDisabled}
              id="eventListDisplayType"
              optionLabelProp="label"
              onChange={handleSelectChange('eventListDisplayType')}
              value={
                values.eventListDisplayType ?? BrandedContainerDisplayType.List
              }
            >
              {displayTypes.map((type) => (
                <Select.Option
                  label={type.label}
                  key={type.key}
                  value={type.value}
                >
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </section>
      <section>
        <div className="section-title">
          <Text>Additions</Text>
        </div>
        <div className="section-content">
          {/*
          // @ts-ignore */}

          <FormItemLockerComponent
            name="legacyId"
            label="Legacy ID"
            isFormDisabled={isFormDisabled}
            lockers={lockers}
            setLockers={setLockers}
            component={
              <Input
                name="legacyId"
                onChange={handleInputChange('legacyId')}
                value={values.legacyId || ''}
                disabled={lockers.legacyId || isFormDisabled}
              />
            }
            warningMessage="You are about to change the Legacy ID of the Branded Container. Changing it may affect systems that are referencing this as an identifier."
          />
        </div>
      </section>
    </DisplaySettingsContainer>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  REMOVE_ARTIST,
  RemoveArtistResult,
  RemoveArtistVariables,
} from '../../../graphql/mutations/remove-artist';
import { Artist } from '../../../types/artist';
import {
  BrandedContainersByArtistDocument,
  BrandedContainersByArtistQueryResult,
  BrandedContainersByArtistQueryVariables,
  BrandedContainersQueryResult,
  FilterArtistsDocument,
} from '../../../resolver.types';
import { useDataListContext } from '../../common/data-list/data-list.context';

export type DeleteModalHook = {
  error?: string;
  setError: (error: string) => any;
  relatedBrandedContainersLoading: boolean;
  relatedBrandedContainers?: BrandedContainersQueryResult | null;
  relatedBrandedContainersCount: number;
  removeArtist: () => Promise<void>;
  removeArtistResult?: RemoveArtistResult['removeArtist'];
  removeArtistLoading: boolean;
  fetchAllRelatedBrandedContainers: () => Promise<void>;
};

export type DeleteModalHookConfig = {
  artistID: string;
  afterDelete?: (artist: Pick<Artist, 'id' | 'name'>) => any;
};

export const useDeleteModal = ({
  artistID,
  afterDelete,
}: DeleteModalHookConfig): DeleteModalHook => {
  const [error, setError] = useState<string>();

  const {
    state: { artists: artistsQueryVariables },
  } = useDataListContext();

  const {
    data: relatedBrandedContainerData,
    loading: relatedBrandedContainersLoading,
    error: relatedBrandedContainersError,
    refetch,
  } = useQuery<
    BrandedContainersByArtistQueryResult['data'],
    BrandedContainersByArtistQueryVariables
  >(BrandedContainersByArtistDocument, {
    variables: {
      artistID,
      pagination: {
        start: 0,
        limit: 3,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [
    removeArtist,
    {
      data: removeArtistData,
      loading: removeArtistLoading,
      error: removeArtistError,
    },
  ] = useMutation<RemoveArtistResult, RemoveArtistVariables>(REMOVE_ARTIST, {
    refetchQueries: () => {
      if (!artistsQueryVariables) {
        return [];
      }

      return [
        {
          query: FilterArtistsDocument,
          variables: artistsQueryVariables,
        },
      ];
    },
  });
  const handleRemoval = useCallback(async () => {
    const { data } = await removeArtist({
      variables: { input: { id: artistID } },
    });

    if (data && afterDelete) {
      afterDelete(data.removeArtist);
    }
  }, [removeArtist, artistID, afterDelete]);

  useEffect(() => {
    if (relatedBrandedContainersError) {
      setError(relatedBrandedContainersError.message);
    }
  }, [relatedBrandedContainersError]);

  useEffect(() => {
    if (removeArtistError) {
      setError(removeArtistError.message);
    }
  }, [removeArtistError]);

  const fetchAllRelatedBrandedContainers = useCallback(async () => {
    await refetch({
      pagination: {
        start: 0,
        limit:
          relatedBrandedContainerData?.brandedContainersByArtist?.metadata
            .total ?? 10,
      },
      artistID,
    });
  }, [artistID, refetch, relatedBrandedContainerData]);

  return {
    error,
    setError,
    fetchAllRelatedBrandedContainers,
    removeArtist: handleRemoval,
    relatedBrandedContainersLoading,
    relatedBrandedContainersCount:
      relatedBrandedContainerData?.brandedContainersByArtist?.metadata.total ??
      0,
    removeArtistResult: removeArtistData
      ? removeArtistData.removeArtist
      : undefined,
    removeArtistLoading,
    relatedBrandedContainers:
      (relatedBrandedContainerData?.brandedContainersByArtist as BrandedContainersQueryResult) ??
      undefined,
  };
};

import React from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { DateFormats } from '../../../utils/date-formats';
import { UserRoundAvatar } from '../../common/user-round-avatar/user-round-avatar.component';
import {
  StyledLastModifiedComment,
  CommentHeader,
  IssueInformation,
  CommentContent,
} from './last-branded-container-comment.styles';

type LastModifiedCommentProps = {
  issuer: string;
  content: string;
  date: string;
  maxContentSize?: number;
};

export const LastBrandedContainerComment = ({
  issuer,
  content,
  date,
  maxContentSize = 100,
}: LastModifiedCommentProps) => {
  return (
    <StyledLastModifiedComment className="last-modified-comment">
      <CommentHeader>
        <UserRoundAvatar username={issuer} />
        <IssueInformation>
          <span className="issuer-name">{issuer}</span>
          <span className="comment-date">
            At: {format(parseISO(date), DateFormats.dateTime)}
          </span>
        </IssueInformation>
      </CommentHeader>
      <CommentContent>
        {content.length > maxContentSize
          ? `${content.slice(0, maxContentSize - 1)}...`
          : content}
      </CommentContent>
    </StyledLastModifiedComment>
  );
};

import React, { ReactElement } from 'react';
import { Form } from '@ant-design/compatible';
import { FormStepProps } from './shared';
import '@ant-design/compatible/assets/index.css';
import { Input, Select } from 'antd';
import { DescriptionContentSwitcher } from '../description-content-switcher/description-content-switcher.component';
import { useNavigate, useLocation } from 'react-router';
import { FormItemError } from '../update-data-form/update-data-form.styles';
import { LastUpdatedDate } from './last-updated-date.component';

import { BrandedContainerState } from '../../../resolver.types';
import { PresaleLinksComponent } from './description-step/presale-links.component';
import { Lockers } from '../update-data-form/use-fields-lockers';
import { FormItemLockerComponent } from './description-step/form-item-locker.component';

type DescriptionProps = FormStepProps & {
  lockers: Lockers;
  setLockers: React.Dispatch<Partial<Lockers>>;
  warningComponent: ReactElement | null;
};

// eslint-disable-next-line complexity
export const DescriptionStep = ({
  onChange,
  isFormDisabled = false,
  values: {
    title,
    titleSlug,
    subtitle,
    description,
    shortDescription,
    descriptionSource,
    additionalKeywords,
    dataLastUpdated,
    state,
    id,
  },
  warningComponent,
  errors,
  lockers,
  setLockers,
}: DescriptionProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onSelectKeywords = (keywords: string[]) => {
    onChange({
      target: { name: 'additionalKeywords', value: keywords },
    } as any);
  };

  const onChangeDescriptionContent = (value: string) => {
    const route = location.pathname.replace('description', value);

    navigate(route);
  };

  return (
    <React.Fragment>
      <DescriptionContentSwitcher
        onChange={onChangeDescriptionContent}
        defaultValue="description"
      />
      {warningComponent}
      {/*
      // @ts-ignore */}
      <Form.Item label="Title">
        <Input
          type="text"
          name="title"
          placeholder="Enter title..."
          value={title || ''}
          onChange={onChange}
          disabled={isFormDisabled}
          maxLength={200}
        />
        {errors?.title && <FormItemError>{errors?.title}</FormItemError>}
        {dataLastUpdated.title && (
          <LastUpdatedDate date={dataLastUpdated.title} />
        )}
      </Form.Item>
      <FormItemLockerComponent
        name="titleSlug"
        label="Title slug"
        isFormDisabled={isFormDisabled}
        lockers={lockers}
        setLockers={setLockers}
        dataLastUpdated={dataLastUpdated.titleSlug}
        component={
          <Input
            type="text"
            name="titleSlug"
            placeholder="Enter title slug..."
            value={titleSlug || ''}
            onChange={onChange}
            disabled={lockers.titleSlug || isFormDisabled}
          />
        }
        warningMessage="You are about to change the title slug of the Branded Container. This may affect the SEO-Performance negatively."
      />
      {state === BrandedContainerState.Published && (
        <PresaleLinksComponent
          isFormDisabled={isFormDisabled}
          brandedContainerId={id}
        />
      )}
      {/*
      // @ts-ignore */}
      <Form.Item label="Subtitle">
        <Input
          type="text"
          name="subtitle"
          placeholder="Enter subtitle..."
          value={subtitle || ''}
          onChange={onChange}
          disabled={isFormDisabled}
          maxLength={128}
        />
        {errors?.subtitle && <FormItemError>{errors?.subtitle}</FormItemError>}
        {dataLastUpdated.subtitle && (
          <LastUpdatedDate date={dataLastUpdated.subtitle} />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Editorial long text">
        <Input.TextArea
          autoSize={{
            minRows: 11,
            maxRows: 11,
          }}
          name="description"
          value={description || ''}
          style={{ resize: 'none' }}
          onChange={onChange}
          disabled={isFormDisabled}
          placeholder="Enter editorial long text..."
        />
        {errors?.description && (
          <FormItemError>{errors?.description}</FormItemError>
        )}
        {dataLastUpdated.description && (
          <LastUpdatedDate date={dataLastUpdated.description} />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Editorial short text">
        <Input.TextArea
          autoSize={{
            minRows: 6,
            maxRows: 11,
          }}
          name="shortDescription"
          value={shortDescription || ''}
          style={{ resize: 'none' }}
          onChange={onChange}
          disabled={isFormDisabled}
          placeholder="Enter editorial short text..."
        />
        {errors?.shortDescription && (
          <FormItemError>{errors?.shortDescription}</FormItemError>
        )}
        {dataLastUpdated.shortDescription && (
          <LastUpdatedDate date={dataLastUpdated.shortDescription} />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Editorial text source">
        <Input
          type="text"
          name="descriptionSource"
          value={descriptionSource || ''}
          onChange={onChange}
          disabled={isFormDisabled}
          placeholder="Enter editorial text source..."
        />
        {errors?.descriptionSource && (
          <FormItemError>{errors?.descriptionSource}</FormItemError>
        )}
        {dataLastUpdated.descriptionSource && (
          <LastUpdatedDate date={dataLastUpdated.descriptionSource} />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item label="Additional keywords">
        <Select
          className="styled-tags"
          onChange={onSelectKeywords}
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Enter additional keywords..."
          value={additionalKeywords || []}
          disabled={isFormDisabled}
        />
        {errors?.additionalKeywords && (
          <FormItemError>{errors?.additionalKeywords}</FormItemError>
        )}
        {dataLastUpdated.additionalKeywords && (
          <LastUpdatedDate date={dataLastUpdated.additionalKeywords} />
        )}
      </Form.Item>
    </React.Fragment>
  );
};

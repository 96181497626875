import React from 'react';
import { BrandLabel, BrandTagsContainer } from './brand-switcher.styles';
import { BrandTag } from '../../common/brand-tag/brand-tag.component';
import { Brand } from 'yggdrasil-shared/domain/dictionary';

type BrandSwitcherProps = {
  onChange: (brand: string) => void;
  selectedBrandValue?: string;
  brands: Brand[];
  isNestedDrawer?: boolean;
};

export const BrandSwitcher = ({
  onChange: cb,
  brands,
  selectedBrandValue,
  isNestedDrawer,
}: BrandSwitcherProps) => {
  const [value, setValue] = React.useState(
    selectedBrandValue || brands[0].name
  );

  const onChange = ({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValue(value);
      cb(value);
    }
  };

  return (
    <BrandTagsContainer className="brand-tags-container">
      {brands.map((brand, index) => (
        <BrandLabel color={brand.color} key={`brand-${index}`}>
          <BrandTag
            brand={brand.shortcut}
            color={
              !isNestedDrawer || brand.name === value ? brand.color : 'grey'
            }
            disabled={isNestedDrawer}
          />
          <input
            type="radio"
            name="brand"
            value={brand.name}
            checked={brand.name === value}
            onChange={onChange}
            disabled={isNestedDrawer}
          />
          <span className="active-border"></span>
        </BrandLabel>
      ))}
    </BrandTagsContainer>
  );
};

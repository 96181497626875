import React, { forwardRef, Ref, useCallback } from 'react';
import { useFiltersContext } from '../../../hooks';
import { ResetButton } from '../../../common/reset-button/reset-button.component';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import type { RefSelectProps } from 'antd/lib/select';
import { GenresDropdown } from '../../../../branded-container/genres-dropdown/genres-dropdown.component';

export type GenresProps = {
  name?: string;
  width?: string;
  label?: string;
};

const Genres = ({ name = 'genres', width, label }: GenresProps) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } =
    useFiltersContext();
  const value = getFieldValue(name);

  const resetValue = useCallback(() => {
    setFieldsValue({
      [name]: [],
    });
  }, [setFieldsValue, name]);

  const Dropdown = forwardRef((props, ref: Ref<RefSelectProps>) => (
    <GenresDropdown
      showClear={false}
      selectRef={ref}
      showCheckbox
      placeholder="Genre..."
      styledSelection
      {...props}
    />
  ));

  return (
    <>
      {/*
    // @ts-ignore */}
      <Form.Item
        label={label}
        colon={false}
        style={{ width, minWidth: '100px' }}
        className="compatible-row"
      >
        {value && value.length > 0 && <ResetButton onClick={resetValue} />}
        {getFieldDecorator(name)(<Dropdown />)}
      </Form.Item>
    </>
  );
};

export default Genres;

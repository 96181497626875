import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import { routes } from '../../../route-urls';
import Drawer from '../drawer/drawer';

const DrawerWrapper = () => {
  const { id } = useParams() as { id: string };

  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(routes.events());
  }, [navigate]);

  return <Drawer id={id} handleClose={handleClose} />;
};

export default DrawerWrapper;

import { Route, Routes } from 'react-router-dom';
import { DeleteModal } from '../../components/artist/delete-modal/delete-modal.component';
import React from 'react';

export const DeleteArtistPage = () => {
  return (
    <Routes>
      <Route element={<DeleteModal />} path={`delete-artist/:id`} />
    </Routes>
  );
};

import React, { useCallback } from 'react';
import { SearchOption } from '../search-with-option/search-with-option.component';
import { CloseOutlined } from '@ant-design/icons';
import { Select, Button } from 'antd';
import { SearchValueContainer } from './search-value.styles';
import { SearchValue as SearchValueType } from '../../../context/event-filters.context';
import EditableText from '../editable-text/editable-text.component';

type SearchValueProps = {
  option: SearchOption;
  value: string;
  onRemove?: (value: SearchValueType, index?: number) => void;
  onChange?: (value: SearchValueType, index?: number) => void;
  index?: number;
};

export const SearchValue = ({
  option,
  value,
  onRemove,
  onChange,
  index,
}: SearchValueProps) => {
  const removeHandler = () => {
    if (onRemove) {
      onRemove({
        value,
        option,
      });
    }
  };

  const handleOptionChange = useCallback(
    (newOption: SearchOption) => {
      if (onChange) {
        onChange(
          {
            value,
            option: newOption,
          },
          index
        );
      }
    },
    [index, value, onChange]
  );

  const handleTextChange = useCallback(
    (changedValue: string) => {
      if (onChange) {
        onChange(
          {
            value: changedValue,
            option,
          },
          index
        );
      }
    },
    [onChange, option, index]
  );

  return (
    <SearchValueContainer className="search-value">
      <Select value={option} onChange={handleOptionChange}>
        {Object.values(SearchOption).map((option) => (
          <Select.Option
            value={option}
            key={option}
            style={{ fontSize: '10px' }}
          >
            {`${option[0].toUpperCase()}${option.slice(1)}`}
          </Select.Option>
        ))}
      </Select>
      <span className="value-text">
        <EditableText onChange={handleTextChange} value={value} />
      </span>
      <Button icon={<CloseOutlined />} onClick={removeHandler} />
    </SearchValueContainer>
  );
};

import { Artist } from '../../types/artist';
import { CreateArtistDocument } from '../../resolver.types';

export const CREATE_ARTIST = CreateArtistDocument;

export type CreateArtistInput = {
  name: string;
  aliasNames?: string[];
  countryOfOrigin: string;
  dateOfBirth: string;
  source?: string;
  externalID?: string;
  externalDataSource?: string;
  issuer: string;
  genres: string[];
};

export type CreateArtistVariables = {
  input: CreateArtistInput;
};

export type CreateArtistResult = {
  createArtist: Artist;
};

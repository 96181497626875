import { KeyboardEvent, KeyboardEventHandler, useCallback } from 'react';

export type KeyPressHandler = (event: KeyboardEvent) => any;
export type KeyPressHandlersMap = Record<
  string,
  KeyPressHandler | KeyPressHandler[]
>;

const useKeypressHandler = (handlersMap: KeyPressHandlersMap) => {
  const handler: KeyboardEventHandler = useCallback(
    (event) => {
      event.stopPropagation();

      if (handlersMap[event.key]) {
        const handlers = Array.isArray(handlersMap[event.key])
          ? handlersMap[event.key]
          : [handlersMap[event.key]];

        (handlers as KeyPressHandler[]).forEach((handler) => handler(event));
      }
    },
    [handlersMap]
  );

  return handler;
};

export default useKeypressHandler;

import styled from 'styled-components';

export const NavigationContainer = styled.div`
  min-width: 260px;
  max-width: 260px;

  & > .navigation-title {
    margin-top: 20px;
    padding-left: 25px;
    font-size: 11px;
    font-weight: 300;
  }

  & > .navigation-list {
    list-style: none;
    padding: 0;
    margin-top: 8px;
  }
`;
export const NavigationItem = styled.li.attrs(
  ({ active = false }: { active: boolean }) => ({
    active,
  })
)`
  & > a {
    padding-left: 25px;
    color: ${(props) => (props.active ? '#E4A140' : '#8d8d8d')};
    font-weight: ${(props) => (props.active ? '500' : '300')};
    border-left: ${(props) => (props.active ? '3px solid #E4A140' : 'none')};
    font-size: 13px;

    display: block;
    margin-top: 10px;
    user-select: none;
    cursor: pointer;

    & > .menu-item-icon {
      margin-right: 15px;
      color: ${(props) => (props.active ? '#E4A140' : '#8d8d8d')};
      font-size: 15px;
      font-weight: 500;
    }
  }
`;

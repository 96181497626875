import { Route, Routes } from 'react-router-dom';
import { Drawer } from '../../components/artist/drawer/drawer.component';
import React from 'react';

const ArtistDrawer = () => {
  return (
    <Routes>
      <Route path={`set-artist-data/:id`} element={<Drawer />} />
    </Routes>
  );
};

export default ArtistDrawer;

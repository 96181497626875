import React from 'react';
import { EventTypeLabel, SwitcherContainer } from './events-switcher.styles';

export enum EventsType {
  Default = 'default',
  Suggested = 'suggested',
  Declined = 'declined',
  All = 'all',
}

type EventsSwitcherProps = {
  eventsCount: number;
  suggestedEventsCount: number;
  declinedEventsCount: number;
  value?: EventsType;
  onChange?: (value: EventsType) => void;
};

export const EventsSwitcher = ({
  value: initialValue = EventsType.Default,
  onChange: cb,
  eventsCount,
  suggestedEventsCount,
  declinedEventsCount,
}: EventsSwitcherProps) => {
  const [value, setValue] = React.useState<EventsType>(initialValue);

  const onChange = ({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValue(value as EventsType);
      if (cb) {
        cb(value as EventsType);
      }
    }
  };

  return (
    <SwitcherContainer>
      <EventTypeLabel className="event-type-label">
        <span className="label-text">Events ({eventsCount})</span>
        <input
          type="radio"
          onChange={onChange}
          checked={value === EventsType.Default}
          value="default"
          name="event-type"
        />
        <span className="active-border"></span>
      </EventTypeLabel>
      <EventTypeLabel className="event-type-label">
        <span className="label-text">
          Suggested events ({suggestedEventsCount})
        </span>
        <input
          type="radio"
          onChange={onChange}
          checked={value === EventsType.Suggested}
          value="suggested"
          name="event-type"
        />
        <span className="active-border"></span>
      </EventTypeLabel>
      <EventTypeLabel className="event-type-label">
        <span className="label-text">
          Declined events ({declinedEventsCount})
        </span>
        <input
          type="radio"
          onChange={onChange}
          checked={value === EventsType.Declined}
          value="declined"
          name="event-type"
        />
        <span className="active-border"></span>
      </EventTypeLabel>
    </SwitcherContainer>
  );
};

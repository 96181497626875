import styled from 'styled-components';
import { Row, Col } from 'antd';

export const DataListWrapper = styled.div`
  .ant-table-column-title {
    font-weight: 600;
    font-size: 12px;
    color: #666666;
  }

  th.ant-table-cell {
    font-weight: 600;
    font-size: 12px;
    color: #666666;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-pagination {
    align-items: center;
  }

  .ant-pagination-options {
    margin-right: 16px;
  }

  .ant-pagination-total-text {
    position: absolute;
    left: 0;
    margin-left: 10px;
  }

  .ant-pagination-options-size-changer {
    .ant-select-selection {
      border: none;
      background: transparent;
      box-shadow: none;
    }
  }

  .ant-table-tbody tr td {
    padding: 0;

    > * {
      padding: 16px;
      min-height: 60px;
      width: 100%;
      display: block;
    }
  }

  .pagination-searchAfter {
    .ant-pagination-item:not(.ant-pagination-prev):not(.ant-pagination-next) {
      display: none;
    }

    .ant-pagination-item-link,
    .ant-btn {
      font-size: 20px;
      width: auto;
    }

    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      display: none;
    }
  }
`;

export const SelectionRow = styled(Row)`
  padding: 15px 15px 25px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  box-shadow: 0 -3px 6px 0 rgba(230, 230, 230, 0.5);
  display: flex !important;
  flex-flow: row wrap;

  .ant-row {
    display: flex;
    flex-flow: row wrap;
  }
`;

export const SelectionButtonsCol = styled(Col)`
  & .ant-btn {
    margin-left: 8px !important; /** Antd overwrites margin */
  }

  & .ant-btn:first-child {
    margin-left: 0px;
  }
`;

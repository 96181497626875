import { Artist } from '../resolver.types';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DateFormats } from './date-formats';

export const getName = (artist: Artist) => {
  const { name, dateOfBirth, countryOfOrigin, genres } = artist;

  const meta = [
    dateOfBirth ? format(parseISO(dateOfBirth), DateFormats.date) : null,
    countryOfOrigin,
    genres ? genres.join(', ') : null,
  ].filter(Boolean);

  let display = `${name}`;

  if (meta.length) {
    display += ` (${meta.join(', ')})`;
  }

  return display;
};

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  &,
  &:hover {
    color: #000;
  }
`;
export const Container = styled.div`
  min-width: 150px;
`;

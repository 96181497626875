import React from 'react';
import { BrandedContainerComment as BrandedContainerCommentData } from '../../../resolver.types';
import { BrandedContainerComment } from '../comment/comment.component';
import {
  Comments,
  NoCommentsInfo,
  CommentWrapper,
} from './comments-container.styles';

type CommentsContainerProps = {
  comments: BrandedContainerCommentData[];
};

export const CommentsContainer = ({ comments }: CommentsContainerProps) => {
  return (
    <Comments id="comments-container">
      {comments.length ? (
        comments.map((comment) => (
          <CommentWrapper className="comment-wrapper" key={comment.date}>
            <BrandedContainerComment comment={comment} />
          </CommentWrapper>
        ))
      ) : (
        <NoCommentsInfo>There is no comments yet.</NoCommentsInfo>
      )}
    </Comments>
  );
};

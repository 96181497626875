import React from 'react';
import {
  Dropdown as EntityDropdown,
  DropdownProps as EntityDropdownProps,
} from '../../common/generic-dropdown/dropdown.component';
import {
  Place,
  PlacesByIdsDocument,
  PlacesByIdsQuery,
  SearchPlacesDocument,
  SearchPlacesQuery,
} from '../../../resolver.types';
import { getName } from '../../../utils/place';

type PlaceType = Pick<Place, 'city_id' | 'unique_city_name'>;
type PlacesByIdQueryType = Omit<PlacesByIdsQuery, '__typename'>;
type SearchPlacesQueryType = Omit<SearchPlacesQuery, '__typename'>;
type PlaceDropdownProps = Omit<
  EntityDropdownProps<PlaceType, PlacesByIdQueryType, SearchPlacesQueryType>,
  'entityProps'
>;

export const PlaceDropdown = (props: PlaceDropdownProps) => {
  return (
    <EntityDropdown<PlaceType, PlacesByIdQueryType, SearchPlacesQueryType>
      {...props}
      entityProps={{
        displayName: 'places',
        idKey: 'city_id',
        nameKey: 'unique_city_name',
        entitiesByIdsQueryKey: 'placesByIds',
        entitiesSearchQueryKey: 'searchPlaces',
        entitiesByIdsDocument: PlacesByIdsDocument,
        entitiesSearchDocument: SearchPlacesDocument,
        nameGetter: (place) => getName(place as Place),
      }}
    />
  );
};

import * as React from 'react';
import { useMemo } from 'react';
import { routes } from '../../../route-urls';
import { Link } from 'react-router-dom';
import { EventBrandedContainersProps } from '../../../hooks/use-event-columns';
import { Container, StyledLink } from './event-branded-containers.styles';
import { BrandTag } from '../../common/brand-tag/brand-tag.component';
import { brandsMap } from 'yggdrasil-shared/domain/dictionary';

export const EventBrandedContainers = ({
  record,
}: EventBrandedContainersProps) => {
  const stopEventPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => event.stopPropagation();

  const hasBrandedContainers = useMemo(
    () => Boolean(record.brandedContainers?.metadata.total),
    [record]
  );

  return (
    <Container>
      {!hasBrandedContainers && (
        <StyledLink to={routes.setEventData(record.id)}>
          <span>0</span>
        </StyledLink>
      )}
      {hasBrandedContainers && (
        <div style={{ width: 'fit-content' }} onClick={stopEventPropagation}>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <Link
              style={{ textDecoration: 'underline' }}
              to={{
                pathname: routes.setEventData(record.id, 'branded-containers'),
              }}
            >
              {record.relatedBrandedContainersCount}
            </Link>
          </div>
          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            {record.relatedBrandedContainersBrands?.map((brandName) => {
              const brand = brandsMap[brandName];
              return (
                <div
                  key={brand.name}
                  style={{ display: 'inline-block', marginRight: '2px' }}
                >
                  <BrandTag
                    brand={brand.shortcut}
                    color={brand.color}
                    disabled={false}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Container>
  );
};

import {
  CityOrZip as BaseCityOrZip,
  CityOrZipProps as BaseProps,
} from '../../../../common/city-or-zip/city-or-zip.component';
import { useFiltersContext } from '../../../hooks';
import { LabeledValue } from 'antd/lib/select';
import React from 'react';

export type CityOrZipProps = BaseProps & {
  initialValue?: LabeledValue[];
};

const CityOrZip = ({
  name = 'cityOrZip',
  initialValue = [],
  ...props
}: CityOrZipProps) => {
  const { getFieldDecorator } = useFiltersContext();
  const decorator = getFieldDecorator(name, {
    initialValue,
  });

  return <>{decorator(<BaseCityOrZip name={name} {...props} />)}</>;
};

export default CityOrZip;

import React from 'react';
import { BrandedContainerComment } from '../../../resolver.types';
import { LastBrandedContainerComment } from '../last-branded-container-comment/last-branded-container-comment.component';
import { LimitedContainer } from '../../common/limited-container/limited-container.component';
import { routes } from '../../../route-urls';
import { CommentsContainer } from './last-branded-container-comments.styles';

type LastModifiedCommentsListProps = {
  comments: BrandedContainerComment[];
  brandedContainerId: string;
};

const DEFAULT_OFFSET = 3;

export const LastModifiedCommentList = ({
  comments,
  brandedContainerId,
}: LastModifiedCommentsListProps) => {
  return (
    <CommentsContainer>
      <LimitedContainer
        total={comments.length}
        offset={DEFAULT_OFFSET}
        title="LAST COMMENTS"
        redirectPath={routes.setBrandedContainerData(
          brandedContainerId,
          'comments'
        )}
      >
        {comments.length ? (
          (comments.length > DEFAULT_OFFSET
            ? comments.slice(comments.length - DEFAULT_OFFSET)
            : comments
          )
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            )
            .map((comment) => (
              <LastBrandedContainerComment key={comment.date} {...comment} />
            ))
        ) : (
          <span className="no-comments">There are no comments yet.</span>
        )}
      </LimitedContainer>
    </CommentsContainer>
  );
};

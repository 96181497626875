import { Event } from '../../../resolver.types';
import { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { displayTicketsAvailability } from '../../../utils/display-tickets-availability';

export interface TicketsAvailabilityProps {
  event: Event;
  onClick: () => void;
}

const TicketsAvailabilityContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: baseline;
  min-width: 130px;
  font-size: 12px;
`;

const TicketsAvailability: FC<TicketsAvailabilityProps> = ({
  event,
  onClick = () => {},
}) => {
  return (
    <TicketsAvailabilityContainer onClick={onClick}>
      {
        <div className="tickets-amount">
          <span>
            {displayTicketsAvailability(
              event.ticketsAmount,
              event.totalTicketsAmount
            )}
          </span>
        </div>
      }
    </TicketsAvailabilityContainer>
  );
};

export default TicketsAvailability;

import styled from 'styled-components';

export const StyledLastModifiedComment = styled.div`
  width: 215px;
  display: flex;
  flex-direction: column;
`;

export const CommentHeader = styled.div`
  display: flex;
`;

export const IssueInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  & > .issuer-name {
    font-size: 12px;
    font-weight: 600;
  }

  & > .comment-date {
    font-size: 11px;
  }
`;

export const CommentContent = styled.div`
  background-color: #f3f3f3;
  font-size: 11px;
  padding: 8px;
  border-radius: 10px;

  margin-top: 8px;
`;

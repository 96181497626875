import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import { v4 } from 'uuid';
import styled from 'styled-components';
import { Input, Modal } from 'antd';
import { Form } from '@ant-design/compatible';
import { StatusCodes } from 'http-status-codes';
import { retryWrapper } from 'yggdrasil-shared/utils/common';
import {
  BrandedContainerImage,
  BrandedContainerImageAspectRatio,
  BrandedContainerImageCropBoundary,
  File,
  GetBrandedContainerDocument,
  RemoveBrandedContainerImageDocument,
  SetBrandedContainerImageDocument,
  SetBrandedContainerThumbnailsDocument,
  UpdateBrandedContainerInput,
  UploadFileDocument,
  BrandedContainerImageType,
  BrandedContainerThumbnail,
  BrandedContainerThumbnailType,
} from '../../../../resolver.types';
import { UploadImage } from './upload-image.component';
import { ImageCropContainer } from './image-crop-container.component';
import { SelectAspectRatio } from './select-aspect-ratio.component';
import { SpinLoader } from '../../../common/spin-loader/spin-loader.component';
import { raw64BasetoBlob } from '../../../../utils/raw-64-base-to-blob';
import { RectShape } from './image-cropper.component';
import {
  InputError,
  VersionMismatchError,
} from 'yggdrasil-shared/domain/error';
import { FormStepProps } from '../shared';
import usePrevious from '../../../../hooks/use-previous';
import { AppContext, AppStoreApi } from '../../../../context/app.context';
import { UploadImageWrapper } from './upload-image-wrapper.component';

type MediaStepProps = {
  images: BrandedContainerImage[];
  cropBoundaries: BrandedContainerImageCropBoundary[];
  version: number;
  disabled?: boolean;
  setVersionMismatch?: (error: VersionMismatchError | null) => any;
  onChange: FormStepProps['onChange'];
  imagesFormValues: UpdateBrandedContainerInput['images'];
  clearImageFormValue: (aspectRatio: BrandedContainerImageAspectRatio) => void;
  addImageFormValue: (image: BrandedContainerImage) => void;
};

type CropShape = {
  x: number;
  y: number;
  height: number;
  width: number;
};

export const MediaStep = ({
  images,
  version,
  cropBoundaries,
  disabled = false,
  setVersionMismatch,
  onChange,
  imagesFormValues,
  clearImageFormValue,
  addImageFormValue,
}: MediaStepProps) => {
  const {
    state: { activeBrandTab },
  } = React.useContext(AppContext) as AppStoreApi;
  const { id } = useParams() as { id: string };

  const [selectedAspectRatio, selectAspectRatio] =
    React.useState<BrandedContainerImageAspectRatio>(
      BrandedContainerImageAspectRatio.A1x1
    );

  const [uploadingThumbnails, setUploadingThumbnails] = React.useState(false);
  const [isThumbnailGenerating, setIsCropGenerating] = React.useState(false);

  const [uploadFile, { error: uploadFileError }] =
    useMutation(UploadFileDocument);
  const [updateThumbnails, { error: updateThumbnailsError }] = useMutation(
    SetBrandedContainerThumbnailsDocument,
    {
      refetchQueries: [
        { query: GetBrandedContainerDocument, variables: { id } },
      ],
    }
  );

  const [imgUrl, setImgUrl] = React.useState('');
  const [imgMimetype, setImgMimetype] = React.useState('');
  const [setImages, { error: setImagesError }] = useMutation(
    SetBrandedContainerImageDocument,
    {
      refetchQueries: [
        { query: GetBrandedContainerDocument, variables: { id } },
      ],
    }
  );

  const [removeImage, { error: removeImageError }] = useMutation(
    RemoveBrandedContainerImageDocument,
    {
      refetchQueries: [
        { query: GetBrandedContainerDocument, variables: { id } },
      ],
    }
  );

  const [removingImage, setRemovingImage] = React.useState(false);
  const [replacingImage, setReplacingImage] = React.useState(false);

  const [initialShape, setInitialShape] = React.useState<CropShape | undefined>(
    undefined
  );

  const cropSupported = React.useMemo(
    () =>
      [
        BrandedContainerImageAspectRatio.A1x1,
        BrandedContainerImageAspectRatio.A16x9,
      ].includes(selectedAspectRatio),
    [selectedAspectRatio]
  );

  const prevImages = usePrevious(images);

  const findCopyrightInfo = React.useCallback(
    (images?: BrandedContainerImage[]) =>
      images?.find((image) => image.aspectRatio === selectedAspectRatio)
        ?.copyrightInformation,
    [selectedAspectRatio]
  );

  const fetchedCopyrightInfo = React.useMemo(
    () => findCopyrightInfo(images),
    [images, findCopyrightInfo]
  );

  const formCopyrightInformation = React.useMemo(
    () => findCopyrightInfo(imagesFormValues ?? []),
    [imagesFormValues, findCopyrightInfo]
  );

  const onCopyrightChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        target: {
          name: 'copyright',
          value: event.target.value,
          dataset: {
            aspectRatio: selectedAspectRatio,
          },
        },
      } as any);
    },
    [onChange, selectedAspectRatio]
  );

  const onUpload = async (file: File) => {
    setIsCropGenerating(true);
    const { data } = await setImages({
      variables: {
        input: {
          id,
          croppedImageId: file.metadata?.crop,
          version,
          image: {
            url: file.filename,
            aspectRatio: selectedAspectRatio,
            mimetype: file.mimetype,
          },
        },
      },
    });

    addImageFormValue({ url: file.path, aspectRatio: selectedAspectRatio });

    setImgUrl(file.path);
    setImgMimetype(file.mimetype);

    if (selectedAspectRatio === BrandedContainerImageAspectRatio.A1x1) {
      const url = data.setBrandedContainerImage.thumbnails?.find(
        (thumbnail: BrandedContainerThumbnail) =>
          thumbnail.type === BrandedContainerThumbnailType.A50x50
      )?.url;

      return url;
    }

    setIsCropGenerating(false);
  };

  const confirmThumbnailGeneration = async (newThumbnailUrl: string) => {
    await retryWrapper<Response>(
      async ({ attempt }) => {
        const response = await fetch(newThumbnailUrl);

        if (response.status !== StatusCodes.NOT_FOUND) {
          return response;
        }

        if (attempt >= 5) {
          setIsCropGenerating(false);
        }

        throw new Error('Thumbnail generation timeout.');
      },
      { delay: 800, limit: 15 },
      async (error) => {
        setIsCropGenerating(false);
        throw error;
      }
    )();

    setIsCropGenerating(false);
  };

  const setAspectRatio = (aspectRatio: BrandedContainerImageAspectRatio) => {
    selectAspectRatio(aspectRatio);

    const existingImage = images.find(
      (image) => image.aspectRatio === aspectRatio
    );

    if (existingImage) {
      setImgUrl(existingImage.url);
      setImgMimetype(existingImage.mimetype || '');
    } else {
      setImgUrl('');
      setImgMimetype('');
    }
  };

  const onDeleteImage = async () => {
    setRemovingImage(true);
    try {
      await removeImage({
        variables: {
          input: {
            id,
            version,
            aspectRatio: selectedAspectRatio,
          },
        },
      });
    } finally {
      setRemovingImage(false);
    }

    clearImageFormValue(selectedAspectRatio);
    setImgUrl('');
    setImgMimetype('');
  };

  const handleImageLoading = useCallback(
    (status: boolean) => {
      setReplacingImage(status);
    },
    [setReplacingImage]
  );

  const manualCrop = async (fileDataURL: string, crop: RectShape) => {
    setUploadingThumbnails(true);
    setIsCropGenerating(true);

    const blobToUpload = raw64BasetoBlob({
      raw64BaseData: fileDataURL.replace(/^data:image.+;base64,/, ''),
      contentType: 'image/webp',
      outputName: `${v4()}.webp`,
    });

    const upload = await uploadFile({
      variables: {
        file: blobToUpload,
        metadata: {
          ratio: selectedAspectRatio,
          type: BrandedContainerImageType.Cropped,
        },
        brand: activeBrandTab,
      },
    });

    const cropBoundary: CropShape = {
      x: !Number.isNaN(crop.x) ? Math.ceil(crop.x) : 0,
      y: !Number.isNaN(crop.y) ? Math.ceil(crop.y) : 0,
      width: Math.ceil(crop.width),
      height: Math.ceil(crop.height),
    };

    try {
      const { data } = await updateThumbnails({
        variables: {
          input: {
            id,
            version,
            filename: upload.data.uploadFile.filename,
            aspectRatio: selectedAspectRatio,
            cropBoundary,
          },
        },
      });
      setUploadingThumbnails(false);
      setInitialShape(cropBoundary);

      if (selectedAspectRatio === BrandedContainerImageAspectRatio.A1x1) {
        const url = data.setBrandedContainerThumbnails.thumbnails?.find(
          (thumbnail: BrandedContainerThumbnail) =>
            thumbnail.type === BrandedContainerThumbnailType.A50x50
        )?.url;

        await confirmThumbnailGeneration(url);
      }
    } catch {
      setUploadingThumbnails(false);
      setInitialShape(cropBoundary);
    } finally {
      setIsCropGenerating(false);
    }
  };

  useEffect(() => {
    const existingImage = images.find(
      (image) => image.aspectRatio === selectedAspectRatio
    );

    if (!existingImage) {
      setImgUrl('');
      setImgMimetype('');
      return;
    }

    setImgUrl(existingImage.url);
    setImgMimetype(existingImage.mimetype || '');
  }, [images, selectedAspectRatio]);

  useEffect(() => {
    const shape = cropBoundaries.find(
      (crop) => crop.aspectRatio === selectedAspectRatio
    );

    if (shape) {
      const { __typename, aspectRatio, ...boundary } = shape;

      return setInitialShape(boundary);
    }

    return setInitialShape(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCopyrightInfo, imgUrl, selectedAspectRatio]);

  useEffect(() => {
    const prevImageUrl = prevImages?.find(
      (prevImage) => prevImage.aspectRatio === selectedAspectRatio
    )?.url;
    const imageUrl = images?.find(
      (image) => image.aspectRatio === selectedAspectRatio
    )?.url;

    if (prevImageUrl && prevImageUrl !== imageUrl) {
      setInitialShape(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, setInitialShape]);

  const handleError = useCallback(
    (apolloError: ApolloError, title: string) => {
      const [error] = apolloError.graphQLErrors;

      if (error?.name === 'VersionMismatchError' && setVersionMismatch) {
        setVersionMismatch(error as unknown as VersionMismatchError);
      } else {
        Modal.error({
          title,
          content: (
            <>
              <p style={{ whiteSpace: 'pre-line' }}>{error.message}</p>
              {'details' in error && (
                <ul>
                  {Object.values((error as InputError).details || []).map(
                    ({ message, context: { key } }: any) => (
                      <li key={key}>{message}</li>
                    )
                  )}
                </ul>
              )}
            </>
          ),
        });
      }
    },
    [setVersionMismatch]
  );

  useEffect(() => {
    if (removeImageError) {
      handleError(removeImageError, 'Remove image error');
    }
  }, [handleError, removeImageError]);

  useEffect(() => {
    if (setImagesError) {
      handleError(setImagesError, 'Set images error');
    }
  }, [handleError, setImagesError]);

  useEffect(() => {
    if (uploadFileError) {
      handleError(uploadFileError, 'Upload file error');
    }
  }, [handleError, uploadFileError]);

  useEffect(() => {
    if (updateThumbnailsError) {
      handleError(updateThumbnailsError, 'Update thumbnails error');
      setUploadingThumbnails(false);
    }
  }, [handleError, updateThumbnailsError]);

  return (
    <MediaStepContainer>
      {imgUrl ? (
        <div style={{ height: '430px' }}>
          {removingImage ? (
            <SpinLoader loadingMessage="Removing image..." />
          ) : replacingImage ? (
            <SpinLoader loadingMessage="Replacing image..." />
          ) : uploadingThumbnails ? (
            <SpinLoader loadingMessage="Uploading thumbnails..." />
          ) : (
            <ImageCropContainer
              imgUrl={imgUrl}
              imgMimetype={imgMimetype}
              isThumbnailGenerating={isThumbnailGenerating}
              selectedAspectRatio={selectedAspectRatio}
              onSelectAspectRatio={setAspectRatio}
              onDelete={onDeleteImage}
              onReplace={onUpload}
              confirmThumbnailGeneration={confirmThumbnailGeneration}
              cropSupported={cropSupported}
              handleImageLoading={handleImageLoading}
              onSelect={manualCrop}
              initialShape={initialShape}
              disabled={disabled}
              copyrightFormItem={
                <>
                  {/*
                  // @ts-ignore */}
                  <Form.Item label="Copyright information">
                    <Input
                      type="text"
                      placeholder="Enter copyright information..."
                      value={formCopyrightInformation || ''}
                      onChange={onCopyrightChange}
                      disabled={disabled}
                      maxLength={128}
                    />
                  </Form.Item>
                </>
              }
            />
          )}
        </div>
      ) : (
        <UploadImageContainer>
          <UploadImageWrapper
            onUpload={onUpload}
            confirmThumbnailGeneration={confirmThumbnailGeneration}
            aspectRatio={selectedAspectRatio}
            disabled={disabled}
            uploadComponent={({
              customRequest,
              onChange,
              isImageUploading,
            }) => (
              <UploadImage
                customRequest={customRequest}
                onChange={onChange}
                isImageUploading={isImageUploading}
              />
            )}
          />
          <SelectAspectRatio
            defaultAspectRatio={selectedAspectRatio}
            onChange={setAspectRatio}
            cropSupported={cropSupported}
          />
        </UploadImageContainer>
      )}
    </MediaStepContainer>
  );
};

const MediaStepContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > .preview-title {
    margin: 10px 0;
    font-size: 12px;
    font-weight: 600;
  }
`;

const UploadImageContainer = styled.div`
  width: 640px;
  min-height: 430px;
`;

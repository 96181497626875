import React, {
  ReactElement,
  useCallback,
  useRef,
  cloneElement,
  useEffect,
} from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';
import { Form } from '@ant-design/compatible';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { StyledButton } from './description-step.styles';
import { LastUpdatedDate } from '../last-updated-date.component';
import { Lockers } from '../../update-data-form/use-fields-lockers';
import usePrevious from '../../../../hooks/use-previous';

export type FormItemLockerComponentProps = {
  name: keyof Lockers;
  label: string;
  component: ReactElement;
  isFormDisabled?: boolean;
  lockers: Lockers;
  setLockers: React.Dispatch<Partial<Lockers>>;
  warningMessage: string;
  dataLastUpdated?: string | null;
};

export const FormItemLockerComponent = ({
  name,
  label,
  component,
  isFormDisabled = false,
  lockers,
  setLockers,
  warningMessage,
  dataLastUpdated,
}: FormItemLockerComponentProps) => {
  const ref = useRef<HTMLFormElement>();
  const lockerState = lockers[name];
  const prevLockerState = usePrevious(lockerState);

  const enable = useCallback(() => {
    setLockers({
      [name]: false,
    });
  }, [name, setLockers]);

  const onClick = useCallback(() => {
    Modal.confirm({
      title: 'Do you really want to continue?',
      content: warningMessage,
      okText: 'Continue',
      onOk: enable,
    });
  }, [enable, warningMessage]);

  useEffect(() => {
    if (prevLockerState === true && lockerState === false) {
      ref?.current?.focus();
    }
  }, [lockerState, prevLockerState]);

  return (
    <>
      {/*
      // @ts-ignore */}
      <Form.Item label={label}>
        <LockerContainer $locked={lockerState}>
          {cloneElement(component, { ref })}
          {lockerState && (
            <StyledButton
              onClick={onClick}
              disabled={isFormDisabled}
              icon={<EditOutlined />}
            >
              Edit
            </StyledButton>
          )}
        </LockerContainer>
        {dataLastUpdated && <LastUpdatedDate date={dataLastUpdated} />}
      </Form.Item>
    </>
  );
};

const LockerContainer = styled.div.attrs(
  ({ $locked }: { $locked: boolean }) => ({
    $locked,
  })
)`
  display: flex !important;

  & > *:first-child {
    width: ${(props) => (props.$locked ? 'calc(100% - 100px)' : '100%')};
  }
`;

import { useQuery } from '@apollo/react-hooks';
import { VersionDocument, VersionQueryResult } from '../../../resolver.types';
import React from 'react';
import { CloudServerOutlined, DesktopOutlined } from '@ant-design/icons';
import { Avatar, List, Tooltip } from 'antd';

const AppVersion = () => {
  const { data, error } = useQuery<VersionQueryResult['data']>(VersionDocument);

  return (
    <List size="small">
      {data && (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Tooltip title="Asgard version">
                <Avatar>
                  <CloudServerOutlined />
                </Avatar>
              </Tooltip>
            }
            title={data.version.currentVersion}
          />
        </List.Item>
      )}
      {error && (
        <List.Item>
          <List.Item.Meta
            title={`Unable to fetch asgard version: ${error.message}`}
          />
        </List.Item>
      )}
      <List.Item>
        <List.Item.Meta
          avatar={
            <Tooltip title="Midgard version">
              <Avatar>
                <DesktopOutlined />
              </Avatar>
            </Tooltip>
          }
          title={process.env.REACT_APP_npm_package_version}
        />
      </List.Item>
    </List>
  );
};

export default AppVersion;

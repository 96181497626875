import React, { FC, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useFiltersContext } from '../../../hooks';
import { FilterInputProps } from '../../../types';
import Tags from './tags.component';

export type TagsWrapperProps = FilterInputProps<string[]> & {
  onChange?: (value: string[]) => any;
  defaultOpen?: boolean;
  maxTagCount?: number;
};

const TagsWrapper: FC<TagsWrapperProps> = ({
  onChange,
  name,
  width,
  initialValue = [],
  label,
  ...props
}) => {
  const { getFieldDecorator, getFieldValue } = useFiltersContext();
  const value = getFieldValue(name);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <>
      {/*
      // @ts-ignore */}
      <Form.Item
        label={label}
        colon={false}
        style={{ minWidth: '100px', width }}
        className="compatible-row"
      >
        {getFieldDecorator(name, {
          initialValue,
        })(<Tags maxTagWidth="110px" maxTagCount={1} {...props} />)}
      </Form.Item>
    </>
  );
};

export default TagsWrapper;

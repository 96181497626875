import React from 'react';
import differenceInYears from 'date-fns/differenceInYears';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { Alert } from 'antd';
import { Warning } from './outdated-description.styles';

import { DateFormats } from '../../../utils/date-formats';

type OutdatedDescriptionProps = {
  date: string;
  author: string;
};

export const OutdatedDescription = ({
  date,
  author,
}: OutdatedDescriptionProps) => {
  const isoDate = parseISO(date);
  const yearDiff = differenceInYears(Date.now(), isoDate);

  const warningText = `Watch out! Outdated text. Last edited on ${format(
    isoDate,
    DateFormats.date
  )} by ${author}`;

  return (
    <React.Fragment>
      {yearDiff >= 1 && (
        <Warning>
          <Alert message={warningText} type="warning" />
        </Warning>
      )}
    </React.Fragment>
  );
};

import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';

export type DropdownArrowProps = {
  $open: boolean;
};

export const DropdownArrow = styled(DownOutlined).attrs({
  type: 'down',
})<DropdownArrowProps>`
  &.anticon {
    color: #bfbfbf;
    font-size: 16px;
    pointer-events: none;

    svg {
      transition: transform 0.3s;
    }

    ${({ $open }) =>
      $open &&
      `
      svg {
        transform: rotate(180deg); 
      }
    `}
  }
`;

import * as React from 'react';

export const makeUrlClickable = (message: string): React.ReactNode => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const uuidPattern = /([0-9a-fA-F-]{36})/;

  const parts = [];
  let lastIndex = 0;

  let match;
  while ((match = urlPattern.exec(message)) !== null) {
    parts.push(
      <span key={lastIndex}>{message.slice(lastIndex, match.index)}</span>
    );

    const url = match[0];
    const uuidMatch = uuidPattern.exec(url);
    const uuid = uuidMatch ? uuidMatch[0] : url;

    parts.push(
      <a key={match.index} href={url} target="_blank" rel="noopener noreferrer">
        {uuid}
      </a>
    );

    lastIndex = match.index + url.length;
  }

  parts.push(<span key={lastIndex}>{message.slice(lastIndex)}</span>);

  return <>{parts}</>;
};

import styled from 'styled-components';
import { Drawer as AntdDrawer } from 'antd';

export const StyledDrawer = styled(AntdDrawer)`
  .ant-drawer-close {
    top: 15px;
    color: #e1e1e1;
  }
`;

export const DrawerContainer = styled.div`
  width: 100%;
  height: calc(100% - 91px);
  display: flex;

  & > .content-container {
    flex: 1;

    & > .content {
      flex: 1;
      overflow-y: scroll;
      height: calc(100% - 62px);
    }

    & > .bottom-bar {
      display: flex;
      justify-content: flex-end;
      background-color: #f3f3f3;
      padding: 15px 25px;

      & > .ant-btn:first-child {
        margin-right: 10px;
      }
    }
  }
`;

export const DrawerNotice = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .notice-icon {
    font-size: 3rem;
  }

  .notice-text {
    font-size: 1rem;
  }

  > * {
    margin-top: 10px;
  }
`;
export const DrawerContentWrapper = styled.div.attrs({
  className: 'drawer-content-wrapper',
})`
  width: 100%;
  height: calc(100% - 110px);
  display: flex;

  & > .content-container {
    flex: 1;

    & > .content {
      flex: 1;
      overflow-y: scroll;
      height: calc(100% - 62px);
    }

    & > .bottom-bar {
      display: flex;
      justify-content: flex-end;
      background-color: #f3f3f3;
      padding: 15px 25px;

      & > .ant-btn:first-child {
        margin-right: 10px;
      }
    }
  }
`;
export const ContentContainer = styled.div.attrs({
  className: 'drawer-content-container',
})`
  width: 100%;
  max-width: 775px;

  & .ant-input {
    border-radius: 0;

    &[readonly] {
      background-color: #fafafa;
    }
  }

  & .brand-tags-container {
    margin: 15px 0;
  }
`;
export const MenuContainer = styled.div`
  height: 100%;
  background-color: #fcfcfc;
  border-right: 2px solid #f1f1f1;
  min-width: 260px;
  overflow-y: auto;
  overflow-x: hidden;
`;

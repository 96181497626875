import React from 'react';
import { TopbarContainer } from '../../layout/drawer/drawer-topbar.styles';
import { Thumbnail } from '../../common/thumbnail/thumbnail.component';
import styled from 'styled-components';
import SaleState from '../sale-state/sale-state.component';
import { EventSalesStatus } from '../../../resolver.types';

export type DrawerTopbarProps = {
  imageUrl?: string;
  title: string;
  organizerName: string;
  organizerID: string;
  saleStatus?: EventSalesStatus | null;
  saleStartDateTime?: string | null;
};

const StyledTopbar = styled(TopbarContainer)`
  justify-content: flex-start;

  .event-title-meta {
    margin-left: 10px;
  }

  .event-ticket-meta {
    flex: 1;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
  }
`;

const DrawerTopbar = ({
  imageUrl,
  organizerID,
  organizerName,
  saleStartDateTime,
  saleStatus,
  title,
}: DrawerTopbarProps) => {
  return (
    <StyledTopbar>
      <Thumbnail imageUrl={imageUrl} showBrand={false} />
      <div className="event-title-meta">
        <div>{title}</div>
        <div>
          <small>
            {organizerName} ({organizerID})
          </small>
        </div>
      </div>
      {saleStatus && (
        <div className="event-ticket-meta">
          <SaleState saleDate={saleStartDateTime} saleStatus={saleStatus} />
        </div>
      )}
    </StyledTopbar>
  );
};

export default DrawerTopbar;

import { Column } from '../components/common/data-list/types';
import { Link } from 'react-router-dom';
import { routes } from '../route-urls';
import * as React from 'react';
import { useMemo } from 'react';
import { Event } from '../resolver.types';
import SaleState from '../components/event/sale-state/sale-state.component';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DateFormats } from '../utils/date-formats';
import styled from 'styled-components';
import { EventTitle } from '../components/event/event-title/event-title.component';
import { EventBrandedContainers } from '../components/event/event-branded-containers/event-branded-containers.component';
import { displayTicketsAvailability } from '../utils/display-tickets-availability';
import Prices from '../components/event/prices/prices.componenet';

const StyledLink = styled(Link)`
  &,
  &:hover {
    color: #000;
  }
`;

export type EventBrandedContainersProps = {
  record: Event;
};

const useEventColumns = () => {
  return useMemo<Array<Column<Event>>>(
    () => [
      {
        title: 'Events ({count})',
        dataIndex: 'title',
        render: (_, record) => {
          return (
            <StyledLink to={routes.setEventData(record.id)}>
              <EventTitle
                {...record}
                title={record.title ?? ''}
                imageUrl={record.imageUrl ?? ''}
                genre={record?.genres[0].name}
                startDateTime={null}
              />
            </StyledLink>
          );
        },
      },
      {
        title: 'Assignments',
        dataIndex: 'relatedBrandedContainersCount',
        sortable: true,
        render: (_, record) => {
          return <EventBrandedContainers record={record} />;
        },
      },
      {
        title: 'Status',
        dataIndex: 'saleStartDateTime',
        render: (_, record) => {
          return (
            <StyledLink to={routes.setEventData(record.id)}>
              <SaleState
                saleDate={record.saleStartDateTime}
                saleStatus={record.salesStatus}
              />
            </StyledLink>
          );
        },
      },
      {
        title: 'Prices',
        render: (_, record) => (
          <StyledLink to={routes.setEventData(record.id)}>
            <Prices
              minPrices={record.pricing.minPrices}
              maxPrices={record.pricing.maxPrices}
            />
          </StyledLink>
        ),
      },
      {
        title: 'Available tickets',
        render: (_, record) => (
          <StyledLink to={routes.setEventData(record.id)}>
            <span>
              {displayTicketsAvailability(
                record.ticketsAmount,
                record.totalTicketsAmount
              )}
            </span>
          </StyledLink>
        ),
      },
      {
        title: 'Event Date',
        dataIndex: 'startDateTime',
        sortable: true,
        render: (_, record) => (
          <StyledLink to={routes.setEventData(record.id)}>
            <span>
              {format(parseISO(record.startDateTime), DateFormats.dateTime)}
            </span>
          </StyledLink>
        ),
      },
    ],
    []
  );
};

export default useEventColumns;

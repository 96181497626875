import styled from 'styled-components';

export const BrandTagsContainer = styled.div`
  margin-top: 25px;
  display: flex;
`;

export const BrandLabel = styled.label.attrs(
  ({ color }: { color: string }) => ({
    color,
  })
)`
  position: relative;
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }

  & > input {
    display: none;
  }

  & > .active-border {
    width: 27px;
    height: 3px;
    background-color: ${(props) => props.color};
    display: none;
    position: absolute;
    bottom: -7px;
  }

  input:checked ~ .active-border {
    display: block;
  }
`;

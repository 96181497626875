import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  Reducer,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { AppAction } from '../../../context/shared';
import { QueryParams } from './types';

export type DataListState = Record<string, QueryParams>;
export type DataListActions = AppAction<
  'SET_LIST_VARIABLES',
  { dataType: string; variables: QueryParams }
>;

export type DataListApi = {
  dispatch: Dispatch<DataListActions>;
  state: DataListState;
};

const initialState: DataListState = {};

const DataListContext = createContext<DataListApi | null>(null);

export const useDataListContext = () =>
  useContext(DataListContext) as DataListApi;

const reducer = (state: DataListState, action: DataListActions) => {
  switch (action.type) {
    case 'SET_LIST_VARIABLES': {
      return {
        ...state,
        [action.dataType]: action.variables,
      };
    }

    default:
      return state;
  }
};

const DataListProvider = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer<Reducer<DataListState, DataListActions>>(
    reducer,
    initialState
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <DataListContext.Provider value={value}>
      {children}
    </DataListContext.Provider>
  );
};

export default DataListProvider;

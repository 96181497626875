import { TopbarContainer } from '../../layout/drawer/drawer-topbar.styles';
import React from 'react';
import { Title } from '../title/title';
import { Artist } from '../../../resolver.types';

export type DrawerTopbarProps = {
  artist: Artist;
};

export const DrawerTopbar = ({ artist }: DrawerTopbarProps) => {
  return (
    <TopbarContainer>
      <Title artist={artist} />
    </TopbarContainer>
  );
};

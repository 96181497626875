import { ModalHeader, StyledModal } from '../../layout/modal/modal.styles';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArtistImage from '../../../assets/images/artist.svg';
import { ModalImage } from '../form-modal/form-modal.styles';
import { ModalContainer } from './delete-modal.styles';
import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Spin } from 'antd';
import { DeleteModalHookConfig, useDeleteModal } from './hooks';
import { routes } from '../../../route-urls';
import { List } from '../../branded-container/list/list.component';
import { BrandedContainer } from '../../../resolver.types';

export type DeleteModalProps = {
  onCancel?: () => any;
  onClose?: () => any;
  afterDelete?: DeleteModalHookConfig['afterDelete'];
};

type ModalFooterProps = {
  removeArtistResult: any;
  removeArtistLoading: boolean;
  removeArtist: () => Promise<void>;
  handleClose: () => any;
  handleCancel: () => any;
};

const ModalFooter = ({
  removeArtist,
  removeArtistLoading,
  removeArtistResult,
  handleCancel,
  handleClose,
}: ModalFooterProps) => {
  return (
    <div>
      {!removeArtistResult && (
        <Button
          type="primary"
          className="remove-artist-btn"
          loading={removeArtistLoading}
          onClick={removeArtist}
          danger
        >
          {!removeArtistLoading && (
            <>
              <DeleteOutlined />
              <span> Delete artist</span>
            </>
          )}
          {removeArtistLoading && <span>Deleting...</span>}
        </Button>
      )}
      <Button
        disabled={removeArtistLoading}
        onClick={removeArtistResult ? handleClose : handleCancel}
      >
        {removeArtistResult ? 'Close' : 'Cancel'}
      </Button>
    </div>
  );
};

export const DeleteModal = ({
  onCancel,
  onClose,
  afterDelete,
}: DeleteModalProps) => {
  const { id: artistID } = useParams() as { id: string };
  const navigate = useNavigate();
  const onCancelDefault = useCallback(() => navigate(-1), [navigate]);
  const onCloseDefault = useCallback(
    () => navigate(routes.artists()),
    [navigate]
  );
  const handleCancel = onCancel || onCancelDefault;
  const handleClose = onClose || onCloseDefault;

  const {
    error,
    relatedBrandedContainers,
    relatedBrandedContainersLoading,
    removeArtistLoading,
    removeArtistResult,
    removeArtist,
    relatedBrandedContainersCount,
    fetchAllRelatedBrandedContainers,
  } = useDeleteModal({ artistID, afterDelete });

  const hasRelatedArtists =
    relatedBrandedContainers && relatedBrandedContainers.metadata.total > 0;
  return (
    <StyledModal
      onCancel={removeArtistResult ? handleClose : handleCancel}
      footer={
        !relatedBrandedContainersLoading &&
        !hasRelatedArtists &&
        !error && (
          <ModalFooter
            removeArtistResult={removeArtistResult}
            removeArtistLoading={removeArtistLoading}
            removeArtist={removeArtist}
            handleCancel={handleCancel}
            handleClose={handleClose}
          />
        )
      }
      title={<ModalHeader>Delete artist</ModalHeader>}
      visible
    >
      <ModalContainer>
        <ModalImage noGuttersBottom src={ArtistImage} alt="" />
        {error && <Alert message={error} showIcon type="error" />}
        {relatedBrandedContainersLoading && !relatedBrandedContainers && (
          <Spin tip="Loading..." size="large" />
        )}
        {relatedBrandedContainers && !removeArtistResult && (
          <>
            {hasRelatedArtists && (
              <>
                <div style={{ marginBottom: '15px' }}>
                  The following {relatedBrandedContainersCount} branded
                  container(s) feature still the artist or mention it in the
                  list of related artists:
                </div>
                <List
                  loading={relatedBrandedContainersLoading}
                  total={relatedBrandedContainersCount}
                  brandedContainers={
                    relatedBrandedContainers.items as BrandedContainer[]
                  }
                  onShowAll={fetchAllRelatedBrandedContainers}
                />
              </>
            )}

            {!hasRelatedArtists && !error && (
              <div>Are you sure you want to delete this artist?</div>
            )}
          </>
        )}
        {removeArtistResult && (
          <Alert
            style={{ width: '100%' }}
            message="Artist deleted."
            showIcon
            type="success"
          />
        )}
      </ModalContainer>
    </StyledModal>
  );
};

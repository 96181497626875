import React, { FC, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import {
  SearchIcon,
  StyledSelect,
} from '../../../../common/search-results/search-results.styles';

export type TagsProps = {
  onChange?: (value: string[]) => any;
  value?: string[] | null;
  defaultOpen?: boolean;
  maxTagCount?: number;
  allowClear?: boolean;
  placeholder?: React.ReactNode;
  maxTagWidth?: string;
  width?: string;
};

const Tags: FC<TagsProps> = ({
  onChange,
  defaultOpen = false,
  value,
  placeholder,
  maxTagCount,
  allowClear,
  maxTagWidth,
  width,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <StyledSelect
      $maxTagWidth={maxTagWidth}
      maxTagCount={maxTagCount}
      style={{ width }}
      notFoundContent={<span>Start typing to add options...</span>}
      className={`detailed-tags ${
        maxTagCount && value && value.length - maxTagCount >= 10
          ? ' more-than-10'
          : ' less-than-10'
      }`}
      showArrow
      mode="tags"
      placeholder={placeholder}
      open={open}
      onDropdownVisibleChange={setOpen}
      suffixIcon={<SearchIcon />}
      onChange={onChange as any}
      allowClear={allowClear}
      value={value}
    />
  );
};

export default Tags;

import {
  Event,
  GetBrandedContainersDocument,
  GetBrandedContainersQueryResult,
  QueryFilterBrandedContainersArgs,
} from '../../../resolver.types';
import React, { useEffect, useState } from 'react';
import DrawerNavigation from '../drawer-navigation/drawer-navigation.component';
import { MenuContainer } from '../../layout/drawer/drawer.styles';
import {
  FormContainer,
  ContentContainer,
  DrawerContentWrapper,
  PaddingWrapper,
} from './drawer-content.styles';
import { Route, Routes, Navigate } from 'react-router';
import DescriptionStep from './steps/description-step.component';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import InformationStep from './steps/information-step.component';
import { useQuery } from '@apollo/react-hooks';
import BrandedContainersStep from './steps/branded-containers-step.component';
import type { EventDetailsAction } from './shared/types';
import { DrawerActionsBar } from '../../common/drawer-actions-bar/drawer-actions-bar.component';

export type DrawerContentProps = {
  event: Event;
  actions?: EventDetailsAction[];
  handleClose: () => void;
  disabled?: boolean;
};

const DrawerContent = ({
  event,
  actions,
  handleClose,
  disabled,
}: DrawerContentProps) => {
  const [brandedContainersCount, setBrandedContainersCount] = useState<
    number | undefined
  >();

  const { data } = useQuery<
    GetBrandedContainersQueryResult['data'],
    QueryFilterBrandedContainersArgs
  >(GetBrandedContainersDocument, {
    variables: {
      pagination: {
        start: 0,
        limit: 10,
      },
      filters: {
        eventIDs: [event.id],
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.filterBrandedContainers) {
      setBrandedContainersCount(data.filterBrandedContainers.metadata.total);
    } else {
      setBrandedContainersCount(undefined);
    }
  }, [data]);

  return (
    <DrawerContentWrapper>
      <MenuContainer>
        <DrawerNavigation brandedContainersCount={brandedContainersCount} />
      </MenuContainer>
      <ContentContainer>
        <FormContainer
          style={{
            height: actions?.length ? `calc(100% - 64px)` : '100%',
          }}
        >
          <Form hideRequiredMark className="compatible-row">
            <Routes>
              <Route
                path={`description`}
                element={
                  <PaddingWrapper>
                    <DescriptionStep event={event} readonly />
                  </PaddingWrapper>
                }
              />
              <Route
                path={`information`}
                element={
                  <PaddingWrapper>
                    <InformationStep event={event} readonly />
                  </PaddingWrapper>
                }
              />
              <Route
                path={`branded-containers/*`}
                element={
                  <BrandedContainersStep event={event} disabled={disabled} />
                }
              />
              <Route
                path={`*`}
                element={<Navigate replace to="description" />}
              />
            </Routes>
          </Form>
        </FormContainer>
        {actions && (
          <DrawerActionsBar
            actions={actions}
            description="1 event selected"
            callback={handleClose}
            data={event}
          />
        )}
      </ContentContainer>
    </DrawerContentWrapper>
  );
};

export default DrawerContent;

import styled from 'styled-components';

export const CommentsContainer = styled.div`
  & .last-modified-comment {
    margin-top: 15px;
  }

  & .no-comments {
    font-size: 11px;
  }
`;

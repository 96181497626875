import styled from 'styled-components';

export const SourceButtonsContainer = styled.div`
  display: flex;
`;

export const ContentLabel = styled.label`
  position: relative;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;

  & > .label-text {
    font-size: 12px;
    color: #6d6d6d;
    font-weight: 500;
  }

  & > input {
    display: none;
  }

  & > .active-border {
    width: 100%;
    height: 3px;
    background-color: #e4a140;
    display: none;
  }

  input:checked ~ .active-border {
    display: block;
  }
`;

import styled from 'styled-components';
import { Select, Input } from 'antd';

export const StyledSelect = styled(Select)`
  width: calc(100% - 100px) !important;

  .ant-select-selector {
    border-radius: 0px !important;
    padding: 4px 11px !important;
    height: 40px !important;
  }
`;

export const StyledInput = styled(Input)`
  width: calc(100% - 100px) !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  background-color: transparent !important;

  &.ant-input[disabled] {
    background-color: #f5f5f5 !important;
  }
`;

import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { FormEvent, ReactNode, useCallback, useState } from 'react';
import { hasErrors } from '../utils/forms';

export type UseFormConfig<Input extends object> = {
  form: WrappedFormUtils<Input>;
  submitHandler: (values: Input, form: Form<Input>) => void | Promise<void>;
  errorHandler?: <Err extends Error>(
    err: Err,
    form: Form<Input>
  ) => void | Promise<void>;
};

const useForm = <Input extends object>({
  form,
  submitHandler,
  errorHandler,
}: UseFormConfig<Input>): Form<Input> => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<FormState>({
    message: '',
  });

  const formResult: Form<Input> = {
    ...form,
    isSubmitting,
    hasFieldErrors: () => hasErrors(form.getFieldsError()),
    handleSubmit: useCallback(
      async (e?: FormEvent) => {
        if (e) {
          e.preventDefault();
        }

        form.validateFieldsAndScroll();

        if (hasErrors(form.getFieldsError())) {
          return;
        }

        setSubmitting(true);

        try {
          await submitHandler(form.getFieldsValue() as Input, formResult);
        } catch (e) {
          setSubmitting(false);

          if (errorHandler) {
            await errorHandler(e as Error, formResult);
          }
        }

        setSubmitting(false);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [form, submitHandler, errorHandler]
    ),
    status,
    setStatus,
  };

  return formResult;
};

export default useForm;

export type Form<Input> = WrappedFormUtils<Input> & {
  handleSubmit: (e?: FormEvent) => any;
  hasFieldErrors: () => boolean;
  isSubmitting: boolean;
  status: FormState;
  setStatus: (state: FormState) => void;
};

export type FormState = {
  message?: string | ReactNode;
  state?: 'success' | 'info' | 'warning' | 'error';
};

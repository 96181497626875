import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { DataList } from '../../../common/data-list/data-list.component';
import {
  BrandedContainer,
  GetBrandedContainerDocument,
  GetBrandedContainersDocument,
  Order,
  OrderDirection,
} from '../../../../resolver.types';
import AllBrandedContainersTab from './all-branded-containers-tab/all-branded-containers-tab-container.component';
import { ApolloError, useMutation } from '@apollo/client';
import { UPDATE_BRANDED_CONTAINER } from '../../../../graphql/mutations/update-branded-container';
import { defaultBrandedContainersColumns } from '../../../../hooks/use-related-branded-containers-columns';
import {
  InputError,
  VersionMismatchError,
} from 'yggdrasil-shared/domain/error';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Modal, Tabs } from 'antd';
import { noop } from '../../../../utils/text';
import { Container } from './branded-containers-step.styles';
import { Route, Routes, useNavigate } from 'react-router';
import { routes } from '../../../../route-urls';
import BrandedContainerDetailsDrawer from './branded-containers-details-drawer.component';
import { BrandedContainerDetailsAction } from '../../drawer-content/shared/types';

type BrandedContainersStepProps = {
  relatedContainerIDs: string[];
  brandedContainerId: string;
  brand: string;
  version: number;
  disabled?: boolean;
  setVersionMismatch?: (error: VersionMismatchError | null) => any;
};

const order: Order = {
  column: 'relevance',
  direction: OrderDirection.Desc,
};

export const BrandedContainersStep = ({
  relatedContainerIDs,
  brandedContainerId,
  brand,
  version,
  disabled = false,
  setVersionMismatch,
}: BrandedContainersStepProps) => {
  const navigate = useNavigate();

  const [detailsActions, setDetailsActions] = useState<
    BrandedContainerDetailsAction[]
  >([]);

  const closeDetailsOpen = useCallback(() => {
    navigate(
      routes.setBrandedContainerData(brandedContainerId, 'branded-containers')
    );
    setDetailsActions([]);
  }, [brandedContainerId, navigate]);

  const queryVars = useMemo(
    () => ({
      brand,
      filters: {
        ids: relatedContainerIDs,
      },
    }),
    [brand, relatedContainerIDs]
  );

  const [updateRelatedBrandedContainers, { loading }] = useMutation(
    UPDATE_BRANDED_CONTAINER,
    {
      refetchQueries: [
        {
          query: GetBrandedContainerDocument,
          variables: { id: brandedContainerId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleError = useCallback(
    (error: Error) => {
      if (setVersionMismatch) {
        if (error.name === 'VersionMismatchError') {
          message.destroy();
          setVersionMismatch(error as VersionMismatchError);
        } else {
          Modal.error({
            title: 'Update related branded containers error',
            content: (
              <>
                <p style={{ whiteSpace: 'pre-line' }}>{error.message}</p>
                {'details' in error && (
                  <ul>
                    {Object.values((error as InputError).details || []).map(
                      ({ message, context: { key } }: any) => (
                        <li key={key}>{message}</li>
                      )
                    )}
                  </ul>
                )}
              </>
            ),
          });
          setVersionMismatch(null);
        }
      }
    },
    [setVersionMismatch]
  );

  const onSelectRelatedBrandedContainers = useCallback(
    async (selectedBrandedContainers: BrandedContainer[]) => {
      const assigningRelatedBcsKey = 'assiging-related-bc';
      const selectedIDs = selectedBrandedContainers.map(
        (brandedContainer) => brandedContainer.id
      );

      message.loading({
        key: assigningRelatedBcsKey,
        content: 'Assigning related branded container(s)...',
      });

      try {
        await updateRelatedBrandedContainers({
          variables: {
            brandedContainer: {
              version,
              id: brandedContainerId,
              relatedContainerIDs: Array.from(
                new Set([...relatedContainerIDs, ...selectedIDs])
              ),
            },
          },
        });

        message.success({
          key: assigningRelatedBcsKey,
          content: 'Success! Branded container(s) assigned.',
          duration: 3,
        });
      } catch (e) {
        const apolloError = e as ApolloError;
        const [error] = apolloError.graphQLErrors;
        handleError(error);
      }
    },
    [
      updateRelatedBrandedContainers,
      version,
      brandedContainerId,
      relatedContainerIDs,
      handleError,
    ]
  );

  const onRemoveBrandedContainers = useCallback(
    async (selectedBrandedContainers: BrandedContainer[]) => {
      const removingRelatedBcsKey = 'removing-related-bc';

      const containerIDsToRemove = selectedBrandedContainers.map(
        (brandedContainer) => brandedContainer.id
      );

      const newRelatedBrandedContainers = Array.from(
        new Set([
          ...relatedContainerIDs.filter(
            (id) => !containerIDsToRemove.includes(id)
          ),
        ])
      );

      message.loading({
        key: removingRelatedBcsKey,
        content: 'Removing related branded container(s)...',
      });

      try {
        await updateRelatedBrandedContainers({
          variables: {
            brandedContainer: {
              version,
              id: brandedContainerId,
              relatedContainerIDs: newRelatedBrandedContainers,
            },
          },
        });

        message.success({
          key: removingRelatedBcsKey,
          content: 'Success! Branded container(s) removed.',
          duration: 3,
        });
      } catch (e) {
        const apolloError = e as ApolloError;
        const [error] = apolloError.graphQLErrors;
        handleError(error);
      }
    },
    [
      updateRelatedBrandedContainers,
      version,
      brandedContainerId,
      relatedContainerIDs,
      handleError,
    ]
  );

  const assignAction: BrandedContainerDetailsAction = useMemo(
    () => ({
      label: 'Assign',
      icon: <PlusOutlined />,
      type: 'primary',
      onClick: onSelectRelatedBrandedContainers,
      loading: loading,
    }),
    [onSelectRelatedBrandedContainers, loading]
  );

  const removeAction: BrandedContainerDetailsAction = useMemo(
    () => ({
      label: 'Remove',
      icon: <CloseOutlined />,
      type: 'primary',
      onClick: onRemoveBrandedContainers,
      loading: loading,
    }),
    [onRemoveBrandedContainers, loading]
  );

  const defaultDetailsActions: BrandedContainerDetailsAction[] = useMemo(
    () => [removeAction],
    [removeAction]
  );

  const allBrandedContainerDetailsActions: BrandedContainerDetailsAction[] =
    useMemo(() => [assignAction], [assignAction]);

  const goToDetailView = React.useCallback(
    (relatedBC: BrandedContainer) => {
      navigate(routes.bcRelatedBcsDetailView(brandedContainerId, relatedBC.id));
    },
    [brandedContainerId, navigate]
  );

  const openDetails =
    (actions: BrandedContainerDetailsAction[]) =>
    (record: BrandedContainer) => {
      setDetailsActions(actions);
      goToDetailView(record);
    };

  const relatedBrandedContainersColumns = defaultBrandedContainersColumns({
    onClick: openDetails(defaultDetailsActions),
    disabled,
    showBrand: false,
  });

  useEffect(() => {
    setDetailsActions(
      detailsActions.map((action) => {
        return {
          ...action,
          loading,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Container>
      <Routes>
        <Route
          path={`:id/*`}
          element={
            <BrandedContainerDetailsDrawer
              handleClose={closeDetailsOpen}
              actions={detailsActions}
            />
          }
        />
      </Routes>
      <Tabs defaultActiveKey="brandedContainers">
        <Tabs.TabPane
          tab={`Assigned Branded Containers (${relatedContainerIDs.length})`}
          key="brandedContainers"
          style={{
            fontSize: '12px',
            margin: '0 32px 0 0',
            padding: '12px 16px',
          }}
        >
          <DataList<BrandedContainer>
            dataType="relatedBrandedContainers"
            query={GetBrandedContainersDocument}
            columns={relatedBrandedContainersColumns}
            queryVars={queryVars}
            customOrder={order}
            useRowSelection={!disabled}
            rowSelectionActions={[
              {
                label: loading ? 'Removing...' : 'Remove',
                icon: <CloseOutlined />,
                type: 'primary',
                resetSelection: true,
                onClick: onRemoveBrandedContainers,
                loading,
              },
            ]}
            selectionLabelProvider={(count: number) => (
              <span>
                <strong>{count}</strong>{' '}
                {noop(count, 'branded container', 'branded containers')}{' '}
                selected
              </span>
            )}
            rowSelectionColPush={0}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={`All Branded Containers`}
          key={'allBrandedContaienrs'}
          style={{
            fontSize: '12px',
            margin: '0 32px 0 0',
            padding: '12px 16px',
          }}
        >
          <AllBrandedContainersTab
            brandedContainerId={brandedContainerId}
            relatedBrandedContainerIDs={relatedContainerIDs}
            brand={brand}
            onSelect={onSelectRelatedBrandedContainers}
            openDetails={openDetails(allBrandedContainerDetailsActions)}
            disabled={disabled}
            loading={loading}
          />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};

import { Except } from 'type-fest';
import {
  Multiselect,
  MultiSelectProps,
} from '../../common/multiselect/multiselect.component';
import { LabeledValue } from 'antd/lib/select';
import { camelCaseToSentence } from '../../../../../utils/text';
import React from 'react';
import labelsMap from './labels-map';
import { BrandedContainerMissingContent } from '../../../../../resolver.types';

export type MissingContentProps = Except<
  MultiSelectProps,
  'choices' | 'perPage' | 'showSearch'
>;

/**
 * @note "value" has been added - required by antd v4
 */
const choices: LabeledValue[] = Object.values(
  BrandedContainerMissingContent
).map((value) => ({
  key: value,
  label: labelsMap[value] ?? camelCaseToSentence(value),
  value,
}));

const MissingContent = (props: MissingContentProps) => {
  return (
    <Multiselect
      maxTagWidth="110px"
      maxTagCount={1}
      choices={choices}
      showSearch={false}
      perPage={10}
      {...props}
    />
  );
};

export default MissingContent;

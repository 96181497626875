import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import { DataList } from '../../components/common/data-list/data-list.component';
import {
  AssignEventsDocument,
  AssignEventsMutationResult,
  AssignEventsMutationVariables,
  AssignEventsToBcInput,
  BrandedContainer,
  Event,
  GetBrandedContainersForEventAssignmentDocument,
} from '../../resolver.types';
import { Column } from '../../components/common/data-list/types';
import useBrandedContainerColumns from '../../hooks/use-branded-container-columns';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal } from 'antd';
import styled from 'styled-components';
import { TopicDropdown } from '../../components/filters/inputs/branded-container/topic-dropdown/topic-dropdown.component';
import { RowSelectionAction } from '../../components/common/data-list/hooks/use-selection';
import { noop } from '../../utils/text';
import { routes } from '../../route-urls';
import { useMutation } from '@apollo/react-hooks';
import { InputError } from 'yggdrasil-shared/domain/error';

const StyledModal = styled(Modal)`
  &.ant-modal {
    min-width: 900px;
    max-width: 70vw;

    .ant-modal-body {
      padding: 0;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  .button-container {
    flex: 1;
    text-align: right;
    margin-right: 30px;
  }
`;

const AssignEventsToBcPage = () => {
  const { state } = useLocation() as {
    state: { events?: Event[] } | undefined;
  };
  const navigate = useNavigate();

  const baseColumns = useBrandedContainerColumns();

  const [mutate, { loading, error }] = useMutation<
    AssignEventsMutationResult['data'],
    AssignEventsMutationVariables
  >(AssignEventsDocument, {
    refetchQueries: ['GetEvents'],
  });

  const columns: Array<Column<BrandedContainer>> = useMemo(() => {
    return baseColumns.length > 2 ? [baseColumns[0], baseColumns[2]] : [];
  }, [baseColumns]);

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const actions: Array<RowSelectionAction<BrandedContainer>> = useMemo(
    () => [
      {
        icon: <PlusOutlined />,
        label: loading ? 'Assigning...' : 'Assign',
        onClick: async (brandedContainers) => {
          const input: AssignEventsToBcInput = {
            eventIDs: state!.events!.map((event) => event.id),
            brandedContainerIDs: brandedContainers.map(
              (brandedContainer) => brandedContainer.id
            ),
          };

          const { data } = await mutate({
            variables: {
              input,
            },
          });

          if (data?.assignEvents?.length) {
            navigate(-1);

            message.success('Events assigned!');
          }
        },
        type: 'primary',
        loading,
      },
    ],
    [loading, mutate, state, navigate]
  );

  const actionLabelProvider = useCallback((count: number) => {
    return (
      <span>
        <strong>{count}</strong>{' '}
        {noop(count, 'branded container', 'branded containers')} selected
      </span>
    );
  }, []);

  useEffect(() => {
    if (!state?.events?.length) {
      navigate(-1);
    }
  }, [navigate, state]);

  useEffect(() => {
    if (error) {
      Modal.error({
        title: 'Assign events error',
        content: (
          <>
            <p style={{ whiteSpace: 'pre-line' }}>{error.message}</p>
            {'details' in error && (
              <ul>
                {Object.values((error as InputError).details || []).map(
                  ({ message, context: { key } }: any) => (
                    <li key={key}>{message}</li>
                  )
                )}
              </ul>
            )}
          </>
        ),
      });
    }
  }, [error]);

  return (
    <StyledModal
      width="900px"
      className="compatible-row"
      onCancel={handleCancel}
      visible
      title={
        <Title>
          <span>Assign events</span>
          <div className="button-container">
            <Link
              to={{
                pathname: routes.selectPromitionItems('events'),
              }}
              state={state}
            >
              <Button icon={<PlusOutlined />}>
                Create new branded container
              </Button>
            </Link>
          </div>
        </Title>
      }
      footer={null}
    >
      <DataList<BrandedContainer>
        rowSelectionPosition="sticky"
        useRowSelection
        selectionLabelProvider={actionLabelProvider}
        rowSelectionColPush={0}
        rowSelectionActions={actions}
        filters={[
          <Col key={1} xxl={16} xl={16} md={16} xs={24}>
            <TopicDropdown
              name="topic"
              placeholder="Branded container topic..."
            />
          </Col>,
        ]}
        columns={columns}
        query={GetBrandedContainersForEventAssignmentDocument}
        dataType="brandedContainersForAssignment"
      />
    </StyledModal>
  );
};

export default AssignEventsToBcPage;

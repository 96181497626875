import React from 'react';
import {
  Dropdown as EntityDropdown,
  DropdownProps as EntityDropdownProps,
} from '../../common/generic-dropdown/dropdown.component';
import {
  Venue,
  VenuesByIdsDocument,
  VenuesByIdsQuery,
  SearchVenuesDocument,
  SearchVenuesQuery,
} from '../../../resolver.types';
import { getName } from '../../../utils/venue';

type VenueType = Pick<Venue, 'id' | 'name'>;
type VenuesByIdQueryType = Omit<VenuesByIdsQuery, '__typename'>;
type SearchVenuesQueryType = Omit<SearchVenuesQuery, '__typename'>;
type VenueDropdownProps = Omit<
  EntityDropdownProps<VenueType, VenuesByIdQueryType, SearchVenuesQueryType>,
  'entityProps'
>;

export const VenueDropdown = (props: VenueDropdownProps) => {
  return (
    <EntityDropdown<VenueType, VenuesByIdQueryType, SearchVenuesQueryType>
      {...props}
      entityProps={{
        displayName: 'venues',
        idKey: 'id',
        nameKey: 'name',
        entitiesByIdsQueryKey: 'venuesByIds',
        entitiesSearchQueryKey: 'searchVenues',
        entitiesByIdsDocument: VenuesByIdsDocument,
        entitiesSearchDocument: SearchVenuesDocument,
        nameGetter: (venue) => getName(venue as Venue),
      }}
    />
  );
};

import {
  DrawerContentWrapper as StyledDrawerContentWrapper,
  ContentContainer as StyledContentContainer,
} from '../../layout/drawer/drawer.styles';
import styled from 'styled-components';

export const DrawerContentWrapper = styled(StyledDrawerContentWrapper)`
  height: 100%;
`;

export const PaddingWrapper = styled.div`
  padding: 10px 20px;
`;

export const ContentContainer = styled(StyledContentContainer)`
  height: 100%;
  .ant-input {
    border-radius: 0;

    &[disabled] {
      color: inherit;
    }
  }

  .ant-select-disabled {
    .ant-select-selection__choice {
      color: #000 !important;
    }
  }
`;

export const FormContainer = styled.div`
  overflow-y: scroll;
`;

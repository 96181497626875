import styled from 'styled-components';

export const AvatarContainer = styled.div.attrs(
  ({ color }: { color: string }) => ({
    color,
  })
)`
  background-color: ${(props) => props.color};
  color: #ffffff;
  font-size: 11px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-weight: 600;
  text-align: center;
  background-position: center;
  background-size: cover;
`;

import { FilterInputProps } from '../../../types';
import { useFiltersContext } from '../../../hooks';
import React, { useCallback, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { AutoComplete as AntdAutocomplete, Input } from 'antd';
import { ResetButton } from '../../../common/reset-button/reset-button.component';
import { SearchIcon } from '../../../../common/search-results/search-results.styles';

export type AutoCompleteProps = FilterInputProps<string> & {
  dataSource?: string[];
  onChange?: (value: string) => any;
};
/**
 *
 * @note dataSource parameter has been removed. It was unused and going to be deprecated
 */
export const AutoComplete = ({
  name,
  placeholder,
  initialValue = '',
  width,
  onChange,
  label,
}: AutoCompleteProps) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } =
    useFiltersContext();
  const decorator = getFieldDecorator(name, {
    initialValue,
    preserve: true,
  });
  const value = getFieldValue(name) ?? initialValue;

  const resetValue = useCallback(() => {
    setFieldsValue({
      [name]: initialValue,
    });
  }, [setFieldsValue, initialValue, name]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <>
      {/*
      // @ts-ignore */}
      <Form.Item
        colon={false}
        label={label}
        style={{ width, minWidth: '100px' }}
        className="compatible-row"
      >
        {Boolean(value) && <ResetButton onClick={resetValue} />}
        {decorator(
          <AntdAutocomplete placeholder={placeholder}>
            <Input suffix={<SearchIcon className="input-icon" />} />
          </AntdAutocomplete>
        )}
      </Form.Item>
    </>
  );
};

import * as React from 'react';
import { useMemo } from 'react';
import { DataList } from '../../components/common/data-list/data-list.component';
import { TopicDropdown } from '../../components/filters/inputs/branded-container/topic-dropdown/topic-dropdown.component';
import {
  BrandedContainer,
  GetBrandedContainersDocument,
} from '../../resolver.types';
import { PublishingState } from '../../components/filters/inputs/branded-container/publishing-state/publishing-state';
import { RegionSettings } from '../../components/filters/inputs/branded-container/region-settings/region-settings.component';
import { AutoComplete } from '../../components/filters/inputs/common/autocomplete/autocomplete.component';
import { Col } from 'antd';
import useMedia from '../../hooks/use-media';
import { Range } from '../../components/filters/inputs/common/range/range.component';
import useAppContext from '../../hooks/use-app-context';
import MissingContent from '../../components/filters/inputs/branded-container/missing-content/missing-content.component';
import useBrandedContainerColumns from '../../hooks/use-branded-container-columns';
import CityOrZip from '../../components/filters/inputs/common/city-or-zip/city-or-zip.component';
import { pastPickerRanges } from '../../components/filters/inputs/common/date-select/utils';
import { DateSelectWrapper } from '../../components/filters/inputs/common/date-select/date-select.wrapper';
import TopicTypeWithMethod from '../../components/filters/inputs/branded-container/topic-type/topic-type-with-method.component';

export const ContainersPage = () => {
  const {
    state: { selectedBrand },
  } = useAppContext();

  const displayedColumns = useMedia<number>(
    ['(min-width: 1600px)', '(min-width: 1136px) and (max-width: 1336px)'],
    [5, 3],
    4
  );

  // const maxTagTextLength = useMedia<number>(['(max-width: 1025px)'], [10], 6);

  const columns = useBrandedContainerColumns();

  const queryVars = useMemo(
    () => ({
      brand: selectedBrand?.name,
    }),
    [selectedBrand]
  );

  return (
    <DataList<BrandedContainer>
      filters={[
        <Col key={0} xxl={8} xl={10} lg={11} md={16} xs={24}>
          <TopicDropdown
            label="Branded container topic"
            name="topic"
            placeholder="Branded container topic..."
          />
        </Col>,
        <Col key={1} xxl={5} xl={6} lg={7} md={12} xs={18}>
          <TopicTypeWithMethod
            label="Topic type"
            name="topicType"
            placeholder="Topic type..."
          />
        </Col>,
        <Col key={2} xxl={5} xl={4} lg={6} md={12} xs={18}>
          <AutoComplete
            label="Branded container title"
            name="title"
            placeholder="Branded container title..."
          />
        </Col>,
        <Col key={3} xxl={3} xl={4} lg={4} md={8} xs={12}>
          <PublishingState
            name="state"
            placeholder="Publishing state..."
            label="Publishing state"
          />
        </Col>,
        <Col key={4} xxl={3} xl={4} lg={4} md={6} xs={9}>
          <DateSelectWrapper
            timeSelection={false}
            name="lastModified"
            placeholder="Last modified at..."
            label="Last modified"
            pickerRanges={pastPickerRanges}
          />
        </Col>,
        <Col key={5} xl={3} md={6} xs={9}>
          <Range
            label="Importance"
            onlyAbsolute
            onlyIntegers
            max={10}
            name="relevance"
            placeholder="Importance..."
          />
        </Col>,
        <Col key={6} xl={4} md={8} xs={12}>
          <CityOrZip
            label="City or postal code"
            placeholder="City or postal code..."
            showReset
          />
        </Col>,
        <Col key={7} xl={4} md={8} xs={12}>
          <RegionSettings
            name="regionDisplay"
            placeholder="Region settings..."
            label="Region settings"
          />
        </Col>,
        /**
         * @note commented temporarily - filter out of usage
         */
        // <Col key={8} xl={4} md={8} xs={12}>
        //   <DateSelectWrapper
        //     timeSelection={false}
        //     name="eventsStart"
        //     placeholder="Events start..."
        //     label="Events Start"
        //     disabled={true}
        //     tooltipText="Filter is currently not available"
        //   />
        // </Col>,
        <Col key={9} xl={4} md={8} xs={12}>
          <AutoComplete
            name="lastModifiedBy"
            placeholder="Last modified by..."
            label="Last modified by"
          />
        </Col>,
        <Col key={10} xl={4} md={8} xs={12}>
          <MissingContent
            placeholder="Missing content..."
            label="Missing content"
            name="missingContent"
          />
        </Col>,
      ]}
      filterProps={{
        displayFiltersCount: displayedColumns,
        fullWidth: true,
      }}
      query={GetBrandedContainersDocument}
      queryVars={queryVars}
      columns={columns}
      dataType="brandedContainers"
    />
  );
};

import * as React from 'react';
import {
  PromotionItem,
  PromotionItemCard,
} from '../promotion-item/promotion-item.component';
import { StyledPromotionItemsContainer } from './promotion-items-container.styles';

type PromotionItemsContainerProps = {
  promotionItems: PromotionItemCard[];
  onTogglePromotionItem: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const PromotionItemsContainer = ({
  promotionItems,
  onTogglePromotionItem,
}: PromotionItemsContainerProps) => {
  return (
    <StyledPromotionItemsContainer>
      {promotionItems.map((promotionItem) => (
        <PromotionItem
          {...promotionItem}
          key={promotionItem.key}
          onChange={onTogglePromotionItem}
        />
      ))}
    </StyledPromotionItemsContainer>
  );
};

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

export const StyledMenuLink = styled(Link)`
  font-size: 12px;
  color: #4b4b4b;
`;

export const StyledMenu = styled(Menu)`
  & > .ant-dropdown-menu-item {
    padding-right: 60px;
  }
`;

import React, { useEffect } from 'react';
import { DocumentNode } from 'graphql';
import { useQuery } from '@apollo/client';
import usePrevious from './use-previous';
import isEqual from 'lodash.isequal';
import { Pagination } from '../resolver.types';

type UseInfiniteScrollProps<T extends QueryVars = QueryVars> = {
  query: DocumentNode;
  variables?: T;
  offset?: number;
};

type QueryVars = Record<string, any>;

export type InfiniteScrollQueryVariables<T extends QueryVars = QueryVars> =
  T & {
    pagination: Pagination;
  };

export const useInfiniteScroll = <T extends QueryVars = QueryVars>({
  query,
  variables,
  offset = 10,
}: UseInfiniteScrollProps<T>) => {
  const prevVariables = usePrevious(variables);

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [maxPages, setMaxPages] = React.useState<number | null>(null);
  const isFirstFetchCompleted = React.useRef(false);
  const [items, setItems] = React.useState<any>([]);
  const [fetchingData, setFetchingData] = React.useState(true);

  const { data, refetch, loading } = useQuery<
    any,
    InfiniteScrollQueryVariables<T>
  >(query, {
    variables: {
      pagination: {
        start: currentPage - 1,
        limit: offset,
      },
      ...variables!,
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (!loading && !isFirstFetchCompleted.current) {
      isFirstFetchCompleted.current = true;

      if (!data ?? !Object.values(data)) {
        return;
      }

      const {
        items,
        metadata: { total },
      } = Object.values(data)[0] as any;

      setItems(items);
      setMaxPages(Math.ceil(total / offset));
      setFetchingData(false);

      setCurrentPage(2);
    }
  }, [data, loading, offset]);

  const onFetchMore = async () => {
    setFetchingData(true);

    if (currentPage - 1 === maxPages!) {
      return;
    }

    const { data } = await refetch({
      pagination: {
        start: (currentPage - 1) * offset,
        limit: offset,
      },
      ...variables!,
    });

    setFetchingData(false);

    setItems([...items, ...(Object.values(data)[0] as any).items]);

    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (isEqual(variables, prevVariables) || fetchingData) {
      return;
    }

    setCurrentPage(1);
    setItems([]);
    isFirstFetchCompleted.current = false;
    refetch();
  }, [variables, prevVariables, refetch, fetchingData]);

  return { items, onFetchMore, fetchingData };
};

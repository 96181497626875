import React from 'react';
import { Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type WarningProps = {
  message: string;
  hidden?: boolean;
};

export const Warning = ({ message, hidden }: WarningProps) => {
  return (
    <Tag
      hidden={hidden}
      icon={<ExclamationCircleOutlined />}
      color="orange"
      style={{ marginLeft: 'auto', marginRight: 0 }}
    >
      {message}
    </Tag>
  );
};

import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  cloneElement,
  ReactElement,
} from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Row } from 'antd';
import type { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { FilterValues } from './types';
import useForm, { Form as FormHook } from '../../hooks/use-form';
import {
  FiltersButtons,
  FiltersButtonsContainer,
  FiltersContainer,
  FiltersForm,
  FiltersWrapper,
} from './filters.styles';
import { SpinLoader } from '../common/spin-loader/spin-loader.component';

export interface FilterProps extends FormComponentProps<FilterValues> {
  children?: ReactElement[] | ReactElement;
  onSubmit: (value: FilterValues) => any;
  onReset?: (value: FilterValues) => any;
  displayFiltersCount?: number;
  onChange?: (value: FilterValues) => any;
  defaultFilters?: FilterValues;
  generateInGridButtons?: (
    key: number | string,
    buttons: ReactNode,
    renderedFilters: ReactNode[]
  ) => ReactNode;
  fullWidth?: boolean;
}

export type FiltersContextType = FormHook<FilterValues> & {
  isReset: boolean;
};

export const FiltersContext = createContext<FiltersContextType | null>(null);

const Filters = (props: FilterProps) => {
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(true);
  const toggleShowAll = useCallback(() => {
    setShowAll(!showAll);
  }, [showAll]);

  const [isReset, setIsReset] = useState(false);

  const {
    form,
    onSubmit,
    displayFiltersCount = 10,
    onReset,
    onChange,
    generateInGridButtons,
    fullWidth,
  } = props;

  const children = props.children
    ? Array.isArray(props.children)
      ? props.children
      : [props.children]
    : undefined;

  const formHook = useForm({
    form,
    submitHandler: onSubmit,
  });
  const context = { ...formHook, isReset };

  const { resetFields, getFieldsValue } = form;
  const value = getFieldsValue();

  const handleReset = useCallback(() => {
    resetFields();
    setIsReset(true);

    const value = getFieldsValue() as FilterValues;

    if (onReset) {
      onReset(value);
    }

    setTimeout(() => setIsReset(false), 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onReset, getFieldsValue]);

  const renderChildren =
    children &&
    children.map((field, index) => {
      if (index >= displayFiltersCount) {
        const clonnedField = cloneElement(field, {
          ...field.props,
          style: { display: showAll ? 'block' : 'none' },
        });
        return clonnedField;
      }

      return field;
    });

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [onChange, value]);

  // Set show all to false after initial render, so that all fields will get registered in form hook
  // (they have to render first in order to achieve that)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAll(false);
      setLoading(false);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const buttons = (
    <>
      <Button onClick={handleReset}>
        <CloseOutlined />
        <span>Reset filter</span>
      </Button>
      <Button
        className="apply-filters"
        onClick={formHook.handleSubmit}
        type="primary"
      >
        <CheckOutlined />
        <span>Apply filter</span>
      </Button>
    </>
  );

  return (
    <FiltersContext.Provider value={context}>
      <FiltersWrapper className="filters-wrapper">
        <FiltersContainer $fullWidth={fullWidth}>
          <FiltersForm $fullWidth={fullWidth}>
            {loading && (
              <SpinLoader className="filters-loader" loadingMessage={''} />
            )}
            <Row
              className="filters-row compatible-col"
              align="middle"
              gutter={[20, 0]}
              style={{ display: 'flex' }}
            >
              {renderChildren}
              {generateInGridButtons &&
                generateInGridButtons(
                  children ? children.length : 0,
                  buttons,
                  renderChildren ? renderChildren : []
                )}
            </Row>
          </FiltersForm>
        </FiltersContainer>

        {children && children.length > displayFiltersCount && (
          <>
            <Divider style={{ marginBottom: 0, marginTop: 0 }} />
            <div
              style={{ width: '100%', textAlign: 'center', marginTop: '5px' }}
            >
              <Button size="small" onClick={toggleShowAll} type="link">
                {showAll ? (
                  <UpOutlined style={{ fontSize: '12px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )}
                {showAll ? 'less filters' : 'more filters'}
              </Button>
            </div>
            <Divider style={{ marginTop: '5px', marginBottom: 0 }} />
          </>
        )}
      </FiltersWrapper>
      <FiltersButtonsContainer>
        {!generateInGridButtons && <FiltersButtons>{buttons}</FiltersButtons>}
      </FiltersButtonsContainer>
    </FiltersContext.Provider>
  );
};

export default Form.create<FilterProps>({ name: 'filters' })(Filters);

import { Artist, Venue, Place } from '../resolver.types';

const ignoredKeys = ['artists', 'venue', 'place', '__typename'];
const order = [
  'artistsEntities',
  'genres',
  'venueEntities',
  'campaign',
  'eventSeries',
  'placeEntities',
  'workOfArt',
];

type TopicDataTuple = [
  string,
  string | string[] | Artist[] | Venue[] | Place[]
];

export const getTitle = (topicData: Record<string, any>) => {
  const createTitle = (title: string, [key, topicValue]: TopicDataTuple) => {
    if (ignoredKeys.includes(key)) {
      return title;
    }

    if (key === 'artistsEntities') {
      return `${title} ${(topicValue as Artist[])
        .map((artist) => artist.name)
        .join(' ')}`;
    }

    if (key === 'venueEntities') {
      return `${title} ${(topicValue as Venue[])
        .map((venue) => `${venue.name}${venue.city ? ` (${venue.city})` : ''}`)
        .join(' ')}`;
    }

    if (key === 'placeEntities') {
      return `${title} ${(topicValue as Place[])
        .map((place) => place.unique_city_name)
        .join(' ')}`;
    }

    return `${title} ${
      Array.isArray(topicValue) ? topicValue.join(' ') : topicValue
    }`;
  };

  const titleArr = Object.entries(topicData);

  const sortedTitle = (
    order
      .map((topicType) => {
        const topicValue = titleArr.find(([key]) => key === topicType);

        if (!topicValue) {
          return null;
        }

        const [, value] = topicValue;

        return value ? topicValue : null;
      })
      .filter(Boolean) as Array<[string, any]>
  )
    .reduce(createTitle, '')
    .trim();

  return sortedTitle;
};

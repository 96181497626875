export const camelCaseToSentence = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');

  return ucfirst(result);
};

export const snakeCaseToSentence = (text: string): string =>
  ucfirst(text.replace(/_/g, ' ').toLowerCase());

export const ucfirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const clipText = (
  text: string,
  desiredLength: number,
  dots = '...'
): string => {
  if (text.length <= desiredLength) {
    return text;
  }

  return `${text.slice(0, desiredLength)} ${dots}`;
};

export const noop = (value: number, singular: string, plural: string) =>
  value === 1 ? singular : plural;

import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DateFormats } from '../../../utils/date-formats';
import { UserRoundAvatar } from '../user-round-avatar/user-round-avatar.component';
import {
  LastModifiedContainer,
  ModificationInfoContainer,
} from './last-modified-item.styles';

export type LastModifiedItemProps = {
  updateAction: string;
  updatedAt: string;
  updatedBy: string;
};

export const LastModifiedItem = ({
  updateAction,
  updatedAt,
  updatedBy,
}: LastModifiedItemProps) => {
  return (
    <LastModifiedContainer className="last-modified-item">
      <UserRoundAvatar username={updatedBy} />
      <ModificationInfoContainer>
        <div className="modification-date">
          At: {format(parseISO(updatedAt), DateFormats.dateTime)}
        </div>
        <div className="modification-issuer">From: {updatedBy}</div>
        <div className="modification-type">{updateAction}</div>
      </ModificationInfoContainer>
    </LastModifiedContainer>
  );
};

import {
  CloseCircleOutlined,
  ExportOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Alert, Button, message, Modal, Popconfirm, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { VersionMismatchError } from 'yggdrasil-shared/domain/error';
import { routes } from '../../../route-urls';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  BrandedContainer,
  GetActivityForBrandedContainerDocument,
  GetActivityForBrandedContainerQueryResult,
  GetActivityForBrandedContainerQueryVariables,
  GetBrandedContainerDocument,
  GetBrandedContainerQueryResult,
  GetBrandedContainerQueryVariables,
} from '../../../resolver.types';

export type VersionMismatchProps = {
  error: VersionMismatchError;
  visible?: boolean;
  onCancel?: () => any;
  onDataFetched?: (data: BrandedContainer) => any;
  toggleDirty: (state: boolean) => void;
};

const { Paragraph } = Typography;

const VersionMismatch = ({
  error,
  visible = true,
  onCancel,
  onDataFetched,
  toggleDirty,
}: VersionMismatchProps) => {
  const [
    refetchBrandedContainer,
    { loading: bcLoading, data: bcData, error: bcError },
  ] = useLazyQuery<
    GetBrandedContainerQueryResult['data'],
    GetBrandedContainerQueryVariables
  >(GetBrandedContainerDocument, {
    fetchPolicy: 'network-only',
  });
  const [
    refetchActivityLog,
    { loading: activityLogLoading, error: activityLogError },
  ] = useLazyQuery<
    GetActivityForBrandedContainerQueryResult['data'],
    GetActivityForBrandedContainerQueryVariables
  >(GetActivityForBrandedContainerDocument, {
    fetchPolicy: 'network-only',
  });

  const loading = useMemo(
    () => bcLoading || activityLogLoading,
    [bcLoading, activityLogLoading]
  );

  const handleRefetch = useCallback(() => {
    toggleDirty(false);

    refetchBrandedContainer({
      variables: {
        id: error.recordID,
      },
    });

    refetchActivityLog({
      variables: {
        id: error.recordID,
        pagination: {
          start: 0,
          limit: 3,
        },
      },
    });
  }, [refetchBrandedContainer, error, refetchActivityLog, toggleDirty]);

  useEffect(() => {
    if (bcData?.brandedContainer) {
      message.success('Branded container re-fetched.');

      if (onDataFetched) {
        onDataFetched(bcData.brandedContainer as BrandedContainer);
      }
    }
  }, [bcData, onDataFetched]);

  return (
    <Modal
      footer={[
        <Button disabled={loading} onClick={onCancel} key={0}>
          Close
        </Button>,
        <Popconfirm
          onConfirm={handleRefetch}
          title="This will undo all your current changes. Are you sure?"
          key={1}
        >
          <Button loading={loading} icon={<ReloadOutlined />} type="primary">
            Re-fetch
          </Button>
        </Popconfirm>,
      ]}
      onCancel={onCancel}
      visible={visible}
      title={
        <span>
          <CloseCircleOutlined
            style={{ color: '#f5222d', marginRight: '5px' }}
          />
          <span>Version mismatch error</span>
        </span>
      }
    >
      <div>
        {bcError && <Alert type="error" message={bcError.message} />}
        {activityLogError && (
          <Alert type="error" message={activityLogError.message} />
        )}
        <Paragraph>
          There was an version mismatch error in current branded container.
        </Paragraph>
        <Paragraph>
          Your version: <strong>{error.givenVersion}</strong>, current version:{' '}
          <strong>{error.currentVersion}</strong>.
        </Paragraph>
        <Paragraph>
          <Link
            target="_blank"
            className="text--primary"
            style={{ textDecoration: 'underline' }}
            to={routes.setBrandedContainerData(error.recordID)}
          >
            View current branded container <ExportOutlined />
          </Link>
        </Paragraph>
      </div>
    </Modal>
  );
};

export default VersionMismatch;

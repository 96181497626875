import { keycloakClient } from '../context/keycloak.context';

export type UserData = {
  accessToken?: string;
  name?: string;
  email?: string;
};

export const USER_DATA_KEY = 'user_data';

export const getUserData = (): UserData => {
  const userData = localStorage.getItem(USER_DATA_KEY);
  const parsedUserData = JSON.parse(userData || '{}');

  return {
    accessToken: keycloakClient.token,
    name: parsedUserData.name,
    email: parsedUserData.email,
  };
};

export const setUserData = (userData: UserData) => {
  localStorage.setItem(
    USER_DATA_KEY,
    JSON.stringify({
      name: userData.name,
      email: userData.email,
    })
  );
};

import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  BrandedContainerState,
  GetBrandedContainerDocument,
  GetBrandedContainersDocument,
  GetBrandedContainerStateDocument,
  UpdateBrandedContainerStateDocument,
  UpdateBrandedContainerStateMutationResult,
  GetBrandedContainersQueryResult,
} from '../../../resolver.types';
import { message } from 'antd';
import { useDataListContext } from '../../common/data-list/data-list.context';

type UpdateStateData = {
  id: string;
  version: number;
  state: BrandedContainerState;
  reason?: string;
};

type UpdateStateProps = {
  afterUpdate?: (state: BrandedContainerState) => void;
};

const messageKey = 'update-branded-container-state';

const useUpdateState = ({ afterUpdate }: UpdateStateProps) => {
  const [update] = useMutation<
    UpdateBrandedContainerStateMutationResult['data']
  >(UpdateBrandedContainerStateDocument);
  const [loading, setLoading] = React.useState(false);

  const {
    state: { brandedContainers: brandedContainerQueryData },
  } = useDataListContext();

  const updateState = useCallback(
    async (input: UpdateStateData) => {
      const isDeleted = input.state === BrandedContainerState.Deleted;

      setLoading(true);
      message.loading({
        key: messageKey,
        content: isDeleted
          ? 'Removing branded container...'
          : 'Updating branded container state...',
      });

      try {
        await update({
          variables: { input },
          refetchQueries: () => {
            const queries: any = [];

            if (!isDeleted) {
              queries.push({
                query: GetBrandedContainerDocument,
                variables: { id: input.id },
              });

              queries.push({
                query: GetBrandedContainerStateDocument,
                variables: { id: input.id },
              });
            }

            return queries;
          },
          update: (cache, { data }) => {
            if (!data?.updateBrandedContainerState || !isDeleted) {
              return;
            }

            const queryData = {
              query: GetBrandedContainersDocument,
              variables: brandedContainerQueryData,
            };

            try {
              const brandedContainers =
                cache.readQuery<GetBrandedContainersQueryResult['data']>(
                  queryData
                );

              if (!brandedContainers?.filterBrandedContainers) {
                return;
              }

              cache.writeQuery<GetBrandedContainersQueryResult['data']>({
                ...queryData,
                data: {
                  filterBrandedContainers: {
                    ...brandedContainers.filterBrandedContainers,
                    metadata: {
                      ...brandedContainers.filterBrandedContainers.metadata,
                      total:
                        brandedContainers.filterBrandedContainers.metadata
                          .total - 1,
                    },
                    items:
                      brandedContainers.filterBrandedContainers.items!.filter(
                        (bc) => bc!.id !== input.id
                      ),
                  },
                },
              });
            } catch {}
          },
          awaitRefetchQueries: true,
        });

        message.success({
          key: messageKey,
          content:
            input.state === BrandedContainerState.Deleted
              ? 'Branded container removed.'
              : 'Branded container state updated.',
        });

        if (afterUpdate) {
          afterUpdate(input.state);
        }
      } catch {
        message.error({
          key: messageKey,
          content: 'Branded container state update failed.',
        });
      } finally {
        if (input.state !== BrandedContainerState.Deleted) {
          setLoading(false);
        }
      }
    },
    [afterUpdate, brandedContainerQueryData, update]
  );

  return { updateState, loading };
};

export default useUpdateState;

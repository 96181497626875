import { Route, Routes } from 'react-router-dom';
import { DeleteModal } from '../../components/branded-container/delete-modal/delete-modal.component';
import React from 'react';

export const DeleteBrandedContainerPage = () => {
  return (
    <Routes>
      <Route
        element={<DeleteModal />}
        path={`delete-branded-container/:id/:version`}
      />
    </Routes>
  );
};

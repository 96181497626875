import React from 'react';
import {
  CustomLocale,
  DataList,
  DataListProps,
} from '../../../common/data-list/data-list.component';
import { Event, GetFilterEventsDocument } from '../../../../resolver.types';
import { Column } from '../../../common/data-list/types';
import { customOrder } from './custom-order';

type EventListProps = {
  columns: Array<Column<Event>>;
  rowSelection?: object;
  ids?: string[];
  onQueryUpdate?: DataListProps<Event>['onQueryUpdate'];
  onlyFuture?: boolean;
  customLocale?: CustomLocale;
};

export const EventList = ({
  columns,
  rowSelection,
  ids,
  onlyFuture,
  customLocale,
  onQueryUpdate,
}: EventListProps) => {
  const queryVars = React.useMemo(
    () => ({
      filters: {
        ids,
      },
    }),
    [ids]
  );

  return (
    <DataList<Event>
      columns={columns}
      query={GetFilterEventsDocument}
      dataType="brandedContainerEvents"
      queryVars={queryVars}
      onQueryUpdate={onQueryUpdate}
      customOrder={customOrder}
      onlyFuture={onlyFuture}
      customLocale={customLocale}
      {...rowSelection}
    />
  );
};

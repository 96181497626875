import { Except } from 'type-fest';
import {
  Multiselect,
  MultiSelectProps,
} from '../../common/multiselect/multiselect.component';
import { BrandedContainerState } from '../../../../../resolver.types';
import { LabeledValue } from 'antd/lib/select';
import { camelCaseToSentence } from '../../../../../utils/text';
import React from 'react';

export type PublishingStateProps = Except<
  MultiSelectProps,
  'choices' | 'initialValue'
>;

const choices: LabeledValue[] = Object.values(BrandedContainerState).map(
  (val) => ({
    key: val,
    label: camelCaseToSentence(val),
    value: val,
  })
);

/**
 * @note "value" has been added BUT commented to not break filters reset
 * Look for "@note Adjust graphQL schemas to accept "value"." to get more details
 */

const initialValue: LabeledValue[] = [
  // @ts-ignore
  {
    key: BrandedContainerState.Draft,
    // value: BrandedContainerState.Draft,
    label: camelCaseToSentence(BrandedContainerState.Draft),
  },
  // @ts-ignore
  {
    key: BrandedContainerState.Published,
    // value: BrandedContainerState.Published,
    label: camelCaseToSentence(BrandedContainerState.Published),
  },
];

export const PublishingState = (props: PublishingStateProps) => (
  <Multiselect
    {...props}
    maxTagWidth="110px"
    maxTagCount={1}
    perPage={10}
    initialValue={initialValue}
    showSearch={false}
    choices={choices}
  />
);

import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import debounce from 'lodash.debounce';
import {
  CoordinatesData,
  CoordinatesDocument,
  CoordinatesQueryResult,
  CoordinatesQueryVariables,
} from '../../../resolver.types';
import SearchResults, {
  SearchResultChoice,
  SearchResultsProps,
} from '../search-results/search-results.component';
import { Except } from 'type-fest';
import type { RefSelectProps } from 'antd/lib/select';

export type ChoicesMapper = (
  locationData: CoordinatesData
) => SearchResultChoice;

export type CityOrZipProps = Except<SearchResultsProps, 'name' | 'choices'> & {
  debounceTimeout?: number;
  onChoicesChange?: (choices: SearchResultChoice[]) => any;
  choicesMapper?: ChoicesMapper;
  name?: string;
};

const defaultPerPage = 100;
const requiredSearchLength = 2;

/**
 * @note "value" has been added - required by antd v4
 */

const defaultChoicesMapper: ChoicesMapper = (
  locationData: CoordinatesData
) => ({
  key: {
    lat: locationData.lat,
    lon: locationData.lon,
  },
  label: locationData.searchString.toString(),
  value: {
    lat: locationData.lat,
    lon: locationData.lon,
  },
});

export const CityOrZip = forwardRef<RefSelectProps, CityOrZipProps>(
  (
    {
      debounceTimeout = 500,
      onChoicesChange,
      choicesMapper = defaultChoicesMapper,
      placeholder = 'City or postal code...',
      name = 'cityOrZip',
      ...props
    },
    ref
  ) => {
    const [search, setSearch] = useState<string | null>(null);
    const [perPage, setPerPage] = useState(defaultPerPage);
    const handleSearch = debounce(setSearch, debounceTimeout);

    const [fetch, { data, loading }] = useLazyQuery<
      CoordinatesQueryResult['data'],
      CoordinatesQueryVariables
    >(CoordinatesDocument, {
      fetchPolicy: 'network-only',
    });
    const [choices, setChoices] = useState<SearchResultChoice[]>([]);
    const [total, setTotal] = useState(0);

    const handleShowAllChange = useCallback(
      (showAll: boolean) => {
        setPerPage(
          showAll && data?.coordinates
            ? data.coordinates.metadata.total
            : defaultPerPage
        );
      },
      [data]
    );

    useEffect(() => {
      if (!data?.coordinates) {
        setChoices([]);
        setTotal(0);

        return;
      }

      const newChoices: SearchResultChoice[] =
        data.coordinates.items?.map(choicesMapper) ?? [];

      setChoices(newChoices);
      setTotal(data.coordinates.metadata.total);
    }, [data, choicesMapper]);

    useEffect(() => {
      if (!search || search.length < requiredSearchLength) {
        setChoices([]);
        setTotal(0);

        return;
      }

      fetch({
        variables: {
          searchBy: search,
          pageSize: perPage,
        },
      });
    }, [fetch, search, perPage]);

    useEffect(() => {
      return () => {
        handleSearch.cancel();
      };
    }, [handleSearch]);

    useEffect(() => {
      if (onChoicesChange) {
        onChoicesChange(choices);
      }
    }, [onChoicesChange, choices]);

    return (
      <SearchResults
        {...props}
        ref={ref}
        name={name}
        onShowAllChange={handleShowAllChange}
        choicesSkip={total}
        totalChoices={total}
        loading={loading}
        onSearch={handleSearch}
        placeholder={placeholder}
        choices={choices}
        requiredSearchLength={requiredSearchLength}
        setChevronIcon
        maxTagWidth="110px"
        maxTagCount={1}
      />
    );
  }
);

import styled from 'styled-components';

export const DrawerContentContainer = styled.div`
  flex: 1;

  & > .content {
    flex: 1;
    overflow-y: scroll;
    height: calc(100% - 62px);
  }

  & > .bottom-bar {
    display: flex;
    justify-content: flex-end;
    background-color: #f3f3f3;
    padding: 15px 25px;

    & > .ant-btn:first-child {
      margin-right: 10px;
    }
  }

  .drawer-buttons {
    width: 100%;
    justify-content: flex-end;
    display: flex;

    button {
      margin-left: 10px;
    }
  }
`;

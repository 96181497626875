import styled from 'styled-components';

export const CommentContainer = styled.div`
  width: 295px;
`;

export const CommentContent = styled.div`
  border: 1px solid #e3e3e3;
  padding: 10px 15px;
  font-size: 12px;
  color: #717171;
`;

export const CommentFooter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const CommentInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const CommentAuthor = styled.span`
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 500;
`;

export const CommentDate = styled.span`
  font-size: 11px;
`;

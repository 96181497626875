import { Except } from 'type-fest';
import {
  Multiselect,
  MultiSelectProps,
} from '../../common/multiselect/multiselect.component';
import React from 'react';
import { TopicTypes } from '../../../../../resolver.types';
import { camelCaseToSentence } from '../../../../../utils/text';

export type TopicTypeProps = Except<MultiSelectProps, 'choices'>;

const topicTypesArr = Object.values(TopicTypes);

/**
 * @note "value" has been added - required by antd v4
 */

const choices = topicTypesArr.map((topicType) => {
  return {
    key: topicType,
    value: topicType,
    label: camelCaseToSentence(topicType),
  };
});

export const TopicType = (props: TopicTypeProps) => (
  <Multiselect
    maxTagWidth="110px"
    maxTagCount={1}
    perPage={10}
    showSearch={false}
    {...props}
    choices={choices}
  />
);

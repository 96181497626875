import React, { ReactElement } from 'react';
import Icon from '@ant-design/icons';
import { Button, Select } from 'antd';
import { createPortal } from 'react-dom';
import {
  EntityDropdownHook,
  EntityDropdownHookConfig,
  EntityProps,
  useEntityDropdown,
  QueryResult,
} from './hooks';
import { SpinLoader } from '../spin-loader/spin-loader.component';
import { NoResultsContainer } from '../no-results-container/no-results-container.styles';
import { StyledSelect } from '../styled-select/styled-select.styles';

export type DropdownProps<T, A, B> = {
  onToggle?: EntityDropdownHookConfig<T, A, B>['onToggle'];
  onChange: EntityDropdownHookConfig<T, A, B>['onChange'];
  value: string | string[];
  inlineCreation?: boolean;
  modalPortalTarget?: HTMLElement;
  disabledIDs?: string[];
  defaultOpen?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  closeOnSelect?: boolean;
  entityProps: EntityProps<T, A, B>;
  setupCreateFormManager?: (
    onEntityCreated: EntityDropdownHook<T>['onEntityCreated'],
    toggleEntityForm: EntityDropdownHook<T>['toggleEntityForm']
  ) => ReactElement;
};

type NoResultsProps<T> = {
  onCreateNew: () => any;
  inlineCreation: boolean;
  search: string;
  displayName: string;
  setupCreateFormManager?: (
    onEntityCreated: EntityDropdownHook<T>['onEntityCreated'],
    toggleEntityForm: EntityDropdownHook<T>['toggleEntityForm']
  ) => ReactElement;
};

function NoResults<T>({
  onCreateNew,
  inlineCreation,
  search,
  displayName,
  setupCreateFormManager,
}: NoResultsProps<T>) {
  const enableCreation = setupCreateFormManager && inlineCreation;

  return (
    <NoResultsContainer>
      <div
        className="none-text"
        style={{ ...(!enableCreation && { marginTop: '10px' }) }}
      >
        {!search && `Start typing to search for ${displayName}...`}
        {search && (
          <>{enableCreation ? 'None found?' : `No ${displayName} found.`}</>
        )}
      </div>
      {enableCreation && (
        <Button
          onMouseDown={(e) => e.preventDefault()}
          className="no-results-create-btn"
          type="primary"
          onClick={onCreateNew}
        >
          <Icon component={() => <i className="fal fa-plus-circle" />} />
          <span>Create new</span>
        </Button>
      )}
    </NoResultsContainer>
  );
}

export function Dropdown<
  T extends Record<string, unknown>,
  A extends Record<string, QueryResult<T>>,
  B extends Record<string, QueryResult<T>>
>({
  onToggle,
  onChange,
  value,
  autoFocus = false,
  inlineCreation = true,
  modalPortalTarget,
  disabledIDs,
  defaultOpen,
  disabled,
  closeOnSelect,
  entityProps,
  setupCreateFormManager,
}: DropdownProps<T, A, B>) {
  const { idKey, nameGetter, displayName } = entityProps;

  const arrayValue = Array.isArray(value) ? value : [value];
  const {
    isAddingEntity,
    toggleEntityForm,
    handleSearch,
    onEntityCreated,
    handleChange,
    search,
    query: { loading, entities },
    open,
    setOpen,
  } = useEntityDropdown<T, A, B>({
    onChange,
    onToggle,
    value: arrayValue,
    defaultOpen,
    closeOnSelect,
    entityProps,
  });

  if (setupCreateFormManager && isAddingEntity && inlineCreation) {
    return createPortal(
      setupCreateFormManager(onEntityCreated, toggleEntityForm),
      modalPortalTarget ?? (document.getElementById('root') as HTMLElement)
    );
  }

  return (
    <StyledSelect
      $inlineCreation={inlineCreation}
      disabled={disabled}
      open={open}
      onDropdownVisibleChange={setOpen}
      mode="multiple"
      showArrow
      value={value}
      autoFocus={autoFocus}
      maxTagCount={5}
      maxTagTextLength={10}
      onSearch={handleSearch}
      loading={loading}
      filterOption={false}
      onChange={handleChange}
      showSearch
      dropdownRender={(menu: React.ReactElement) => {
        if (loading) {
          return <SpinLoader loadingMessage={`Searching ${displayName}...`} />;
        }

        if (
          !entities.filter(
            (entity) => !arrayValue.includes(String(entity[idKey]))
          ).length
        ) {
          return (
            <NoResults<T>
              search={search}
              inlineCreation={inlineCreation}
              onCreateNew={toggleEntityForm}
              displayName={displayName}
              setupCreateFormManager={setupCreateFormManager}
            />
          );
        }

        return menu;
      }}
      notFoundContent={<span />}
      placeholder={`Please select ${displayName}...`}
      style={{ width: '100%' }}
      autoClearSearchValue
      suffixIcon={
        inlineCreation && (
          <Button
            className="add-entity-btn"
            onClick={toggleEntityForm}
            type="link"
          >
            <Icon component={() => <i className="fal fa-plus-circle" />} />
          </Button>
        )
      }
    >
      {entities.length &&
        entities.map((entity) => (
          <Select.Option
            disabled={disabledIDs?.includes(String(entity[idKey]))}
            className="test"
            style={{
              display: value.includes(String(entity[idKey])) ? 'none' : 'block',
            }}
            key={String(entity[idKey])}
            value={entity[idKey]}
          >
            {nameGetter(entity)}
          </Select.Option>
        ))}
    </StyledSelect>
  );
}

import { MutableRefObject, useEffect } from 'react';

const useOutsideClick = (
  ref: MutableRefObject<HTMLElement | undefined>,
  handler: (event: Event) => any
) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const listener = (event: Event) => {
      if (ref.current!.contains(event.target as HTMLElement)) {
        return;
      }

      handler(event);
    };

    document.body.addEventListener('click', listener);

    return () => {
      document.body.removeEventListener('click', listener);
    };
  }, [ref, handler]);
};

export default useOutsideClick;

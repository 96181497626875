import { Route, useNavigate, Routes } from 'react-router-dom';
import React, { useCallback } from 'react';
import { routes } from '../../route-urls';
import CreateFormManager from '../../components/artist/create-form-manager/create-form-manager.component';
import FormModal from '../../components/artist/form-modal/form-modal.component';
import { Artist } from '../../types/artist';

const ArtistFormPage = () => {
  const navigate = useNavigate();

  const onCancel = useCallback(() => navigate(-1), [navigate]);
  const afterSubmit = useCallback(
    (artist: Artist) => {
      const redirect = () => navigate(routes.setArtistData(artist.id));
      setTimeout(redirect, 50);
    },
    [navigate]
  );

  return (
    <Routes>
      <Route
        path={`${routes.artistForm()}`}
        element={
          <>
            {/*
            // @ts-ignore */}
            <CreateFormManager afterSubmit={afterSubmit}>
              <FormModal onCancel={onCancel} />
            </CreateFormManager>
          </>
        }
      />
    </Routes>
  );
};

export default ArtistFormPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../route-urls';
import { BrandedContainer, TopicData } from '../../../resolver.types';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { BrandTag } from '../../common/brand-tag/brand-tag.component';
import { getTitle } from '../../../utils/branded-container';
import { v4 as uuidv4 } from 'uuid';
import {
  brandOrderedSortPattern,
  brandsMap,
} from 'yggdrasil-shared/domain/dictionary';

export type BrandedContainersWithSimilarTopicExistsWarningProps = {
  brandedContainers: BrandedContainer[];
  topicData: TopicData | undefined;
};

type GroupedContainer = {
  id: string;
  containerTopicTitle: string;
  brands: {
    shortcut: string;
    color: string;
    originalId: string;
    brandName: string;
  }[];
  exactMatch: boolean;
};

const normalizeTopicData = (topicData: TopicData): string => {
  const result = {
    artists: topicData.artists || null,
    genres: topicData.genres || null,
    venue: topicData.venue || null,
    place: topicData.place || null,
    eventSeries: topicData.eventSeries || null,
    campaign: topicData.campaign || null,
    workOfArt: topicData.workOfArt || null,
  };

  return JSON.stringify(result);
};

const isExactMatch = (inputTopic: TopicData, bcTopic: string): boolean => {
  const inputTopicKey = normalizeTopicData(inputTopic);
  return inputTopicKey === bcTopic;
};

export const groupBrandedContainersByTopic = (
  brandedContainers: BrandedContainer[],
  inputTopicData: TopicData
): GroupedContainer[] => {
  const groupedByTopic = brandedContainers.reduce(
    (acc: Record<string, GroupedContainer>, container) => {
      const containerTopicKey = normalizeTopicData(container.topic.data);
      const brand = brandsMap[container.brand];

      if (!acc[containerTopicKey]) {
        acc[containerTopicKey] = {
          id: uuidv4(),
          containerTopicTitle: getTitle(container.topic.data),
          brands: [],
          exactMatch: isExactMatch(inputTopicData, containerTopicKey),
        };
      }

      if (
        !acc[containerTopicKey].brands.some(
          (b) => b.shortcut === brand.shortcut
        )
      ) {
        acc[containerTopicKey].brands.push({
          shortcut: brand.shortcut,
          color: brand.color,
          originalId: container.id,
          brandName: brand.name,
        });
      }

      return acc;
    },
    {}
  );

  const sortedGroupedArray = Object.values(groupedByTopic).sort((a, b) => {
    // Sorting logic to make sure exactMatch:true records appear first
    if (a.exactMatch && !b.exactMatch) {
      return -1;
    }

    if (!a.exactMatch && b.exactMatch) {
      return 1;
    }

    return 0;
  });

  return sortedGroupedArray;
};

const BrandedContainersWithSimilarTopicExistsWarning = ({
  brandedContainers,
  topicData,
}: BrandedContainersWithSimilarTopicExistsWarningProps) => {
  const groupedBrandedContainersByTopic = groupBrandedContainersByTopic(
    brandedContainers,
    topicData!
  );

  return (
    <div style={{ padding: '10px', color: 'black' }}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <ExclamationCircleOutlined
          style={{
            color: 'orange',
            verticalAlign: 'middle',
            marginRight: '10px',
          }}
        />
        Following Branded container(s) of any brand with a similar topic already
        exist:
      </div>
      <div>
        <ul
          style={{
            paddingLeft: '20px',
            color: 'orange',
            listStyleType: 'disc !important',
          }}
        >
          {groupedBrandedContainersByTopic.map(
            (
              {
                id,
                containerTopicTitle: containerTopicDataTitle,
                brands,
                exactMatch,
              },
              index
            ) => {
              return (
                <li key={`bc-${id}-${index}`} style={{ alignItems: 'center' }}>
                  <Link
                    style={{
                      textDecoration: 'underline',
                      color: 'orange',
                      marginRight: '5px',
                      display: 'inline-block',
                    }}
                    to={routes.setBrandedContainerData(brands[0].originalId)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {containerTopicDataTitle}
                  </Link>
                  {brands
                    .sort(
                      (a, b) =>
                        brandOrderedSortPattern[a.brandName] -
                        brandOrderedSortPattern[b.brandName]
                    )
                    .map((brand, brandIndex) => (
                      <React.Fragment
                        key={`brand-fragment-${id}-${brand.shortcut}-${brandIndex}`}
                      >
                        <Link
                          style={{
                            textDecoration: 'none',
                            marginRight: '5px',
                            display: 'inline-block',
                            marginBottom: '5px',
                          }}
                          to={routes.setBrandedContainerData(brand.originalId)}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={`brand-link-${id}-${brand.shortcut}-${brandIndex}`}
                        >
                          <BrandTag
                            key={`brand-${id}-${brand.shortcut}-${brandIndex}`}
                            brand={brand.shortcut}
                            color={brand.color}
                            disabled={false}
                          />
                        </Link>
                      </React.Fragment>
                    ))}
                  {exactMatch && (
                    <React.Fragment>
                      <CheckCircleOutlined
                        style={{ color: 'green' }}
                        title="Exact match!"
                      />
                      <span style={{ marginLeft: '5px', color: 'green' }}>
                        Exact match!
                      </span>
                    </React.Fragment>
                  )}
                </li>
              );
            }
          )}
        </ul>
      </div>
      Please make sure that you provide enough information to make them
      distinguishable.
    </div>
  );
};

export default BrandedContainersWithSimilarTopicExistsWarning;

import React from 'react';
import { UpdateBrandedContainerInput } from '../../../resolver.types';

type UseFieldsLockersProps = {
  initialValues: UpdateBrandedContainerInput;
};

export type Lockers = {
  [key in keyof UpdateBrandedContainerInput]: boolean;
};

const lockedByDefault: Partial<Lockers> = {
  titleSlug: true,
  legacyId: true,
};

const getInitialLockersObject = (
  initialValues: UpdateBrandedContainerInput
) => {
  return Object.keys(initialValues).reduce<Lockers>(
    (acc, key) => {
      return {
        ...acc,
        [key]: lockedByDefault[key as keyof Lockers] || false,
      };
    },
    { id: false, version: false }
  );
};

export function useFieldsLockers({ initialValues }: UseFieldsLockersProps) {
  const [lockers, setLockers] = React.useReducer(
    (state: Lockers, newState: Partial<Lockers>) => ({ ...state, ...newState }),
    getInitialLockersObject(initialValues)
  );

  const resetLockersToDefault = () => {
    setLockers(getInitialLockersObject(initialValues));
  };

  return {
    lockers,
    setLockers,
    resetLockersToDefault,
  };
}

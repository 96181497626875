import styled from 'styled-components';

export const UserContainer = styled.div`
  display: inline;
  padding-left: 10px;

  & .brand-tag {
    position: absolute;
    right: -8px;
    top: -10px;
    width: 24px;
    border-radius: 8px;
    font-size: 8px;
    line-height: 16px;
  }
`;

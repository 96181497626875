import { FormStepProps } from '../shared/types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Input, Row, Select } from 'antd';
import { DatePicker } from '../../../../date-fns/DatePicker';
import React, { useMemo } from 'react';
import parseISO from 'date-fns/parseISO';
import { DateFormats } from '../../../../utils/date-formats';
import { mapCurrencySymbol } from '../../../../utils/event';
import { snakeCaseToSentence } from '../../../../utils/text';
import { LabeledValue } from 'antd/lib/select';
import styled from 'styled-components';
import { displayTicketsAvailability } from '../../../../utils/display-tickets-availability';

const InformationStep = ({ event, readonly, disabled }: FormStepProps) => {
  const genres = event.genres.map((genre) => ({
    key: genre.sourceId,
    label: genre.name,
  }));

  const prices = useMemo(
    () =>
      event.pricing.maxPrices.length && event.pricing.minPrices.length
        ? `Highest: ${event.pricing.maxPrices[0].value.toFixed(
            2
          )} ${mapCurrencySymbol(
            event.pricing.maxPrices[0].currency
          )} / Lowest: ${event.pricing.minPrices[0].value.toFixed(
            2
          )} ${mapCurrencySymbol(event.pricing.minPrices[0].currency)}`
        : 'No data',
    [event]
  );
  const additionalServices = useMemo<LabeledValue[]>(
    () =>
      event?.ticketTypes
        ? event.ticketTypes.filter(Boolean).map(
            (ticketType): LabeledValue => ({
              key: ticketType,
              label: snakeCaseToSentence(ticketType),
              value: ticketType,
            })
          )
        : [],
    [event]
  );

  return (
    <Container>
      {/*
      // @ts-ignore */}
      <Form.Item colon={false} label="Genres">
        <Select
          className="detailed-tags"
          mode="multiple"
          disabled={disabled || readonly}
          labelInValue
          defaultValue={genres}
        >
          {genres.map((genre) => (
            <Select.Option key={genre.key}>{genre.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item colon={false} label="Additional services">
        <Select
          defaultValue={additionalServices}
          className="detailed-tags"
          mode="multiple"
          disabled={disabled || readonly}
          labelInValue
        >
          {additionalServices.map((service) => (
            <Select.Option key={service.key}>{service.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Row gutter={20} className="compatible-col">
        <Col span={12}>
          {/*
          // @ts-ignore */}
          <Form.Item label="Venue" colon={false}>
            <Input
              readOnly={readonly}
              disabled={disabled || readonly}
              defaultValue={event.venue.name ?? ''}
            />
          </Form.Item>
          {/*
          // @ts-ignore */}
          <Form.Item label="Prices" colon={false}>
            <Input
              className="prices"
              readOnly={readonly}
              disabled={disabled || readonly}
              defaultValue={prices}
            />
          </Form.Item>
          {/*
          // @ts-ignore */}
          <Form.Item label="Start of event" colon={false}>
            <DatePicker
              style={{
                width: '100%',
                fontSize: '16px',
              }}
              disabled={disabled || readonly}
              format={DateFormats.dateTime}
              defaultValue={parseISO(event.startDateTime)}
            />
          </Form.Item>
          {/*
          // @ts-ignore */}
          <Form.Item label="Source" colon={false}>
            <Input
              readOnly={readonly}
              disabled={disabled || readonly}
              defaultValue={event.source}
            />
          </Form.Item>
          {/*
          // @ts-ignore */}
          <Form.Item label="Source event ID" colon={false}>
            <Input
              readOnly={readonly}
              disabled={disabled || readonly}
              defaultValue={event.sourceEventID}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/*
          // @ts-ignore */}
          <Form.Item label="City" colon={false}>
            <Input
              readOnly={readonly}
              disabled={disabled || readonly}
              defaultValue={event.venue.address?.city ?? ''}
            />
          </Form.Item>
          {/*
          // @ts-ignore */}
          <Form.Item label="Postal code" colon={false}>
            <Input
              readOnly={readonly}
              disabled={disabled || readonly}
              defaultValue={event.venue.address?.zipCode ?? ''}
            />
          </Form.Item>
          {/*
          // @ts-ignore */}
          <Form.Item label="Available tickets" colon={false}>
            <Input
              readOnly={readonly}
              disabled={disabled || readonly}
              defaultValue={displayTicketsAvailability(
                event.ticketsAmount,
                event.totalTicketsAmount
              )}
            />
          </Form.Item>
          {/*
          // @ts-ignore */}
          <Form.Item label="Presale start" colon={false}>
            <DatePicker
              style={{
                width: '100%',
                fontSize: '16px',
              }}
              disabled={disabled || readonly}
              format={DateFormats.dateTime}
              defaultValue={
                event.saleStartDateTime
                  ? parseISO(event.saleStartDateTime)
                  : undefined
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  .ant-input {
    &[readonly] {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }
  }
`;

export default InformationStep;

import {
  EventFilters,
  RangeValue,
  SearchValue,
} from '../../context/event-filters.context';
import {
  EventFieldMap,
  FilterQueryType,
  FilterType,
  MatchFuzzinessType,
  SubqueryFilterDefinition,
  CustomFilterDefinition,
  ValueType,
  View,
  StandardFilterDefinition,
} from 'yggdrasil-shared/database/persistable-filters';
import { SearchOption } from '../../components/common/search-with-option/search-with-option.component';

type EventFiltersMap = {
  [key in keyof Partial<EventFilters>]: (
    value: number | string | string[] | SearchValue | SearchValue[] | RangeValue
  ) =>
    | StandardFilterDefinition
    | SubqueryFilterDefinition
    | CustomFilterDefinition;
};

type EventFilterConfig = {
  [key in keyof Partial<EventFieldMap>]:
    | StandardFilterDefinition
    | SubqueryFilterDefinition
    | CustomFilterDefinition
    | object[];
};

const reduceTitles = (
  titles: SearchValue[],
  field: string,
  additionalFields: string[]
) => {
  return titles.reduce((prev, curr, index) => {
    return {
      ...prev,
      [`${field}${index}`]: {
        type: FilterType.Match,
        query: {
          type:
            curr.option === SearchOption.Negative
              ? FilterQueryType.MustNot
              : FilterQueryType.Should,
        },
        props: {
          default: { type: ValueType.Exact, value: curr.value },
          phrase: curr.option === SearchOption.Exact,
        },
        additionalFields: additionalFields.map(
          (additionalField) => `${additionalField}${index}`
        ),
        ...(curr.option === SearchOption.Fuzzy && {
          fuzziness: {
            type: MatchFuzzinessType.Auto,
          },
        }),
      },
    };
  }, {});
};

const eventFiltersMap: EventFiltersMap = {
  titleSearchValues: (titles) => ({
    type: FilterType.Subquery,
    query: {
      type: FilterQueryType.Must,
    },
    props: {},
    config: {
      filtersConfig: reduceTitles(titles as SearchValue[], 'title', []),
      view: View.Event,
    },
  }),
  fullTextSearchValues: (values) => ({
    type: FilterType.Subquery,
    query: {
      type: FilterQueryType.Must,
    },
    props: {},
    config: {
      filtersConfig: reduceTitles(values as SearchValue[], 'subtitle', [
        'eventGroupDescription',
        'title',
      ]),
      view: View.Event,
    },
  }),
  sourceEventIds: (sourceEventIds) => ({
    type: FilterType.Terms,
    query: {
      type: FilterQueryType.Must,
    },
    props: {
      default: { value: sourceEventIds, type: ValueType.Exact },
    },
  }),
  organizers: (organizers) => ({
    type: FilterType.Custom,
    props: {
      default: { value: organizers, type: ValueType.Exact },
    },
  }),
  genres: (genres) => ({
    type: FilterType.Terms,
    query: {
      type: FilterQueryType.Must,
    },
    props: {
      default: { value: genres, type: ValueType.Exact },
    },
  }),
  minPrice: (price) => ({
    type: FilterType.Range,
    query: {
      type: FilterQueryType.Must,
    },
    props: {
      min: { value: price, type: ValueType.Exact },
    },
  }),
  maxPrice: (price) => ({
    type: FilterType.Range,
    query: {
      type: FilterQueryType.Must,
    },
    props: {
      max: { value: price, type: ValueType.Exact },
    },
  }),
  startDateTime: (dateTimes) => ({
    type: FilterType.DateRange,
    query: {
      type: FilterQueryType.Must,
    },
    props: {
      min: { value: (dateTimes as RangeValue).min, type: ValueType.Exact },
      max: { value: (dateTimes as RangeValue).max, type: ValueType.Exact },
    },
  }),
  saleStartDateTime: (dateTimes) => ({
    type: FilterType.DateRange,
    query: {
      type: FilterQueryType.Must,
    },
    props: {
      min: { value: (dateTimes as RangeValue).min, type: ValueType.Exact },
      max: { value: (dateTimes as RangeValue).max, type: ValueType.Exact },
    },
  }),
  locations: (locations) => ({
    type: FilterType.Custom,
    props: {
      default: { value: locations, type: ValueType.Exact },
    },
  }),
  locationIDs: (locationIDs) => ({
    type: FilterType.Ids,
    query: {
      type: FilterQueryType.Should,
      minimumShouldMatch: 1,
    },
    props: {
      default: { value: locationIDs, type: ValueType.Exact },
    },
  }),
  ticketSpecials: (ticketSpecials) => ({
    type: FilterType.Terms,
    query: {
      type: FilterQueryType.Must,
    },
    props: {
      default: { value: ticketSpecials, type: ValueType.Exact },
    },
  }),
};

export const buildEventFilters = (
  filters: Partial<EventFilters>
): { view: 'event'; filtersConfig: EventFilterConfig } => {
  let filtersConfig: Partial<EventFilterConfig> = {};

  if (filters.sourceEventIds && filters.sourceEventIds.length) {
    filtersConfig.sourceEventId = eventFiltersMap.sourceEventIds!(
      filters.sourceEventIds
    );
  }

  if (filters.genres && filters.genres.length) {
    filtersConfig.genre = eventFiltersMap.genres!(filters.genres);
  }

  if (filters.titleSearchValues && filters.titleSearchValues.length) {
    filtersConfig.title = eventFiltersMap.titleSearchValues!(
      filters.titleSearchValues
    );
  }

  if (filters.minPrice) {
    filtersConfig.priceMin = eventFiltersMap.minPrice!(filters.minPrice);
  }

  if (filters.maxPrice) {
    filtersConfig.priceMax = eventFiltersMap.maxPrice!(filters.maxPrice);
  }

  if (filters.startDateTime) {
    filtersConfig.startDateTime = eventFiltersMap.startDateTime!(
      filters.startDateTime
    );
  }

  if (filters.fullTextSearchValues && filters.fullTextSearchValues.length) {
    filtersConfig.relevantTextFields = eventFiltersMap.fullTextSearchValues!(
      filters.fullTextSearchValues
    );
  }

  if (filters.saleStartDateTime) {
    filtersConfig.saleStartDateTime = eventFiltersMap.saleStartDateTime!(
      filters.saleStartDateTime
    );
  }
  /**
   * @todo Type assersion
   */

  if (filters.locations?.length) {
    filtersConfig.location = eventFiltersMap.locations!(
      filters.locations.map((val) => val.value as string)
    );
  }

  if (filters.locationIDs && filters.locationIDs.length) {
    filtersConfig.locationId = eventFiltersMap.locationIDs!(
      filters.locationIDs
    );
  }

  if (filters.organizers?.length) {
    filtersConfig.organizers = eventFiltersMap.organizers!(
      filters.organizers.map((val) => val.value as string)
    );
  }

  if (filters.ticketSpecials && filters.ticketSpecials.length) {
    filtersConfig.ticketTypes = eventFiltersMap.ticketSpecials!(
      filters.ticketSpecials
    );
  }

  return {
    view: 'event',
    filtersConfig,
  };
};

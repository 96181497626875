import type {
  BrandedContainer,
  UpdateBrandedContainerInput,
} from '../../../resolver.types';

export const modifyDirty = (
  currentState: BrandedContainer,
  incomingData: UpdateBrandedContainerInput
): UpdateBrandedContainerInput => {
  return {
    ...currentState,
    eventIDs: incomingData.eventIDs,
    declinedEventIDs: incomingData.declinedEventIDs,
    relatedContainerIDs: incomingData.relatedContainerIDs,
    suggestedEventIDs: incomingData.suggestedEventIDs,
    suggestedGenres: incomingData.suggestedGenres,
    version: incomingData.version,
  };
};

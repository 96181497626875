import { RemoveArtistDocument } from '../../resolver.types';
import { Artist } from '../../types/artist';

export const REMOVE_ARTIST = RemoveArtistDocument;

export type RemoveArtistInput = {
  id: string;
};

export type RemoveArtistVariables = {
  input: RemoveArtistInput;
};

export type RemoveArtistResult = {
  removeArtist: Pick<Artist, 'id' | 'name'>;
};

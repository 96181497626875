import { FilterValues } from '../../filters/types';
import { LabeledValue } from 'antd/lib/select';
import { tryJsonParse } from 'yggdrasil-shared/utils/common';
import mapValues from 'lodash.mapvalues';
import { isLabeledValue } from '../../../utils/type-checkers';

const parseToString = (key: any) => {
  const parsedKey = tryJsonParse(key);

  return typeof parsedKey === 'number' ? parsedKey.toString() : parsedKey;
};

const parseFilters = (filters: FilterValues) => {
  return mapValues(filters, (value) => {
    const isArray = Array.isArray(value);
    const isLabeled = isArray
      ? isLabeledValue((value as any[])[0])
      : isLabeledValue(value);

    if (!isLabeled) {
      return value;
    }

    if (!isArray) {
      const labeled = value as LabeledValue;
      return {
        ...labeled,
        /**
         * @note Adjust graphQL schemas to accept "value" - decided to not handle "value" in schemas since it is reduntant on backend
         * "value" had to be removed from object after antd upgrade - it is not accepted by our schemas
         * "value" is forced to be used by antd v4 components
         * "key" and "label" might be undefined in some scenarios
         */
        value: undefined,
        key: parseToString(labeled.key) ?? parseToString(labeled.value),
        label: labeled.label?.toString() ?? labeled.value?.toString() ?? '',
      };
    }

    const labeled = value as unknown as LabeledValue[];

    return labeled.map((value) => ({
      ...value,
      /**
       * @note Adjust graphQL schemas to accept "value" - decided to not handle "value" in schemas since it is reduntant on backend
       * "value" had to be removed from object after antd upgrade - it is not accepted by our schemas
       * "value" is forced to be used by antd v4 components
       * "key" and "label" might be undefined in some scenarios
       */
      value: undefined,
      key: parseToString(value.key) ?? parseToString(value.value),
      label: value.label?.toString() ?? value.value?.toString() ?? '',
    }));
  });
};

export default parseFilters;

import styled from 'styled-components';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Row, Select } from 'antd';
import { Highlight } from '../highlight/hightlight.component';

export const SearchIcon = styled(SearchOutlined)`
  font-size: 16px;
  &.anticon.ant-select-arrow-icon svg {
    transform: none;
  }
`;

export const LoadingIcon = styled(LoadingOutlined)`
  &.anticon.ant-select-arrow-icon svg {
    transform: none;
  }
`;

export const StyledSelect = styled(Select).attrs(
  ({
    $maxTagWidth,
    $antSelectorStyle,
  }: {
    $maxTagWidth: string;
    $antSelectorStyle?: string;
  }) => ({
    $maxTagWidth,
    $antSelectorStyle,
  })
)`
  &.has-value {
    .ant-select-selection__rendered {
      padding-left: 10px;
    }
  }

  & .ant-select-selector {
    ${(props) => props.$antSelectorStyle && props.$antSelectorStyle};
  }

  & .ant-select-selection-overflow-item {
    max-width: ${(props) =>
      props.$maxTagWidth ? `${props.$maxTagWidth}` : 'none'};

    & span.tag {
      display: none;
    }
  }
`;

export const ResultsRow = styled(Row)`
  width: 100%;

  .image-col {
    width: auto;
  }
`;

export const ShowAll = styled.div`
  text-align: center;
  padding: 10px 0;
  background: #f3f3f3;

  .ant-btn {
    color: #000000;
    font-weight: 300;
  }
`;

export const StyledHighlight = styled(Highlight)`
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

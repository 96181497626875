import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;

  & > .right-side {
    display: flex;
    & button {
      margin-left: 10px;
    }

    & .ant-btn {
      font-size: 12px;
    }
  }
`;

import React, { Component, ErrorInfo } from 'react';
import { Result, List, Typography, Tabs } from 'antd';

export interface ErrorBoundaryState {
  error?: Error;
  componentStack?: string[];
}

const splitStack = (stack: string): string[] =>
  stack
    .split('\n')
    .map((err) => err.trim())
    .filter(Boolean);

type StackListProps = {
  stack: string[];
  title: string;
};

const StackList = ({ title, stack }: StackListProps) => {
  return (
    <List
      dataSource={stack}
      renderItem={(item) => (
        <List.Item>
          <Typography.Text>{item}</Typography.Text>
        </List.Item>
      )}
      header={<h4>{title}</h4>}
    />
  );
};

class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {};

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      componentStack: splitStack(errorInfo.componentStack),
    });
  }

  public render() {
    if (this.state.error) {
      return (
        <Result
          status="500"
          title="Oops, error occured!"
          subTitle={`Following error occured: ${this.state.error.message}`}
        >
          {process.env.NODE_ENV !== 'production' && (
            <Tabs defaultActiveKey="componentStack">
              <Tabs.TabPane tab="Component Stack" key="componentStack">
                <StackList
                  stack={this.state.componentStack!}
                  title="Component stack"
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Error Stack" key="errorStack">
                <StackList
                  stack={splitStack(this.state.error.stack!)}
                  title="Error stack"
                />
              </Tabs.TabPane>
            </Tabs>
          )}
        </Result>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { useCallback, useMemo, useState } from 'react';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ButtonType } from 'antd/lib/button';

export type RowSelectionAction<Entity> = {
  label: string;
  type: ButtonType;
  icon?: React.ReactNode;
  onClick: (selectedItems: Entity[], selectedKeys: string[]) => any;
  loading?: boolean;
  resetSelection?: boolean;
};

type BaseEntity = {
  id: string;
};

export type SelectionHook<Entity extends BaseEntity> = {
  rowSelection: TableRowSelection<Entity>;
  selectedItems: Entity[];
  resetSelection: () => void;
  selectedRowsCount: number;
};

const useSelection = <Entity extends BaseEntity>(): SelectionHook<Entity> => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<Entity[]>([]);

  const onChange = useCallback(
    (selectedRowKeys: any[], currentSelectedItems: Entity[]) => {
      const newSelectedItems = [
        ...currentSelectedItems,
        ...selectedItems,
      ].filter(
        (item, index, arr) =>
          selectedRowKeys.includes(item.id) &&
          arr.findIndex((entity) => entity.id === item.id) === index
      );

      setSelectedRows(selectedRowKeys);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems]
  );

  const selectedRowsCount = useMemo(() => {
    return selectedRows?.length ?? 0;
  }, [selectedRows]);

  const resetSelection = useCallback(() => {
    setSelectedRows([]);
    setSelectedItems([]);
  }, []);

  return {
    rowSelection: {
      selectedRowKeys: selectedRows,
      onChange,
    },
    selectedItems,
    resetSelection,
    selectedRowsCount,
  };
};

export default useSelection;

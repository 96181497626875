import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonsContainer = styled.div`
  width: 100%;
  background-color: #fafafa;
  border-bottom: 1px solid #f2f2f2;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const ButtonElement = styled(Button)`
  &.ant-btn {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 8px;
  }
`;

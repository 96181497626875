import styled from 'styled-components';

export const LastModifiedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ModificationInfoContainer = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  font-size: 11px;

  & > .modification-type {
    font-style: italic;
  }
`;

import React from 'react';
import { Spin } from 'antd';
import { SpinContainer } from './spin-loader.styles';

type SpinLoaderProps = {
  loadingMessage?: string;
  className?: string;
};

export const SpinLoader = ({
  loadingMessage = 'Loading data...',
  className,
}: SpinLoaderProps) => {
  return (
    <SpinContainer className={className}>
      <Spin size="large" tip={loadingMessage} />
    </SpinContainer>
  );
};

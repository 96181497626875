import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { Circle, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty, List, Slider } from 'antd';
import {
  StyledMapContainer,
  RangeContainer,
  SliderContainer,
} from './region-range.styles';
import { BrandedContainerRegionFeature } from '../../../resolver.types';
import { FeatureGroup, LatLngTuple, Marker as LeafletMarker } from 'leaflet';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../../route-urls';
import { Map } from 'leaflet';
import { swapRoute } from '../../../utils/swap-route';

export type RegionRangeProps = {
  range: number;
  disabled?: boolean;
  onChange: (range: number) => any;
  features?: BrandedContainerRegionFeature[] | null;
  mapUrl?: string;
};

export type MarkerProps = {
  eventsCount: number;
  coordinates: LatLngTuple;

  events: Array<{
    id: string;
    title?: string | null;
  }>;
};

export type RegionMarkerProps = MarkerProps & {
  range: number;
};

const getCoordinates = (
  feature: BrandedContainerRegionFeature
): LatLngTuple => {
  return feature.geometry.coordinates as LatLngTuple;
};

export const RegionRange = ({
  range,
  disabled = false,
  onChange,
  features,
  mapUrl = process.env.REACT_APP_OPEN_STREET_MAP_URL,
}: RegionRangeProps) => {
  const location = useLocation();
  const mapRef = useRef<Map>();

  const hasFeatures = useMemo(() => {
    return features && features.length > 0;
  }, [features]);

  const markers: Record<string, MarkerProps> = useMemo(() => {
    if (!hasFeatures) {
      return {};
    }

    return features!.reduce<Record<string, MarkerProps>>((acc, feature) => {
      const coordinates = getCoordinates(feature);
      const key = coordinates.toString();

      if (acc[key]) {
        acc[key].eventsCount++;
        acc[key].events.push({
          id: feature.properties.id,
          title: feature.properties.title,
        });
      } else {
        acc[key] = {
          eventsCount: 1,
          coordinates,
          events: [
            {
              id: feature.properties.id,
              title: feature.properties.title,
            },
          ],
        };
      }

      return acc;
    }, {});
  }, [hasFeatures, features]);
  const markersArr = useMemo(() => Object.entries(markers), [markers]);

  const center = useMemo(() => {
    if (!hasFeatures) {
      return null;
    }

    return Object.values(markers)[0].coordinates;
  }, [hasFeatures, markers]);

  useEffect(() => {
    const markersArr = Object.values(markers);

    if (!mapRef.current || !markersArr.length) {
      return;
    }

    const { current: map } = mapRef;

    const leafletMarkers = markersArr.map(
      (marker) => new LeafletMarker(marker.coordinates)
    );

    if (leafletMarkers.length > 1) {
      const group = new FeatureGroup(leafletMarkers);

      map.fitBounds(group.getBounds());
    }
  }, [mapRef, markers]);

  return (
    <div>
      <StyledMapContainer>
        {hasFeatures && (
          <MapContainer
            center={center!}
            zoom={10}
            ref={mapRef as MutableRefObject<Map>}
            style={{ width: '100%', height: '100%' }}
          >
            <TileLayer url={mapUrl} />
            {markersArr.map(([key, marker]) => (
              <RegionMarker key={key} {...marker} range={range} />
            ))}
          </MapContainer>
        )}
        {!hasFeatures && (
          <Empty className="empty" description="No region data found.">
            <Link to={swapRoute(location.pathname, 'bc-events')}>
              <Button icon={<PlusOutlined />} type="primary">
                Assign events
              </Button>
            </Link>
          </Empty>
        )}
        <SliderContainer>
          <Slider
            min={0}
            max={250}
            step={1}
            onChange={onChange}
            value={range}
            disabled={disabled}
          />
          <RangeContainer className="range-container">
            {range} km
          </RangeContainer>
        </SliderContainer>
      </StyledMapContainer>
    </div>
  );
};

const RegionMarker = ({
  coordinates,
  eventsCount,
  events,
  range,
}: RegionMarkerProps) => {
  return (
    <>
      <Marker position={coordinates}>
        <Popup>
          <List
            renderItem={(item) => (
              <List.Item>
                <Link
                  style={{
                    color: '#e3a14a',
                    textDecoration: 'underline',
                  }}
                  to={routes.setEventData(item.id)}
                >
                  {item.title ?? 'Event'}
                </Link>
              </List.Item>
            )}
            dataSource={events}
            header={<div>Events count: {eventsCount}</div>}
          />
        </Popup>
      </Marker>
      <Circle
        center={coordinates}
        color="inherit"
        fillColor="rgba(66, 133, 244, 1)"
        radius={range * 1000}
      />
    </>
  );
};

import * as React from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { TitleContainer } from '../../layout/drawer/title.styles';
import { DateFormats } from '../../../utils/date-formats';
import { Artist } from '../../../resolver.types';

export type TitleProps = {
  artist: Artist;
};

export const Title = ({ artist }: TitleProps) => {
  const createdAt = parseISO(artist.createdAt);

  return (
    <TitleContainer>
      <div className="title-and-topic">
        <div className="title">
          {artist.name}
          <div
            className="tag"
            style={{
              maxWidth: '50px',
              marginLeft: '5px',
              display: 'inline-block',
            }}
          >
            Artist
          </div>
        </div>
      </div>
      <div className="sub-title">
        <small>
          Created on {format(createdAt, DateFormats.date)} from{' '}
          {artist.createdBy}
        </small>
      </div>
    </TitleContainer>
  );
};

import styled from 'styled-components';

export const CommentsStepContainer = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const MessageContainer = styled.div`
  height: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .ant-input {
    height: 80px;
    resize: none;
    margin-bottom: 0;
    background-color: #fafafa;
  }

  & > .ant-btn {
    height: 80px;
    width: 130px;
    border-radius: 0;
  }
`;

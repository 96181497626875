import React from 'react';
import { GetActivityForBrandedContainerDocument } from '../../../resolver.types';
import { SpinLoader } from '../../common/spin-loader/spin-loader.component';
import { ActivityLog } from '../activity-log/activity-log.component';
import {
  ActivityLogList,
  LastModifiedStepContainer,
  LastModifiedTitle,
  NoActivityInformation,
} from './last-modified-step.styles';
import { useInfiniteScroll } from '../../../hooks/use-infinite-scroll';

export type LastModifiedStepProps = {
  id: string;
};

export const LastModifiedStep = ({ id }: LastModifiedStepProps) => {
  const isFirstFetchReady = React.useRef(false);

  const { items, onFetchMore, fetchingData } = useInfiniteScroll({
    query: GetActivityForBrandedContainerDocument,
    variables: {
      id,
    },
    offset: 20,
  });

  React.useEffect(() => {
    if (!isFirstFetchReady.current && !fetchingData) {
      isFirstFetchReady.current = true;
    }
  }, [fetchingData]);

  const handleScroll = ({
    currentTarget: { scrollTop, clientHeight, scrollHeight },
  }: React.UIEvent<HTMLDivElement>) => {
    if (fetchingData) {
      return;
    }

    if (scrollTop + clientHeight >= scrollHeight) {
      onFetchMore();
    }
  };

  return (
    <LastModifiedStepContainer>
      <LastModifiedTitle className="last-modified-title">
        Last modified
      </LastModifiedTitle>

      {!isFirstFetchReady.current ? (
        <SpinLoader loadingMessage="Fetching activity log..." />
      ) : (
        <React.Fragment>
          {items.length ? (
            <ActivityLogList onScroll={handleScroll}>
              {items.map((activity: any) => (
                <ActivityLog key={activity.id} {...activity} />
              ))}
            </ActivityLogList>
          ) : (
            <NoActivityInformation>
              There is no activity yet.
            </NoActivityInformation>
          )}
        </React.Fragment>
      )}
    </LastModifiedStepContainer>
  );
};

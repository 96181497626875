import {
  BrandedContainer,
  BrandedContainerState,
} from '../../../resolver.types';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../route-urls';
import styled from 'styled-components';

export type BrandedContainerLinkProps = {
  children: React.ReactNode;
  brandedContainer: BrandedContainer;
  content?: string;
  urlCreator?: (containerID: string, content?: string) => string;
  noPadding?: boolean;
  underline?: boolean;
  primaryColor?: boolean;
  state?: any;
};

const StyledLink = styled(Link)`
  &.no-padding {
    padding: 0;
  }

  &.underline {
    text-decoration: underline;
  }

  &:not(.text--primary) {
    color: #000;
  }
`;

const BrandedContainerLink: FC<BrandedContainerLinkProps> = ({
  brandedContainer,
  children,
  content = 'description',
  urlCreator = routes.setBrandedContainerData,
  noPadding = false,
  primaryColor = false,
  underline = false,
  state,
}) => {
  return (
    <StyledLink
      className={`${primaryColor ? 'text--primary' : ''} ${
        underline ? 'underline' : ''
      } ${noPadding ? 'no-padding' : ''}`}
      to={{
        pathname:
          brandedContainer.state === BrandedContainerState.Deleted
            ? ''
            : urlCreator(brandedContainer.id, content),
      }}
      state={state}
    >
      {children}
    </StyledLink>
  );
};

export default BrandedContainerLink;

import styled from 'styled-components';

export const Comments = styled.div`
  min-height: calc(100% - 110px);
  max-height: calc(100% - 110px);
  overflow-y: scroll;
  margin-bottom: 10px;

  & > .comment-wrapper:nth-child(even) {
    align-items: flex-end;
  }

  & > .comment-wrapper:nth-child(odd) {
    align-items: flex-start;
  }
`;

export const CommentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NoCommentsInfo = styled.span`
  font-size: 14px;
`;

import { ModalHeader, StyledModal } from '../../layout/modal/modal.styles';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalContainer } from './delete-modal.styles';
import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { routes } from '../../../route-urls';
import useUpdateState from '../hooks/use-update-state.hook';
import { BrandedContainerState } from '../../../resolver.types';
import TextArea from 'antd/lib/input/TextArea';
import { useAddBrandedContainerComment } from '../hooks/add-branded-container-comment.hook';
import useAppContext from '../../../hooks/use-app-context';

export type DeleteModalProps = {
  onClose?: () => any;
};

type ModalFooterProps = {
  removeBrandedContainer: () => any;
  btnDisabled: boolean;
  handleClose: () => any;
};

const ModalFooter = ({
  removeBrandedContainer,
  handleClose,
  btnDisabled,
}: ModalFooterProps) => {
  return (
    <div>
      <Button
        className="remove-bc-btn"
        onClick={removeBrandedContainer}
        danger
        disabled={btnDisabled}
      >
        <Icon component={() => <i className="fal fa-trash-alt" />} />
        <span>Delete branded container</span>
      </Button>

      <Button onClick={handleClose}>Close</Button>
    </div>
  );
};

export const DeleteModal = ({ onClose }: DeleteModalProps) => {
  const { id: brandedContainerID, version } = useParams() as {
    id: string;
    version: string;
  };
  const {
    state: { issuer },
  } = useAppContext();

  const navigate = useNavigate();
  const onCloseDefault = useCallback(() => navigate(-1), [navigate]);
  const handleClose = onClose || onCloseDefault;
  const { updateState } = useUpdateState({});
  const { addComment } = useAddBrandedContainerComment(brandedContainerID);

  const [reason, setReason] = useState('');

  const removeBrandedContainer = () => {
    navigate(routes.brandedContainers());
    addComment({
      content: reason,
      issuer,
      version: Number(version),
    }).then(() => {
      updateState({
        id: brandedContainerID,
        version: Number(version) + 1,
        state: BrandedContainerState.Deleted,
        reason,
      });
    });
  };

  return (
    <StyledModal
      onCancel={handleClose}
      width="450px"
      footer={
        <ModalFooter
          removeBrandedContainer={removeBrandedContainer}
          handleClose={handleClose}
          btnDisabled={reason === ''}
        />
      }
      title={<ModalHeader>Delete branded container</ModalHeader>}
      visible
    >
      <ModalContainer>
        <TextArea
          className="reason-bc-delete"
          onChange={(event) => setReason(event.target.value)}
          rows={3}
          placeholder="Reason for deleting branded container"
        />
      </ModalContainer>
    </StyledModal>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > .search-values {
    &__title {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 11px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;

      & > .search-value {
        margin: 5px;
      }
      & .ant-select-selector {
        min-height: 0px;
        height: 30px;
      }
    }
  }
`;

import React, { createContext, PropsWithChildren, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message } from 'antd';
import {
  FormComponentProps,
  WrappedFormUtils,
} from '@ant-design/compatible/lib/form/Form';
import {
  CREATE_ARTIST,
  CreateArtistInput,
  CreateArtistResult,
  CreateArtistVariables,
} from '../../../graphql/mutations/create-artist';
import { useMutation } from '@apollo/react-hooks';
import { Artist } from '../../../types/artist';
import useForm from '../../../hooks/use-form';
import parseISO from 'date-fns/parseISO';
import { useDataListContext } from '../../common/data-list/data-list.context';
import useAppContext from '../../../hooks/use-app-context';
import DistinguishError from '../distinguish-error/distinguish-error.component';
import { FilterArtistsDocument } from '../../../resolver.types';
import { DistinguishError as Error } from 'yggdrasil-shared/domain/error';
import { Artist as ArtistType } from '../../../resolver.types';

export const CreateFormContext =
  createContext<WrappedFormUtils<CreateArtistInput> | null>(null);

export interface ArtistFormManagerProps
  extends FormComponentProps<CreateArtistInput> {
  defaultInput?: Partial<CreateArtistInput>;
  initialValues?: Partial<CreateArtistInput>;
  afterSubmit?: (artist: Artist) => any;
}

const CreateFormManager = (
  props: PropsWithChildren<ArtistFormManagerProps>
) => {
  const { form, children, initialValues, afterSubmit } = props;

  const {
    state: { artists: artistsQueryVariables },
  } = useDataListContext();

  const {
    state: { issuer },
  } = useAppContext();

  const [mutate] = useMutation<CreateArtistResult, CreateArtistVariables>(
    CREATE_ARTIST,
    {
      refetchQueries: () => {
        if (!artistsQueryVariables) {
          return [];
        }

        return [
          {
            query: FilterArtistsDocument,
            variables: artistsQueryVariables,
          },
        ];
      },
    }
  );

  const context = useForm<CreateArtistInput>({
    form,
    submitHandler: async (values, form) => {
      try {
        const input: CreateArtistInput = {
          ...values,
          issuer,
        };

        const { data, errors } = await mutate({
          variables: {
            input,
          },
        });

        if (afterSubmit && !errors) {
          afterSubmit(data!.createArtist);
        }
      } catch (e) {
        const { graphQLErrors } = e as {
          graphQLErrors: Error<ArtistType>[];
        };

        const distinguishError = graphQLErrors.find(
          (error: Error<ArtistType>) => error.name === 'DistinguishError'
        );

        if (distinguishError) {
          form.setStatus({
            state: 'error',
            message: (
              <DistinguishError
                ignoredFields={['source']}
                error={distinguishError}
              />
            ),
          });
        } else {
          throw e;
        }
      }
    },
    errorHandler: (e) => {
      message.error(e.message);
    },
  });

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        dateOfBirth: initialValues.dateOfBirth
          ? parseISO(initialValues.dateOfBirth)
          : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <CreateFormContext.Provider value={context}>
      {children}
    </CreateFormContext.Provider>
  );
};

export default Form.create<ArtistFormManagerProps>({ name: 'create-artist' })(
  CreateFormManager
);

import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import * as React from 'react';
import styled from 'styled-components';

type NavbarProps = {
  children: React.ReactElement[];
};

type NavbarItemProps = {
  href: string;
  children: React.ReactNode;
};

const isNavbarItem = (
  item: React.ReactElement
): item is React.ReactElement<NavbarItemProps> => item?.props?.href;

export const Navbar = (props: NavbarProps) => {
  const location = useLocation();

  /**
   * @note Type assertion made after Midgard update
   */

  const childrenArray = (
    React.Children.toArray(props.children) as React.ReactElement[]
  ).filter(isNavbarItem);

  const itemKeys = childrenArray.map((item) => item.props.href);
  const selectedKey =
    itemKeys.find((key) => location.pathname.includes(key)) || itemKeys[0];

  return (
    <StyledMenu mode="horizontal" selectedKeys={[selectedKey]}>
      {childrenArray.map((item, index) => (
        <Menu.Item key={itemKeys[index]}>{item}</Menu.Item>
      ))}
    </StyledMenu>
  );
};

export const StyledMenu = styled(Menu)`
  &.ant-menu-horizontal {
    line-height: 62px;
  }
`;

export const NavbarItem = (props: NavbarItemProps) => (
  <NavLink to={props.href}>{props.children}</NavLink>
);

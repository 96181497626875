import * as React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { Col, Layout, Row, ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import { Navbar, NavbarItem } from './components/layout/header/navbar/navbar';
import { Logo } from './components/layout/header/logo/logo';
import { ContainersPage } from './pages/containers/containers.page';
import { EventsPage } from './pages/events/events.page';
import { ArtistsPage } from './pages/artists/artists.page';
import { Header } from './components/layout/header/header';
import { Content } from './components/layout/content/content';
import { TopbarNewButton } from './components/layout/header/topbar-new-button/topbar-new-button.component';
import CreateBrandedContainerFromTopicPage from './pages/create-branded-container-from-topic/create-branded-container-from-topic.page';
import ArtistFormPage from './pages/artist-form/artist-form.page';
import { useQuery } from '@apollo/client';
import ArtistDrawer from './pages/artist-drawer/artist-drawer.page';
import { DeleteArtistPage } from './pages/delete-artist/delete-artist.page';
import useAppContext from './hooks/use-app-context';
import DataListProvider from './components/common/data-list/data-list.context';
import { User } from './components/layout/header/user/user';
import ContainersForArtist from './pages/containers-for-artist/containers-for-artist.page';
import { DeleteBrandedContainerPage } from './pages/delete-branded-container/delete-branded-container.page';
import ErrorBoundary from './components/common/error-boundary/error-boundary.component';
import EventDrawer from './pages/event-drawer/event-drawer.page';
import AppVersion from './components/common/app-version/app-version.component';
import { Brand } from 'yggdrasil-shared/domain/dictionary';
import { BrandsDocument, BrandsQueryResult } from './resolver.types';
import PostLoginPage from './pages/post-login/post-login.page';

export const App = () => {
  const { dispatch, state } = useAppContext();
  const { data } = useQuery<BrandsQueryResult['data']>(BrandsDocument);

  React.useEffect(() => {
    if (data?.brands) {
      dispatch({ type: 'SET_BRANDS', brands: data.brands as Brand[] });

      if (!state.selectedBrand) {
        dispatch({ type: 'SELECT_BRAND', brand: data.brands[0] as Brand });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ConfigProvider locale={en_GB}>
      <ErrorBoundary>
        <Layout>
          <Header>
            <Row justify="center" align="middle">
              <Col span={3}>
                <Link to="/">
                  <Logo />
                </Link>
              </Col>
              <Col span={18}>
                <Navbar>
                  <NavbarItem href="/containers">Branded Containers</NavbarItem>
                  <NavbarItem href="/events">Events</NavbarItem>
                  <NavbarItem href="/artists">Artists</NavbarItem>
                </Navbar>
              </Col>
              <Col
                span={3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingRight: '22px',
                }}
              >
                <TopbarNewButton />
                <User />
              </Col>
            </Row>
          </Header>
          <Content>
            <DataListProvider>
              <Routes>
                <Route path="post-login" element={<PostLoginPage />} />
                <Route path="containers" element={<ContainersPage />} />
                <Route
                  path="events/*"
                  element={
                    <>
                      <EventsPage />
                      <EventDrawer />
                    </>
                  }
                />
                <Route
                  path="artists/*"
                  element={
                    <>
                      <ArtistsPage />
                      <ArtistFormPage />
                      <ArtistDrawer />
                      <DeleteArtistPage />
                    </>
                  }
                />
                <Route
                  path="artist/:artistID/containers/*"
                  element={<ContainersForArtist />}
                />

                <Route path="/*" element={<ContainersPage />} />
              </Routes>
              <Routes>
                <Route
                  path="branded-container/*"
                  element={
                    <>
                      <CreateBrandedContainerFromTopicPage />
                      <DeleteBrandedContainerPage />
                    </>
                  }
                />
              </Routes>
            </DataListProvider>
          </Content>
          <Layout.Footer>
            <AppVersion />
          </Layout.Footer>
        </Layout>
      </ErrorBoundary>
    </ConfigProvider>
  );
};

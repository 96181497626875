import { EventPrice, Event } from '../resolver.types';

export const sumEventPrices = (prices: EventPrice[]) =>
  prices.reduce<number>((price, pricing) => {
    return price + pricing.value;
  }, 0);

export const getAveragePrice = (event: Event) => {
  const minPrice = event?.pricing?.minPrices
    ? sumEventPrices(event.pricing.minPrices)
    : 0;
  const maxPrice = event?.pricing?.maxPrices
    ? sumEventPrices(event.pricing.maxPrices)
    : 0;

  return ((minPrice + maxPrice) / 2).toFixed(2);
};

export const mapCurrencySymbol = (currency: string) => {
  const mapper = { EUR: '€' };

  return mapper[currency as keyof typeof mapper] ?? '€';
};

import { Except } from 'type-fest';
import SearchResults, {
  SearchResultChoice,
  SearchResultsProps,
} from '../../common/search-results/search-results.component';
import {
  GetOrganizersDocument,
  GetOrganizersQueryResult,
  GetOrganizersQueryVariables,
  OrganizerQueryIdResult,
  OrganizerQueryNameResult,
  OrganizerQueryResult,
} from '../../../resolver.types';
import { useLazyQuery } from '@apollo/react-hooks';
import React, { forwardRef, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { RefSelectProps } from 'antd/lib/select';

export type ChoicesMapper = (
  result: OrganizerQueryResult
) => SearchResultChoice[];

export type OrganizerInputProps = Except<
  SearchResultsProps,
  'choices' | 'choicesSkip'
> & {
  choicesMapper?: ChoicesMapper;
  debounceTimeout?: number;
};

/**
 * @note "value" has been added - required by antd v4
 */

const mapOrganizerMeta = ({
  key,
}: OrganizerQueryIdResult | OrganizerQueryNameResult) => {
  return {
    label: key,
    value: key,
    key,
  };
};

const defaultMapper: ChoicesMapper = ({ ids, names }) => {
  return [
    ...(ids?.map(mapOrganizerMeta) ?? []),
    ...(names?.map(mapOrganizerMeta) ?? []),
  ];
};

const OrganizerInput = forwardRef<RefSelectProps, OrganizerInputProps>(
  (
    {
      choicesMapper = defaultMapper,
      requiredSearchLength = 2,
      onSearch,
      debounceTimeout = 500,
      defaultSearch = '',
      ...props
    },
    ref
  ) => {
    const [getOrganizers, { data, loading }] = useLazyQuery<
      GetOrganizersQueryResult['data'],
      GetOrganizersQueryVariables
    >(GetOrganizersDocument, {
      fetchPolicy: 'network-only',
    });

    const [search, setSearch] = useState<string | null>(defaultSearch);
    const handleSearch = debounce(setSearch, debounceTimeout);

    const [choices, setChoices] = useState<SearchResultChoice[]>([]);

    useEffect(() => {
      if (!search || search.length < requiredSearchLength) {
        setChoices([]);

        return;
      }

      getOrganizers({
        variables: {
          pagination: {
            limit: 100,
          },
          search,
        },
      });
    }, [search, getOrganizers, requiredSearchLength]);

    useEffect(() => {
      if (data?.getOrganizers) {
        const newChoices = choicesMapper(data.getOrganizers);
        setChoices(newChoices);
      } else {
        setChoices([]);
      }
    }, [data, choicesMapper]);

    return (
      <SearchResults
        ref={ref}
        loading={loading || props.loading}
        choicesSkip={choices.length}
        choices={choices}
        onSearch={handleSearch}
        requiredSearchLength={requiredSearchLength}
        setChevronIcon
        {...props}
      />
    );
  }
);

export default OrganizerInput;

import { MAXIMUM_TOTAL_EVENTS_LIMIT } from 'yggdrasil-shared/database/persistable-filters';

export const isSuggestedEventsTotalOverMax = (
  suggestedEventsTotal: number
): boolean => {
  return suggestedEventsTotal > MAXIMUM_TOTAL_EVENTS_LIMIT;
};

export const getSuggestedEventsCount = (
  suggestedEventsTotal: number,
  displayNumberOnly: boolean
): string => {
  const displayedNumber = Math.min(
    suggestedEventsTotal,
    MAXIMUM_TOTAL_EVENTS_LIMIT
  );
  const numberPrefix = isSuggestedEventsTotalOverMax(suggestedEventsTotal)
    ? '+'
    : '';

  return displayNumberOnly
    ? `${numberPrefix}${displayedNumber}`
    : `Suggested events (${numberPrefix}${displayedNumber})`;
};

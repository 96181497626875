import * as React from 'react';
import { SetPromotionItemModal } from '../set-promotion-item-modal/set-promotion-item-modal.component';
import { SelectPromotionItemsModal } from '../select-promotion-items-modal/select-promotion-items-modal.component';
import { promoteItems } from '../../../static/promotion-items-data';
import { TopicData } from '../../../pages/create-branded-container-from-topic/create-branded-container-from-topic.page';

type TopicSelectionManagerProps = {
  onTopicDataSet: (topicData: TopicData) => void;
  isLoading: boolean;
};

type PromotionItemToResolve = {
  value: string | string[];
  name: string;
  imageUrl: string;
  placeholder: string;
  label: string;
  resolved: boolean;
  order: number;
};

const TopicSelectionManager = ({
  onTopicDataSet,
  isLoading,
}: TopicSelectionManagerProps) => {
  const [promotionItemsToResolve, setPromotionItemsToResolve] = React.useState<
    PromotionItemToResolve[]
  >([]);

  const [selectedPromotionItemToResolve, setSelectedPromotionItemToResolve] =
    React.useState<PromotionItemToResolve | null>(null);

  const onGoBack = () => {
    if (selectedPromotionItemToResolve!.order === 1) {
      setSelectedPromotionItemToResolve(null);
    } else {
      const previousPromotionItem = promotionItemsToResolve.find(
        (promotionItem) =>
          promotionItem.order === selectedPromotionItemToResolve!.order - 1
      );

      setPromotionItemsToResolve(
        promotionItemsToResolve.map((promotionItem) => {
          if (promotionItem.name === selectedPromotionItemToResolve!.name) {
            return {
              ...promotionItem,
              resolved: false,
            };
          }

          return promotionItem;
        })
      );

      setSelectedPromotionItemToResolve(previousPromotionItem!);
    }
  };

  const onNext = ({ value }: { value: string | string[] }): TopicData => {
    const promotionItems = promotionItemsToResolve.map((promotionItem) => {
      if (promotionItem.name === selectedPromotionItemToResolve!.name) {
        return {
          ...promotionItem,
          value,
          resolved: true,
        };
      }

      return promotionItem;
    });

    setPromotionItemsToResolve(promotionItems);

    const promotionItemToResolve = promotionItems.find(
      (promoteItem) => !promoteItem.resolved
    );

    if (!promotionItemToResolve) {
      const topicData = Object.values(promotionItems).reduce(
        (prev, curr) => ({
          ...prev,
          [curr.name]: curr.value,
        }),
        {}
      ) as TopicData;

      return topicData;
    }

    setSelectedPromotionItemToResolve(promotionItemToResolve);

    return {} as TopicData;
  };

  const onSelectPromotionItems = (selectedPromotionItems: string[]) => {
    const promotionItems = promoteItems
      .filter((promoteItem) =>
        selectedPromotionItems.includes(promoteItem.name)
      )
      .map((promoteItem, index) => ({
        value: promoteItem.singleValue ? '' : [],
        name: promoteItem.name,
        imageUrl: promoteItem.imgUrl,
        placeholder: promoteItem.placeholder,
        label: promoteItem.label,
        disabled: promoteItem.disabled,
        resolved: false,
        order: index + 1,
      }));

    setPromotionItemsToResolve(promotionItems);

    setSelectedPromotionItemToResolve(promotionItems[0]);
  };

  return (
    <React.Fragment>
      {selectedPromotionItemToResolve ? (
        <SetPromotionItemModal
          promotionItem={selectedPromotionItemToResolve}
          promotionItemsAmount={promotionItemsToResolve.length}
          onGoBack={onGoBack}
          onNext={onNext}
          onTopicDataSet={onTopicDataSet}
          isLoading={isLoading}
        />
      ) : (
        <SelectPromotionItemsModal onNext={onSelectPromotionItems} />
      )}
    </React.Fragment>
  );
};

export default TopicSelectionManager;

import { StyledDrawerForm } from './drawer-form.styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useUpdateForm } from '../update-form-manager/hooks';
import { getDecorators } from './decorators';
import Icon from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input } from 'antd';
import { DatePicker } from '../../../date-fns/DatePicker';
import { birthdayDateValidator } from '../../../utils/validators';
import { DrawerContent } from '../../layout/drawer/drawer-content/drawer-content.component';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../route-urls';
import {
  Artist,
  CountriesDocument,
  GenresDocument,
} from '../../../resolver.types';
import { DateFormats } from '../../../utils/date-formats';
import NameInput from '../name-input/name-input.component';
import ArtistsWithSameNameExistsWarning from '../artist-with-same-name-exists-warning/artists-with-same-name-exists-warning.component';
import isEqual from 'lodash.isequal';
import { AsyncDropdown } from '../../common/async-dropdown/async-dropdown.component';
import { formatValueToCompare } from './utils';

export type DrawerFormProps = {
  artist: Artist;
  onCancel: () => any;
  onDirtyChange?: (dirty: boolean) => any;
};

const StyledDrawerContent = styled(DrawerContent)`
  width: 100%;
  height: 100%;

  .delete-artist-btn {
    margin-right: auto;
    padding: 0;
    font-weight: 100;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
  }

  .form-alert {
    margin-bottom: 15px;
  }
`;

export const DrawerForm = ({
  artist,
  onCancel,
  onDirtyChange,
}: DrawerFormProps) => {
  const navigate = useNavigate();
  const handleDelete = useCallback(() => {
    navigate(routes.deleteArtist(artist.id));
  }, [navigate, artist.id]);

  const form = useUpdateForm();
  const { isSubmitting, getFieldsValue, status } = form;
  const { name, dateOfBirth, countryOfOrigin, genres, source } = getDecorators(
    form,
    artist
  );
  const value = getFieldsValue();
  const [isDirty, setIsDirty] = useState(false);

  const handleAlertClose = useCallback(() => {
    form.setStatus({});
  }, [form]);

  const handleSimilarArtistsFound = useCallback(
    (artists: Artist[]) => {
      if (artists.length) {
        form.setStatus({
          state: 'warning',
          message: <ArtistsWithSameNameExistsWarning artists={artists} />,
        });
      } else {
        form.setStatus({});
      }
    },
    [form]
  );

  useEffect(() => {
    Object.entries(value).forEach(([key, value]) => {
      const valueToCompare = formatValueToCompare(value);
      const artistValueToCompare = formatValueToCompare(
        artist[key as keyof Artist]
      );

      if (!isEqual(artistValueToCompare, valueToCompare)) {
        setIsDirty(true);
      }
    });
  }, [value, artist]);

  useEffect(() => {
    if (status.state === 'success') {
      setIsDirty(false);
    }
  }, [status]);

  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(isDirty);
    }
  }, [isDirty, onDirtyChange]);

  const GenresDropdown = React.forwardRef((props, ref) => {
    return (
      <AsyncDropdown
        myRef={ref}
        query={GenresDocument}
        multiple
        placeholder="Provide genres"
        {...props}
      />
    );
  });

  const CountryDropdown = React.forwardRef((props, ref) => (
    <AsyncDropdown
      showClear
      query={CountriesDocument}
      myRef={ref}
      placeholder="Provide country"
      {...props}
    />
  ));

  return (
    <StyledDrawerContent
      hasErrors={form.hasFieldErrors()}
      loading={isSubmitting}
      onCancel={onCancel}
      onSave={form.handleSubmit}
      submitDisabled={!isDirty}
      bottomBarContent={
        <Button
          onClick={handleDelete}
          className="delete-artist-btn text--black"
          type="link"
        >
          <Icon component={() => <i className="fal fa-trash-alt" />} />
          <span>Delete artist</span>
        </Button>
      }
    >
      <StyledDrawerForm
        layout="horizontal"
        hideRequiredMark
        colon={false}
        className="drawer-form compatible-row"
        onSubmit={form.handleSubmit}
      >
        {form.status.state && (
          <Alert
            className="form-alert"
            afterClose={handleAlertClose}
            closable
            message={form.status.message}
            type={form.status.state}
            showIcon
          />
        )}
        {/*
        // @ts-ignore */}
        <Form.Item label="Name" className="form-item">
          {name(
            <NameInput
              onArtistsFoundChange={handleSimilarArtistsFound}
              currentArtistID={artist.id}
              allowClear
            />
          )}
        </Form.Item>
        {/*
        // @ts-ignore */}
        <Form.Item label="Birthday" className="form-item">
          {dateOfBirth(
            <DatePicker
              allowClear
              disabled={isSubmitting}
              disabledDate={birthdayDateValidator}
              format={DateFormats.date}
              showToday={false}
            />
          )}
        </Form.Item>
        {/*
        // @ts-ignore */}
        <Form.Item className={'form-item'} label="Country">
          {countryOfOrigin(<CountryDropdown />)}
        </Form.Item>
        {/*
        // @ts-ignore */}
        <Form.Item className={'form-item'} label="Genres">
          {genres(<GenresDropdown />)}
        </Form.Item>
        {/*
        // @ts-ignore */}
        <Form.Item label="URL" className="form-item">
          {source(<Input className="source-input" allowClear />)}
        </Form.Item>
      </StyledDrawerForm>
    </StyledDrawerContent>
  );
};

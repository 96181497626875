import React, { useMemo } from 'react';
import styled from 'styled-components';
import { BrandedContainer } from '../resolver.types';
import { Column } from '../components/common/data-list/types';
import { stateData } from '../components/branded-container/state-switcher/helpers';
import Title from '../components/branded-container/title/title.component';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const defaultColumns = (
  onClick?: (event: BrandedContainer) => void,
  disabled?: boolean,
  showBrand?: boolean
): Array<Column<BrandedContainer>> => [
  {
    title: 'Branded container',
    render: (_, brandedContainer) => {
      return (
        <div
          style={{
            padding: '16px',
            minHeight: '60px',
            width: '100%',
            display: 'block',
          }}
        >
          <Title
            brandedContainer={brandedContainer}
            thumbnailProps={{
              showBrand,
              brandSource: 'brandedContainer',
            }}
            isRegularSize={false}
            showRelevance
            onClick={() => !disabled && onClick && onClick(brandedContainer)}
          />
        </div>
      );
    },
    dataIndex: 'relevance',
    sortable: true,
  },
  {
    title: 'Publishing state',
    render: (_, brandedContainer) => {
      const state = stateData.find(
        (state) => state.value === brandedContainer.state
      )!;

      return (
        <PublishStateContainer
          onClick={() => !disabled && onClick && onClick(brandedContainer)}
        >
          <span className="publish-icon" style={{ color: state.color }}>
            <i className={`fal ${state.iconClass}`} />
          </span>
          <span className="publish-text">{state.value}</span>
        </PublishStateContainer>
      );
    },
  },
  {
    title: 'Events',
    render: (_, brandedContainer) => {
      return (
        <span
          style={{ fontSize: '14px' }}
          onClick={() => !disabled && onClick && onClick(brandedContainer)}
        >
          {brandedContainer.eventIDs?.length ?? 0}
        </span>
      );
    },
  },
];

const actionColumns = (
  assignBrandedContainers: (brandedContainer: BrandedContainer[]) => void,
  loading?: boolean
): Array<Column<BrandedContainer>> => [
  {
    title: '',
    render: (_, brandedContainer) => {
      return (
        <AdditionalButtonsContainer>
          <Button
            style={{ maxWidth: '105px', alignSelf: 'self-end' }}
            disabled={loading}
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => assignBrandedContainers([brandedContainer])}
          >
            Assign
          </Button>
        </AdditionalButtonsContainer>
      );
    },
  },
];

export const defaultBrandedContainersColumns = ({
  onClick,
  disabled,
  showBrand,
}: {
  onClick?: (brandedContainer: BrandedContainer) => void;
  disabled?: boolean;
  showBrand?: boolean;
}): Array<Column<BrandedContainer>> => {
  return useMemo<Array<Column<BrandedContainer>>>(
    () => [...defaultColumns(onClick, disabled, showBrand)],
    [onClick, disabled, showBrand]
  );
};

export const allBrandedContainersColumns = ({
  assignBrandedContainers,
  loading,
  onClick,
  disabled,
  showBrand,
}: {
  assignBrandedContainers: (brandedContainer: BrandedContainer[]) => void;
  loading?: boolean;
  onClick?: (brandedContainer: BrandedContainer) => void;
  disabled?: boolean;
  showBrand?: boolean;
}): Array<Column<BrandedContainer>> => {
  return useMemo<Array<Column<BrandedContainer>>>(
    () => [
      ...defaultColumns(onClick, disabled, showBrand),
      ...actionColumns(assignBrandedContainers, loading),
    ],
    [onClick, disabled, assignBrandedContainers, loading, showBrand]
  );
};

const PublishStateContainer = styled.div`
  display: flex !important;
  align-items: center;

  & > .publish-icon {
    font-size: 16px;
    margin-right: 8px;
  }

  & > .publish-text {
    font-size: 14px;
  }
`;

const AdditionalButtonsContainer = styled.div`
  display: flex !important; /* Antd table overwrites display */
  flex-direction: column;

  & .ant-btn:first-child {
    margin-bottom: 8px;
  }
`;

import styled from 'styled-components';

export const StyledMapContainer = styled.div`
  width: 650px;
  height: 280px;

  .empty {
    margin-bottom: 20px;
  }
`;

export const SliderContainer = styled.div`
  background-color: #fafafa;
  padding: 10px 0;
  display: flex;
  position: relative;
  margin-bottom: 10px;

  & .ant-slider {
    width: calc(100% - 100px);
    margin: 12px 12px 13px;
  }

  & .ant-slider-rail {
    &::before,
    &::after {
      content: '0 km';
      font-size: 10px;
      position: absolute;
      top: 11px;
    }

    &::after {
      content: '250 km';
      right: 0;
    }
  }

  & .ant-slider-track {
    background-color: #e4a140;
  }

  & .ant-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -10px;
  }
`;

export const RangeContainer = styled.div`
  user-select: none;
  background-color: #f3f3f3;
  width: 65px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  line-height: 52px;
  font-size: 12px;
`;

import styled from 'styled-components';

export const ListItem = styled.li`
  border-bottom: none;
  margin-bottom: 15px;

  > a {
    display: flex;
    align-items: baseline;
    color: inherit;

    > * {
      margin-right: 10px;
    }
  }

  .title {
    max-width: 35%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ListContainer = styled.div`
  text-align: center;
  width: 100%;

  .show-all {
    margin-top: 10px;
  }

  .branded-containers-list {
    max-height: 500px;
    overflow: auto;
  }
`;

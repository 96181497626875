import React from 'react';
import { DrawerNotice } from '../../layout/drawer/drawer.styles';

import {
  EventDocument,
  EventQueryResult,
  EventQueryVariables,
  Event,
} from '../../../resolver.types';
import { useQuery } from '@apollo/react-hooks';
import { SpinLoader } from '../../common/spin-loader/spin-loader.component';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { StyledDrawer, StyledDrawerContainer } from './drawer.styles';
import DrawerTopbar from '../drawer-topbar/drawer-topbar';
import DrawerContent from '../drawer-content/drawer-content.component';
import { EventDetailsAction } from '../drawer-content/shared/types';

type DrawerProps = {
  id: string;
  handleClose: () => void;
  width?: number;
  actions?: EventDetailsAction[];
  isNestedDrawer?: boolean;
};

const Drawer = ({
  id,
  handleClose,
  width,
  actions,
  isNestedDrawer,
}: DrawerProps) => {
  const { data, loading } = useQuery<
    EventQueryResult['data'],
    EventQueryVariables
  >(EventDocument, {
    variables: {
      id,
    },
  });

  return (
    <StyledDrawer
      push={false}
      width={width || 960}
      onClose={handleClose}
      closable
      visible
    >
      {data?.event && (
        <DrawerTopbar
          title={data.event.title ?? ''}
          organizerName={data.event.organizer.name}
          organizerID={data.event.organizer.id}
          saleStatus={data.event.salesStatus}
          saleStartDateTime={data.event.saleStartDateTime}
          imageUrl={data.event.imageUrl ?? ''}
        />
      )}
      <StyledDrawerContainer>
        {data?.event && (
          <DrawerContent
            event={data.event as Event}
            actions={actions}
            handleClose={handleClose}
            disabled={isNestedDrawer}
          />
        )}
        {(!data?.event || loading) && (
          <DrawerNotice>
            {loading && <SpinLoader loadingMessage="Loading event..." />}
            {!data?.event && !loading && (
              <Empty
                description={
                  <span>
                    No event with ID <strong>{id}</strong> found.
                  </span>
                }
                image={
                  <WarningOutlined
                    style={{ fontSize: '60px' }}
                    className="notice-icon"
                  />
                }
              >
                <Button onClick={handleClose} icon={<CloseOutlined />}>
                  Close
                </Button>
              </Empty>
            )}
          </DrawerNotice>
        )}
      </StyledDrawerContainer>
    </StyledDrawer>
  );
};

export default Drawer;

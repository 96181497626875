import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { FormStepProps } from './shared';
import { DescriptionContentSwitcher } from '../description-content-switcher/description-content-switcher.component';
import { AppContext, AppStoreApi } from '../../../context/app.context';
import { MetaFormItem } from './meta-step/meta-form-item.component';

export const MetaStep = ({
  onChange,
  isFormDisabled = false,
  values: { metaTitle, metaDescription, dataLastUpdated },
}: FormStepProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    state: { activeBrandTab },
  } = React.useContext(AppContext) as AppStoreApi;

  const onChangeDescriptionContent = (value: string) => {
    const route = location.pathname.replace('meta', value);

    navigate(route);
  };

  return (
    <>
      <DescriptionContentSwitcher
        onChange={onChangeDescriptionContent}
        defaultValue="meta"
      />
      <MetaFormItem
        name="metaTitle"
        label="Meta title"
        placeholder="Enter meta title..."
        onChange={onChange}
        activeBrandTab={activeBrandTab}
        value={metaTitle}
        isFormDisabled={isFormDisabled}
        dataLastUpdated={dataLastUpdated.metaTitle}
        tooltipMessage="Example for meta title on adticket.de: Flic Flac Tickets ab 20,00€"
      />
      <MetaFormItem
        name="metaDescription"
        label="Meta description"
        placeholder="Enter meta description..."
        onChange={onChange}
        activeBrandTab={activeBrandTab}
        value={metaDescription}
        isFormDisabled={isFormDisabled}
        dataLastUpdated={dataLastUpdated.metaDescription}
      />
    </>
  );
};

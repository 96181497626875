import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';

type DeleteMediaImageButtonProps = {
  onClick: () => Promise<void>;
};

export const DeleteMediaImageButton = ({
  onClick: cb,
}: DeleteMediaImageButtonProps) => {
  const onOk = async () => {
    try {
      await cb();
    } catch {}
  };

  const onClick = () => {
    Modal.confirm({
      title: 'Remove branded container image',
      content: 'Are you sure you want to remove image for branded container?',
      onOk,
    });
  };

  return (
    <DeleteButton onClick={onClick} className="delete-media-button">
      <span className="icon">
        <i className="fal fa-trash-alt"></i>
      </span>
    </DeleteButton>
  );
};

const DeleteButton = styled(Button)`
  &.ant-btn {
    padding: 0 5px;
    height: 28px;

    & .icon {
      margin-right: 0px !important;
      font-size: 14px;
    }
  }
`;

import React from 'react';
import {
  scale,
  ratioMap,
  validateThresholds,
} from 'yggdrasil-shared/domain/image';
import { getMouseCoordinates, isMouseInRect } from './helpers';
import { SpinLoader } from '../../../common/spin-loader/spin-loader.component';
import {
  ResizeOption,
  getResizeOptions,
  getNewShapeByResizePosition,
} from './get-resize-options';
import {
  BrandedContainerImageAspectRatio,
  BrandedContainerImageCropBoundaryInput,
} from '../../../../resolver.types';
import styled from 'styled-components';

const CANVAS_MAX_WIDTH = 640;
const CANVAS_MAX_HEIGHT = 430;

export type Coordinates = {
  x: number;
  y: number;
};

export type RectShape = Coordinates & {
  width: number;
  height: number;
};

enum ActionState {
  DRAGGING = 'Dragging',
  RESIZING = 'Resizing',
}

const RESIZE_OPTION_SIZE = 20;

type PreviewElement = {
  ctx: CanvasRenderingContext2D;
  width: number;
  height: number;
};

type ImageCropperProps = {
  imgUrl: string;
  selectedAspectRatio: BrandedContainerImageAspectRatio;
  previewCanvases: Array<React.RefObject<HTMLCanvasElement>>;
  flexibleCanvas: React.MutableRefObject<HTMLCanvasElement | null>;
  initialShape?: BrandedContainerImageCropBoundaryInput;
  onCrop?: (shape: RectShape) => void;
  setCropEnabled: (cropEnabled: boolean) => void;
  disabled?: boolean;
  cropSupported: boolean;
};

export const ImageCropper = ({
  imgUrl,
  previewCanvases,
  flexibleCanvas,
  selectedAspectRatio,
  initialShape,
  onCrop,
  setCropEnabled,
  disabled = false,
  cropSupported,
}: ImageCropperProps) => {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);
  const imageRef = React.useRef(new Image());

  const [actionState, setActionState] = React.useState<ActionState | null>(
    null
  );

  const [dragInitPosition, setDragInitPosition] = React.useState<Coordinates>({
    x: 0,
    y: 0,
  });

  const scaleToDispatch = React.useCallback(
    (prop: number, ratio: number) => prop * (1 / ratio),
    []
  );

  const size = React.useMemo(
    () => {
      const imgRatio = imageRef.current.width / imageRef.current.height;

      let newHeight = CANVAS_MAX_HEIGHT;
      let newWidth = newHeight * imgRatio;

      if (newWidth > CANVAS_MAX_WIDTH) {
        newWidth = CANVAS_MAX_WIDTH;
        newHeight = newWidth / imgRatio;
      }

      let cropSourceHeight = imageRef.current.height;
      let cropSourceWidth = cropSourceHeight * imgRatio;

      if (cropSourceWidth > imageRef.current.width) {
        cropSourceWidth = imageRef.current.width;
        cropSourceHeight = cropSourceWidth / imgRatio;
      }

      const flexibleCanvasInit = document.createElement('canvas');

      const { width, height } = scale({
        width: cropSourceWidth,
        height: cropSourceHeight,
        aspectRatio: selectedAspectRatio,
      });

      flexibleCanvasInit.width = width;
      flexibleCanvasInit.height = height;

      flexibleCanvas.current = flexibleCanvasInit;

      return {
        width: newWidth,
        height: newHeight,
        cropSourceWidth,
        cropSourceHeight,
        canvasToCropSourceRatio: {
          height: newHeight / cropSourceHeight,
          width: newWidth / cropSourceWidth,
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedAspectRatio,
      imageRef.current.src,
      imageRef.current.width,
      imageRef.current.height,
    ]
  );

  const [resizeInitPosition, setResizeInitPosition] =
    React.useState<Coordinates>({ x: 0, y: 0 });

  const selectedResizeOptionRef = React.useRef<ResizeOption | null>(null);

  const getInitialCropShape = React.useMemo(() => {
    if (!cropSupported) {
      return {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
    }

    if (initialShape) {
      return {
        x: initialShape.x,
        y: initialShape.y,
        width: initialShape.width,
        height: initialShape.height,
      };
    }

    const scaled = scale({
      width: size.width,
      height: size.height,
      aspectRatio: selectedAspectRatio,
    });

    return {
      x: scaled.startX,
      y: scaled.startY,
      width: scaled.width,
      height: scaled.height,
    };
  }, [
    initialShape,
    selectedAspectRatio,
    size.width,
    size.height,
    cropSupported,
  ]);

  const getInitialDispatchShape = React.useMemo(
    () => ({
      x: getInitialCropShape.x
        ? scaleToDispatch(
            getInitialCropShape.x,
            size.canvasToCropSourceRatio.width
          )
        : 0,
      y: getInitialCropShape.y
        ? scaleToDispatch(
            getInitialCropShape.y,
            size.canvasToCropSourceRatio.height
          )
        : 0,
      width: getInitialCropShape.width
        ? scaleToDispatch(
            getInitialCropShape.width,
            size.canvasToCropSourceRatio.width
          )
        : 0,
      height: getInitialCropShape.height
        ? scaleToDispatch(
            getInitialCropShape.height,
            size.canvasToCropSourceRatio.height
          )
        : 0,
    }),
    [getInitialCropShape, scaleToDispatch, size]
  );

  const cropShapeRef = React.useRef<RectShape>(getInitialCropShape);
  const dispatchShapeRef = React.useRef<RectShape>(getInitialDispatchShape);

  const resizeOptionsRef = React.useRef<ResizeOption[]>([]);

  const boundCropShapeToCanvas = () => {
    const cropShape = cropShapeRef.current;
    const dispatchShape = dispatchShapeRef.current;
    const canvas = canvasRef.current!;
    const image = imageRef.current;

    const bound = (
      shape: RectShape,
      container: { width: number; height: number }
    ) => {
      if (shape.x <= 0) {
        shape.x = 0;
      }

      if (shape.x >= container.width - Math.abs(shape.width)) {
        shape.x = container.width - shape.width;
      }

      if (shape.y <= 0) {
        shape.y = 0;
      }

      if (shape.y >= container.height - Math.abs(shape.height)) {
        shape.y = container.height - shape.height;
      }
    };

    bound(cropShape, canvas);
    bound(dispatchShape, image);
  };

  const getPreviewContexts = React.useCallback(() => {
    return previewCanvases.reduce<PreviewElement[]>((contexts, canvas) => {
      if (!canvas.current) return contexts;

      return [
        ...contexts,
        {
          width: canvas.current.width,
          height: canvas.current.width * ratioMap[selectedAspectRatio],
          ctx: canvas.current.getContext('2d') as CanvasRenderingContext2D,
        },
      ];
    }, []);
  }, [previewCanvases, selectedAspectRatio]);

  const getFlexiblePreviewContext = React.useCallback(() => {
    if (!flexibleCanvas.current) return [];

    const { width: cropWidth, height: cropHeight } = scale({
      width: dispatchShapeRef.current.width,
      height: dispatchShapeRef.current.height,
      aspectRatio: selectedAspectRatio,
    });

    flexibleCanvas.current.width = cropWidth;
    flexibleCanvas.current.height = cropHeight;

    return [
      {
        width: cropWidth,
        height: cropHeight,
        ctx: flexibleCanvas.current.getContext(
          '2d'
        ) as CanvasRenderingContext2D,
      },
    ];
  }, [flexibleCanvas, dispatchShapeRef, selectedAspectRatio]);

  type DrawPreviewsProps = {
    shapeRef: React.MutableRefObject<RectShape>;
    sizeWidth: number;
    sizeHeight: number;
    previewCanvasGetter: () => {
      width: number;
      height: number;
      ctx: CanvasRenderingContext2D;
    }[];
    cropCallback?: (shape: RectShape) => void;
  };

  const drawPreviews = React.useCallback(
    ({
      shapeRef,
      sizeWidth,
      sizeHeight,
      previewCanvasGetter,
      cropCallback,
    }: DrawPreviewsProps) => {
      const cropShape = shapeRef.current;

      const previewCanvas = document.createElement('canvas');
      const context = previewCanvas.getContext(
        '2d'
      ) as CanvasRenderingContext2D;
      previewCanvas.width = sizeWidth;
      previewCanvas.height = sizeHeight;

      context.drawImage(imageRef.current, 0, 0, sizeWidth, sizeHeight);

      const tempImage = new Image();
      tempImage.crossOrigin = 'Anonymous';
      tempImage.addEventListener('load', () => {
        previewCanvasGetter().forEach(({ ctx, width, height }) => {
          ctx.drawImage(
            tempImage,
            cropShape.x,
            cropShape.y,
            cropShape.width,
            cropShape.height,
            0,
            0,
            width,
            height
          );
        });

        if (cropCallback) {
          cropCallback(cropShape);
        }
      });

      tempImage.src = previewCanvas.toDataURL('image/png');
    },
    []
  );

  const renderCropPreview = React.useCallback(() => {
    drawPreviews({
      shapeRef: cropShapeRef,
      sizeWidth: size.width,
      sizeHeight: size.height,
      previewCanvasGetter: getPreviewContexts,
      cropCallback: onCrop,
    });

    drawPreviews({
      shapeRef: dispatchShapeRef,
      sizeWidth: size.cropSourceWidth,
      sizeHeight: size.cropSourceHeight,
      previewCanvasGetter: getFlexiblePreviewContext,
    });
  }, [
    drawPreviews,
    size.width,
    size.height,
    getPreviewContexts,
    onCrop,
    getFlexiblePreviewContext,
    size.cropSourceHeight,
    size.cropSourceWidth,
  ]);

  const onMouseDown = (event: MouseEvent) => {
    const canvas = canvasRef.current!;

    const cropShape = cropShapeRef.current;

    const mousePosition = getMouseCoordinates(event, canvas);

    selectedResizeOptionRef.current =
      resizeOptionsRef.current.find((option) =>
        isMouseInRect(event, canvas, option)
      ) || null;

    if (selectedResizeOptionRef.current !== null) {
      setResizeInitPosition(mousePosition);
      setActionState(ActionState.RESIZING);
    } else if (isMouseInRect(event, canvas, cropShapeRef.current)) {
      setDragInitPosition({
        x: mousePosition.x - cropShape.x,
        y: mousePosition.y - cropShape.y,
      });
      setActionState(ActionState.DRAGGING);
    }
  };

  const onMouseMove = (event: MouseEvent) => {
    const canvas = canvasRef.current!;

    const mousePosition = getMouseCoordinates(event, canvas);

    switch (actionState) {
      case ActionState.DRAGGING:
        canvas.style.cursor = 'all-scroll';

        cropShapeRef.current.x = mousePosition.x - dragInitPosition.x;
        cropShapeRef.current.y = mousePosition.y - dragInitPosition.y;

        dispatchShapeRef.current.x = scaleToDispatch(
          cropShapeRef.current.x,
          size.canvasToCropSourceRatio.width
        );

        dispatchShapeRef.current.y = scaleToDispatch(
          cropShapeRef.current.y,
          size.canvasToCropSourceRatio.height
        );

        boundCropShapeToCanvas();
        break;

      case ActionState.RESIZING:
        {
          if (!selectedResizeOptionRef.current) {
            return;
          }

          canvas.style.cursor = `${selectedResizeOptionRef.current.position}-resize`;

          const newShape = getNewShapeByResizePosition({
            mousePosition,
            rect: cropShapeRef.current,
            aspectRatio: selectedAspectRatio,
            initialMousePosition: resizeInitPosition,
            position: selectedResizeOptionRef.current.position,
            minimalSize:
              validateThresholds[selectedAspectRatio].width *
              size.canvasToCropSourceRatio.width,
          });

          cropShapeRef.current = newShape;

          dispatchShapeRef.current = {
            x: scaleToDispatch(newShape.x, size.canvasToCropSourceRatio.width),
            y: scaleToDispatch(newShape.y, size.canvasToCropSourceRatio.height),
            width: scaleToDispatch(
              newShape.width,
              size.canvasToCropSourceRatio.width
            ),
            height: scaleToDispatch(
              newShape.height,
              size.canvasToCropSourceRatio.height
            ),
          };

          const isOriginalAspectRatio =
            selectedAspectRatio === BrandedContainerImageAspectRatio.A1x1;

          if (cropShapeRef.current.height >= size.height) {
            const sizeHeight = size.height;
            const sizeWidth = isOriginalAspectRatio
              ? size.height
              : size.height * (1 / ratioMap[selectedAspectRatio]);

            cropShapeRef.current.height = sizeHeight;
            cropShapeRef.current.width = sizeWidth;

            dispatchShapeRef.current.width =
              sizeWidth * (1 / size.canvasToCropSourceRatio.width);

            dispatchShapeRef.current.height =
              sizeHeight * (1 / size.canvasToCropSourceRatio.height);
          }

          if (cropShapeRef.current.width >= size.width) {
            const sizeWidth = size.width;
            const sizeHeight = isOriginalAspectRatio
              ? size.width
              : size.width * ratioMap[selectedAspectRatio];

            cropShapeRef.current.width = sizeWidth;
            cropShapeRef.current.height = sizeHeight;

            dispatchShapeRef.current.width =
              sizeWidth * (1 / size.canvasToCropSourceRatio.width);

            dispatchShapeRef.current.height =
              sizeHeight * (1 / size.canvasToCropSourceRatio.height);
          }

          boundCropShapeToCanvas();
        }

        break;

      default:
        break;
    }
  };

  const onMouseUp = React.useCallback(() => {
    const canvas = canvasRef.current!;

    canvas.style.cursor = 'default';

    renderCropPreview();

    setActionState(null);
  }, [renderCropPreview]);

  React.useEffect(() => {
    if (imageRef.current.complete) {
      cropShapeRef.current = getInitialCropShape;
      dispatchShapeRef.current = getInitialDispatchShape;
      renderCropPreview();
    }
  }, [
    imageRef.current.complete,
    renderCropPreview,
    getInitialCropShape,
    getInitialDispatchShape,
  ]);

  React.useEffect(() => {
    setCropEnabled(false);
    setIsImageLoaded(false);

    const onLoadImage = () => {
      setIsImageLoaded(true);
      setCropEnabled(true);
    };

    const image = imageRef.current;
    image.crossOrigin = 'Anonymous';
    image.addEventListener('load', onLoadImage, { once: true });
    image.src = imgUrl;

    return () => {
      image.removeEventListener('load', onLoadImage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgUrl]);

  React.useEffect(() => {
    let animationRequestId: number;

    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    if (!disabled || !cropSupported) {
      canvas.addEventListener('mousedown', onMouseDown);
      canvas.addEventListener('mousemove', onMouseMove);
      canvas.addEventListener('mouseup', onMouseUp);
      canvas.addEventListener('mouseleave', onMouseUp);
    }

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const update = () => {
      const cropShape = cropShapeRef.current;

      resizeOptionsRef.current = getResizeOptions(
        cropShape,
        RESIZE_OPTION_SIZE
      );
    };

    const render = () => {
      const cropShape = cropShapeRef.current;

      update();
      ctx.clearRect(0, 0, size.width, size.height);

      ctx.drawImage(imageRef.current, 0, 0, size.width, size.height);

      if (!cropSupported) {
        return;
      }

      if (actionState) {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      ctx.setLineDash([10, 10]);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(255, 255, 255)';

      ctx.strokeRect(
        cropShape.x,
        cropShape.y,
        cropShape.width,
        cropShape.height
      );

      ctx.fillStyle = 'rgba(255, 255, 255, 0.4)';
      ctx.fillRect(cropShape.x, cropShape.y, cropShape.width, cropShape.height);

      ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';
      resizeOptionsRef.current.forEach(({ x, y, width, height }) => {
        ctx.fillRect(x, y, width, height);
      });

      animationRequestId = requestAnimationFrame(render);
    };

    render();

    return () => {
      cancelAnimationFrame(animationRequestId);
      if (!disabled) {
        canvas.removeEventListener('mousedown', onMouseDown);
        canvas.removeEventListener('mousemove', onMouseMove);
        canvas.removeEventListener('mouseup', onMouseUp);
        canvas.removeEventListener('mouseleave', onMouseUp);
      }
    };
  });

  return (
    <CanvasContainer>
      {isImageLoaded ? (
        <canvas
          width={size.width}
          height={size.height}
          ref={canvasRef}
        ></canvas>
      ) : (
        <SpinLoader loadingMessage="Loading..." />
      )}
    </CanvasContainer>
  );
};

const CanvasContainer = styled.div`
  width: 640px;
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

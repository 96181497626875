import { RectShape, Coordinates } from './image-cropper.component';

export const getMouseCoordinates = (
  event: MouseEvent,
  canvas: HTMLCanvasElement
): Coordinates => {
  const canvasBoundingBox = canvas.getBoundingClientRect();

  return {
    x: event.clientX - canvasBoundingBox.left,
    y: event.clientY - canvasBoundingBox.top,
  };
};

export const isMouseInRect = (
  event: MouseEvent,
  canvas: HTMLCanvasElement,
  rect: RectShape
) => {
  const mousePosition = getMouseCoordinates(event, canvas);

  if (rect.width < 0 || rect.height < 0) {
    const newRectX = rect.x + rect.width;
    const newRectY = rect.y + rect.height;

    return (
      mousePosition.x >= newRectX &&
      mousePosition.x <= newRectX + Math.abs(rect.width) &&
      mousePosition.y >= newRectY &&
      mousePosition.y <= newRectY + Math.abs(rect.height)
    );
  }

  return (
    mousePosition.x >= rect.x &&
    mousePosition.x <= rect.x + rect.width &&
    mousePosition.y >= rect.y &&
    mousePosition.y <= rect.y + rect.height
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { CheckOutlined, LinkOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  GetLinksByBrandedContainerIdDocument,
  PresaleLink,
} from '../../../../resolver.types';
import { StyledSelect, StyledInput } from './presale-links.styles';
import { StyledButton, StyledFormItem } from './description-step.styles';

type PresaleLinksComponentDeps = {
  isFormDisabled: boolean;
  brandedContainerId: string;
};

export const PresaleLinksComponent = ({
  isFormDisabled = false,
  brandedContainerId,
}: PresaleLinksComponentDeps) => {
  const [presaleLinks, setPresaleLinks] = useState<string[]>([]);
  const [chosenPresaleLink, setChosenPresaleLink] = useState('');
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const { data, loading, stopPolling, startPolling } = useQuery(
    GetLinksByBrandedContainerIdDocument,
    {
      variables: {
        brandedContainerId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const copyTimeout = React.useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    let maxPollTimeout: NodeJS.Timeout;

    startPolling(15 * 1000);
    maxPollTimeout = setTimeout(() => stopPolling(), 5 * 60 * 1000);

    return () => {
      clearTimeout(maxPollTimeout);
      clearTimeout(copyTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandedContainerId]);

  useEffect(() => {
    if (!data || !Object.keys(data).length) {
      return;
    }

    const linkObjects = (Object.values(data)[0] as any).items as PresaleLink[];

    if (linkObjects.length) {
      stopPolling();
    }

    const links = linkObjects.map((linkObject) => linkObject.publishedUrl);

    setPresaleLinks(links.length ? [links[0]] : []);
    setChosenPresaleLink(links[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onChangePresaleLink = useCallback(
    (link: string) => {
      setCopiedToClipboard(false);
      setChosenPresaleLink(link);
    },
    [setCopiedToClipboard, setChosenPresaleLink]
  );

  const onCopyToClipboard = useCallback((_: string, result: boolean) => {
    setCopiedToClipboard(result);

    clearTimeout(copyTimeout.current);
    copyTimeout.current = setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  }, []);

  return (
    <>
      <StyledFormItem label="Presale link">
        {presaleLinks.length <= 1 ? (
          <StyledInput
            style={{ width: 'calc(100% - 100px)' }}
            type="text"
            placeholder={
              !presaleLinks.length
                ? 'No link has been generated yet...'
                : undefined
            }
            value={chosenPresaleLink ?? undefined}
            disabled={isFormDisabled || !presaleLinks.length}
            readOnly={true}
          />
        ) : (
          <StyledSelect
            // @ts-ignore
            onChange={onChangePresaleLink}
            value={chosenPresaleLink ?? undefined}
            disabled={isFormDisabled}
            loading={loading}
            dropdownStyle={{ borderRadius: '0px' }}
          >
            {presaleLinks.map((link, index) => (
              <Select.Option key={index} value={link}>
                {link}
              </Select.Option>
            ))}
          </StyledSelect>
        )}

        <CopyToClipboard text={chosenPresaleLink} onCopy={onCopyToClipboard}>
          <StyledButton
            $clicked={copiedToClipboard}
            disabled={loading || isFormDisabled || !presaleLinks.length}
            icon={!copiedToClipboard ? <LinkOutlined /> : <CheckOutlined />}
          >
            {!copiedToClipboard ? 'Copy' : 'Copied'}
          </StyledButton>
        </CopyToClipboard>
      </StyledFormItem>
    </>
  );
};

import React, { ReactElement } from 'react';
import { brandCustom } from 'yggdrasil-shared/domain/brand-custom';
import { Form } from '@ant-design/compatible';

import { FormStepProps } from './shared';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { DescriptionContentSwitcher } from '../description-content-switcher/description-content-switcher.component';
import { useNavigate, useLocation } from 'react-router';
import { AppContext, AppStoreApi } from '../../../context/app.context';
import { LastUpdatedDate } from './last-updated-date.component';
import {
  BcTranslation,
  BcTranslationInputItem,
  LangLocale,
} from '../../../resolver.types';
import { InputError } from 'yggdrasil-shared/domain/error';
import { localeDisplayLanguageMapper } from 'yggdrasil-shared/domain/public-api/enums/index';

type TranslationsProps = FormStepProps & {
  translationIndex: number;
  translationLocale: LangLocale;
  onTranslationChange: (translations: BcTranslationInputItem[]) => void;
  warningComponent: ReactElement | null;
};
// eslint-disable-next-line complexity
export const TranslationsStep = ({
  onChange,
  onTranslationChange,
  isFormDisabled = false,
  values: {
    translations,
    title,
    subtitle,
    description,
    shortDescription,
    pressText,
    metaTitle,
    metaDescription,
  },
  translationLocale,
  translationIndex,
  warningComponent,
}: TranslationsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    state: { activeBrandTab },
  } = React.useContext(AppContext) as AppStoreApi;

  const onChangeDescriptionContent = (value: string) => {
    const route = location.pathname.replace('translations', value);
    navigate(route);
  };

  const onTranslatedInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name as keyof BcTranslationInputItem;
    const updatedTranslations = translations ?? [];

    if (name === 'locale') {
      throw new InputError(`Invalid input`);
    }

    const updateTranslations = (
      updatedTranslations: BcTranslation[] | BcTranslationInputItem[]
    ) => {
      onTranslationChange(updatedTranslations);
      onChange({
        target: {
          name: 'translations',
          value: updatedTranslations,
        },
      } as any);
    };

    if (translationIndex !== -1) {
      updatedTranslations[translationIndex][name] = event.target.value;
      updateTranslations(updatedTranslations);
    } else {
      updatedTranslations.push({
        locale: translationLocale,
        [event.target.name]: event.target.value,
      });
      updateTranslations(updatedTranslations);
    }
  };

  const currentTranslation =
    translationIndex > -1 && translations?.[translationIndex]
      ? translations?.[translationIndex]
      : undefined;

  return (
    <React.Fragment>
      <DescriptionContentSwitcher
        onChange={onChangeDescriptionContent}
        defaultValue="translations"
      />
      {warningComponent}

      {/*
      // @ts-ignore */}
      <Form.Item
        label={`Title (${localeDisplayLanguageMapper[translationLocale]})`}
      >
        <Input
          type="text"
          name="title"
          placeholder={title || 'Enter title...'}
          value={currentTranslation?.title || ''}
          onChange={onTranslatedInputChange}
          disabled={isFormDisabled}
          maxLength={200}
        />
        {currentTranslation?.dataLastUpdated?.title && (
          <LastUpdatedDate date={currentTranslation.dataLastUpdated.title} />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item
        label={`Subtitle (${localeDisplayLanguageMapper[translationLocale]})`}
      >
        <Input
          type="text"
          name="subtitle"
          placeholder={subtitle || 'Enter subtitle...'}
          value={currentTranslation?.subtitle || ''}
          onChange={onTranslatedInputChange}
          disabled={isFormDisabled}
          maxLength={128}
        />
        {currentTranslation?.dataLastUpdated?.subtitle && (
          <LastUpdatedDate date={currentTranslation.dataLastUpdated.subtitle} />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item
        label={`Editorial long text (${localeDisplayLanguageMapper[translationLocale]})`}
      >
        <Input.TextArea
          autoSize={{
            minRows: 11,
            maxRows: 11,
          }}
          name="description"
          value={currentTranslation?.description || ''}
          style={{ resize: 'none' }}
          onChange={onTranslatedInputChange}
          disabled={isFormDisabled}
          placeholder={description || 'Enter editorial long text...'}
        />
        {currentTranslation?.dataLastUpdated?.description && (
          <LastUpdatedDate
            date={currentTranslation.dataLastUpdated.description}
          />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item
        label={`Editorial short text (${localeDisplayLanguageMapper[translationLocale]})`}
      >
        <Input.TextArea
          autoSize={{
            minRows: 6,
            maxRows: 11,
          }}
          name="shortDescription"
          value={currentTranslation?.shortDescription || ''}
          style={{ resize: 'none' }}
          onChange={onTranslatedInputChange}
          disabled={isFormDisabled}
          placeholder={shortDescription || 'Enter editorial short text...'}
        />
        {currentTranslation?.dataLastUpdated?.shortDescription && (
          <LastUpdatedDate
            date={currentTranslation.dataLastUpdated.shortDescription}
          />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      <Form.Item
        label={`Press text (${localeDisplayLanguageMapper[translationLocale]})`}
      >
        <Input.TextArea
          autoSize={{
            minRows: 6,
            maxRows: 11,
          }}
          name="pressText"
          value={currentTranslation?.pressText || ''}
          style={{ resize: 'none' }}
          onChange={onTranslatedInputChange}
          disabled={isFormDisabled}
          placeholder={pressText || 'Enter press text...'}
        />
        {currentTranslation?.dataLastUpdated?.pressText && (
          <LastUpdatedDate
            date={currentTranslation.dataLastUpdated.pressText}
          />
        )}
      </Form.Item>
      {/*
      // @ts-ignore */}
      {brandCustom.isMetaEnabled(activeBrandTab) && (
        <React.Fragment>
          {/*
      // @ts-ignore */}
          <Form.Item
            label={`Meta title (${localeDisplayLanguageMapper[translationLocale]})`}
          >
            <Input
              type="text"
              name="metaTitle"
              placeholder={metaTitle || 'Enter meta title...'}
              value={currentTranslation?.metaTitle || ''}
              onChange={onTranslatedInputChange}
              disabled={isFormDisabled}
            />
            {currentTranslation?.dataLastUpdated?.metaTitle && (
              <LastUpdatedDate
                date={currentTranslation.dataLastUpdated.metaTitle}
              />
            )}
          </Form.Item>
          {/*
      // @ts-ignore */}
          <Form.Item
            label={`Meta Description (${localeDisplayLanguageMapper[translationLocale]})`}
          >
            <Input.TextArea
              autoSize={{
                minRows: 6,
                maxRows: 11,
              }}
              name="metaDescription"
              value={currentTranslation?.metaDescription || ''}
              style={{ resize: 'none' }}
              onChange={onTranslatedInputChange}
              disabled={isFormDisabled}
              placeholder={metaDescription || 'Enter meta description...'}
            />
            {currentTranslation?.dataLastUpdated?.metaDescription && (
              <LastUpdatedDate
                date={currentTranslation.dataLastUpdated.metaDescription}
              />
            )}
          </Form.Item>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import type { DocumentNode } from '@apollo/client';
import { Pagination } from '../../../resolver.types';

type SearchVariables = {
  search: string;
  pagination: Pagination;
};

type ByIdsVariables = {
  ids: string[];
};

export type QueryResult<T> =
  | {
      items?: T[];
      metadata?: { total: number };
    }
  | null
  | undefined;

export type EntityProps<T, A, B> = {
  displayName: string;
  idKey: keyof T;
  nameKey: keyof T;
  nameGetter: (entity: T) => string;
  entitiesByIdsQueryKey: keyof A;
  entitiesSearchQueryKey: keyof B;
  entitiesByIdsDocument: DocumentNode;
  entitiesSearchDocument: DocumentNode;
};

export type EntityDropdownHookConfig<T, A, B> = {
  onChange: (value: string[] | string) => any;
  onToggle?: () => any;
  value: string[];
  defaultOpen?: boolean;
  closeOnSelect?: boolean;
  entityProps: EntityProps<T, A, B>;
};

export type EntityDropdownHook<T> = {
  isAddingEntity: boolean;
  toggleEntityForm: () => void;
  handleChange: (value: any) => void;
  handleSearch: (value: string) => void;
  onEntityCreated: (entity: T) => void;
  search: string;
  open: boolean;
  setOpen: (open: boolean) => any;
  query: {
    entities: T[];
    totalEntities: number;
    loading: boolean;
    error?: string;
  };
};

export const useEntityDropdown = <
  T extends Record<string, unknown>,
  A extends Record<string, QueryResult<T>>,
  B extends Record<string, QueryResult<T>>
>({
  onChange,
  onToggle,
  value,
  defaultOpen = false,
  closeOnSelect = true,
  entityProps: {
    idKey,
    nameKey,
    entitiesByIdsDocument,
    entitiesSearchDocument,
    entitiesByIdsQueryKey,
    entitiesSearchQueryKey,
  },
}: EntityDropdownHookConfig<T, A, B>): EntityDropdownHook<T> => {
  const [isAddingEntity, setIsAddingEntity] = useState(false);
  const [didInitialFetch, setDidInitialFetch] = useState(false);
  const [entities, setEntities] = useState<T[]>([]);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(defaultOpen);

  const [getEntities, { data: getEntitiesData }] = useLazyQuery<
    A,
    ByIdsVariables
  >(entitiesByIdsDocument);

  const toggleEntityForm = useCallback(() => {
    if (onToggle) {
      onToggle();
    }

    setIsAddingEntity(!isAddingEntity);
  }, [onToggle, setIsAddingEntity, isAddingEntity]);

  const { data, error, loading } = useQuery<B, SearchVariables>(
    entitiesSearchDocument,
    {
      variables: {
        search,
        pagination: {
          start: 0,
          limit: 100,
        },
      },
      fetchPolicy: 'network-only',
    }
  );

  const handleChange = useCallback(
    (value: string[]) => {
      if (closeOnSelect) {
        setOpen(false);
      }

      onChange(value);
      setSearch('');
    },
    [onChange, closeOnSelect]
  );

  const onEntityCreated = useCallback(
    (entity: T) => {
      const newEntities = [entity, ...entities];
      setEntities(newEntities);

      onChange([...value, String(entity[idKey])]);
      setTimeout(() => toggleEntityForm(), 101);
    },
    [entities, onChange, value, toggleEntityForm, idKey]
  );

  useEffect(() => {
    const filteredEntities = entities.filter((entity) =>
      value.includes(String(entity[idKey]))
    );

    const newEntities: T[] = [
      ...(data?.[entitiesSearchQueryKey]?.items ?? []),
      ...filteredEntities,
    ]
      .filter((entity, index, arr) => {
        const foundIndex = arr.findIndex(
          (item) => item[idKey] === entity[idKey]
        );

        return foundIndex === index;
      })
      .sort((a, b) => (a[nameKey] < b[nameKey] ? 1 : -1))
      .sort((a) =>
        filteredEntities.find((entity) => entity[nameKey] === a[nameKey])
          ? 1
          : -1
      );

    setEntities(newEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value]);

  useEffect(() => {
    if (didInitialFetch || entities.length || !value.length) {
      return;
    }

    getEntities({
      variables: {
        ids: value,
      },
    });

    setDidInitialFetch(true);
  }, [value, didInitialFetch, entities, getEntities]);

  useEffect(() => {
    if (getEntitiesData?.[entitiesByIdsQueryKey]) {
      setEntities(getEntitiesData?.[entitiesByIdsQueryKey]?.items as T[]);
    } else {
      setDidInitialFetch(false);
    }
  }, [getEntitiesData, entitiesByIdsQueryKey]);

  return {
    isAddingEntity,
    toggleEntityForm,
    handleChange,
    handleSearch: debounce(setSearch, 500),
    onEntityCreated,
    open,
    setOpen,
    search,
    query: {
      entities,
      totalEntities: data?.[entitiesSearchQueryKey]?.metadata?.total ?? 0,
      loading,
      error: error?.message,
    },
  };
};

import styled from 'styled-components';
import { Button } from 'antd';

export const FiltersContainer = styled.div`
  background-color: #fafafa;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background-color: #fafafa;
  padding: 10px 20px;

  .ant-input[disabled] {
    background-color: transparent !important;
  }

  .ant-select-selection--single {
    height: 41px;
  }

  .ant-select-selection--multiple {
    white-space: nowrap;
    min-height: 30px;
    height: auto;
    overflow: auto;
  }

  .ant-form-item-control {
    line-height: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-legacy-form-item-control {
    line-height: 0;
  }

  .ant-legacy-form-item {
    margin-bottom: 0;

    & .ant-input-affix-wrapper {
      height: 40px;
    }

    & .ant-input {
      top: -4px;
      height: 38px;
    }
  }

  .ant-picker {
    height: 40px;
  }

  .ant-calendar-picker-input.ant-input {
    font-size: 13px;
  }
  .ant-picker-input.ant-input {
    font-size: 13px;
  }

  .ant-input-group-addon {
    & > button {
      height: 40px;
      border-left: none;
    }
  }

  .ant-select-selector {
    min-height: 40px;
  }

  & .ant-select-selection-overflow-item {
    max-width: 110px;
  }

  & .ant-input-group-addon .ant-select-selection-item {
    line-height: 40px;
  }

  & > .row {
    margin: 12px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > .filter-field {
      position: relative;

      & > .filter-field__label {
        font-size: 11px;
        position: absolute;
        top: -13px;
        background-color: #fafafa;
        left: 11px;
        z-index: 100;
        padding: 0 5px;
      }
    }

    & .async-dropdown {
      & .ant-select-selection--single {
        height: 100%;
      }
    }
  }
`;

export const ToggleFiltersContainer = styled.div`
  width: 100%;
  background-color: #fafafa;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

export const ToggleFiltersButton = styled(Button)`
  &.ant-btn {
    font-size: 11px;
    padding: 0;
    height: 18px;
    color: #e4a140;
    background-color: inherit;
    border: none;
    box-shadow: none;
    font-weight: 500;

    & i {
      margin-left: 8px;
    }

    &:focus,
    &:hover {
      background-color: inherit;
    }
  }
`;

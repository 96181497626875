import format from 'date-fns/format';
import { DateFormats } from '../../../../../utils/date-formats';

export type PickerRanges = {
  [key: string]: [Date, Date];
};

export const hasTime = (date: Date): boolean => {
  const time = format(date, 'HH:mm');

  return time !== '00:00';
};

export const formatDate = (
  date: Date,
  relatedDate: Date,
  showTime = true
): string => {
  if (!showTime) {
    return format(date, DateFormats.date);
  }

  if (hasTime(relatedDate)) {
    return format(date, DateFormats.dateTime);
  }

  return !hasTime(date)
    ? format(date, DateFormats.date)
    : format(date, DateFormats.dateTime);
};

const getOffsetDate = (startDate: Date, daysOffset: number) =>
  new Date(startDate.setDate(startDate.getDate() + daysOffset));

const getNextWeekend = (startDate: Date, satOffset = 6): [Date, Date] => {
  const saturday = new Date(startDate.getTime());
  saturday.setDate(
    startDate.getDate() + ((7 + satOffset - startDate.getDay()) % 7)
  );

  const sunday = new Date(saturday);
  sunday.setDate(saturday.getDate() + 1);

  return [saturday, sunday];
};

export const pastPickerRanges: PickerRanges = {
  'Last month': [getOffsetDate(new Date(), -30), new Date()],
  'Last 14 days': [getOffsetDate(new Date(), -14), new Date()],
  Today: [new Date(), new Date()],
};

export const pickerRanges: PickerRanges = {
  ...pastPickerRanges,
  'Next weekend': getNextWeekend(new Date()),
  'Next month': [new Date(), getOffsetDate(new Date(), 30)],
  'Next 3 months': [new Date(), getOffsetDate(new Date(), 90)],
};

import { CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Tooltip } from 'antd';
import { DatePicker } from '../../../../../date-fns/DatePicker';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import dateFnsFormat from 'date-fns/format';
import {
  formatDate,
  hasTime,
  PickerRanges,
  pickerRanges as defaultPickerRanges,
} from './utils';
import { ResetButton } from '../../../common/reset-button/reset-button.component';
import { DateFormats } from '../../../../../utils/date-formats';
import styled from 'styled-components';

export type DateSelectProps = {
  value: any;
  name: string;
  resetValue?: () => void;
  handleCalendarChange: (dates: any) => void;
  endInputPlaceholder?: string;
  defaultOpen?: boolean;
  onChange?: (value: [Date, Date] | []) => any;
  timeSelection?: boolean;
  separator?: string;
  disabled?: boolean;
  tooltipText?: string;
  pickerRanges?: PickerRanges;
  decorator?: (node: React.ReactNode) => React.ReactNode;
  label?: string;
  placeholder?: string;
  width?: string;
  customFormat?: DateFormats | DateFormats[];
  allowClear?: boolean;
  hideArrow?: boolean;
};

const { RangePicker } = DatePicker;

const setDefaultTime = () => {
  const date = new Date();
  date.setHours(0, 0, 0);
  return date;
};

const defaultTime = setDefaultTime();

export const DateSelect = ({
  value,
  resetValue,
  decorator,
  placeholder,
  name,
  width,
  endInputPlaceholder = `${placeholder} to`,
  handleCalendarChange,
  defaultOpen = false,
  onChange,
  timeSelection = true,
  separator = '-',
  label,
  disabled,
  tooltipText,
  pickerRanges,
  allowClear = false,
  hideArrow = false,
}: DateSelectProps) => {
  const containerRef = useRef<HTMLSpanElement>();
  const getPickerContainer = useCallback(() => {
    return containerRef.current ?? document.body;
  }, [containerRef]);

  const [hasEndDate, setHasEndDate] = useState(false);
  const [open, setOpen] = useState(defaultOpen);
  const [format, setFormat] = useState([DateFormats.date, DateFormats.date]);

  useEffect(() => {
    if (!value?.length) {
      setHasEndDate(false);
      return;
    }

    const [startDate, endDate] = value;

    setHasEndDate(
      Boolean(endDate) && startDate.valueOf() !== endDate.valueOf()
    );
  }, [value]);

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  useEffect(() => {
    if (!value?.length) {
      return;
    }

    if (!timeSelection) {
      setFormat([DateFormats.date, DateFormats.date]);
      return;
    }

    const [startDate, endDate] = value;
    const format =
      hasTime(startDate) || hasTime(endDate)
        ? DateFormats.dateTime
        : DateFormats.date;

    setFormat([format, format]);
  }, [value, timeSelection]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  const commonProps = {
    style: { width: '100%' },
    getPopupContainer: getPickerContainer,
    onOpenChange: handleOpenChange,
    ranges: pickerRanges || defaultPickerRanges,
    allowClear,
    open,
    disabled,
    dropdownClassName: `styled-range-picker range-picker-single-input date-dropdown-${name} ${
      hasEndDate ? 'has-end-date' : ''
    }`,
    className: `range-picker-single-input ${
      value?.length === 2 ? 'has-value' : ''
    }`,
    placeholder: [placeholder, endInputPlaceholder] as [string, string],
    showTime: timeSelection
      ? {
          defaultValue: [defaultTime, defaultTime],
          format: 'HH:mm',
        }
      : false,
    renderExtraFooter: () => {
      if (!value) {
        return null;
      }

      const [startDate, endDate] = value;

      if (!startDate) {
        return null;
      }

      return (
        <span className="custom-footer">
          <span>{formatDate(startDate, endDate, timeSelection)}</span>
          {endDate && endDate.valueOf() !== startDate.valueOf() && (
            <>
              {` ${separator} `}
              <span>{formatDate(endDate, startDate, timeSelection)}</span>
            </>
          )}
          {value?.length === 2 && resetValue && (
            <Button
              className="small-icon-btn"
              onClick={resetValue}
              size="small"
              style={{ marginLeft: '5px' }}
              shape="circle"
              type="primary"
            >
              <CloseOutlined />
            </Button>
          )}
        </span>
      );
    },
  };

  return (
    <Container $hideArrow={hideArrow}>
      {/*
      // @ts-ignore */}
      <Form.Item
        label={label}
        colon={false}
        className="range-picker-single-input-container compatible-row"
        style={{ width, minWidth: '100px' }}
      >
        {value?.length > 0 && !open && resetValue && (
          <ResetButton onClick={resetValue} />
        )}
        {value?.length <= 0 && (
          <span className="placeholder ant-select-selection__placeholder">
            {placeholder}
          </span>
        )}
        {value?.length > 0 && (
          <Row className="value">
            <span>{value[0] && dateFnsFormat(value[0], format[0])}</span>
            {value[1] && value[0]?.valueOf() !== value[1]?.valueOf() && (
              <>
                <span className="separator">{` ${separator} `}</span>
                <span>{dateFnsFormat(value[1], format[1])}</span>
              </>
            )}
          </Row>
        )}
        <span ref={containerRef as MutableRefObject<HTMLSpanElement>}>
          <Tooltip title={tooltipText}>
            {decorator ? (
              decorator(
                <RangePicker
                  onCalendarChange={handleCalendarChange}
                  format={format}
                  {...commonProps}
                />
              )
            ) : (
              <RangePicker
                onChange={handleCalendarChange}
                value={value}
                format={DateFormats.date}
                {...commonProps}
              />
            )}
          </Tooltip>
        </span>
      </Form.Item>
    </Container>
  );
};

const Container = styled.div.attrs(
  ({ $hideArrow }: { $hideArrow: boolean }) => ({
    $hideArrow,
  })
)`
  & .anticon-calendar svg {
    font-size: 16px;
  }

  & .ant-legacy-form-item-control {
    line-height: 40px !important;
  }

  & .ant-picker-range-arrow {
    display: ${(props) => (props.$hideArrow ? 'none' : 'block')} !important;
  }
`;

import React, { useMemo } from 'react';
import { Thumbnail } from '../../common/thumbnail/thumbnail.component';
import { EventOrganizer, EventVenue } from '../../../resolver.types';
import { DateFormats } from '../../../utils/date-formats';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { TitleWrapper, Container } from './event-title.styles';
import { Tooltip } from 'antd';

type EventTitleProps = {
  title: string;
  organizer?: EventOrganizer;
  venue?: EventVenue;
  startDateTime?: string | null;
  imageUrl: string;
  genre?: string;
  showMeta?: boolean;
  sourceEventID?: string;
  onClick?: () => void;
  smallMode?: boolean;
};

export const EventTitle = ({
  title,
  organizer,
  venue,
  startDateTime,
  imageUrl,
  genre,
  showMeta = true,
  sourceEventID,
  onClick = () => {},
  smallMode,
}: EventTitleProps) => {
  const startDateTimeDisplay = useMemo(() => {
    if (!startDateTime) {
      return null;
    }

    return format(parseISO(startDateTime), DateFormats.dateTime);
  }, [startDateTime]);

  return (
    <Container onClick={onClick} smallMode={smallMode}>
      <div className="inner-container">
        <Thumbnail showBrand={false} size="35px" imageUrl={imageUrl ?? ''} />
        <TitleWrapper className="event-title-container" smallMode={smallMode}>
          <span className="title-wrapper">
            <Tooltip title={title}>
              <span className="title">{title}</span>
            </Tooltip>
            {genre && (
              <Tooltip title={genre}>
                <div className="tag secondary">{genre}</div>
              </Tooltip>
            )}
          </span>
          {sourceEventID && (
            <>
              {' '}
              <span className="source-event-id"> (ID: {sourceEventID})</span>
            </>
          )}
          {organizer && (
            <span className="event-details organizer-name">
              {organizer.name} (ID: {organizer.id})
            </span>
          )}
          {showMeta && venue && (
            <>
              <div className="text-with-icon">
                <span className="icon">
                  <i className="fal fa-map-marker" />
                </span>
                <span className="text">
                  {venue.address?.street}, {venue.address?.city}
                </span>
              </div>
              {startDateTimeDisplay && (
                <div className="text-with-icon">
                  <span className="icon">
                    <i className="fal fa-calendar" />
                  </span>
                  <span className="text">{startDateTimeDisplay}</span>
                </div>
              )}
            </>
          )}
        </TitleWrapper>
      </div>
    </Container>
  );
};

import React from 'react';
import { BrandedContainerComment as BrandedContainerCommentData } from '../../../resolver.types';
import {
  CommentContainer,
  CommentContent,
  CommentFooter,
  CommentInfo,
  CommentAuthor,
  CommentDate,
} from './comment.styles';
import { UserRoundAvatar } from '../../common/user-round-avatar/user-round-avatar.component';

type BrandedContainerCommentProps = {
  comment: BrandedContainerCommentData;
};

export const BrandedContainerComment = ({
  comment: { content, issuer, date },
}: BrandedContainerCommentProps) => {
  return (
    <CommentContainer className="comment-container">
      <CommentContent>{content}</CommentContent>
      <CommentFooter>
        <UserRoundAvatar username={issuer} />
        <CommentInfo>
          <CommentAuthor>{issuer}</CommentAuthor>
          <CommentDate>{new Date(date).toLocaleString()}</CommentDate>
        </CommentInfo>
      </CommentFooter>
    </CommentContainer>
  );
};

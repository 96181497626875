import { useParams } from 'react-router';
import {
  BrandedContainer,
  BrandedContainersByArtistListDocument,
  GetArtistDocument,
  GetArtistQueryResult,
  GetArtistQueryVariables,
} from '../../resolver.types';
import useBrandedContainerColumns from '../../hooks/use-branded-container-columns';
import { DataList } from '../../components/common/data-list/data-list.component';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../route-urls';
import { Row } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { SpinLoader } from '../../components/common/spin-loader/spin-loader.component';
import ContainerForArtist from '../container-for-artist/container-for-artist';

const ContainersForArtist = () => {
  const { artistID } = useParams();
  const { data, loading } = useQuery<
    GetArtistQueryResult['data'],
    GetArtistQueryVariables
  >(GetArtistDocument, {
    variables: {
      id: artistID!,
    },
  });

  const columns = useBrandedContainerColumns({
    containerUrlCreator: (containerID, content) =>
      routes.brandedContainerForArtist(artistID!, containerID, content),
    showBrands: true,
  });

  return (
    <>
      <ContainerForArtist />
      <div>
        <div
          style={{
            paddingTop: '25px',
            paddingBottom: '15px',
            paddingLeft: '15px',
          }}
        >
          <Row align="middle">
            {loading ? (
              <SpinLoader />
            ) : (
              <>
                {data && data.artist && (
                  <>
                    Showing branded containers that feature artist:
                    <Link
                      to={routes.setArtistData(data.artist.id)}
                      style={{ marginLeft: '5px', textDecoration: 'underline' }}
                    >
                      {data.artist.name}
                    </Link>
                    <div
                      style={{
                        width: 'auto',
                        display: 'inline-block',
                        marginLeft: '5px',
                      }}
                      className="topic-tag tag"
                    >
                      Artist
                    </div>
                  </>
                )}
              </>
            )}
          </Row>
        </div>

        <DataList<BrandedContainer>
          columns={columns}
          query={BrandedContainersByArtistListDocument}
          dataType="branded_containers_for_artist"
          queryVars={{
            artistID,
          }}
        />
      </div>
    </>
  );
};

export default ContainersForArtist;

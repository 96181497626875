import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Select } from 'antd';
import { LabeledValue, OptionProps, RefSelectProps } from 'antd/lib/select';
import React, {
  ClassicComponentClass,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  LoadingIcon,
  ResultsRow,
  SearchIcon,
  ShowAll,
  StyledHighlight,
  StyledSelect,
} from './search-results.styles';
import isEqual from 'lodash.isequal';
import { ResetButton } from '../../filters/common/reset-button/reset-button.component';
import usePrevious from '../../../hooks/use-previous';
import { DropdownArrow } from '../icons/icons';

/**
 * @note "value" has been added - required by antd v4
 */
export interface SearchResultChoice extends LabeledValue {
  key: any;
  value: any;
}

interface InternalSearchResultChoice extends SearchResultChoice {
  isInValue?: boolean;
}

// Workaround for Typescript invalid treating of "label" props
const Option = Select.Option as unknown as ClassicComponentClass<
  OptionProps & {
    label?: ReactNode;
  }
>;

export type SearchResultsProps = {
  name: string;
  label?: string;
  placeholder?: string;
  width?: string;
  loading?: boolean;
  defaultOpen?: boolean;
  choices: SearchResultChoice[];
  onSearch?: (search: string) => any;
  onChange?: (value: LabeledValue[]) => any;
  value?: LabeledValue[];
  choicesSkip?: number;
  onShowAllChange?: (showAll: boolean) => any;
  totalChoices?: number;
  defaultSearch?: string;
  requiredSearchLength?: number;
  mode?: 'tags' | 'multiple';
  showReset?: boolean;
  maxTagCount?: number;
  clearable?: boolean;
  renderOption?: (option: any) => ReactNode;
  renderLabel?: (option: any) => ReactNode;
  optionFilterProp?: string;
  setChevronIcon?: boolean;
  maxTagTextLength?: number;
  maxTagWidth?: string;
};

/**
 * @note "value" has been added - required by antd v4
 */
const stringifyKey = (choice: LabeledValue) => ({
  ...choice,
  key: typeof choice.key === 'object' ? JSON.stringify(choice.key) : choice.key,
});

const SearchResults = forwardRef<RefSelectProps, SearchResultsProps>(
  (
    {
      loading,
      defaultOpen,
      placeholder,
      name,
      width,
      choices,
      onSearch,
      onChange,
      choicesSkip = 5,
      onShowAllChange,
      totalChoices = 0,
      defaultSearch = '',
      requiredSearchLength = 2,
      mode = 'multiple',
      maxTagCount,
      value = [],
      showReset,
      label,
      renderOption,
      renderLabel,
      optionFilterProp = 'label',
      setChevronIcon,
      maxTagTextLength,
      maxTagWidth,
    },
    ref
  ) => {
    const resetValue = useCallback(() => {
      if (onChange) {
        onChange([]);
      }
    }, [onChange]);

    const [search, setSearch] = useState<string>(defaultSearch);
    const previousSearch = usePrevious(search);
    const [open, setOpen] = useState(defaultOpen);
    const [showAll, setShowAll] = useState(false);
    const previousShowAll = usePrevious(showAll);
    const switchShowAll = useCallback(() => {
      setShowAll(!showAll);
    }, [showAll]);

    const notFoundContent = useMemo(() => {
      if (loading) {
        return 'Loading...';
      }

      if (!requiredSearchLength || requiredSearchLength > search.length) {
        return `You need to type at least ${requiredSearchLength} characters.`;
      }

      return 'Nothing found';
    }, [loading, search, requiredSearchLength]);

    useEffect(() => {
      if (onSearch) {
        onSearch(search);
      }
    }, [search, onSearch]);

    useEffect(() => {
      if (search !== previousSearch) {
        setShowAll(false);
      }
    }, [search, previousSearch]);

    useEffect(() => {
      if (onShowAllChange && showAll !== previousShowAll) {
        onShowAllChange(showAll);
      }
    }, [onShowAllChange, showAll, previousShowAll]);

    useEffect(() => {
      if (!open) {
        setSearch('');
      }
    }, [open]);

    const allChoices = useMemo<InternalSearchResultChoice[]>(() => {
      /**
       * @note key has been assigned with to val.value after antd v4 migration
       * to keep the same logic behaviour
       */
      const mappedValue = value.map(
        (val): InternalSearchResultChoice => ({
          ...val,
          isInValue: true,
          key: val.value,
          label: val.label,
        })
      );

      return [...mappedValue, ...choices]
        .map(stringifyKey)
        .filter((choice, index, arr) => {
          const foundIndex = arr.findIndex((item) =>
            isEqual(item.key, choice.key)
          );

          return foundIndex === index;
        });
    }, [choices, value]);

    return (
      <>
        {/*
        // @ts-ignore */}
        <Form.Item
          className="compatible-row"
          colon={false}
          label={label}
          style={{ width, minWidth: '100px' }}
        >
          {value.length > 0 && showReset && (
            <ResetButton onClick={resetValue} />
          )}
          <span>
            <StyledSelect
              $maxTagWidth={maxTagWidth}
              ref={ref as any}
              id={name}
              onFocus={() => setOpen(true)}
              dropdownMatchSelectWidth={false}
              autoClearSearchValue
              notFoundContent={notFoundContent}
              loading={loading}
              open={open}
              onChange={onChange as any}
              value={value}
              onDropdownVisibleChange={setOpen}
              dropdownClassName="styled-dropdown no-checks"
              placeholder={placeholder}
              maxTagTextLength={maxTagTextLength}
              maxTagCount={maxTagCount}
              className={`detailed-tags${value.length > 0 ? ' has-value' : ''}${
                maxTagCount && value.length - maxTagCount >= 10
                  ? ' more-than-10'
                  : ' less-than-10'
              }`}
              showArrow
              suffixIcon={
                loading ? (
                  <LoadingIcon />
                ) : setChevronIcon ? (
                  <DropdownArrow $open={open!} />
                ) : (
                  <SearchIcon />
                )
              }
              onSearch={setSearch}
              labelInValue
              optionLabelProp="label"
              optionFilterProp={optionFilterProp}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {search && !showAll && totalChoices > choicesSkip && (
                    <ShowAll style={{ textAlign: 'center' }}>
                      <Button
                        className="show-all"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={switchShowAll}
                        type="link"
                      >
                        Show all results ({totalChoices})
                      </Button>
                    </ShowAll>
                  )}
                </>
              )}
              mode={mode}
            >
              {allChoices.map((choice) => (
                <Option
                  className="search-results-choice"
                  style={{ paddingRight: '12px' }}
                  label={renderLabel ? renderLabel(choice) : choice.label}
                  key={choice.key}
                  title={choice.label as string}
                >
                  {renderOption ? (
                    renderOption(choice)
                  ) : (
                    <ResultsRow>
                      <Col className="image-col" span={2}>
                        <span>
                          {choice.isInValue && <Checkbox checked />}
                          {!choice.isInValue && <Checkbox />}
                        </span>
                      </Col>
                      <Col
                        style={{ paddingLeft: '10px' }}
                        className="option-label"
                        span={16}
                      >
                        <StyledHighlight
                          text={(choice.label || choice.key) as string}
                          highlight={search}
                        />
                      </Col>
                    </ResultsRow>
                  )}
                </Option>
              ))}
            </StyledSelect>
          </span>
        </Form.Item>
      </>
    );
  }
);

export default SearchResults;

export const routes = {
  brandedContainers: () => `/containers`,
  artistForm: () => 'create-artist',
  artists: () => '/artists',
  events: () => '/events',
  setArtistData: (id: string) => `/artists/set-artist-data/${id}`,
  setEventData: (id: string, content = 'description') =>
    `/events/set-event-data/${id}/${content}`,
  deleteArtist: (id: string) => `/artists/delete-artist/${id}`,
  deleteBrandedContainer: (id: string, version: number) =>
    `/branded-container/delete-branded-container/${id}/${version}`,
  setBrandedContainerData: (id: string, content = 'description') =>
    `/branded-container/set-container-data/${id}/${content}`,
  brandedContainersForArtist: (artistID: string) =>
    `/artist/${artistID}/containers`,
  brandedContainerForArtist: (
    artistID: string,
    containerID: string,
    content = 'description'
  ) => `/artist/${artistID}/containers/${containerID}/${content}`,
  login: () => '/login',
  postLogin: () => '/post-login',
  selectPromitionItems: (context: 'branded-containers' | 'events') =>
    `/${context}/select-promotion-items`,
  bcEventsDetailView: (
    bcID: string,
    eventID: string,
    content = 'description'
  ) =>
    `/branded-container/set-container-data/${bcID}/bc-events/${eventID}/${content}`,
  bcRelatedBcsDetailView: (
    bcID: string,
    relatedBcID: string,
    content = 'description'
  ) =>
    `/branded-container/set-container-data/${bcID}/branded-containers/${relatedBcID}/${content}`,
  eventRelatedBcsDetailView: (
    eventId: string,
    relatedBcID: string,
    content = 'description'
  ) =>
    `/events/set-event-data/${eventId}/branded-containers/${relatedBcID}/${content}`,
};

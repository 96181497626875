import React, { forwardRef, useCallback, useState } from 'react';
import { LabeledValue } from 'antd/lib/select';
import useTopicSuggestions from './use-topic-suggestions';
import SearchResults, {
  SearchResultChoice,
  SearchResultsProps,
} from '../../common/search-results/search-results.component';
import { Except } from 'type-fest';
import { SearchOutlined } from '@ant-design/icons';
import type { RefSelectProps } from 'antd/lib/select';
import { tryParseJSON } from 'yggdrasil-shared/utils/common';
import { TopicFilter } from './types';
import TopicItem from './topic-item.component';
import { AppContext, AppStoreApi } from '../../../context/app.context';

export type TopicDropdownProps = Except<
  SearchResultsProps,
  'value' | 'choices' | 'onChange' | 'renderOption' | 'onSearch' | 'loading'
> & {
  label?: string;
  name: string;
  value?: TopicFilter[];
  onChange?: (value: TopicFilter[]) => any;
};

const TopicDropdown = forwardRef<RefSelectProps, TopicDropdownProps>(
  ({ value = [], onChange, ...props }, ref) => {
    const {
      state: { activeBrandTab },
    } = React.useContext(AppContext) as AppStoreApi;

    const [search, setSearch] = useState<string | null>('');
    const { loading, choices } = useTopicSuggestions({
      requiredSearchLength: 2,
      value,
      search,
      brand: activeBrandTab,
    });

    const handleChange = useCallback(
      (value: SearchResultChoice[]) => {
        const mappedValue = value.map((val) => ({
          ...val,
          key: tryParseJSON(JSON.parse(val.key), val.key),
        })) as TopicFilter[];

        if (onChange) {
          onChange(mappedValue);
        }
      },
      [onChange]
    );

    const isInValue = useCallback(
      (choice: TopicFilter) => {
        const key =
          typeof choice.key === 'object'
            ? JSON.stringify(choice.key)
            : choice.key;

        return Boolean(
          value.find((item) => {
            const stringKey =
              typeof item.key === 'object'
                ? JSON.stringify(item.key)
                : item.key;

            return stringKey === key;
          })
        );
      },
      [value]
    );

    return (
      <SearchResults
        value={value as unknown as LabeledValue[]}
        onChange={handleChange as (value: LabeledValue[]) => any}
        ref={ref}
        loading={loading}
        choices={choices}
        onSearch={setSearch}
        optionFilterProp="title"
        requiredSearchLength={2}
        maxTagCount={2}
        maxTagWidth="210px"
        renderLabel={(choice: TopicFilter) => (
          <TopicItem value={choice} search={''} />
        )}
        renderOption={(choice: TopicFilter) => {
          const isValue = isInValue(choice);

          return (
            <TopicItem
              value={choice}
              search={search ?? ''}
              showCheckbox={isValue}
              checked={isValue}
              icon={!isValue ? <SearchOutlined /> : undefined}
            />
          );
        }}
        {...props}
      />
    );
  }
);

export default TopicDropdown;

import React, { useCallback, useMemo } from 'react';
import {
  BrandSwitcherContainer,
  DefaultPaddingContainer,
  FormContainer,
  StyledForm,
} from './update-data-form.styles';
import { EditDataBottomBar } from '../edit-data-bottom-bar/edit-bottom-bar.component';
import { Alert, Button, Input } from 'antd';
import { useUpdateForm } from './use-update-form';
import { BrandedContainerState } from '../../../resolver.types';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router';
import { DescriptionStep } from '../form-steps/description-step.component';
import { PressTextStep } from '../form-steps/press-text-step.component';
import { InformationStep } from '../form-steps/information-step.component';
import { BrandSwitcher } from '../brand-switcher/brand-switcher.component';
import { AppContext, AppStoreApi } from '../../../context/app.context';
import { BrandedContainerData } from '../drawer-content/drawer-content.component';
import { validationRules } from './validation-rules';
import { CommentsStep } from '../form-steps/comments-step.component';
import { LastModifiedStep } from '../form-steps/last-modified-step.component';
import { MediaStep } from '../form-steps/media-step/media-step.component';
import { BrandedContainersStep } from '../form-steps/branded-containers-step/branded-containers-step.component';
import { DisplaySettingsStep } from '../form-steps/display-settings-step.component';
import { EventsStep } from '../form-steps/events-step/events-step.component';
import { OutdatedDescription } from '../outdated-description/outdated-description.component';
import { routes } from '../../../route-urls';
import { EventFiltersStateProvider } from '../../../context/event-filters.context';
import { getInitialEventFilters } from '../../../utils/event-filters/get-initial-event-filters';
import VersionMismatch from '../version-mismatch/version-mismatch.component';
import browserHistory from '../../../utils/history';
import { modifyDirty } from './modify-dirty';
import { useFieldsLockers } from './use-fields-lockers';
import { MetaStep } from '../form-steps/meta-step.component';
import { TranslationsStep } from '../form-steps/translations-step.component';
import { BrandName } from 'yggdrasil-shared/domain/dictionary';
import { useTranslations } from './use-translations';
import { Warning } from '../../common/warning/warning.component';

export type UpdateDataFormContainerProps = {
  data: BrandedContainerData;
  onChangeBrand: (brand: string) => void;
  onDirtyChange?: (isDirty: boolean) => void;
  isFormDisabled?: boolean;
  isNestedDrawer?: boolean;
};

const deletableStates = [
  BrandedContainerState.Draft,
  BrandedContainerState.Archived,
];

const FORM_DATA_PATHS = [
  'description',
  'media',
  'press-text',
  'meta',
  'information',
  'display-settings',
  'translations',
];

// eslint-disable-next-line complexity
export const UpdateDataFormContainer = ({
  data,
  onChangeBrand,
  onDirtyChange,
  isFormDisabled = false,
  isNestedDrawer = false,
}: UpdateDataFormContainerProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    state: { selectedBrand, activeBrandTab },
  } = React.useContext(AppContext) as AppStoreApi;

  const initialValues = {
    ...data,
    regionRadius: data.region?.properties.radius ?? 30,
  };

  const { lockers, setLockers, resetLockersToDefault } = useFieldsLockers({
    initialValues,
  });

  const {
    shouldWarningDisplay,
    translationIndex,
    translationLocale,
    onTranslationChange,
    recalculateMissingTranslations,
    compareAndResetTranslations,
  } = useTranslations({
    data: initialValues,
    selectedBrand: selectedBrand?.name,
    activeBrand: activeBrandTab,
  });

  const {
    onChange,
    onSubmit,
    values,
    setValues,
    isDirty,
    isLoading,
    discardChanges,
    errors,
    versionMismatchError,
    mismatchErrorVisible,
    toggleMismatchErrorVisible,
    removeMismatchError,
    setVersionMismatchError,
    toggleDirty,
    clearImageFormValue,
    addImageFormValue,
  } = useUpdateForm({
    afterSubmit: (data) => {
      if (data) {
        recalculateMissingTranslations(data, activeBrandTab);
      }
    },
    validationRules,
    initialValues,
    onDirtyChange,
    lockers,
    resetLockersToDefault,
    compareAndResetTranslations,
  });

  const [selectedBrandDirtyCache, setSelectedBrandDirtyCache] =
    React.useState(null);

  const shouldDisplayBottomBar = React.useMemo(
    () => FORM_DATA_PATHS.includes(location.pathname.split('/').pop() ?? ''),
    [location.pathname]
  );

  const shouldRenderOutdatedDescription = React.useMemo(
    () => data.dataLastUpdated.description && data.lastUpdatedAuthor,
    [data.dataLastUpdated.description, data.lastUpdatedAuthor]
  );

  const onDelete = useCallback(() => {
    navigate(routes.deleteBrandedContainer(data.id, data.version));
  }, [navigate, data.id, data.version]);

  React.useEffect(() => {
    const handleUpdateOnDirty = () => {
      if (selectedBrand?.name !== data.brand && selectedBrandDirtyCache) {
        return { ...data };
      }

      if (selectedBrand?.name !== data.brand) {
        setSelectedBrandDirtyCache(values);
        return { ...data };
      }

      if (selectedBrandDirtyCache) {
        const modifiedValues = modifyDirty(selectedBrandDirtyCache, data);
        setSelectedBrandDirtyCache(null);
        return { ...modifiedValues };
      }

      const modifiedValues = modifyDirty(values, data);
      return { ...modifiedValues };
    };

    setValues(isDirty ? handleUpdateOnDirty() : { ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setValues]);

  React.useEffect(() => {
    const unlisten = browserHistory.listen(({ location }) => {
      if ((location.state as { reselectBrand: boolean })?.reselectBrand) {
        onChangeBrand(selectedBrand!.name);
      }
    });

    return () => {
      unlisten();
    };
  }, [location.pathname, onChangeBrand, selectedBrand]);

  const canDeleteBc = useMemo(
    () =>
      deletableStates.includes(data.state) &&
      selectedBrand?.name === data.brand,
    [data, selectedBrand]
  );

  const brandSwitcherComponent = useMemo(
    () => (
      <BrandSwitcher
        brands={data.brands}
        selectedBrandValue={data.brand}
        onChange={onChangeBrand}
        isNestedDrawer={isNestedDrawer}
      />
    ),
    [data.brands, data.brand, onChangeBrand, isNestedDrawer]
  );

  const warningComponent = useMemo(() => {
    return (
      <Warning
        message="Translations are missing or need to be updated. Complete the translations or the German field will be used as fallback."
        hidden={!shouldWarningDisplay}
      />
    );
  }, [shouldWarningDisplay]);

  return (
    <StyledForm
      onSubmit={(e) => e.preventDefault()}
      className="compatible-row"
      style={{
        height: isNestedDrawer ? `calc(100% - 64px)` : '100%',
      }}
    >
      {versionMismatchError && (
        <VersionMismatch
          visible={mismatchErrorVisible}
          onCancel={toggleMismatchErrorVisible}
          error={versionMismatchError}
          onDataFetched={removeMismatchError}
          toggleDirty={toggleDirty}
        />
      )}
      <FormContainer
        style={{
          height:
            shouldDisplayBottomBar && !isNestedDrawer
              ? `calc(100% - 64px)`
              : '100%',
        }}
      >
        <Input type="hidden" value={values.id} name="id" />
        <Input type="hidden" value={values.version} name="version" />
        {versionMismatchError && !mismatchErrorVisible && (
          <BrandSwitcherContainer>
            <Alert
              message={
                <span>
                  <span>There is pending version mismatch error. </span>
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={toggleMismatchErrorVisible}
                  >
                    View.
                  </Button>
                </span>
              }
              type="warning"
            />
          </BrandSwitcherContainer>
        )}
        <Routes>
          <Route
            path={`description`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                {shouldRenderOutdatedDescription && (
                  <OutdatedDescription
                    date={data.dataLastUpdated.description!}
                    author={data.lastUpdatedAuthor!}
                  />
                )}
                <DescriptionStep
                  values={values}
                  onChange={onChange}
                  isFormDisabled={isFormDisabled}
                  errors={errors}
                  lockers={lockers}
                  setLockers={setLockers}
                  warningComponent={warningComponent}
                />
              </DefaultPaddingContainer>
            }
          />
          <Route
            path={`press-text`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                <PressTextStep
                  values={values}
                  onChange={onChange}
                  isFormDisabled={isFormDisabled}
                  warningComponent={warningComponent}
                />
              </DefaultPaddingContainer>
            }
          />
          <Route
            path={`meta`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                <MetaStep
                  values={values}
                  onChange={onChange}
                  isFormDisabled={isFormDisabled}
                />
              </DefaultPaddingContainer>
            }
          />
          <Route
            path={`translations`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                <TranslationsStep
                  values={values}
                  onChange={onChange}
                  isFormDisabled={
                    isFormDisabled ||
                    activeBrandTab !== BrandName.DEUTSCHER_FUSSBALL_BUND
                  }
                  translationIndex={translationIndex}
                  translationLocale={translationLocale}
                  onTranslationChange={onTranslationChange}
                  warningComponent={warningComponent}
                />
              </DefaultPaddingContainer>
            }
          />
          <Route
            path={`information`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                <InformationStep
                  topicArtists={data.topic?.data.artists ?? []}
                  topicVenues={data.topic?.data.venue ?? []}
                  topicPlaces={data.topic?.data.place ?? []}
                  topicGenres={data.topic?.data.genres ?? []}
                  values={values}
                  onChange={onChange}
                  isFormDisabled={isFormDisabled}
                  regionFeatures={data.region?.features}
                  suggestedGenres={data.suggestedGenres as string[] | undefined}
                />
              </DefaultPaddingContainer>
            }
          />
          <Route
            path={`display-settings`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                <DisplaySettingsStep
                  onChange={onChange}
                  values={values}
                  isFormDisabled={isFormDisabled}
                  lockers={lockers}
                  setLockers={setLockers}
                />
              </DefaultPaddingContainer>
            }
          />
          <Route
            path={`comments`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                <CommentsStep
                  setVersionMismatchError={setVersionMismatchError}
                  brandedContainerBrand={data.brand}
                  comments={data.comments ?? []}
                  version={values.version}
                  isNestedDrawer={isNestedDrawer}
                />
              </DefaultPaddingContainer>
            }
          />

          <Route
            path={`last-modified`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                <LastModifiedStep id={data.id} />
              </DefaultPaddingContainer>
            }
          />

          <Route
            path={`media`}
            element={
              <DefaultPaddingContainer>
                {brandSwitcherComponent}
                <MediaStep
                  setVersionMismatch={setVersionMismatchError}
                  version={data.version}
                  images={data.images ?? []}
                  cropBoundaries={data.cropBoundaries ?? []}
                  disabled={isFormDisabled}
                  onChange={onChange}
                  imagesFormValues={[...values.images]}
                  clearImageFormValue={clearImageFormValue}
                  addImageFormValue={addImageFormValue}
                />
              </DefaultPaddingContainer>
            }
          />

          <Route
            path={`branded-containers/*`}
            element={
              <>
                <BrandSwitcherContainer>
                  {brandSwitcherComponent}
                </BrandSwitcherContainer>
                <BrandedContainersStep
                  setVersionMismatch={setVersionMismatchError}
                  relatedContainerIDs={data.relatedContainerIDs ?? []}
                  brandedContainerId={data.id}
                  brand={data.brand}
                  version={values.version}
                  disabled={isFormDisabled}
                />
              </>
            }
          />

          <Route
            path={`bc-events/*`}
            element={
              <>
                <BrandSwitcherContainer>
                  {brandSwitcherComponent}
                </BrandSwitcherContainer>
                <EventFiltersStateProvider
                  initialFilters={
                    data.suggestedEventFilters
                      ? getInitialEventFilters(
                          JSON.parse(data.suggestedEventFilters)
                        )
                      : {}
                  }
                >
                  <EventsStep
                    setVersionMismatch={setVersionMismatchError}
                    eventIDs={data.eventIDs ?? []}
                    suggestedIDs={data.suggestedEventIDs ?? []}
                    suggestedEventsTotal={
                      data.suggestedEventsTotal ??
                      data.suggestedEventIDs?.length ??
                      0
                    }
                    declinedIDs={data.declinedEventIDs ?? []}
                    brandedContainerId={data.id}
                    version={values.version}
                    disabled={isFormDisabled}
                  />
                </EventFiltersStateProvider>
              </>
            }
          />
          <Route path={`*`} element={<Navigate replace to="description" />} />
        </Routes>
      </FormContainer>
      {shouldDisplayBottomBar && !isNestedDrawer && (
        <EditDataBottomBar
          onCancel={discardChanges}
          onRemove={onDelete}
          onSubmit={onSubmit}
          submitDisabled={isFormDisabled || !isDirty || isLoading}
          cancelDisabled={isFormDisabled ? true : !isDirty}
          deleteDisabled={!canDeleteBc}
        />
      )}
    </StyledForm>
  );
};

import React from 'react';
import { TopicType } from './topic-type.component';
import TopicTypeMethodSelect from './custom-select';

interface TopicTypeWithMethodProps {
  label: string;
  name: string;
  placeholder: string;
}

const TopicTypeWithMethod: React.FC<TopicTypeWithMethodProps> = ({
  label,
  name,
  placeholder,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <TopicTypeMethodSelect label={label} selectFieldName="topicTypeMethod" />
      <div style={{ flex: 1 }}>
        <TopicType label={'      '} name={name} placeholder={placeholder} />
      </div>
    </div>
  );
};

export default TopicTypeWithMethod;

import React, { ReactNode } from 'react';
import { message } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import { useMutation } from '@apollo/client';
import { validate } from 'yggdrasil-shared/domain/image';
import {
  UploadFileDocument,
  File,
  BrandedContainerImageAspectRatio,
  BrandedContainerImageType,
} from '../../../../resolver.types';
import { v4 } from 'uuid';
import { AppContext, AppStoreApi } from '../../../../context/app.context';

type UploadComponentParams = {
  customRequest: UploadProps<any>['customRequest'];
  onChange: () => void;
  isImageUploading: boolean;
};

type UploadImageProps = {
  onUpload: (file: File) => Promise<string | void>;
  confirmThumbnailGeneration?: (url: string) => Promise<void>;
  aspectRatio: BrandedContainerImageAspectRatio;
  disabled?: boolean;
  uploadComponent: (params: UploadComponentParams) => ReactNode;
  handleImageLoading?: (status: boolean) => void;
};

const MAX_FILE_SIZE_MB = 10;

export const UploadImageWrapper = ({
  onUpload,
  aspectRatio,
  uploadComponent,
  handleImageLoading,
  confirmThumbnailGeneration,
}: UploadImageProps) => {
  const {
    state: { activeBrandTab },
  } = React.useContext(AppContext) as AppStoreApi;
  const [uploadFile] = useMutation(UploadFileDocument);
  const [uploadingImage, setUploadingImage] = React.useState<boolean>(false);

  const customRequest: UploadProps<any>['customRequest'] = async ({
    file,
    onSuccess,
  }) => {
    try {
      await validateFileSize(file);
      await getImageError(file as any);

      const res = await uploadFile({
        variables: {
          file: file || null,
          metadata: {
            ratio: aspectRatio,
            type: BrandedContainerImageType.Original,
            crop: v4(),
          },
          brand: activeBrandTab,
        },
      });

      onSuccess!(res, file as any);
      const newThumbnailUrl = await onUpload(res.data.uploadFile);

      setUploadingImage(false);
      if (handleImageLoading) handleImageLoading(false);

      if (newThumbnailUrl && confirmThumbnailGeneration) {
        await confirmThumbnailGeneration(newThumbnailUrl);
      }
    } catch (error: unknown) {
      message.error((error as Error).message);

      setUploadingImage(false);
      if (handleImageLoading) handleImageLoading(false);
    }
  };

  const validateFileSize = (
    file: File | string | Blob | RcFile
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (typeof file === 'string') {
        resolve();
      } else {
        const fileSizeInBytes = getFileSizeInBytes(file);

        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

        if (fileSizeInMB > MAX_FILE_SIZE_MB) {
          reject(
            new Error(`File size exceeds the limit of ${MAX_FILE_SIZE_MB} MB`)
          );

          return;
        }

        resolve();
      }
    });
  };

  const getFileSizeInBytes = (file: File | Blob | RcFile): number => {
    if ('size' in file) {
      return file.size;
    }

    throw new Error('Invalid file type');
  };

  const getImageError = (file: File): Promise<string | null> => {
    const img = new Image();
    const fileURL = (window.URL || window.webkitURL).createObjectURL(
      file as any
    );

    return new Promise((resolve, reject) => {
      img.onload = () => {
        const { result, defaultErrorMessage } = validate({
          width: img.width,
          height: img.height,
          aspectRatio,
        });

        if (!result) {
          reject(new Error(defaultErrorMessage));
        }

        resolve(null);
      };

      img.src = fileURL;
    });
  };

  const onChange = () => {
    setUploadingImage(true);
    if (handleImageLoading) handleImageLoading(true);
  };

  return (
    <React.Fragment>
      {uploadComponent({
        customRequest,
        onChange,
        isImageUploading: uploadingImage,
      })}
    </React.Fragment>
  );
};

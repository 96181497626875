import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect = styled(Select).attrs(
  ({ $inlineCreation }: { $inlineCreation?: boolean }) => ({
    $inlineCreation,
  })
)`
  ${(props) =>
    props.$inlineCreation &&
    `.ant-select-selection--multiple span.ant-select-arrow {
      top: 10px;
      right: 0;
      }
  
    .ant-select-selection__rendered {
      margin-right: 30px;
    }`}

  .ant-select-selection__choice__disabled {
    background: linear-gradient(270deg, #fe9350 0%, #ff645a 100%);
  }

  .ant-select-selector {
    height: 40px;
  }
`;

import React, { useCallback } from 'react';
import eventNavigationData from '../../../static/event-navigation-data';
import BaseDrawerNavigation, {
  DrawerNavigationItem,
} from '../../common/drawer-navigation/drawer-navigation.component';

export type DrawerNavigationProps = {
  brandedContainersCount?: number;
};

const DrawerNavigation = ({
  brandedContainersCount,
}: DrawerNavigationProps) => {
  const mapItem = useCallback(
    (item: DrawerNavigationItem) => {
      if (item.link === 'branded-containers') {
        item.total = brandedContainersCount;
      }

      return item;
    },
    [brandedContainersCount]
  );

  return (
    <BaseDrawerNavigation items={eventNavigationData} mapNavItem={mapItem} />
  );
};

export default DrawerNavigation;

import styled from 'styled-components';

/* eslint-disable-next-line */
const img = require('../../../../assets/images/logo.png');

export const Logo = styled.div`
  margin: auto;
  background-image: url(${img});
  background-size: cover;
  width: 90px;
  height: 40px;
`;

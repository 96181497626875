import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLimitedContainer = styled.div`
  padding: 10px 25px;
`;

export const ContainerTitle = styled.div`
  font-size: 11px;
  font-weight: 300;
`;

export const ItemsContainer = styled.div``;

export const LinkContainer = styled.div`
  text-align: center;
`;

export const ShowAllLink = styled(Link)`
  color: #4b4b4b;
  font-size: 11px;
`;

import styled from 'styled-components';

export const SetPromotionItemModalHeader = styled.div`
  text-align: center;
  position: relative;

  & > .anticon.anticon-arrow-left {
    position: absolute;
    left: 0;
    border: 1px solid #8d8d8d;
    border-radius: 50%;
    font-size: 11px;
    padding: 3px;
    color: #8d8d8d;
    cursor: pointer;
  }
`;

export const SetPromotionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img {
    width: 150px;
    height: 170px;
    margin: 20px 0;
  }

  & > input,
  & > .ant-select {
    margin-bottom: 40px;
  }
`;

import React, { useState, useEffect, useMemo } from 'react';
import { brandCustom } from 'yggdrasil-shared/domain/brand-custom';
import { BrandName } from 'yggdrasil-shared/domain/dictionary';
import { isKeyObject } from 'yggdrasil-shared/utils/common';
import usePrevious from '../../../hooks/use-previous';
import {
  BcTranslation,
  BcTranslationInputItem,
  BrandedContainer,
  BrandedContainerState,
  LangLocale,
  UpdateBrandedContainerInput,
} from '../../../resolver.types';
import { BrandedContainerData } from '../drawer-content/drawer-content.component';

type UseTranslationsProps = {
  data: BrandedContainerData;
  selectedBrand?: BrandName;
  activeBrand: BrandName;
};

export const editableFields: (keyof BcTranslationInputItem)[] = [
  'title',
  'subtitle',
  'description',
  'shortDescription',
  'pressText',
  'metaTitle',
  'metaDescription',
];

const getTranslationValue = (
  translations: BcTranslation[] | BcTranslationInputItem[] | null | undefined,
  field: keyof BcTranslationInputItem,
  translationIndex?: number
) => {
  if (translationIndex === undefined || translationIndex < 0) {
    return '';
  }

  if (!translations) {
    return '';
  }

  if (!translations[translationIndex]) {
    return '';
  }

  return translations[translationIndex][field];
};

export const isMissingTranslation =
  (
    values: BrandedContainer | UpdateBrandedContainerInput,
    activeBrand: BrandName,
    translationIndex?: number
  ) =>
  (field: keyof BcTranslationInputItem) => {
    const originalValue = isKeyObject<
      BrandedContainer | UpdateBrandedContainerInput
    >(field, values)
      ? values[field]
      : '';

    if (!originalValue) {
      return false;
    }

    const translationValue = getTranslationValue(
      values.translations,
      field,
      translationIndex
    );

    if (field === 'metaTitle' || field === 'metaDescription') {
      const defaultValues: Record<string, unknown> = {
        metaTitle: brandCustom.getFallbackMetaTitle(activeBrand),
        metaDescription: brandCustom.getFallbackMetaDescription(activeBrand),
      };

      return (
        Boolean(originalValue) &&
        Boolean(translationValue) &&
        originalValue !== defaultValues[field]
      );
    }

    return Boolean(originalValue) && !translationValue;
  };

const determineMissingTranslations = (
  values: BrandedContainer | UpdateBrandedContainerInput,
  activeBrand: BrandName,
  translationIndex?: number
) =>
  editableFields.filter(
    isMissingTranslation(values, activeBrand, translationIndex)
  );

const determineTranslationIndex = (
  translations: BcTranslation[] | BcTranslationInputItem[] | null | undefined,
  locale: LangLocale
) =>
  translations
    ? translations?.findIndex(
        (t: BcTranslationInputItem) => t.locale === locale
      )
    : -1;

const determineFuzzyWarningConditions = (
  missingList: (keyof BcTranslationInputItem)[]
) => {
  return missingList.length > 0;
};

export function useTranslations({
  data,
  selectedBrand,
  activeBrand,
}: UseTranslationsProps) {
  const [locale] = React.useState<LangLocale>(LangLocale.EN_GB);

  const [translationIndex, setTranslationIndex] = useState<number>(
    determineTranslationIndex(data.translations, locale)
  );

  const [missingList, setMissingList] = useState(
    determineMissingTranslations(data, activeBrand, translationIndex)
  );

  const [isWarningEnabled, setIsWarningEnabled] = useState(
    data.state === BrandedContainerState.Published &&
      determineFuzzyWarningConditions(missingList)
  );

  const brandWarningConditions = useMemo(
    () =>
      selectedBrand === BrandName.DEUTSCHER_FUSSBALL_BUND &&
      data.brand === activeBrand &&
      selectedBrand === activeBrand,
    [data, selectedBrand, activeBrand]
  );

  const prevData = usePrevious(data);
  useEffect(() => {
    const determineIfReallySwitchedToPublishExcludingBrowsingBcTabs = () => {
      if (
        prevData?.brand === data.brand &&
        data.state === BrandedContainerState.Published
      ) {
        setIsWarningEnabled(determineFuzzyWarningConditions(missingList));
      }
    };

    determineIfReallySwitchedToPublishExcludingBrowsingBcTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.state]);

  useEffect(() => {
    const transactionIndex = determineTranslationIndex(
      data.translations,
      locale
    );
    setTranslationIndex(transactionIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.brand]);

  const onTranslationChange = (translations: BcTranslationInputItem[]) => {
    const transactionIndex = determineTranslationIndex(translations, locale);
    setTranslationIndex(transactionIndex);
  };

  const recalculateMissingTranslations = (
    values: BrandedContainer | UpdateBrandedContainerInput,
    activeBrand: BrandName
  ) => {
    const transactionIndex = determineTranslationIndex(
      values.translations,
      locale
    );
    setTranslationIndex(transactionIndex);

    const missingList = determineMissingTranslations(
      values,
      activeBrand,
      translationIndex
    );
    setMissingList(missingList);
    setIsWarningEnabled(determineFuzzyWarningConditions(missingList));
  };

  const compareAndResetTranslations = (initialValues: any, values: any) => {
    const newTranslations = values.translations.map((newTranslation: any) => {
      const oldTranslation = initialValues.translations.find(
        (el: BcTranslation) => el.locale === newTranslation.locale
      );

      if (!oldTranslation) {
        return newTranslation;
      }

      const changes = editableFields.reduce<Partial<BcTranslationInputItem>>(
        (acc, key) => {
          if (
            oldTranslation &&
            newTranslation &&
            initialValues[key]?.trim() !== values[key]?.trim() &&
            oldTranslation[key] === newTranslation[key]
          ) {
            acc = { ...acc, [key]: '' };
          }

          return acc;
        },
        {}
      );

      return { ...newTranslation, ...changes };
    });

    return newTranslations;
  };

  return {
    shouldWarningDisplay: isWarningEnabled && brandWarningConditions,
    translationIndex,
    translationLocale: locale,
    missingList,
    recalculateMissingTranslations,
    onTranslationChange,
    compareAndResetTranslations,
  };
}

import styled from 'styled-components';

export const StyledSpan = styled.span`
  font-size: small;
  font-family: Font Awesome 5 Pro;
  margin-bottom: 10px;
  text-align: center;
  display: block;
  strong {
    font-weight: bold;
  }
`;

export const StyledComponent = styled.div`
  & .ant-empty {
    margin-top: 30px;
  }
`;

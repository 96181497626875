import { LabeledValue } from 'antd/lib/select';
import { isSet } from 'yggdrasil-shared/utils/common';

export const isLabeledValue = (value: any): value is LabeledValue => {
  return (
    value &&
    typeof value === 'object' &&
    isSet(value, 'key') &&
    isSet(value, 'label')
  );
};

import React, { PropsWithChildren, Reducer, useReducer } from 'react';
import { Brand } from 'yggdrasil-shared/domain/dictionary';
import { getUserData, UserData } from '../utils/token';
import { AppAction } from './shared';

export type AppState = {
  brands: Brand[];
  selectedBrand: Brand | null;
  issuer: string;
  user: UserData;
  activeBrandTab: Brand['name'];
};

type AppActionTypes =
  | AppAction<'SELECT_BRAND', { brand: Brand }>
  | AppAction<'SET_BRANDS', { brands: Brand[] }>
  | AppAction<'SET_USER', { user: UserData }>
  | AppAction<'SET_ACTIVE_BRAND_TAB', { activeBrandTab: Brand['name'] }>;

export type AppStoreApi = {
  state: AppState;
  dispatch: React.Dispatch<AppActionTypes>;
};

export const initialState: AppState = {
  brands: [],
  selectedBrand: localStorage.getItem('selectedBrand')
    ? JSON.parse(localStorage.getItem('selectedBrand')!)
    : null,
  issuer: '',
  user: getUserData(),
  activeBrandTab: localStorage.getItem('selectedBrand')
    ? JSON.parse(localStorage.getItem('selectedBrand')!).name
    : null,
};

const reducer = (state: AppState, action: AppActionTypes) => {
  switch (action.type) {
    case 'SELECT_BRAND':
      localStorage.setItem('selectedBrand', JSON.stringify(action.brand));

      return {
        ...state,
        selectedBrand: action.brand,
        activeBrandTab: action.brand.name,
      };

    case 'SET_BRANDS':
      return {
        ...state,
        brands: action.brands,
      };

    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };

    case 'SET_ACTIVE_BRAND_TAB':
      return {
        ...state,
        activeBrandTab: action.activeBrandTab,
      };

    default:
      return state;
  }
};

export const AppContext = React.createContext<AppStoreApi | AppState>(
  initialState
);

export type AppStateProviderProps = {
  defaultState?: AppState;
};

export const AppStateProvider = ({
  children,
  defaultState = initialState,
}: PropsWithChildren<AppStateProviderProps>) => {
  const [state, dispatch] = useReducer<Reducer<AppState, AppActionTypes>>(
    reducer,
    defaultState
  );
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

import {
  BrandedContainer,
  BrandedContainerThumbnailType,
} from '../../../resolver.types';
import { Col, Row } from 'antd';
import {
  Thumbnail,
  ThumbnailProps,
} from '../../common/thumbnail/thumbnail.component';
import { getTitle } from '../../../utils/branded-container';
import { Tags } from '../../topic/tags/tags';
import React, { CSSProperties, useMemo } from 'react';
import styled from 'styled-components';
import { Except } from 'type-fest';

export type TitleProps = {
  brandedContainer: BrandedContainer;
  thumbnailProps?: Except<ThumbnailProps, 'brandedContainerBrand' | 'imageUrl'>;
  showRelevance?: boolean;
  showThumbnail?: boolean;
  noPadding?: boolean;
  isRegularSize?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
};

const TitleContainer = styled(Row).attrs({
  gutter: 8,
  type: 'flex',
})`
  &.branded-container-title {
    display: flex !important;
    color: #000000;

    &:not(.no-padding) {
      padding: 10px !important;
    }
  }

  .relevance-text {
    font-size: 11px;
  }

  .thumbnail-container {
    max-width: 50px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleColumn = styled(Col)<{ regularSize: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${(props) => (props.regularSize ? '600px' : '300px')};

  @media only screen and (max-width: 1300px) {
    max-width: ${(props) => (props.regularSize ? '500px' : '300px')};
  }

  @media only screen and (max-width: 1100px) {
    max-width: 275px;
  }
`;

const Title = ({
  brandedContainer,
  thumbnailProps,
  showRelevance = false,
  showThumbnail = true,
  noPadding = false,
  isRegularSize = true,
  style,
  onClick = () => {},
}: TitleProps) => {
  const thumbnailSrc = useMemo(
    () =>
      brandedContainer.thumbnails?.find(
        (thumbnail) => thumbnail.type === BrandedContainerThumbnailType.A50x50
      )?.url,
    [brandedContainer]
  );

  return (
    <TitleContainer
      onClick={onClick}
      style={style}
      align={showRelevance ? 'top' : 'middle'}
      className={`compatible-col branded-container-title ${
        noPadding ? 'no-padding' : ''
      }`}
    >
      {showThumbnail && (
        <Col className="thumbnail-container" span={4}>
          <Thumbnail
            imageUrl={thumbnailSrc}
            brandedContainerBrand={brandedContainer.brand}
            {...thumbnailProps}
          />
        </Col>
      )}
      <Col span={showThumbnail ? 20 : 24}>
        <Row gutter={4}>
          <ColumnWrapper>
            <TitleColumn regularSize={isRegularSize}>
              {getTitle(brandedContainer.topic.data)}
            </TitleColumn>
            <Col>
              <Tags topic={brandedContainer.topic.data} limit={7} />
            </Col>
          </ColumnWrapper>
          {showRelevance && (
            <Col span={24}>
              <span className="relevance-text">
                Importance: {brandedContainer.relevance ?? 0}/10
              </span>
            </Col>
          )}
        </Row>
      </Col>
    </TitleContainer>
  );
};

export default Title;

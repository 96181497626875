import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import {
  UPDATE_ARTIST,
  UpdateArtistInput,
  UpdateArtistResult,
  UpdateArtistVariables,
} from '../../../graphql/mutations/update-artist';
import React, { createContext, PropsWithChildren, useEffect } from 'react';
import useForm, { Form as FormHook } from '../../../hooks/use-form';
import { useMutation } from '@apollo/react-hooks';
import { Artist, GetArtistDocument } from '../../../resolver.types';
import DistinguishError from '../distinguish-error/distinguish-error.component';
import usePrevious from '../../../hooks/use-previous';
import { DistinguishError as Error } from 'yggdrasil-shared/domain/error';
import { Artist as ArtistType } from '../../../resolver.types';

export interface UpdateFormManagerProps
  extends FormComponentProps<UpdateArtistInput> {
  afterSubmit?: (artist: Artist) => void | Promise<void>;
  artist: Artist;
}

export const UpdateFormContext =
  createContext<FormHook<UpdateArtistInput> | null>(null);

const UpdateFormManager = ({
  form,
  children,
  afterSubmit,
  artist,
}: PropsWithChildren<UpdateFormManagerProps>) => {
  const [mutate] = useMutation<UpdateArtistResult, UpdateArtistVariables>(
    UPDATE_ARTIST,
    {
      refetchQueries: [
        {
          query: GetArtistDocument,
          variables: { id: artist.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const previousArtistID = usePrevious(artist.id);

  const context = useForm({
    form,
    submitHandler: async (input, form) => {
      try {
        const { data } = await mutate({
          variables: {
            input: {
              ...input,
              id: artist.id,
            },
          },
        });

        form.setStatus({
          state: 'success',
          message: 'Artist updated',
        });

        if (data) {
          const { countryOfOrigin, dateOfBirth, genres, name, source } =
            data.updateArtist;

          form.setFieldsValue({
            countryOfOrigin,
            dateOfBirth,
            genres,
            name,
            source,
          });
        }

        if (afterSubmit) {
          await afterSubmit(data!.updateArtist);
        }
      } catch (e) {
        const { graphQLErrors } = e as {
          graphQLErrors: Error<ArtistType>[];
        };

        const distinguishError = graphQLErrors.find(
          (error: Error<ArtistType>) => error.name === 'DistinguishError'
        );

        if (distinguishError) {
          form.setStatus({
            state: 'error',
            message: <DistinguishError error={distinguishError} />,
          });
        } else {
          throw e;
        }
      }
    },
    errorHandler: (error, form) => {
      form.setStatus({
        state: 'error',
        message: error.message,
      });
    },
  });

  useEffect(() => {
    if (artist.id !== previousArtistID) {
      context.setStatus({
        message: '',
      });
    }
  }, [artist.id, context, previousArtistID]);

  return (
    <UpdateFormContext.Provider value={context}>
      {children}
    </UpdateFormContext.Provider>
  );
};

export default Form.create<UpdateFormManagerProps>({ name: 'update-artist' })(
  UpdateFormManager
);

import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { Input } from 'antd';
import useOutsideClick from '../../../hooks/use-outside-click';
import { SearchIcon } from '../search-results/search-results.styles';
import styled from 'styled-components';

export enum SearchOption {
  Exact = 'exact',
  Fuzzy = 'fuzzy',
  Negative = 'negative',
}

type SearchWithOptionProps = {
  value?: string;
  onChange?: (args: { option: SearchOption; value: string }) => void;
  name?: string;
  placeholder?: string;
  selectedOption?: SearchOption;
  width?: string;
  optionWidth?: string;
  toggleLock: (lock: boolean) => void;
};

export const SearchWithOption = ({
  value: initialValue = '',
  onChange: cb,
  name,
  placeholder,
  selectedOption: initialOption = SearchOption.Exact,
  width = '100%',
  toggleLock,
}: SearchWithOptionProps) => {
  const containerRef = useRef<HTMLSpanElement>();

  const dropdownClassName = useMemo(() => `${name}-search-dropdown`, [name]);

  const [selectedOption] = React.useState<SearchOption>(initialOption);
  const [value, setValue] = React.useState(initialValue);

  const onChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setValue(value);
      toggleLock(Boolean(value));
    },
    [toggleLock]
  );

  const handleChange = useCallback(() => {
    if (value && cb) {
      cb({
        value,
        option: selectedOption,
      });
      setValue('');
    }
  }, [value, cb, selectedOption]);

  const handleOutsideClick = useCallback(
    (event: Event) => {
      const dropdown = document.querySelector(`.${dropdownClassName}`);

      if (
        (!dropdown || !dropdown.contains(event.target as HTMLElement)) &&
        value
      ) {
        handleChange();
      }
    },
    [handleChange, value, dropdownClassName]
  );
  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <span ref={containerRef as MutableRefObject<HTMLSpanElement>}>
      <StyledInput
        value={value}
        name={name}
        onChange={onChange}
        style={{ width }}
        placeholder={placeholder}
        onPressEnter={handleChange}
        suffix={<SearchIcon className="input-icon" />}
      />
    </span>
  );
};

const StyledInput = styled(Input)`
  & .ant-input {
    height: 30px;
  }

  & .ant-input-affix-wrapper {
    padding: 0 11px;
  }
`;

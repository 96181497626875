import { Badge } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { EventSalesStatus } from '../../../resolver.types';
import { snakeCaseToSentence } from '../../../utils/text';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DateFormats } from '../../../utils/date-formats';

export type SaleStateProps = {
  saleStatus?: EventSalesStatus | null;
  className?: string;
  saleDate?: string | null;
  smallMode?: boolean;
};

const StyledBadge = styled(Badge)`
  &.ant-badge {
    margin-right: 10px;

    &.on-sale {
      sup {
        background-color: #49a506;
        vertical-align: unset;
        margin-top: 3px;
      }
    }

    &.not-on-sale {
      sup {
        background-color: #f31313;
        vertical-align: unset;
        margin-top: 3px;
      }
    }
  }
`;

const Container = styled.div.attrs(({ smallMode }: { smallMode: boolean }) => ({
  smallMode,
}))`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  min-width: ${(props) => (props.smallMode ? '120px' : '150px')};
  font-size: ${(props) => (props.smallMode ? '12px' : 'inherit')};

  .event-sale-state > div {
    display: flex;
    align-items: baseline;
  }
`;

const SaleState = ({
  saleStatus,
  className = '',
  saleDate,
  smallMode,
}: SaleStateProps) => {
  const isOnSale = useMemo(
    () => saleStatus === EventSalesStatus.OnSale,
    [saleStatus]
  );

  const saleDateDisplay = useMemo(
    () => (saleDate ? format(parseISO(saleDate), DateFormats.dateTime) : ''),
    [saleDate]
  );

  const saleStatusDisplay = useMemo(
    () => (saleStatus ? snakeCaseToSentence(saleStatus) : 'Not on sale'),
    [saleStatus]
  );

  return (
    <Container
      smallMode={smallMode}
      className={`event-sale-state ${className}`}
    >
      <div className="sale-state-container">
        <StyledBadge className={isOnSale ? 'on-sale' : 'not-on-sale'} dot />
        <span>{saleStatusDisplay}</span>
      </div>
      {saleDate && (
        <div className="event-sale-date">
          <small>Presale: {saleDateDisplay}</small>
        </div>
      )}
    </Container>
  );
};

export default SaleState;

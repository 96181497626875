import React, {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Dropdown, Input, Menu, Row, Tooltip } from 'antd';
import { ResetButton } from '../../filters/common/reset-button/reset-button.component';
import { DropdownArrow } from '../icons/icons';

export type RangeValue = [number | undefined, number | undefined];
export type RangeProps = {
  suffix?: string;
  defaultOpen?: boolean;
  onChange?: (value: RangeValue) => any;
  value?: RangeValue;
  initialValue?: RangeValue;
  max?: number;
  separator?: string;
  onlyIntegers?: boolean;
  onlyAbsolute?: boolean;
  label?: string;
  placeholder?: string;
  width?: string;
  showReset?: boolean;
  disabled?: boolean;
  tooltipText?: string;
};

/**
 * @note Ref type has been changed below due to antd upgarde.
 * No influence for the logic behaviour observed
 */
const RangeInput = forwardRef<typeof Dropdown, RangeProps>(
  (
    {
      defaultOpen = false,
      placeholder,
      width,
      suffix = '',
      onChange,
      max,
      separator = '-',
      onlyIntegers,
      onlyAbsolute,
      value = [undefined, undefined],
      initialValue = [undefined, undefined],
      showReset,
      label,
      disabled,
      tooltipText,
    },
    _ref
  ) => {
    const hasValue = useMemo(
      () => value.filter((val) => val !== null && val !== undefined).length > 0,
      [value]
    );

    const resetValue = useCallback(() => {
      if (onChange) {
        onChange(initialValue);
      }
    }, [onChange, initialValue]);

    const [open, setOpen] = useState(defaultOpen);

    const handleChange =
      (index: number): ChangeEventHandler<HTMLInputElement> =>
      (event) => {
        const { target } = event;
        let inputValue: number | undefined = onlyIntegers
          ? parseInt(target.value, 10)
          : parseFloat(target.value) ?? undefined;

        if (isNaN(inputValue)) {
          inputValue = undefined;
        } else if (onlyAbsolute) {
          inputValue = Math.abs(inputValue);
        }

        if (inputValue && max && inputValue > max) {
          inputValue = max;
        }

        const newValue = [...value];
        newValue[index] = inputValue;

        if (index === 0 && inputValue === undefined) {
          newValue[1] = undefined;
        }

        if (onChange) {
          onChange(newValue as RangeValue);
        }
      };

    const inputValue = useMemo(() => {
      const [start, end] = value;

      if (start === undefined && end === undefined) {
        return undefined;
      }

      if (start !== undefined && end === undefined) {
        return `${start}${suffix}`;
      }

      return `${start}${suffix} ${separator} ${end}${suffix}`;
    }, [value, separator, suffix]);

    return (
      <>
        {/*
        // @ts-ignore */}
        <Form.Item
          colon={false}
          label={label}
          style={{ width, minWidth: '160px' }}
          className="compatible-row"
        >
          {hasValue && showReset && <ResetButton onClick={resetValue} />}
          <Dropdown
            disabled={disabled}
            overlayStyle={{ paddingTop: '10px' }}
            trigger={['click']}
            visible={open}
            onVisibleChange={setOpen}
            overlay={
              <Menu>
                <Menu.Item key="menu-item-0">
                  <Row style={{ width: '250px' }} gutter={7} align="middle">
                    <Col span={11}>
                      <Input
                        className="from-value"
                        value={value[0]}
                        onChange={handleChange(0)}
                        type="number"
                        suffix={suffix}
                      />
                    </Col>
                    <Col style={{ textAlign: 'center' }} span={2}>
                      <span>-</span>
                    </Col>
                    <Col span={11}>
                      <Input
                        disabled={value[0] === null || value[0] === undefined}
                        className="to-value"
                        value={value[1]}
                        onChange={handleChange(1)}
                        type="number"
                        suffix={suffix}
                      />
                    </Col>
                  </Row>
                </Menu.Item>
              </Menu>
            }
          >
            <Tooltip title={tooltipText}>
              <span>
                <Input
                  disabled={disabled}
                  className="value-preview"
                  suffix={<DropdownArrow $open={open} />}
                  placeholder={placeholder}
                  readOnly
                  value={inputValue}
                />
              </span>
            </Tooltip>
          </Dropdown>
        </Form.Item>
      </>
    );
  }
);

export default RangeInput;

import React from 'react';
import { AppAction } from './shared';
import { SearchOption } from '../components/common/search-with-option/search-with-option.component';
import { LabeledValue } from 'antd/lib/select';
import cloneDeep from 'lodash.clonedeep';

export type SearchValue = {
  option: SearchOption;
  value: string;
};

export type RangeValue = {
  min?: string | number;
  max?: string | number;
};

export type EventFilters = {
  sourceEventIds: string[];
  minPrice: number;
  maxPrice: number;
  startDateTime: RangeValue;
  saleStartDateTime: RangeValue;
  genres: string[];
  titleSearchValues: SearchValue[];
  organizerIDs: string[];
  organizerNames: string[];
  organizers: LabeledValue[];
  fullTextSearchValues: SearchValue[];
  zipCodes: string[];
  cities: string[];
  locationIDs: string[];
  locations: LabeledValue[];
  ticketSpecials: string[];
};

type SetFilter = {
  name: keyof EventFilters;
  value: number | string | string[] | SearchValue | undefined;
};

export type EventFiltersState = {
  selectedFilters: Partial<EventFilters>;
  filtersCount: number;
  isDirty: boolean;
  initialFilters: Partial<EventFilters>;
  loading: boolean;
  filtersAppliedCount: number;
  isLock: boolean;
};

export type SearchLabel = 'titleSearchValues' | 'fullTextSearchValues';

type EventFiltersActions =
  | AppAction<'SET_FILTERS', { filters: Partial<EventFilters> }>
  | AppAction<'SET_FILTER', { filter: SetFilter }>
  | AppAction<'UNDO_FILTERS'>
  | AppAction<'RESET_FILTERS'>
  | AppAction<
      'ADD_SEARCH_VALUE',
      {
        searchOption: SearchValue;
        name: SearchLabel;
        index?: number;
      }
    >
  | AppAction<
      'REMOVE_SEARCH_VALUE',
      { searchValue: SearchValue; name: SearchLabel }
    >
  | AppAction<'UPDATE_SEARCH_VALUE', { searchValue: SearchValue }>
  | AppAction<'TOGGLE_LOADING', { loading: boolean }>
  | AppAction<'UPDATE_INITIAL_FILTERS', { filters: Partial<EventFilters> }>
  | AppAction<'TOGGLE_LOCK', { lock: boolean }>;

export type EventFiltersStoreApi = {
  state: EventFiltersState;
  dispatch: React.Dispatch<EventFiltersActions>;
};

export const defaultState: EventFiltersState = {
  selectedFilters: {},
  filtersCount: 0,
  filtersAppliedCount: 0,
  isDirty: false,
  initialFilters: {},
  loading: false,
  isLock: false,
};

const getActiveFiltersCount = (filters: Partial<EventFilters>) => {
  const { zipCodes, locationIDs, cities, maxPrice, ...filtersToCount } =
    filters;

  return Object.values(filtersToCount).filter((value) =>
    Array.isArray(value) ? value.length : Boolean(value)
  ).length;
};

const reducer = (state: EventFiltersState, action: EventFiltersActions) => {
  switch (action.type) {
    case 'SET_FILTERS': {
      const filters = {
        ...state.selectedFilters,
        ...action.filters,
      };

      return {
        ...state,
        selectedFilters: filters,
        filtersCount: getActiveFiltersCount(filters),
        isDirty: true,
        isLock: false,
      };
    }

    case 'SET_FILTER': {
      const filters = {
        ...state.selectedFilters,
        [action.filter.name]: action.filter.value,
      };

      return {
        ...state,
        selectedFilters: filters,
        filtersCount: getActiveFiltersCount(filters),
        isDirty: true,
        isLock: false,
      };
    }

    case 'ADD_SEARCH_VALUE': {
      let values = cloneDeep(state.selectedFilters[action.name] ?? []);
      const searchValueIndex =
        action.index ??
        values.findIndex((value) => value.value === action.searchOption.value);

      if (searchValueIndex > -1) {
        values[searchValueIndex] = action.searchOption;
      } else {
        values = [...values, action.searchOption];
      }

      const filters = {
        ...state.selectedFilters,
        [action.name]: values,
      };

      return {
        ...state,
        selectedFilters: filters,
        filtersCount: getActiveFiltersCount(filters),
        isDirty: true,
        isLock: false,
      };
    }

    case 'REMOVE_SEARCH_VALUE': {
      const newSearchValues = [
        ...(state.selectedFilters[action.name] || []).filter(
          (searchValue) => searchValue.value !== action.searchValue.value
        ),
      ];

      const filters = {
        ...state.selectedFilters,
        [action.name]: newSearchValues.length ? newSearchValues : undefined,
      };

      return {
        ...state,
        selectedFilters: filters,
        filtersCount: getActiveFiltersCount(filters),
        isDirty: true,
      };
    }

    case 'TOGGLE_LOADING': {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case 'UPDATE_INITIAL_FILTERS': {
      return {
        ...state,
        initialFilters: cloneDeep(action.filters),
        isDirty: false,
        filtersAppliedCount: getActiveFiltersCount(action.filters),
      };
    }

    case 'UNDO_FILTERS': {
      const filters = state.initialFilters;

      return {
        ...state,
        selectedFilters: filters,
        filtersCount: getActiveFiltersCount(filters),
        isDirty: false,
      };
    }

    case 'RESET_FILTERS': {
      return {
        ...state,
        selectedFilters: {},
        isDirty: true,
        filtersCount: 0,
      };
    }

    case 'TOGGLE_LOCK': {
      return {
        ...state,
        isLock: action.lock,
      };
    }

    default:
      return state;
  }
};

export const EventFiltersContext = React.createContext<
  EventFiltersState | EventFiltersStoreApi
>(defaultState);

export const EventFiltersStateProvider = ({
  children,
  initialState,
  initialFilters = {},
}: {
  children: React.ReactNode;
  initialState?: EventFiltersState;
  initialFilters?: Partial<EventFilters>;
}) => {
  const filters = {
    ...(initialState || defaultState).selectedFilters,
    ...initialFilters,
  };

  const [state, dispatch] = React.useReducer<
    React.Reducer<EventFiltersState, EventFiltersActions>
  >(reducer, {
    ...(initialState || defaultState),
    selectedFilters: filters,
    filtersCount: getActiveFiltersCount(filters),
    filtersAppliedCount: getActiveFiltersCount(filters),
    initialFilters,
  });

  return (
    <EventFiltersContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </EventFiltersContext.Provider>
  );
};

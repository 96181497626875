import React from 'react';
import {
  ActivityLogContainer,
  ActivityInfoContainer,
  UpdateReasonContainer,
  ActivityReason,
  DottedBorder,
  ActivityDate,
  ActivityIssuer,
  ActivityDescriptionWrapper,
  ActivityDescription,
} from './activity-log.styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DateFormats } from '../../../utils/date-formats';
import { UserRoundAvatar } from '../../common/user-round-avatar/user-round-avatar.component';

type ActivityLogProps = {
  updateAction: string;
  updatedAt: string;
  updatedBy: string;
  reason?: string;
};

export const ActivityLog = ({
  updateAction,
  updatedBy,
  updatedAt,
  reason,
}: ActivityLogProps) => {
  return (
    <ActivityLogContainer className="activity-log">
      <UserRoundAvatar username={updatedBy} />
      <ActivityInfoContainer>
        <UpdateReasonContainer>
          <ActivityReason>{updateAction}</ActivityReason>
          <DottedBorder />
          <ActivityDate>
            At: {format(parseISO(updatedAt), DateFormats.dateTime)}
          </ActivityDate>
        </UpdateReasonContainer>
        <ActivityIssuer>{updatedBy}</ActivityIssuer>
        <ActivityDescriptionWrapper
          style={{ display: reason ? 'block' : 'none' }}
        >
          <ActivityReason>Reason:</ActivityReason>
          <ActivityDescription>{reason}</ActivityDescription>
        </ActivityDescriptionWrapper>
      </ActivityInfoContainer>
    </ActivityLogContainer>
  );
};

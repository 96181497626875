import styled from 'styled-components';

export const SwitcherContainer = styled.div`
  padding: 0 20px 20px;

  & > .event-type-label {
    margin-left: 15px;
  }

  & > .event-type-label:first-child {
    margin-left: 0;
  }
`;

export const EventTypeLabel = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;

  & > .label-text {
    font-size: 12px;
    color: #6d6d6d;
  }

  & > input {
    display: none;
  }

  & > .active-border {
    width: 100%;
    height: 2px;
    background-color: #e4a140;
    display: none;
    position: absolute;
    top: 21px;
    left: 0;
  }

  input:checked ~ .active-border {
    display: block;
  }
`;

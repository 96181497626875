import BaseOrganizerInput, {
  OrganizerInputProps as BaseProps,
} from '../../../../event/organizer-input/organizer-input.component';
import { Except } from 'type-fest';
import { LabeledValue } from 'antd/lib/select';
import { useFiltersContext } from '../../../hooks';
import React from 'react';

export type OrganizerInputProps = Except<BaseProps, 'choicesMapper'> & {
  initialValue?: LabeledValue[];
};

const OrganizerInput = ({
  name,
  initialValue,
  ...props
}: OrganizerInputProps) => {
  const { getFieldDecorator } = useFiltersContext();
  const decorator = getFieldDecorator(name, {
    initialValue,
  });

  return (
    <>
      {decorator(
        <BaseOrganizerInput
          maxTagWidth="110px"
          maxTagCount={1}
          name={name}
          {...props}
        />
      )}
    </>
  );
};

export default OrganizerInput;

import { message } from 'antd';
import { ratioMap, validateThresholds } from 'yggdrasil-shared/domain/image';
import { BrandedContainerImageAspectRatio } from '../../../../resolver.types';
import { RectShape, Coordinates } from './image-cropper.component';

message.config({
  maxCount: 1,
});

export type ResizePosition = 'nw' | 'n' | 'ne' | 'w' | 'e' | 'sw' | 's' | 'se';

export type ResizeOption = RectShape & {
  position: ResizePosition;
};

export const getResizeOptions = (
  { x, y, width, height }: RectShape,
  resizeOptionSize: number
): ResizeOption[] => {
  const halfSize = resizeOptionSize / 2;

  return [
    {
      x: x - halfSize,
      y: y - halfSize,
      width: resizeOptionSize,
      height: resizeOptionSize,
      position: 'nw',
    },
    {
      x: x + width / 2 - halfSize,
      y: y - halfSize,
      width: resizeOptionSize,
      height: resizeOptionSize,
      position: 'n',
    },
    {
      x: x + width - halfSize,
      y: y - halfSize,
      width: resizeOptionSize,
      height: resizeOptionSize,
      position: 'ne',
    },
    {
      x: x - halfSize,
      y: y + height / 2 - halfSize,
      width: resizeOptionSize,
      height: resizeOptionSize,
      position: 'w',
    },
    {
      x: x + width - halfSize,
      y: y + height / 2 - halfSize,
      width: resizeOptionSize,
      height: resizeOptionSize,
      position: 'e',
    },
    {
      x: x - halfSize,
      y: y + height - halfSize,
      width: resizeOptionSize,
      height: resizeOptionSize,
      position: 'sw',
    },
    {
      x: x + width / 2 - halfSize,
      y: y + height - halfSize,
      width: resizeOptionSize,
      height: resizeOptionSize,
      position: 's',
    },
    {
      x: x + width - halfSize,
      y: y + height - halfSize,
      width: resizeOptionSize,
      height: resizeOptionSize,
      position: 'se',
    },
  ];
};

type GetNewShapeByResizePositionProps = {
  rect: RectShape;
  initialMousePosition: Coordinates;
  mousePosition: Coordinates;
  position: ResizePosition;
  minimalSize: number;
  aspectRatio: BrandedContainerImageAspectRatio;
};

const conditionalMinimalSizeWarningFactory =
  (
    minimalWidth: number,
    minimalHeight: number,
    aspectRatio: BrandedContainerImageAspectRatio
  ) =>
  (newWidth: number, newHeight: number) => {
    if (newWidth <= minimalWidth || newHeight <= minimalHeight) {
      message.warning(
        `The minimal image size (${validateThresholds[aspectRatio].width}x${validateThresholds[aspectRatio].height}) has been reached.`
      );
    }
  };

export const getNewShapeByResizePosition = ({
  rect,
  mousePosition,
  position,
  initialMousePosition,
  minimalSize: minimalWidth,
  aspectRatio,
}: GetNewShapeByResizePositionProps): RectShape => {
  const oldX = rect.x;
  const oldY = rect.y;

  const ratio = ratioMap[aspectRatio];

  const ratioMultiplier = 1 / ratio;

  const minimalHeight = minimalWidth * ratio;

  const conditionalMinimalSizeWarning = conditionalMinimalSizeWarningFactory(
    minimalWidth,
    minimalHeight,
    aspectRatio
  );

  switch (position) {
    case 'nw': {
      const offset = oldY - mousePosition.y;

      const newHeight = rect.height + offset;
      const newWidth = rect.width + offset * ratioMultiplier;

      conditionalMinimalSizeWarning(newWidth, newHeight);

      return {
        x: newWidth <= minimalWidth ? oldX : rect.x - offset,
        y: newHeight <= minimalHeight ? rect.y : mousePosition.y,
        width: Math.max(newWidth, minimalWidth),
        height: Math.max(newHeight, minimalHeight),
      };
    }

    case 'n': {
      const offset = oldY - mousePosition.y;

      const newHeight = rect.height + offset;
      const newWidth = rect.width + offset * ratioMultiplier;

      conditionalMinimalSizeWarning(newWidth, newHeight);

      return {
        x: initialMousePosition.x - rect.width / 2,
        y: newHeight <= minimalHeight ? rect.y : mousePosition.y,
        width: Math.max(newWidth, minimalWidth),
        height: Math.max(newHeight, minimalHeight),
      };
    }

    case 'ne': {
      const offset = oldY - mousePosition.y;

      const newWidth = rect.width + offset * ratioMultiplier;
      const newHeight = rect.height + offset;

      conditionalMinimalSizeWarning(newWidth, newHeight);

      return {
        x: rect.x,
        y: newHeight <= minimalHeight ? rect.y : mousePosition.y,
        width: Math.max(newWidth, minimalWidth),
        height: Math.max(newHeight, minimalHeight),
      };
    }

    case 'w': {
      const offset = oldX - mousePosition.x;

      const newWidth = rect.width + offset * ratioMultiplier;
      const newHeight = rect.height + offset;

      conditionalMinimalSizeWarning(newWidth, newHeight);

      return {
        x: newWidth <= minimalWidth ? rect.x : rect.x - offset,
        y: initialMousePosition.y - rect.height / 2,
        width: Math.max(newWidth, minimalWidth),
        height: Math.max(newHeight, minimalHeight),
      };
    }

    case 'e': {
      const offset = mousePosition.x - oldX;

      const newHeight = offset / ratioMultiplier;

      conditionalMinimalSizeWarning(offset, newHeight);

      return {
        x: rect.x,
        y: initialMousePosition.y - rect.height / 2,
        width: Math.max(offset, minimalWidth),
        height: Math.max(newHeight, minimalHeight),
      };
    }

    case 'sw': {
      const offset = oldX - mousePosition.x;

      const newWidth = rect.width + offset * ratioMultiplier;
      const newHeight = rect.height + offset;

      conditionalMinimalSizeWarning(newWidth, newHeight);

      return {
        x: newWidth <= minimalWidth ? rect.x : mousePosition.x,
        y: rect.y,
        width: Math.max(newWidth, minimalWidth),
        height: Math.max(newHeight, minimalHeight),
      };
    }

    case 's': {
      const offset = mousePosition.y - oldY;

      const newWidth = offset * ratioMultiplier;

      conditionalMinimalSizeWarning(newWidth, offset);

      return {
        x: initialMousePosition.x - rect.width / 2,
        y: rect.y,
        width: Math.max(newWidth, minimalWidth),
        height: Math.max(offset, minimalHeight),
      };
    }

    case 'se': {
      const offset = mousePosition.x - oldX;

      const newHeight = offset / ratioMultiplier;

      conditionalMinimalSizeWarning(offset, newHeight);

      return {
        ...rect,
        width: Math.max(offset, minimalWidth),
        height: Math.max(newHeight, minimalHeight),
      };
    }

    default:
      return rect;
  }
};

export interface AdvancedActiveMatcher {
  'press-text': string;
  translations: string;
  meta: string;
}

export const advancedActiveMatcher = {
  'press-text': 'description',
  translations: 'description',
  meta: 'description',
};

import styled from 'styled-components';

export const StyledPromotionItem = styled.div.attrs(
  ({ $disabled }: { $disabled?: boolean }) => ({
    $disabled,
  })
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 108px;
  height: 125px;
  box-shadow: 0px 3px 6px rgba(180, 180, 180, 0.5);
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: ${(props) => (props.$disabled ? `auto` : 'pointer')};
  user-select: none;

  ${(props) => props.$disabled && `background-color: #f5f5f5;`}

  & > img {
    margin-bottom: 20px;
    width: 80px;
    height: 90px;
  }

  & > span {
    position: absolute;
    bottom: 11px;
    font-weight: 300;
    font-size: 11px;
  }

  & > input {
    pointer-events: none;
    height: 21px;
    width: 21px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 50%;
    outline: none;
    transition-duration: 0.3s;
    background-color: #fff;
    cursor: pointer;
    position: absolute;
    bottom: -10px;

    &:disabled {
      background-color: #f5f5f5;
    }

    &:checked {
      background-color: #e3a14a;
    }

    &:checked::before {
      content: '\\2713';
      display: block;
      text-align: center;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 17px;
      transform: translate(-50%, -50%);
    }
  }
`;

import * as React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';

import { createUploadLink } from 'apollo-upload-client';
import { getUserData } from '../../../utils/token';

const asgardProtocol = process.env.REACT_APP_ASGARD_PROTOCOL;
const asgardHost = process.env.REACT_APP_ASGARD_HOST;
const asgardPort = process.env.REACT_APP_ASGARD_LOAD_BALANCER_PORT;

const uri = asgardPort
  ? `${asgardProtocol}://${asgardHost}:${asgardPort}/api`
  : `${asgardProtocol}://${asgardHost}/api`;

type ApolloWrapperProps = {
  children: React.ReactNode;
};

const isErrorResponse = (json: any) => {
  return json && json.errors && json.errors[0];
};

const isNotAuthenticated = (json: any) => {
  return (
    isErrorResponse(json) && json.errors[0].message === 'User not Authenticated'
  );
};

const prepareResponse = (text: string, json: any) => ({
  ok: true,
  json: () => Promise.resolve(json),
  text: () => Promise.resolve(text),
});

export const ApolloWrapper = ({ children }: ApolloWrapperProps) => {
  const { keycloak, initialized } = useKeycloak();

  const authenticationFetch = React.useMemo(() => {
    return async (uri: string, options: any): Promise<Response> => {
      if (initialized && keycloak.isTokenExpired(15)) {
        await keycloak.updateToken(45);
      }

      const userData = getUserData();
      options.headers.authorization = `bearer ${userData.accessToken}`;

      const response = await fetch(uri, options);
      const text = await response.text();
      const json = JSON.parse(text);

      if (!isNotAuthenticated(json)) {
        return prepareResponse(text, json) as Response;
      }

      return fetch(uri, options);
    };
  }, [keycloak, initialized]);

  const apolloClient = React.useMemo(() => {
    return new ApolloClient({
      link: createUploadLink({
        uri,
        fetch: authenticationFetch,
        headers: { 'Apollo-Require-Preflight': 'true' },
      }),
      cache: new InMemoryCache(),
    });
  }, [authenticationFetch]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

import React, { useCallback } from 'react';
import parseISO from 'date-fns/parseISO';
import {
  EventFilters,
  EventFiltersContext,
  EventFiltersStoreApi,
  SearchLabel,
  SearchValue,
} from '../../../context/event-filters.context';
import { RangeValue } from '../../filters/inputs/common/range/range.component';
import { SearchWithOption } from '../../common/search-with-option/search-with-option.component';
import { FiltersContainer, FiltersWrapper } from './events-filters.styles';
import { SearchValueContainer } from '../../common/search-value-container/search-value-container.component';
import { LabeledValue } from 'antd/lib/select';
import {
  ChoicesMapper as LocationChoicesMapper,
  CityOrZip,
} from '../../common/city-or-zip/city-or-zip.component';
import { isID } from 'yggdrasil-shared/utils/common';
import RangeInput from '../../common/range-input/range-input.component';
import OrganizerInput from '../organizer-input/organizer-input.component';
import Tags from '../../filters/inputs/common/tags/tags.component';
import { DateSelect } from '../../filters/inputs/common/date-select/date-select.component';
import { GenresDropdown } from '../../branded-container/genres-dropdown/genres-dropdown.component';
import { formatISO } from 'date-fns';
import { Warning } from '../../common/warning/warning.component';

export type EventFiltersProps = {
  open?: boolean;
  showSuggestedEventsInfo: boolean;
};

export const EventsFilters = ({
  open,
  showSuggestedEventsInfo,
}: EventFiltersProps) => {
  const {
    state: { selectedFilters },
    dispatch,
  } = React.useContext(EventFiltersContext) as EventFiltersStoreApi;

  const onStoreDropdownChange =
    (name: keyof EventFilters) => (values: string[]) => {
      dispatch({
        type: 'SET_FILTER',
        filter: {
          name,
          value: values.length ? values : undefined,
        },
      });
    };

  const onOrganizerChange = (values: LabeledValue[]) => {
    if (!values.length) {
      dispatch({
        type: 'SET_FILTERS',
        filters: {
          organizers: undefined,
          organizerIDs: undefined,
          organizerNames: undefined,
        },
      });
      return;
    }

    /**
     *
     * @note val.key has been changed to val.value after antd v4 migration
     */
    const organizerIDs = values
      .filter((val) => isID(val.value))
      .map((val) => val.value) as string[];

    const organizerNames = values
      .filter((val) => !isID(val.value))
      .map((val) => val.value) as string[];

    dispatch({
      type: 'SET_FILTERS',
      filters: {
        organizers: values,
        organizerIDs: organizerIDs.length ? organizerIDs : undefined,
        organizerNames: organizerNames.length ? organizerNames : undefined,
      },
    });
  };

  const onTextInputChange =
    (name: SearchLabel) => (value: SearchValue, index?: number) => {
      dispatch({
        name,
        type: 'ADD_SEARCH_VALUE',
        searchOption: value,
        index,
      });
    };

  const onPriceChange = ([min, max]: RangeValue) => {
    dispatch({
      type: 'SET_FILTERS',
      filters: {
        minPrice: min,
        maxPrice: max,
      },
    });
  };

  const onDateChange = (field: string) => (dates: any[] | null) => {
    if (!Array.isArray(dates)) {
      dispatch({
        type: 'SET_FILTERS',
        filters: {
          [field]: undefined,
        },
      });
      return;
    }

    const [startDate, endDate] = dates;

    dispatch({
      type: 'SET_FILTERS',
      filters: {
        [field]:
          startDate || endDate
            ? {
                min: formatISO(startDate),
                max: formatISO(endDate),
              }
            : undefined,
      },
    });
  };

  const locationResultMapper: LocationChoicesMapper = useCallback(
    (choice) => ({
      key: choice.searchString,
      label: choice.searchString,
      value: choice.searchString,
    }),
    []
  );

  const onLocationChange = (values: LabeledValue[]) => {
    if (!values.length) {
      dispatch({
        type: 'SET_FILTERS',
        filters: {
          zipCodes: undefined,
          cities: undefined,
          locations: undefined,
        },
      });
      return;
    }

    /**
     *
     * @note val.key has been changed to val.value after antd v4 migration
     */
    const zipCodes = values
      .filter((val) => !isNaN(val.value as number))
      .map((val) => val.value) as string[];
    const cities = values
      .filter((val) => isNaN(val.value as number))
      .map((val) => val.value) as string[];

    dispatch({
      type: 'SET_FILTERS',
      filters: {
        zipCodes,
        cities,
        locations: values,
      },
    });
  };

  const onRemoveSearchValue = (name: SearchLabel) => (value: SearchValue) => {
    dispatch({
      name,
      type: 'REMOVE_SEARCH_VALUE',
      searchValue: value,
    });
  };

  const handleLock = useCallback(
    (lock: boolean) => {
      dispatch({
        type: 'TOGGLE_LOCK',
        lock,
      });
    },
    [dispatch]
  );

  return (
    <FiltersContainer>
      {open && (
        <FiltersWrapper className="filters-wrapper">
          <div className="row">
            <div className="filter-field">
              <SearchWithOption
                onChange={onTextInputChange('titleSearchValues')}
                placeholder="Event title..."
                width="210px"
                optionWidth="70px"
                name="eventTitle"
                toggleLock={handleLock}
              />
              <div className="filter-field__label">Event title</div>
            </div>
            <div className="filter-field">
              <RangeInput
                showReset={false}
                onlyAbsolute
                onlyIntegers
                suffix="€"
                placeholder="Price"
                width="210px"
                onChange={onPriceChange}
                value={[selectedFilters.minPrice, selectedFilters.maxPrice]}
                tooltipText="Filter is currently not available"
                disabled={true}
              />
              <div className="filter-field__label">Price</div>
            </div>
            <div className="filter-field">
              <DateSelect
                name="startDateTime"
                width="210px"
                separator="-"
                timeSelection={false}
                placeholder="Event date..."
                allowClear={true}
                handleCalendarChange={onDateChange('startDateTime')}
                value={
                  selectedFilters.startDateTime?.min &&
                  selectedFilters.startDateTime?.max
                    ? [
                        parseISO(
                          selectedFilters.startDateTime.min.toString()
                        ) as any,
                        parseISO(
                          selectedFilters.startDateTime.max.toString()
                        ) as any,
                      ]
                    : []
                }
              />
              <div className="filter-field__label">Event date</div>
            </div>
          </div>

          <div className="row">
            <div className="filter-field">
              <OrganizerInput
                mode="tags"
                maxTagCount={1}
                maxTagWidth="110px"
                onChange={onOrganizerChange}
                value={selectedFilters.organizers ?? []}
                name="organizers"
                placeholder="Organizer name or ID..."
                width="210px"
              />
              <div className="filter-field__label">Organizer name or ID</div>
            </div>
            <div className="filter-field">
              <DateSelect
                name="saleStartDateTime"
                hideArrow={true}
                width="210px"
                separator="-"
                timeSelection={false}
                placeholder="Presale start..."
                allowClear={true}
                handleCalendarChange={onDateChange('saleStartDateTime')}
                value={
                  selectedFilters.saleStartDateTime?.min &&
                  selectedFilters.saleStartDateTime?.max
                    ? [
                        parseISO(
                          selectedFilters.saleStartDateTime.min.toString()
                        ) as any,
                        parseISO(
                          selectedFilters.saleStartDateTime.max.toString()
                        ) as any,
                      ]
                    : []
                }
              />
              <div className="filter-field__label">Presale start</div>
            </div>
            <div className="filter-field">
              <CityOrZip
                clearable
                maxTagCount={1}
                maxTagWidth="110px"
                placeholder="City or postal code..."
                width="210px"
                onChange={onLocationChange}
                value={selectedFilters.locations}
                choicesMapper={locationResultMapper}
                mode="tags"
              />
              <div className="filter-field__label">City or postal code</div>
            </div>
          </div>
          <div className="row">
            <div className="filter-field">
              <SearchWithOption
                onChange={onTextInputChange('fullTextSearchValues')}
                placeholder="Full text search..."
                width="210px"
                optionWidth="70px"
                name="fullTextSearch"
                toggleLock={handleLock}
              />
              <div className="filter-field__label">Full text search</div>
            </div>
            <div className="filter-field">
              <GenresDropdown
                maxTagCount={1}
                maxTagWidth="110px"
                showClear={false}
                showCheckbox
                placeholder="Genre..."
                styledSelection
                width="210px"
                onChange={onStoreDropdownChange('genres')}
                value={selectedFilters.genres}
              />
              <div className="filter-field__label">Genre</div>
            </div>

            <div className="filter-field">
              <Tags
                width="210px"
                maxTagCount={1}
                maxTagWidth="110px"
                placeholder="Event ID..."
                onChange={onStoreDropdownChange('sourceEventIds')}
                allowClear
                value={selectedFilters.sourceEventIds ?? []}
              />
              <div className="filter-field__label">Event ID</div>
            </div>
          </div>
          {/**
           * @note TicketSpecials feature temporary removed - CP-671
           */}

          {selectedFilters.titleSearchValues?.length && (
            <div className="row">
              <SearchValueContainer
                values={selectedFilters.titleSearchValues}
                title="Event title"
                onRemoveValue={onRemoveSearchValue('titleSearchValues')}
                onUpdateValue={onTextInputChange('titleSearchValues')}
              />
            </div>
          )}

          {selectedFilters.fullTextSearchValues?.length && (
            <div className="row">
              <SearchValueContainer
                values={selectedFilters.fullTextSearchValues}
                title="Full text search"
                onRemoveValue={onRemoveSearchValue('fullTextSearchValues')}
                onUpdateValue={onTextInputChange('fullTextSearchValues')}
              />
            </div>
          )}

          <Warning
            message="More than 500 matching events were found. Try to specify your
          filters."
            hidden={!showSuggestedEventsInfo}
          />
        </FiltersWrapper>
      )}
    </FiltersContainer>
  );
};

import { BrandedContainerRegionDisplayFilter } from '../../../../../resolver.types';
import { LabeledValue } from 'antd/lib/select';
import { camelCaseToSentence } from '../../../../../utils/text';
import {
  Multiselect,
  MultiSelectProps,
} from '../../common/multiselect/multiselect.component';
import { Except } from 'type-fest';
import React from 'react';

/**
 * @note "value" has been added - required by antd v4
 */

const choices: LabeledValue[] = Object.values(
  BrandedContainerRegionDisplayFilter
)
  .filter((val) => val !== BrandedContainerRegionDisplayFilter.ShowAll)
  .map((display) => ({
    key: display as string,
    value: display as string,
    label: camelCaseToSentence(display as string),
  }));

export const RegionSettings = (
  props: Except<
    MultiSelectProps,
    'choices' | 'perPage' | 'showSearch' | 'initialValue'
  >
) => {
  return (
    <Multiselect
      maxTagWidth="110px"
      maxTagCount={1}
      {...props}
      perPage={10}
      showSearch={false}
      choices={choices}
    />
  );
};

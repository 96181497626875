import styled from 'styled-components';
import { Button, Form } from 'antd';

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex !important;
  }
`;

export const StyledButton = styled(Button).attrs(
  ({ $clicked }: { $clicked: boolean }) => ({
    $clicked,
  })
)`
  width: 100px;
  height: 40px;
  border-radius: 0px !important;

  &.ant-btn:focus {
    color: ${(props) => (!props.$clicked ? '#4b4b4b' : '#f0bf75')};
    border-color: ${(props) => (!props.$clicked ? '#d9d9d9' : '#f0bf75')};
  }

  &.ant-btn:hover:not([disabled]) {
    color: #f0bf75 !important;
    border-color: #f0bf75 !important;
  }
`;

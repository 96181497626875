import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useMemo } from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { DateFormats } from '../../../utils/date-formats';
import React from 'react';

export type LastSuggestedEventsUpdateDateProps = {
  date?: string | null;
};

const LastSuggestedEventsUpdateDate = ({
  date,
}: LastSuggestedEventsUpdateDateProps) => {
  const displayDate = useMemo(
    () => format(date ? parseISO(date) : new Date(), DateFormats.dateTime),
    [date]
  );

  if (!date) {
    return null;
  }

  return (
    <Tooltip
      title={
        <span>
          Last suggested events update: <strong>{displayDate}</strong>
        </span>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};

export default LastSuggestedEventsUpdateDate;

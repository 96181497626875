import { Form } from '../../../hooks/use-form';
import { UpdateArtistInput } from '../../../graphql/mutations/update-artist';
import { FieldDecoratorResult } from '../../../types/antd';
import parseISO from 'date-fns/parseISO';
import { Artist } from '../../../resolver.types';

export type Decorators = {
  name: FieldDecoratorResult;
  countryOfOrigin: FieldDecoratorResult;
  dateOfBirth: FieldDecoratorResult;
  source: FieldDecoratorResult;
  genres: FieldDecoratorResult;
};

export const getDecorators = (
  form: Form<UpdateArtistInput>,
  artist: Artist
): Decorators => ({
  name: form.getFieldDecorator<UpdateArtistInput>('name', {
    initialValue: artist.name,
    rules: [
      {
        required: true,
        message: 'Provide artist name',
      },
    ],
  }),
  countryOfOrigin: form.getFieldDecorator<UpdateArtistInput>(
    'countryOfOrigin',
    {
      initialValue: artist.countryOfOrigin,
    }
  ),
  dateOfBirth: form.getFieldDecorator<UpdateArtistInput>('dateOfBirth', {
    initialValue: artist.dateOfBirth ? parseISO(artist.dateOfBirth) : null,
    rules: [
      {
        type: 'date',
        message: 'Provide valid artist birth date.',
      },
    ],
  }),
  source: form.getFieldDecorator<UpdateArtistInput>('source', {
    initialValue: artist.source,
  }),
  genres: form.getFieldDecorator<UpdateArtistInput>('genres', {
    initialValue: artist.genres ?? [],
  }),
});

import React, { useCallback } from 'react';
import { brandedContainerNavigationData } from '../../../static/branded-container-navigation-data';
import BaseDrawerNavigation, {
  DrawerNavigationItem,
} from '../../common/drawer-navigation/drawer-navigation.component';

type BrandedContainerDrawerNavigationProps = {
  totalRelatedBrandedContainers?: number;
  totalEvents?: number;
};

export const BrandedContainerDrawerNavigation = ({
  totalEvents,
  totalRelatedBrandedContainers,
}: BrandedContainerDrawerNavigationProps) => {
  const mapItem = useCallback(
    (item: DrawerNavigationItem) => {
      if (item.link === 'branded-containers') {
        return {
          ...item,
          total: totalRelatedBrandedContainers,
        };
      }

      if (item.link === 'bc-events') {
        return {
          ...item,
          total: totalEvents,
        };
      }

      return item;
    },
    [totalRelatedBrandedContainers, totalEvents]
  );

  return (
    <BaseDrawerNavigation
      items={brandedContainerNavigationData}
      mapNavItem={mapItem}
    />
  );
};

import {
  UpdateBrandedContainerDocument,
  UpdateBrandedContainerInput,
} from '../../resolver.types';

export const UPDATE_BRANDED_CONTAINER = UpdateBrandedContainerDocument;

export type UpdateBrandedContainerVariables = {
  brandedContainer: UpdateBrandedContainerInput;
};

export type UpdateBrandedContainerResult = {
  updateBrandedContainer: UpdateBrandedContainerInput;
};

import styled from 'styled-components';
import React, { useMemo } from 'react';
import { camelCaseToSentence } from '../../../utils/text';
import { TopicData } from '../../../resolver.types';

export type TagsProps = {
  topic: TopicData;
  limit: number;
};

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const order: Array<keyof TopicData> = [
  'artists',
  'genres',
  'venue',
  'campaign',
  'eventSeries',
  'place',
  'workOfArt',
];

export const Tags = ({ topic, limit = 5 }: TagsProps) => {
  const topicTags = useMemo(() => {
    return order
      .map((sortedKey) => {
        if (!topic[sortedKey]) {
          return null;
        }

        return sortedKey;
      })
      .filter(Boolean)
      .slice(0, limit) as string[];
  }, [topic, limit]);

  return (
    <TagsContainer className="tags-container">
      {topicTags.map((tag) => (
        <div
          key={tag}
          style={{ whiteSpace: 'nowrap' }}
          className="topic-tag tag"
        >
          {camelCaseToSentence(tag)}
        </div>
      ))}
    </TagsContainer>
  );
};

import React from 'react';
import { AddRelatedBrandedContainersProps } from './assign-related-branded-containers.hook';
import { AllBrandedContainersList } from './all-branded-containers-tab';
import type { BrandedContainer } from '../../../../../resolver.types';
import { DataListProps } from '../../../../common/data-list/data-list.component';

type AddRelatedBrandedContainerProps = AddRelatedBrandedContainersProps & {
  onSelect: (selectedContainersIDs: BrandedContainer[]) => Promise<void>;
  disabled?: boolean;
  openDetails: (event: BrandedContainer) => void;
  loading: boolean;
};

export default ({
  brand,
  relatedBrandedContainerIDs,
  brandedContainerId,
  onSelect,
  disabled,
  loading,
  openDetails,
}: AddRelatedBrandedContainerProps) => {
  const omitBrandedContainersIDs = React.useMemo(
    () => [...relatedBrandedContainerIDs, brandedContainerId],
    [relatedBrandedContainerIDs, brandedContainerId]
  );

  const parseQuery: DataListProps<BrandedContainer>['parseQueryVarsBeforeQuery'] =
    React.useCallback(
      (params: any) => {
        params.brand = brand;
        params.filters = {
          ...(params?.filters ?? {}),
          notIds: [...relatedBrandedContainerIDs, brandedContainerId],
        };
        return params;
      },
      [brand, brandedContainerId, relatedBrandedContainerIDs]
    );

  return (
    <AllBrandedContainersList
      onOk={onSelect}
      omitBrandedContainersIDs={omitBrandedContainersIDs}
      parseQuery={parseQuery}
      disabled={disabled}
      loading={loading}
      openDetails={openDetails}
      brandedContainerId={brandedContainerId}
      relatedBrandedContainerIDs={relatedBrandedContainerIDs}
    />
  );
};

import * as React from 'react';
import Icon from '@ant-design/icons';
import { Brand } from 'yggdrasil-shared/domain/dictionary';
import { Drawer, Button, Row, Col } from 'antd';
import { AppContext, AppStoreApi } from '../../../../context/app.context';
import { UserContainer } from './user.styles';
import { BrandTag } from '../../../common/brand-tag/brand-tag.component';
import { BrandDropdown } from '../../../common/brand-dropdown/brand-dropdown.component';
import { logoutFromKeycloak } from '../../../../context/keycloak.context';
import { AuthContext, AuthStateStore } from '../../../../context/auth.context';

export const User = () => {
  const [open, setOpen] = React.useState(false);

  const {
    state: { user, selectedBrand },
    dispatch,
  } = React.useContext(AppContext) as AppStoreApi;

  const { changeLoginState } = React.useContext(AuthContext) as AuthStateStore;

  const onLogoutClick = React.useCallback(async () => {
    changeLoginState('loggingOut');
    await logoutFromKeycloak();
  }, [changeLoginState]);

  const onProfileClick = () => setOpen(true);
  const onClose = () => setOpen(false);
  const firstUserLetter = (user.name || 'O')[0].toUpperCase();

  const changeBrand = (brand: Brand) => {
    dispatch({
      type: 'SELECT_BRAND',
      brand,
    });
  };

  return (
    <UserContainer>
      <Button
        onClick={onProfileClick}
        style={{
          backgroundColor: '#f3f3f3',
          borderWidth: 0,
        }}
      >
        {firstUserLetter}
        {selectedBrand && (
          <BrandTag
            brand={selectedBrand.shortcut}
            color={selectedBrand.color}
          />
        )}
      </Button>
      <Drawer
        visible={open}
        width={250}
        bodyStyle={{ padding: 15, height: '100%', overflow: 'hidden' }}
        onClose={onClose}
        maskClosable={true}
        title="Profile"
      >
        <Row style={{ display: 'flex', flexDirection: 'row' }}>
          <Col>
            <Button
              style={{
                backgroundColor: '#f3f3f3',
                borderWidth: 0,
              }}
            >
              {firstUserLetter}
            </Button>
          </Col>
          <Col style={{ marginLeft: 10, marginRight: 10 }}>
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              {user.name}
              {selectedBrand && (
                <BrandDropdown value={selectedBrand} onChange={changeBrand} />
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 50 }}>
          <Col>
            <Button onClick={onLogoutClick} style={{ borderWidth: 0 }}>
              <span>Logout</span>
              <Icon component={() => <i className="fal fa-sign-out" />} />
            </Button>
          </Col>
        </Row>
      </Drawer>
    </UserContainer>
  );
};

import React from 'react';
import { AvatarContainer } from './user-round-avatar.styles';

type UserRoundAvatarProps = {
  username: string;
  imageUrl?: string;
  color?: string;
};

export const UserRoundAvatar = ({
  username,
  imageUrl,
  color = '#E4A140',
}: UserRoundAvatarProps) => {
  return (
    <AvatarContainer
      className="user-round-avatar"
      color={color}
      style={{
        ...(imageUrl && { backgroundImage: `url(${imageUrl})` }),
      }}
    >
      {imageUrl ? '' : username[0]}
    </AvatarContainer>
  );
};

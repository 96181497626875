import styled from 'styled-components';
import {
  DrawerContainer,
  StyledDrawer as Drawer,
} from '../../layout/drawer/drawer.styles';

export const StyledDrawer = styled(Drawer)`
  padding: 0;

  .ant-drawer-body {
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledDrawerContainer = styled(DrawerContainer)`
  height: calc(100% - 81px);
`;

import React from 'react';
import { Upload } from 'antd';
import styled from 'styled-components';
import { UploadProps } from 'antd/lib/upload/interface';
import { SpinLoader } from '../../../common/spin-loader/spin-loader.component';

type UploadImageProps = {
  customRequest: UploadProps<any>['customRequest'];
  onChange: () => void;
  isImageUploading: boolean;
  disabled?: boolean;
};

export const UploadImage = ({
  customRequest,
  isImageUploading,
  onChange,
  disabled = false,
}: UploadImageProps) => {
  return (
    <React.Fragment>
      <UploadDragger
        name="file"
        multiple
        customRequest={customRequest}
        onChange={onChange}
        height={430}
        accept="image/*"
        showUploadList={false}
        disabled={disabled}
      >
        <React.Fragment>
          {isImageUploading ? (
            <SpinLoader loadingMessage="Uploading image..." />
          ) : (
            <UploadText>
              <span style={{ fontSize: '20px', color: '#D4D4D4' }}>
                <i className="fal fa-image"></i>
              </span>
              <span className="text">
                Click or drag file to this area to upload image
              </span>
            </UploadText>
          )}
        </React.Fragment>
      </UploadDragger>
    </React.Fragment>
  );
};

const UploadDragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    width: 640px;
  }
`;

const UploadText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #747474;
  font-size: 12px;

  & > .text {
    margin-left: 8px;
  }
`;

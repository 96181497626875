import React, { PropsWithChildren, Reducer, useReducer } from 'react';

export type KeycloakLogginState = 'loggedOut' | 'loggingOut' | 'loggedIn';

export type AuthState = {
  loginState: KeycloakLogginState;
};

export type AuthStateStore = {
  state: AuthState;
  changeLoginState: React.Dispatch<KeycloakLogginState>;
};

export const initialState: AuthState = {
  loginState: 'loggedOut',
};

const reducer = (oldState: AuthState, newState: KeycloakLogginState) => {
  return { ...oldState, loginState: newState };
};

export const AuthContext = React.createContext<AuthStateStore | AuthState>(
  initialState
);

export type AuthStateProviderProps = {
  defaultState?: AuthState;
};

export const AuthStateProvider = ({
  children,
  defaultState = initialState,
}: PropsWithChildren<AuthStateProviderProps>) => {
  const [state, changeLoginState] = useReducer<
    Reducer<AuthState, KeycloakLogginState>
  >(reducer, defaultState);
  return (
    <AuthContext.Provider value={{ state, changeLoginState }}>
      {children}
    </AuthContext.Provider>
  );
};

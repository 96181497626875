import React, { useContext } from 'react';
import { EventList } from './event-list.component';
import { suggestedColumns, defaultColumns } from './event-step-columns';
import { Event } from '../../../../resolver.types';
import useCalculateReservixAPIData from '../../../../hooks/use-calculate-reservix-api-data';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { noop } from '../../../../utils/text';
import {
  EventFiltersContext,
  EventFiltersStoreApi,
} from '../../../../context/event-filters.context';
import { Empty } from 'antd';
import { StyledComponent, StyledSpan } from './suggested-event-list.styles';
import { CustomLocale } from '../../../common/data-list/data-list.component';

type DefaultEventProps = {
  eventIDs: string[];
  declineEventsAction: (events: Event[]) => void;
  assignEventsAction: (events: Event[]) => void;
  openDetails: (event: Event) => void;
  loading?: boolean;
  disabled?: boolean;
};

export const SuggestedEventList = ({
  eventIDs,
  declineEventsAction,
  assignEventsAction,
  loading,
  disabled = false,
  openDetails,
}: DefaultEventProps) => {
  const {
    state: { filtersAppliedCount },
  } = useContext(EventFiltersContext) as EventFiltersStoreApi;
  const { handleQueryChange } = useCalculateReservixAPIData();
  const rowSelection = React.useMemo(
    () => ({
      useRowSelection: true,
      rowSelectionActions: [
        {
          label: 'Assign',
          icon: <PlusOutlined />,
          type: 'primary',
          onClick: assignEventsAction,
          resetSelection: true,
          loading,
        },
        {
          label: 'Decline',
          icon: <CloseOutlined />,
          onClick: declineEventsAction,
          resetSelection: true,
          loading,
        },
      ],
      rowSelectionColPush: 0,
      selectionLabelProvider: (count: number) => (
        <span>
          <strong>{count}</strong>
          {noop(count, ' event selected', ' events selected')}
        </span>
      ),
    }),

    [assignEventsAction, declineEventsAction, loading]
  );

  const customLocale: CustomLocale = {
    emptyText: (
      <StyledComponent>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
        {filtersAppliedCount === 0 ? (
          <StyledSpan className="no-active-filter">
            <strong>No active filter.</strong>
            <br />
            To automatically generate suggested events that might match the
            content of the
            <br />
            branded container, you need to set a filter and save it.
          </StyledSpan>
        ) : (
          <StyledSpan className="no-active-filter">
            <strong>Currently no suggested events.</strong>
            <br />
            There are no events, which are matching your current filter
            settings.
          </StyledSpan>
        )}
      </StyledComponent>
    ),
  };

  return (
    <EventList
      onQueryUpdate={handleQueryChange}
      columns={
        disabled
          ? defaultColumns()
          : suggestedColumns(
              declineEventsAction,
              assignEventsAction,
              loading,
              openDetails
            )
      }
      rowSelection={disabled ? undefined : rowSelection}
      ids={eventIDs}
      onlyFuture
      customLocale={customLocale}
    />
  );
};

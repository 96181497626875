import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import {
  BrandedContainerImageAspectRatio,
  File,
} from '../../../../resolver.types';
import { DeleteMediaImageButton } from './delete-image.component';
import { ReplaceMediaImageButton } from './replace-image.component';

type SelectAspectRatioProps = {
  defaultAspectRatio?: BrandedContainerImageAspectRatio;
  onChange: (aspectRatio: BrandedContainerImageAspectRatio) => void;
  onDelete?: () => Promise<void>;
  onSelect?: () => void;
  onReplace?: (file: File) => Promise<string | void>;
  confirmThumbnailGeneration?: (url: string) => Promise<void>;
  disabled?: boolean;
  handleImageLoading?: (status: boolean) => void;
  cropSupported: boolean;
};

export const SelectAspectRatio = ({
  defaultAspectRatio = BrandedContainerImageAspectRatio.A1x1,
  onChange,
  onDelete,
  onSelect,
  onReplace,
  handleImageLoading,
  confirmThumbnailGeneration,
  disabled = false,
  cropSupported,
}: SelectAspectRatioProps) => {
  const [aspectRatio, setAspectRatio] =
    React.useState<BrandedContainerImageAspectRatio>(defaultAspectRatio);

  const onClick = (aspectRatio: BrandedContainerImageAspectRatio) => () => {
    setAspectRatio(aspectRatio);
    onChange(aspectRatio);
  };

  return (
    <AspectRatioContainer>
      <div>
        <span className="format-text">Ratio</span>
        <AspectRatioButton
          className="aspect-ratio-button"
          type={
            aspectRatio === BrandedContainerImageAspectRatio.A1x1
              ? 'primary'
              : 'default'
          }
          onClick={onClick(BrandedContainerImageAspectRatio.A1x1)}
        >
          1:1
        </AspectRatioButton>
        <AspectRatioButton
          className="aspect-ratio-button"
          type={
            aspectRatio === BrandedContainerImageAspectRatio.A16x9
              ? 'primary'
              : 'default'
          }
          onClick={onClick(BrandedContainerImageAspectRatio.A16x9)}
        >
          16:9
        </AspectRatioButton>
        <AspectRatioButton
          className="aspect-ratio-button"
          type={
            aspectRatio === BrandedContainerImageAspectRatio.A4x1
              ? 'primary'
              : 'default'
          }
          onClick={onClick(BrandedContainerImageAspectRatio.A4x1)}
        >
          4:1
        </AspectRatioButton>
      </div>
      {!disabled && (
        <div className="buttons-container">
          {cropSupported && onSelect && (
            <Button onClick={onSelect} type="primary">
              <span className="icon">
                <i className="fal fa-crop-alt"></i>
              </span>
              Crop Image
            </Button>
          )}
          {onReplace && (
            <ReplaceMediaImageButton
              onReplace={onReplace}
              aspectRatio={aspectRatio}
              disabled={disabled}
              handleImageLoading={handleImageLoading}
              confirmThumbnailGeneration={confirmThumbnailGeneration}
            />
          )}
          {onDelete && <DeleteMediaImageButton onClick={onDelete} />}
        </div>
      )}
    </AspectRatioContainer>
  );
};

const AspectRatioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 12px 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 100%;

  & .format-text {
    margin-right: 10px;
  }

  & .aspect-ratio-button {
    margin-right: 5px;
  }

  & > button {
    margin-left: 8px;
  }

  & .buttons-container {
    & .ant-btn {
      padding: 0 5px;
      height: 28px;

      & .icon {
        margin-right: 5px;
        font-size: 14px;
      }
    }

    & .ant-btn:first-child,
    & .ant-btn:nth-child(2) {
      margin-right: 5px;
    }
  }
`;

const AspectRatioButton = styled(Button)`
  &.ant-btn {
    font-size: 12px;
    height: 24px;
    padding: 0 5px;
  }
`;

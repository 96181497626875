import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & .text-source-buttons {
    margin: 10px 0;
  }

  & .ant-form-item {
    margin-bottom: 10px;
  }

  & .ant-input {
    border-radius: 0;
    height: 40px;
  }

  & .ant-form-item label {
    font-size: 12px;
    font-weight: 700;
    color: #6d6d6d;
  }

  & .ant-form-item-label {
    line-height: 25px;
  }

  & .ant-legacy-form-item {
    margin-bottom: 10px;
  }

  & .ant-legacy-form-item label {
    font-size: 12px;
    font-weight: 700;
    color: #6d6d6d;
  }

  & .ant-legacy-form-item-label {
    line-height: 25px;
  }

  & .ant-select-selection--multiple {
    min-height: 38px;
  }

  & .ant-select-selection {
    border-radius: 0;
  }

  & .ant-select-selection__rendered {
    line-height: 38px;
  }

  & .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 7px;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
  }

  & .ant-select-selection--multiple .ant-select-selection__choice {
    background-color: #e4a140;
    border-radius: 12px;
    border: none;
    color: #ffffff;

    & .anticon.anticon-close.ant-select-remove-icon {
      color: #ffffff;
    }
  }

  & .ant-select-selection--multiple .ant-select-selection__choice__content {
    max-width: 600px;
  }

  & .ant-select-open .ant-select-selection {
    max-width: 650px;
  }
`;

export const FormContainer = styled.div`
  overflow-y: scroll;
`;

export const FormItemError = styled.p`
  color: #e64c3c;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin: 0;
`;

export const DefaultPaddingContainer = styled.div`
  height: calc(100% - 40px);
  padding: 10px 20px;
`;

export const BrandSwitcherContainer = styled.div`
  padding: 10px 20px;
`;

import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const StyledDrawerForm = styled(Form)`
  display: block;

  &.drawer-form {
    padding: 20px;
  }

  .form-item,
  .ant-calendar-picker {
    width: 100%;
  }

  .ant-picker {
    width: 100%;
  }

  .form-item {
    margin-bottom: 10px;
  }

  .form-item-nested {
    .form-item {
      margin-bottom: 0;
    }
  }
`;

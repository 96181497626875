import { FilterInputProps } from '../../../types';

import '@ant-design/compatible/assets/index.css';

import { useFiltersContext } from '../../../hooks';
import React, { useCallback, useEffect } from 'react';

import { PickerRanges } from './utils';

import { DateSelect } from './date-select.component';

export type DateSelectProps = FilterInputProps<[Date, Date]> & {
  endInputPlaceholder?: string;
  defaultOpen?: boolean;
  onChange?: (value: [Date, Date] | []) => any;
  timeSelection?: boolean;
  separator?: string;
  disabled?: boolean;
  tooltipText?: string;
  pickerRanges?: PickerRanges;
};

export const DateSelectWrapper = ({
  name,
  initialValue,
  ...props
}: DateSelectProps) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } =
    useFiltersContext();

  const decorator = getFieldDecorator(name, {
    preserve: true,
  });

  const value = (getFieldValue(name) ?? []) as [Date, Date];

  const resetValue = useCallback(() => {
    setFieldsValue({
      [name]: [],
    });
  }, [name, setFieldsValue]);

  const handleCalendarChange = useCallback(
    (dates: any) => {
      if (dates[1] === null) {
        const [date] = dates;
        setFieldsValue({
          [name]: [date, date],
        });
      }
    },
    [name, setFieldsValue]
  );

  useEffect(() => {
    if (initialValue) {
      setFieldsValue({
        [name]: initialValue,
      });
    }
  }, [initialValue, setFieldsValue, name]);

  return (
    <>
      <DateSelect
        name={name}
        value={value}
        resetValue={resetValue}
        handleCalendarChange={handleCalendarChange}
        decorator={decorator}
        {...props}
      />
    </>
  );
};

import React, { ReactNode } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { DrawerContentContainer } from './drawer-content.styles';

type DrawerFooterProps = {
  children: ReactNode;
  onSave: () => void;
  onCancel: () => void;
  className?: string;
  loading?: boolean;
  hasErrors?: boolean;
  bottomBarContent?: ReactNode;
  submitDisabled?: boolean;
};

export const DrawerContent = ({
  children,
  onCancel,
  onSave,
  className,
  loading,
  bottomBarContent,
  hasErrors,
  submitDisabled,
}: DrawerFooterProps) => {
  return (
    <DrawerContentContainer className={className}>
      <div className="content">{children}</div>
      <div className="bottom-bar">
        {bottomBarContent}
        <div className="drawer-buttons">
          <Button
            disabled={loading || hasErrors}
            icon={<CloseOutlined />}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={hasErrors || submitDisabled}
            type="primary"
            icon={<CheckOutlined />}
            onClick={onSave}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>
    </DrawerContentContainer>
  );
};

import styled from 'styled-components';

export const SpinContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & .ant-spin-text {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;
  }
`;

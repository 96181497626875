import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import { routes } from '../../../route-urls';
import { BrandedContainerDrawer } from '../drawer/drawer.component';

const DrawerWrapper = ({ onClose }: { onClose?: () => void }) => {
  const { id } = useParams() as { id: string };

  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(routes.brandedContainers());
  }, [navigate]);

  return <BrandedContainerDrawer id={id} onClose={onClose || handleClose} />;
};

export default DrawerWrapper;

import isDate from 'date-fns/isDate';

export const formatValueToCompare = (value: any) => {
  if (isDate(value)) {
    return (value as Date).toISOString();
  }

  if (Array.isArray(value) && !value.length) {
    return '';
  }

  return value ?? '';
};

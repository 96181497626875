import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import { BrandedContainerComment } from '../../../resolver.types';
import { useParams } from 'react-router';
import { AppContext, AppStoreApi } from '../../../context/app.context';
import { SpinLoader } from '../../common/spin-loader/spin-loader.component';
import { animateScroll } from 'react-scroll';
import { CommentsContainer } from '../comments-container/comments-container.component';
import {
  CommentsStepContainer,
  MessageContainer,
} from './comments-step.styles';
import { useAddBrandedContainerComment } from '../hooks/add-branded-container-comment.hook';
import { VersionMismatchError } from 'yggdrasil-shared/domain/error';
import type { ApolloError } from '@apollo/client';

type CommentsStepProps = {
  version: number;
  brandedContainerBrand: string;
  comments: BrandedContainerComment[];
  setVersionMismatchError?: (error: VersionMismatchError | null) => any;
  isNestedDrawer?: boolean;
};

export const CommentsStep = ({
  version,
  comments,
  brandedContainerBrand,
  setVersionMismatchError,
  isNestedDrawer = false,
}: CommentsStepProps) => {
  const { id } = useParams() as { id: string };

  const {
    state: { issuer, selectedBrand },
  } = React.useContext(AppContext) as AppStoreApi;

  const [content, setContent] = React.useState<string>('');
  const [sendingMessage, setSendingMessage] = React.useState(false);

  const afterAddComment = () => {
    setContent('');
    animateScroll.scrollToBottom({
      containerId: 'comments-container',
    });
    setSendingMessage(false);
  };

  const { addComment } = useAddBrandedContainerComment(id, afterAddComment);

  const onSend = () => {
    setSendingMessage(true);
    addComment({
      content,
      issuer,
      version,
    }).catch((e) => {
      const apolloError = e as ApolloError;
      const [error] = apolloError.graphQLErrors;
      if (error.name === 'VersionMismatchError' && setVersionMismatchError) {
        message.destroy();
        setVersionMismatchError(error as unknown as VersionMismatchError);
      } else {
        message.error({
          key: 'add-comment',
          duration: 3,
          content: error.message,
        });

        if (setVersionMismatchError) {
          setVersionMismatchError(null);
        }
      }
    });
  };

  React.useEffect(() => {
    animateScroll.scrollToBottom({
      containerId: 'comments-container',
      duration: 0,
    });
  });

  const onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(value);
  };

  return (
    <CommentsStepContainer>
      {comments ? (
        <CommentsContainer comments={comments} />
      ) : (
        <SpinLoader loadingMessage="Fetching comments..." />
      )}
      {!isNestedDrawer && selectedBrand?.name === brandedContainerBrand && (
        <MessageContainer>
          <Input.TextArea
            disabled={sendingMessage}
            placeholder="Enter your comment message..."
            onChange={onChange}
            value={content}
          />
          <Button
            loading={sendingMessage}
            icon={<CheckOutlined />}
            onClick={onSend}
            disabled={sendingMessage}
          >
            {sendingMessage ? 'Sending...' : 'Send'}
          </Button>
        </MessageContainer>
      )}
    </CommentsStepContainer>
  );
};

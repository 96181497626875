import { Venue } from '../resolver.types';

export const getName = (venue: Venue) => {
  const { name, street, city, sourceVenueId } = venue;

  const meta = [street, city].filter(Boolean);

  let display = `${name}`;

  if (meta.length) {
    display += ` ${meta[0]}${meta[1] ? `, ${meta[1]}` : ''}`;
  } else {
    display += ` (ID: ${sourceVenueId})`;
  }

  return display;
};

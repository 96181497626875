import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const FiltersWrapper = styled.div`
  background-color: #ffffff;
  position: relative;
`;

export const FiltersButtonsContainer = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #fafafa;
`;

export const FiltersButtons = styled.div`
  button {
    width: calc(50% - 32px);
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const FiltersContainer = styled.div.attrs(
  ({ $fullWidth }: { $fullWidth: boolean }) => ({
    $fullWidth,
  })
)`
  display: flex;
  padding: ${(props) => (props.$fullWidth ? '10px' : '20px')};

  .ant-form-item-label {
    label {
      font-size: 11px;
      font-weight: normal;
    }
  }

  .ant-legacy-form-item-label {
    label {
      font-size: 11px;
      font-weight: normal;
    }
  }

  .value-check {
    position: absolute;
    z-index: 2;
    right: 33px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: inline-flex;
    top: 0;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }

  .filters-loader {
    position: absolute;
    top: 0;
    z-index: 4;
    background: white;
    left: 0;
  }
`;

export const FiltersForm = styled(Form).attrs(
  ({ $fullWidth }: { $fullWidth: boolean }) => ({
    $fullWidth,
  })
)`
  width: 100%;

  .filters-row {
    @media (min-width: 1500px) {
      width: ${(props) => (props.$fullWidth ? '100%' : '90%')};
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  span.ant-select-arrow {
    font-size: 16px;
  }

  .has-icon-separator div.ant-select-selection__rendered {
    border-right: 2px solid #ebebeb;
    margin-right: 34px;
  }
`;

import * as React from 'react';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { TableProps } from 'antd/es/table';
import { resolvePlaceholders } from '../../../utils/placeholders';
import Filters, { FilterProps } from '../../filters/filters.component';
import {
  AvailablePageSizes,
  Column,
  DataListQueryResult,
  Entity,
  PaginationType,
  QueryParams,
} from './types';
import { Order, OrderDirection } from '../../../resolver.types';
import { ColumnProps } from 'antd/lib/table';
import { FilterValues } from '../../filters/types';
import {
  DataListWrapper,
  SelectionButtonsCol,
  SelectionRow,
} from './data-list.styles';
import { Button, Col, Modal, Row, Table } from 'antd';
import { useDataListContext } from './data-list.context';
import useSelection, { RowSelectionAction } from './hooks/use-selection';
import { noop } from '../../../utils/text';
import parseFilters from './parse-filters';
import type { WatchQueryFetchPolicy } from '@apollo/client';
import usePagination, { defaultPageSizes } from './hooks/use-pagination';
import { HitDataRecord } from 'yggdrasil-shared/database/elastic-views';
import usePrevious from '../../../hooks/use-previous';
import isEqual from 'lodash.isequal';
import { SorterResult } from 'antd/es/table/interface';
import { InputError } from 'yggdrasil-shared/domain/error';

const esLimit = 10000;

export type CustomLocale = {
  [key: string]: string | ReactElement;
};

export type DataListProps<T> = {
  columns: Array<Column<T>>;
  query: any;
  filters?: ReactElement | ReactElement[];
  availablePageSizes?: AvailablePageSizes;
  onRowClick?: (record: T, index?: number) => void;
  filterProps?: Pick<
    FilterProps,
    | 'displayFiltersCount'
    | 'defaultFilters'
    | 'generateInGridButtons'
    | 'fullWidth'
  >;
  dataType: string;
  queryVars?: any;
  parseQueryVarsBeforeQuery?: (
    queryVars: QueryParams<FilterValues>
  ) => QueryParams<FilterValues>;
  onQueryUpdate?: (data: any) => any;
  onTableChange?: TableProps<T>['onChange'];
  onOrderChange?: (order?: Order) => any;
  onFiltersChange?: (values?: FilterValues) => any;
  customOrder?: Order | undefined;
  useRowSelection?: boolean;
  rowSelectionActions?: Array<RowSelectionAction<T>>;
  rowSelectionColPush?: number;
  selectionLabelProvider?: (count: number) => string | ReactNode;
  rowSelectionPosition?: 'fixed' | 'static' | 'sticky';
  fetchPolicy?: WatchQueryFetchPolicy;
  paginationType?: PaginationType;
  skipPaginationLimit?: number;
  dataWhichUpdateQueryParamsWhenChanged?: any;
  onlyFuture?: boolean;
  customLocale?: CustomLocale;
};

export function DataList<T extends Entity = any>({
  query,
  columns,
  availablePageSizes = defaultPageSizes,
  onRowClick,
  filters,
  filterProps,
  onQueryUpdate,
  onTableChange,
  onOrderChange,
  onFiltersChange,
  customOrder,
  dataType,
  queryVars = {},
  useRowSelection,
  rowSelectionActions = [],
  selectionLabelProvider,
  rowSelectionColPush = 9,
  rowSelectionPosition = 'fixed',
  fetchPolicy = 'network-only',
  paginationType,
  skipPaginationLimit = esLimit,
  parseQueryVarsBeforeQuery,
  dataWhichUpdateQueryParamsWhenChanged,
  onlyFuture,
  customLocale,
}: DataListProps<T>) {
  const { dispatch } = useDataListContext();

  const [filtersData, setFiltersData] = useState<FilterValues | undefined>();
  const [order, setOrder] = useState<Order | undefined>(customOrder);
  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState<T[]>([]);
  const [hitData, setHitData] = useState<HitDataRecord>({});
  const [queryParams, setQueryParams] = useState<QueryParams<FilterValues>>({
    pagination: {
      start: 0,
      limit: defaultPageSizes[0],
    },
    order,
    filters: filtersData,
    ...queryVars,
    onlyFuture,
  });

  const changeQueryParams = useCallback(
    (queryParams: QueryParams<FilterValues>) => {
      let parsedQueryParams = queryParams;

      if (parseQueryVarsBeforeQuery) {
        parsedQueryParams = parseQueryVarsBeforeQuery(parsedQueryParams);
      }

      setQueryParams(parsedQueryParams);
    },
    [parseQueryVarsBeforeQuery]
  );

  const previousQueryParams = usePrevious(queryParams);
  const previousQueryVars = usePrevious(queryVars);
  const previousDataWhichUpdateQueryParamsWhenChanged = usePrevious(
    dataWhichUpdateQueryParamsWhenChanged
  );

  const { pagination, parsedPagination, handlePaginationChange } =
    usePagination<T>({
      availablePageSizes,
      totalCount,
      paginationType:
        paginationType ?? totalCount >= skipPaginationLimit
          ? 'searchAfter'
          : 'skip',
      data: items,
      hitData,
      order,
      onGoToFirstPage(pagination) {
        const { pagination: newPagination } = handlePaginationChange({
          ...pagination,
          current: 1,
        });

        const newQueryParams = {
          ...queryParams,
          pagination: newPagination,
          order,
          filters: filtersData,
          onlyFuture,
        };

        changeQueryParams(newQueryParams);
      },
    });

  const { rowSelection, selectedItems, resetSelection, selectedRowsCount } =
    useSelection<T>();

  const handleChange: TableProps<T>['onChange'] = (
    pagination,
    filters,
    sorterResult,
    extra
  ) => {
    let order: Order | undefined;

    /**
     * @note Single sorter mode is used here. Look to multiple sorter in antd v4 docs to find out other scenarios
     */

    const sorter = sorterResult as SorterResult<T>;

    if (sorter && sorter.order) {
      order = {
        column: sorter.field!.toString(),
        direction:
          sorter.order === 'ascend' ? OrderDirection.Asc : OrderDirection.Desc,
      };
    }

    const { pagination: newPagination } = handlePaginationChange(
      pagination,
      order
    );

    let newQueryParams = {
      ...queryParams,
      pagination: newPagination,
      order,
      filters: filtersData,
      onlyFuture,
      ...queryVars,
    };

    changeQueryParams(newQueryParams);
    setOrder(order);

    if (onTableChange) {
      onTableChange(pagination, filters, sorter, extra);
    }
  };

  const { data, loading, error } = useQuery<DataListQueryResult<T>>(query, {
    variables: queryParams,
    fetchPolicy,
  });

  const result = useMemo(() => {
    if (!data) {
      return null;
    }

    const dataArr = Object.values(data);

    if (!dataArr.length) {
      return null;
    }

    return dataArr[0];
  }, [data]);

  const handleFiltersSubmit = useCallback(
    async (filters: FilterValues) => {
      const parsedFilters = parseFilters(filters);

      changeQueryParams({
        ...queryParams,
        pagination: {
          ...queryParams.pagination,
          start: 0,
          searchAfter: null,
        },
        filters: parsedFilters,
      });

      handlePaginationChange({
        ...pagination,
        current: 1,
      });

      setFiltersData(parsedFilters);
    },
    [queryParams, handlePaginationChange, pagination, changeQueryParams]
  );

  const handleReset = useCallback(
    async (value: FilterValues) => {
      changeQueryParams({
        ...queryParams,
        pagination: {
          ...queryParams.pagination,
          start: 0,
          searchAfter: null,
        },
        filters: value,
      });

      handlePaginationChange({
        ...pagination,
        current: 1,
      });

      setFiltersData(value);
    },
    [handlePaginationChange, pagination, queryParams, changeQueryParams]
  );

  const onRow = (record: T, index?: number) => ({
    onClick: () => {
      if (onRowClick) {
        onRowClick(record, index);
      }
    },
  });

  const formattedColumns: Array<ColumnProps<T>> = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        dataIndex: column.dataIndex as string,
        title: resolvePlaceholders(column.title, {
          count: totalCount.toString(),
        }),
        sorter: column.sortable,
        sortOrder:
          column.sortable && order && order.column === column.dataIndex
            ? order.direction === OrderDirection.Asc
              ? 'ascend'
              : 'descend'
            : undefined,
      })),
    [columns, totalCount, order]
  );

  useEffect(() => {
    if (onQueryUpdate) {
      onQueryUpdate(data);
    }
  }, [data, onQueryUpdate]);

  useEffect(() => {
    if (onOrderChange) {
      onOrderChange(order);
    }
  }, [order, onOrderChange]);

  useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange(filtersData);
    }
  }, [filtersData, onFiltersChange]);

  useEffect(() => {
    dispatch({
      type: 'SET_LIST_VARIABLES',
      dataType,
      variables: queryParams,
    });
  }, [queryParams, dispatch, dataType, previousQueryParams]);

  useEffect(() => {
    if (error) {
      Modal.error({
        title: 'Data List error',
        content: (
          <>
            <p style={{ whiteSpace: 'pre-line' }}>{error.message}</p>
            {'details' in error && (
              <ul>
                {Object.values((error as InputError).details || []).map(
                  ({ message, context: { key } }: any) => (
                    <li key={key}>{message}</li>
                  )
                )}
              </ul>
            )}
          </>
        ),
      });
    }
  }, [error]);

  useEffect(() => {
    if (result?.metadata) {
      setTotalCount(result.metadata.total);
      setHitData(result.metadata.hitData ?? {});
    }

    setItems(result?.items ?? []);
  }, [result]);

  const setNewQueryParams = useCallback(
    (prev: QueryParams<FilterValues>) => {
      let newParams = {
        ...prev,
        ...queryVars,
      };

      if (parseQueryVarsBeforeQuery) {
        newParams = parseQueryVarsBeforeQuery(newParams);
      }

      return newParams;
    },
    [parseQueryVarsBeforeQuery, queryVars]
  );

  useEffect(() => {
    if (isEqual(previousQueryVars, queryVars)) {
      return;
    }

    setQueryParams(setNewQueryParams);
  }, [queryVars, previousQueryVars, setNewQueryParams]);

  useEffect(() => {
    if (
      previousDataWhichUpdateQueryParamsWhenChanged &&
      !isEqual(
        previousDataWhichUpdateQueryParamsWhenChanged,
        dataWhichUpdateQueryParamsWhenChanged
      )
    ) {
      setQueryParams(setNewQueryParams);
    }
  }, [
    dataWhichUpdateQueryParamsWhenChanged,
    previousDataWhichUpdateQueryParamsWhenChanged,
    setNewQueryParams,
  ]);

  return (
    <DataListWrapper>
      {filters && (
        <Filters
          onReset={handleReset}
          onSubmit={handleFiltersSubmit}
          {...filterProps}
        >
          {filters}
        </Filters>
      )}
      <Table<T>
        style={{
          paddingBottom: useRowSelection && selectedRowsCount > 0 ? '60px' : 0,
        }}
        locale={customLocale}
        rowSelection={useRowSelection ? rowSelection : undefined}
        rowKey="id"
        dataSource={items}
        pagination={parsedPagination}
        columns={formattedColumns}
        loading={loading}
        onChange={handleChange}
        onRow={onRow}
      />
      {useRowSelection && selectedRowsCount > 0 && (
        <SelectionRow style={{ position: rowSelectionPosition }} align="middle">
          <Col className="selected-rows-count">
            {selectionLabelProvider ? (
              selectionLabelProvider(selectedRowsCount)
            ) : (
              <span>
                <strong>{selectedRowsCount}</strong>
                {noop(selectedRowsCount, ' item selected', ' items selected')}
              </span>
            )}
          </Col>
          {rowSelectionActions.length && (
            <SelectionButtonsCol
              push={rowSelectionColPush}
              style={{ marginLeft: '30px', left: '10%' }}
            >
              <Row>
                {rowSelectionActions.map((action, index) => (
                  <Col key={index}>
                    <Button
                      loading={action.loading}
                      className="selection-action-btn"
                      icon={action.icon}
                      onClick={async () => {
                        await action.onClick(
                          selectedItems,
                          rowSelection.selectedRowKeys as string[]
                        );

                        if (action.resetSelection) {
                          resetSelection();
                        }
                      }}
                      type={action.type}
                    >
                      {action.label}
                    </Button>
                  </Col>
                ))}
              </Row>
            </SelectionButtonsCol>
          )}
        </SelectionRow>
      )}
    </DataListWrapper>
  );
}

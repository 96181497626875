import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DrawerContainer,
  DrawerNotice,
  StyledDrawer,
} from '../../layout/drawer/drawer.styles';
import { useQuery } from '@apollo/react-hooks';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Spin } from 'antd';
import { routes } from '../../../route-urls';
import { DrawerTopbar } from '../drawer-topbar/drawer-topbar.component';
import styled from 'styled-components';
import UpdateFormManager from '../update-form-manager/update-form-manager';
import { DrawerForm } from '../drawer-form/drawer-form.component';
import {
  GetArtistDocument,
  GetArtistQueryResult,
  GetArtistQueryVariables,
} from '../../../resolver.types';

export type DrawerProps = {};

export const Drawer = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };

  const [isDirty, setIsDirty] = useState(false);

  const { loading, data } = useQuery<
    GetArtistQueryResult['data'],
    GetArtistQueryVariables
  >(GetArtistDocument, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const onClose = useCallback(() => {
    navigate(routes.artists());
  }, [navigate]);

  const handleClose = useCallback(() => {
    if (!isDirty) {
      onClose();

      return;
    }

    Modal.confirm({
      className: 'modal-no-title',
      title: '',
      content: 'You have unsaved changes, are you sure you want to continue?',
      cancelText: 'Continue without saving',
      okText: 'Keep editing',
      onCancel: onClose,
    });
  }, [onClose, isDirty]);

  return (
    <ArtistDrawer
      visible
      width={500}
      bodyStyle={{ padding: 0, height: '100%', overflow: 'hidden' }}
      onClose={handleClose}
    >
      {data?.artist && <DrawerTopbar artist={data.artist} />}
      <StyledDrawerContainer>
        {data?.artist && (
          <>
            {/*
            // @ts-ignore */}
            <UpdateFormManager artist={data.artist} after>
              <DrawerForm
                onDirtyChange={setIsDirty}
                onCancel={onClose}
                artist={data.artist}
              />
            </UpdateFormManager>
          </>
        )}
        {(loading || !data) && (
          <DrawerNotice>
            {loading && <Spin tip="Loading artist..." size="large" />}
            {!data && !loading && (
              <Empty
                description={
                  <span>
                    No artist with ID <strong>{id}</strong> found.
                  </span>
                }
                image={
                  <WarningOutlined
                    style={{ fontSize: '60px' }}
                    className="notice-icon"
                  />
                }
              >
                <Button onClick={onClose} icon={<CloseOutlined />}>
                  Close
                </Button>
              </Empty>
            )}
          </DrawerNotice>
        )}
      </StyledDrawerContainer>
    </ArtistDrawer>
  );
};

const StyledDrawerContainer = styled(DrawerContainer)`
  height: calc(100% - 77px);
`;

const ArtistDrawer = styled(StyledDrawer)`
  .ant-drawer-close {
    top: 5px;
  }
`;

export type Raw64BasetoBlobProps = {
  raw64BaseData: string;
  contentType: string;
  outputName: string;
  sliceSize?: number;
};

const atob = (str: string) => {
  return Buffer.from(str, 'base64').toString('binary');
};

export const raw64BasetoBlob = ({
  raw64BaseData,
  contentType,
  outputName,
  sliceSize = 512,
}: Raw64BasetoBlobProps) => {
  const byteCharacters = atob(raw64BaseData);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  // @ts-ignore - blob does not recognize name, but uploaded file do
  blob.name = outputName;
  return blob;
};

import styled from 'styled-components';

export const LastModifiedStepContainer = styled.div`
  & .activity-log {
    margin-top: 15px;
  }

  & .activity-log:first-child {
    margin-top: 0;
  }

  & > .last-modified-title {
    margin-bottom: 25px;
  }

  height: 100%;
  overflow: hidden;
`;

export const LastModifiedTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #6d6d6d;
  border-bottom: 2px solid #e4a140;
  display: inline-block;
`;

export const ActivityLogList = styled.div`
  height: calc(100% - 50px);
  overflow-y: scroll;
`;

export const NoActivityInformation = styled.div`
  font-size: 14px;
`;

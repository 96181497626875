import { useCallback, useState } from 'react';

export type PaginationHookConfig<T = any> = {
  items: T[];
  perPage: number;
  defaultPage?: number;
};

export type PaginationHook<T = any> = {
  prevPage: () => any;
  nextPage: () => any;
  setPage: (page: number) => any;
  maxPages: number;
  page: number;
  pagedItems: T[];
  isDisabled: boolean;
};

export const usePagination = <T = any>({
  items,
  perPage,
  defaultPage = 1,
}: PaginationHookConfig<T>): PaginationHook<T> => {
  const [page, setPage] = useState(defaultPage);
  const maxPages = Math.ceil(items.length / perPage);
  const offset = page > 1 ? perPage * (page - 1) : 0;
  const pagedItems = items.slice(offset, offset + perPage);
  const isDisabled = items.length <= perPage;

  const prevPage = useCallback(() => {
    if (page < 1) {
      return;
    }

    setPage(page - 1);
  }, [page]);

  const nextPage = useCallback(() => {
    if (page > maxPages) {
      return;
    }

    setPage(page + 1);
  }, [maxPages, page]);

  return {
    prevPage,
    nextPage,
    maxPages,
    setPage,
    page,
    pagedItems,
    isDisabled,
  };
};

import {
  BrandedContainer,
  BrandedContainerImageAspectRatio,
} from '../../../resolver.types';

export const emptyFields = [
  {
    label: 'description',
    check: (bcData: BrandedContainer) => Boolean(!bcData.description),
  },
  {
    label: '1 x 1 image',
    check: (bcData: BrandedContainer) =>
      Boolean(
        !bcData.images?.some(
          (image: any) =>
            image.aspectRatio === BrandedContainerImageAspectRatio.A1x1
        )
      ),
  },
  {
    label: 'press text and source',
    check: (bcData: BrandedContainer) => Boolean(!bcData.pressText),
  },
  {
    label: 'short description',
    check: (bcData: BrandedContainer) => Boolean(!bcData.shortDescription),
  },
];

export const validationErrorFields = [
  {
    label: 'title',
    check: (bcData: BrandedContainer) => Boolean(!bcData.title),
  },
  {
    label: 'press text source',
    check: (bcData: BrandedContainer) =>
      Boolean(bcData.pressText && !bcData.pressTextSource),
  },
];

export const validateBcData = (bcData: BrandedContainer) => {
  const { emptyWarningFields, emptyValidationErrorFields } = [
    ...emptyFields,
    ...validationErrorFields,
  ].reduce<{
    emptyWarningFields: string[];
    emptyValidationErrorFields: string[];
  }>(
    (acc, field) => {
      if (field.check(bcData)) {
        if (emptyFields.includes(field)) {
          acc.emptyWarningFields.push(field.label);
        } else {
          acc.emptyValidationErrorFields.push(field.label);
        }
      }

      return acc;
    },
    { emptyWarningFields: [], emptyValidationErrorFields: [] }
  );

  return { emptyWarningFields, emptyValidationErrorFields };
};

import { FilterInputProps } from '../../../types';
import { useFiltersContext } from '../../../hooks';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { ValidationRule } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { ResetButton } from '../../../common/reset-button/reset-button.component';
import React, { useCallback, useEffect } from 'react';

export type TextFieldProps = FilterInputProps<string> & {
  rules?: ValidationRule[];
  onChange?: (value: string) => any;
};

const TextField = ({
  name,
  placeholder,
  initialValue = '',
  rules,
  width,
  onChange,
  label,
}: TextFieldProps) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } =
    useFiltersContext();
  const decorator = getFieldDecorator(name, {
    rules,
    initialValue,
  });

  const value = getFieldValue(name) as string;

  const resetValue = useCallback(() => {
    setFieldsValue({
      [name]: initialValue,
    });
  }, [setFieldsValue, name, initialValue]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <>
      {/*
      // @ts-ignore */}
      <Form.Item
        label={label}
        colon={false}
        style={{ width, minWidth: '100px' }}
        className="compatible-row"
      >
        {Boolean(value) && <ResetButton onClick={resetValue} />}
        {decorator(
          <Input
            placeholder={placeholder}
            suffix={
              <SearchOutlined
                className="input-icon"
                style={{ fontSize: '16px' }}
              />
            }
          />
        )}
      </Form.Item>
    </>
  );
};

export default TextField;

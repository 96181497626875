import { DistinguishError as Error } from 'yggdrasil-shared/domain/error';
import React, { useMemo } from 'react';
import { Artist } from '../../../resolver.types';
import { camelCaseToSentence } from '../../../utils/text';
import { Link } from 'react-router-dom';
import { routes } from '../../../route-urls';

export type ErrorFieldsMap = {
  [Key in keyof Artist]?: string;
};

export const fieldsMap: ErrorFieldsMap = {
  dateOfBirth: 'Birthday',
  countryOfOrigin: 'Country',
  genres: 'Genres',
  source: 'URL',
};

export type DistinguishErrorProps = {
  error: Error<Artist>;
  ignoredFields?: Array<keyof Artist>;
};

const DistinguishError = ({
  error,
  ignoredFields = [],
}: DistinguishErrorProps) => {
  const fields = useMemo(() => {
    return error.fields.filter((field) => !ignoredFields.includes(field));
  }, [error, ignoredFields]);

  return (
    <div style={{ paddingRight: '5px' }}>
      <div>
        An{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={routes.setArtistData(error.similarEntity.id)}
        >
          artist
        </Link>{' '}
        with the exact same details already exists. Please fill or change at
        least one of the following fields in order to make them distinguishable:
      </div>
      <ul style={{ paddingLeft: '20px' }} className="fields-list">
        {fields.map((field) => (
          <li key={field}>{fieldsMap[field] ?? camelCaseToSentence(field)}</li>
        ))}
      </ul>
    </div>
  );
};

export default DistinguishError;

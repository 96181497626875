export type EventNavigationItem = {
  icon: string;
  label: string;
  link: string;
  total?: number;
};

const eventNavigationData: EventNavigationItem[] = [
  {
    icon: 'fa-align-left',
    label: 'Description',
    link: 'description',
  },
  {
    icon: 'fa-address-card',
    label: 'Information',
    link: 'information',
  },
  {
    icon: 'fa-copyright',
    label: 'Branded Containers',
    link: 'branded-containers',
  },
];

export default eventNavigationData;

import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PromotionItemsContainer } from '../promotion-items-container/promotion-items-container.component';
import { promoteItems } from '../../../static/promotion-items-data';
import { PromotionItemCard } from '../promotion-item/promotion-item.component';
import {
  EventDetails,
  ModalCtaButton,
  ModalHeader,
  StyledModal,
} from '../../layout/modal/modal.styles';
import { Event } from '../../../resolver.types';
import { ArrowRightOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { noop } from '../../../utils/text';
import { EventTitle } from '../../event/event-title/event-title.component';

type SelectPromotionItemsModalProps = {
  onNext: (selectedPromotionItems: string[]) => void;
};

export const SelectPromotionItemsModal = ({
  onNext,
}: SelectPromotionItemsModalProps) => {
  const navigate = useNavigate();
  const { state } = useLocation() as {
    state: { events?: Event[] } | undefined;
  };

  const [checkedPromotionItems, setCheckedPromotionItems] = React.useState<
    string[]
  >([]);

  const onCancel = () => {
    navigate(-1);
  };

  const onClick = () => {
    onNext(checkedPromotionItems);
  };

  const onTogglePromotionItem = ({
    target: { name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setCheckedPromotionItems([...checkedPromotionItems, name]);
    } else {
      setCheckedPromotionItems(
        checkedPromotionItems.filter((promotionItem) => promotionItem !== name)
      );
    }
  };

  const promotionItems: PromotionItemCard[] = promoteItems.map(
    (promoteItem) => ({
      imageUrl: promoteItem.imgUrl,
      name: promoteItem.name,
      key: promoteItem.key,
      cardTitle: promoteItem.cardTitle,
      disabled: promoteItem.disabled,
    })
  );

  return (
    <StyledModal
      visible
      maskClosable={false}
      onCancel={onCancel}
      title={
        <ModalHeader>
          <span className="modal-title">What do you want to promote?</span>
          <span className="modal-subtitle">You can choose more than one.</span>
        </ModalHeader>
      }
      footer={
        <div>
          <ModalCtaButton
            onClick={onClick}
            disabled={checkedPromotionItems.length === 0}
            type="primary"
          >
            <ArrowRightOutlined />
            Next
            {checkedPromotionItems.length > 0 &&
              ` (${checkedPromotionItems.length}/${promotionItems.length})`}
          </ModalCtaButton>
        </div>
      }
    >
      <PromotionItemsContainer
        promotionItems={promotionItems}
        onTogglePromotionItem={onTogglePromotionItem}
      />
      {state?.events && state.events.length > 0 && (
        <EventDetails>
          Creating topic for{' '}
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxHeight: '300px',
                  overflow: 'auto',
                }}
              >
                {state.events.map((event, index) => (
                  <Menu.Item key={index}>
                    <EventTitle
                      title={event.title!}
                      organizer={event.organizer}
                      imageUrl={event.imageUrl!}
                      startDateTime={event.startDateTime}
                      showMeta={false}
                      genre={event.genres[0].name}
                    />
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button type="link" style={{ padding: 0 }}>
              {noop(
                state.events.length,
                `${state.events.length} event`,
                `${state.events.length} events`
              )}
              <DownOutlined style={{ marginLeft: '4px' }} />
            </Button>
          </Dropdown>
        </EventDetails>
      )}
    </StyledModal>
  );
};

import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface LabelWithTooltipProps {
  label: string;
  tooltipMessage?: string;
}

export const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({
  label,
  tooltipMessage,
}) => (
  <>
    <span>{label}: </span>
    <Tooltip
      title={<span style={{ whiteSpace: 'nowrap' }}>{tooltipMessage}</span>}
      overlayStyle={{ maxWidth: 'unset' }}
    >
      <InfoCircleOutlined style={{ color: 'grey' }} /> <span></span>
    </Tooltip>
  </>
);

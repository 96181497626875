import React, { useMemo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  NavigationContainer,
  NavigationItem,
} from './drawer-navigation.styles';
import { swapRoute } from '../../../utils/swap-route';
import {
  AdvancedActiveMatcher,
  advancedActiveMatcher,
} from '../../../utils/active-route-matcher';

export type DrawerNavigationItem = {
  icon?: string;
  label: string;
  link: string;
  total?: number;
};

export type DrawerNavigationProps<
  NavItem extends DrawerNavigationItem = DrawerNavigationItem
> = {
  items: NavItem[];
  mapNavItem?: (item: NavItem) => NavItem;
  title?: string;
};

const DrawerNavigation = <
  NavItem extends DrawerNavigationItem = DrawerNavigationItem
>({
  items,
  mapNavItem,
  title = 'NAVIGATION',
}: DrawerNavigationProps<NavItem>) => {
  const location = useLocation();
  const currentLocation = useMemo(
    () => location.pathname.split('/').pop(),
    [location]
  );

  const navigationData = useMemo(
    () =>
      items.map((menuItem) => {
        let item = { ...menuItem };

        if (mapNavItem) {
          item = mapNavItem(item);
        }

        return item;
      }),
    [items, mapNavItem]
  );

  const isActive = useCallback(
    (link: string) => {
      const location = currentLocation as
        | keyof AdvancedActiveMatcher
        | undefined;

      if (location && advancedActiveMatcher[location]) {
        return link === advancedActiveMatcher[location];
      }

      return link === currentLocation;
    },
    [currentLocation]
  );

  return (
    <NavigationContainer>
      <div className="navigation-title">{title}</div>
      <ul className="navigation-list">
        {navigationData.map(({ icon, label, link, total }) => {
          return (
            <NavigationItem key={link} active={isActive(link)}>
              <Link
                to={{
                  pathname: swapRoute(location.pathname, link),
                }}
              >
                <span className="menu-item-icon">
                  <i className={`fal ${icon}`} />
                </span>
                <span className="text">{label}</span>
                {typeof total === 'number' ? (
                  <>
                    {' '}
                    <span className="total">({total})</span>
                  </>
                ) : (
                  ''
                )}
              </Link>
            </NavigationItem>
          );
        })}
      </ul>
    </NavigationContainer>
  );
};

export default DrawerNavigation;

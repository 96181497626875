import styled from 'styled-components';

export const Tag = styled.div.attrs(
  ({ color, disabled }: { color: string; disabled?: boolean }) => ({
    color,
    disabled,
  })
)`
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  width: 27px;
  text-align: center;
  font-size: 10px;
  border-radius: 3px;
  background-color: ${(props) => props.color};
  color: #ffffff;
  user-select: none;
  font-weight: 500;
`;

import React from 'react';
import {
  Dropdown as EntityDropdown,
  DropdownProps as EntityDropdownProps,
} from '../../common/generic-dropdown/dropdown.component';
import {
  Artist,
  ArtistsByIdDocument,
  ArtistsByIdQuery,
  SearchArtistsDocument,
  SearchArtistsQuery,
} from '../../../resolver.types';
import { getName } from '../../../utils/artist';
import FormModal from '../form-modal/form-modal.component';
import CreateFormManager from '../create-form-manager/create-form-manager.component';
import { CreateArtistInput } from '../../../graphql/mutations/create-artist';

type ArtistType = Pick<Artist, 'id' | 'name'>;
type ArtistsByIdQueryType = Omit<ArtistsByIdQuery, '__typename'>;
type SearchArtistsQueryType = Omit<SearchArtistsQuery, '__typename'>;
type ArtistDropdownProps = Omit<
  EntityDropdownProps<ArtistType, ArtistsByIdQueryType, SearchArtistsQueryType>,
  'entityProps'
> & { defaultArtistValues?: Partial<CreateArtistInput> };

export const ArtistDropdown = (props: ArtistDropdownProps) => {
  return (
    <EntityDropdown<ArtistType, ArtistsByIdQueryType, SearchArtistsQueryType>
      {...props}
      entityProps={{
        displayName: 'artists',
        idKey: 'id',
        nameKey: 'name',
        entitiesByIdsQueryKey: 'artistsByID',
        entitiesSearchQueryKey: 'searchArtists',
        entitiesByIdsDocument: ArtistsByIdDocument,
        entitiesSearchDocument: SearchArtistsDocument,
        nameGetter: (artist) => getName(artist as Artist),
      }}
      setupCreateFormManager={(onArtistCreated, toggleAritstForm) => {
        return (
          <>
            {/*
          // @ts-ignore */}
            <CreateFormManager
              initialValues={props.defaultArtistValues}
              afterSubmit={onArtistCreated}
            >
              <FormModal onCancel={toggleAritstForm} />
            </CreateFormManager>
          </>
        );
      }}
    />
  );
};

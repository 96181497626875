import React from 'react';
import { SearchValue as SearchValueType } from '../../../context/event-filters.context';
import { SearchValue } from '../search-value/search-value.component';
import { Container } from './search-value-container.styles';

type SearchValueContainerProps = {
  values: SearchValueType[];
  title: string;
  onRemoveValue: (value: SearchValueType) => void;
  onUpdateValue: (value: SearchValueType) => void;
};

export const SearchValueContainer = ({
  title,
  values,
  onRemoveValue,
  onUpdateValue,
}: SearchValueContainerProps) => {
  return (
    <React.Fragment>
      {values.length > 0 && (
        <Container>
          <div className="search-values__title">{title}</div>
          <div className="search-values__content">
            {values.map((searchValue, index) => (
              <SearchValue
                index={index}
                option={searchValue.option}
                value={searchValue.value}
                key={searchValue.value}
                onRemove={onRemoveValue}
                onChange={onUpdateValue}
              />
            ))}
          </div>
        </Container>
      )}
    </React.Fragment>
  );
};

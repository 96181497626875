import React from 'react';
import styled from 'styled-components';
import { ratioMap } from 'yggdrasil-shared/domain/image';
import { BrandedContainerImageAspectRatio } from '../../../../resolver.types';

type PreviewCanvasProps = {
  size: number;
  aspectRatio: BrandedContainerImageAspectRatio;
  canvasRef: React.Ref<HTMLCanvasElement>;
};

export const CanvasContainer = ({
  size,
  aspectRatio,
  canvasRef,
}: PreviewCanvasProps) => {
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setHeight(Number(size * ratioMap[aspectRatio]));
  }, [size, aspectRatio]);

  return (
    <PreviewContainer className="preview-canvas">
      <Canvas ref={canvasRef} width={size} height={height}></Canvas>
      <PreviewCanvasTitle>{`${size}x${height.toFixed(0)}`}</PreviewCanvasTitle>
    </PreviewContainer>
  );
};

export const PreviewCanvas = React.memo(CanvasContainer);

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Canvas = styled.canvas``;

const PreviewCanvasTitle = styled.div`
  background-color: #f5f5f5;
  text-align: center;
  padding: 8px 0;
  font-size: 12px;
`;

import * as React from 'react';
import { LastUpdatedDateContainer } from './last-updated-date.styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DateFormats } from '../../../utils/date-formats';

type LastUpdatedDateProps = {
  date: string;
};

export const LastUpdatedDate = ({ date }: LastUpdatedDateProps) => {
  return (
    <LastUpdatedDateContainer>
      Last change: {format(parseISO(date), DateFormats.dateTime)}
    </LastUpdatedDateContainer>
  );
};

import React, {
  ChangeEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Input } from 'antd';
import { InputRef } from 'antd/lib/input';
import useOutsideClick from '../../../hooks/use-outside-click';
import styled from 'styled-components';
import useKeypressHandler, {
  KeyPressHandlersMap,
} from '../../../hooks/use-keypress-handler';

export interface EditableTextProps {
  value?: string;
  onChange?: (value: string) => void;
}

const Container = styled.span`
  cursor: text;

  .ant-input {
    height: auto;
    padding: 0 0 0 5px;
    width: auto;
  }
`;

const EditableText = ({ onChange, value = '' }: EditableTextProps) => {
  const containerRef = useRef<HTMLSpanElement>();
  const inputRef = useRef<InputRef>();

  const [internalValue, setInternalValue] = useState(value);
  const saveValue = useCallback(() => {
    if (onChange && value !== internalValue) {
      onChange(internalValue ? internalValue : value);

      if (!internalValue) {
        setInternalValue(value);
      }
    }
  }, [internalValue, onChange, value]);
  const cancel = useCallback(() => {
    setInternalValue(value);
  }, [value]);

  const [isEdit, setIsEdit] = useState(false);
  const toggleEdit = useCallback(() => {
    setIsEdit(!isEdit);
  }, [isEdit]);
  const handleOutsideClick = useCallback(() => {
    if (!isEdit) {
      return;
    }

    setIsEdit(false);
    saveValue();
  }, [saveValue, isEdit]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setInternalValue(event.target.value);
    },
    []
  );

  const handlersMap: KeyPressHandlersMap = useMemo(
    () => ({
      Enter: () => {
        setIsEdit(false);
        saveValue();
      },
      Escape: () => {
        setIsEdit(false);
        cancel();
      },
    }),
    [saveValue, cancel]
  );
  const handleKeyUp = useKeypressHandler(handlersMap);

  useOutsideClick(containerRef, handleOutsideClick);

  useEffect(() => {
    if (inputRef.current && isEdit) {
      inputRef.current.focus();
    }
  }, [inputRef, isEdit]);

  return (
    <Container
      className="editable-text-container"
      ref={containerRef as MutableRefObject<HTMLSpanElement>}
    >
      {!isEdit && (
        <span className="editable-text-value" onClick={toggleEdit}>
          {value}
        </span>
      )}
      {isEdit && (
        <Input
          className="editable-text-input"
          onKeyDown={handleKeyUp}
          size="small"
          ref={inputRef as MutableRefObject<InputRef>}
          onChange={handleChange}
          value={internalValue}
        />
      )}
    </Container>
  );
};

export default EditableText;

import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export type ModalImageProps = {
  noGuttersBottom?: boolean;
};

export const ModalImage = styled.img<ModalImageProps>`
  margin-bottom: ${({ noGuttersBottom }) => (noGuttersBottom ? '0' : '20px')};
  width: 150px;
  height: 160px;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-row {
    margin-bottom: 14px;
  }

  .form-item {
    &,
    .ant-calendar-picker {
      width: 100%;
    }
    ,
    .ant-picker {
      width: 100%;
    }
  }
`;

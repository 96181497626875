import * as React from 'react';
// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag';
import { Route, useLocation, useNavigate, Routes } from 'react-router-dom';
import TopicSelectionManager from '../../components/topic/topic-selection-manager/topic-selection-manager.component';
import { useMutation } from '@apollo/react-hooks';
import { routes } from '../../route-urls';
import { AppStoreApi, AppContext } from '../../context/app.context';
import { GetBrandedContainersDocument } from '../../resolver.types';
import { Modal } from 'antd';
import { useCallback, useContext } from 'react';
import { useDataListContext } from '../../components/common/data-list/data-list.context';
import { BrandedContainer, Event } from '../../resolver.types';
import DrawerWrapper from '../../components/branded-container/drawer-wrapper/drawer-wrapper';
import { makeUrlClickable } from '../../components/common/links-manipulations/make-url-clickable';

const CREATE_BRANDED_CONTAINER = gql`
  mutation CreateBrandedContainer(
    $brandedContainer: CreateBrandedContainerInput!
  ) {
    createBrandedContainer(brandedContainer: $brandedContainer) {
      id
    }
  }
`;

export type TopicData = {
  artists: string[];
  campaign: string;
  eventSeries: string;
  genres: string[];
  place: string[];
  venue: string[];
  workOfArt: string;
};

const CreateBrandedContainerFromTopicPage = () => {
  const navigate = useNavigate();
  const {
    state: { issuer, selectedBrand },
  } = useContext(AppContext) as AppStoreApi;

  const {
    state: { brandedContainers: brandedContainerQueryVariables },
  } = useDataListContext();

  const { state } = useLocation() as {
    pathname: string;
    state: { events?: Event[] } | undefined;
  };

  const [isCreatingBC, setIsCreatingBC] = React.useState(false);

  const [createBrandedContainer] = useMutation<{
    createBrandedContainer: BrandedContainer;
  }>(CREATE_BRANDED_CONTAINER);

  const onTopicDataSet = useCallback(
    async (topicData: TopicData) => {
      try {
        setIsCreatingBC(true);
        const { data } = await createBrandedContainer({
          variables: {
            brandedContainer: {
              issuer,
              eventIDs: state?.events?.map((event) => event.id) ?? [],
              brand: selectedBrand!.name,
              topic: {
                data: topicData,
              },
            },
          },
          refetchQueries: brandedContainerQueryVariables
            ? [
                {
                  query: GetBrandedContainersDocument,
                  variables: {
                    ...brandedContainerQueryVariables,
                    brand: selectedBrand?.name,
                  },
                },
              ]
            : [],
          awaitRefetchQueries: true,
        });

        if (data) {
          navigate(
            routes.setBrandedContainerData(data.createBrandedContainer.id)
          );
        }
      } catch (e) {
        Modal.error({
          title: `Can't create branded container.`,
          content: (
            <>
              <p style={{ whiteSpace: 'pre-line' }}>
                {makeUrlClickable((e as Error).message)}
              </p>
            </>
          ),
        });
      } finally {
        setIsCreatingBC(false);
      }
    },
    [
      brandedContainerQueryVariables,
      createBrandedContainer,
      navigate,
      issuer,
      selectedBrand,
      state,
    ]
  );

  return (
    <>
      <Routes>
        <Route
          path={`select-promotion-items`}
          element={
            <TopicSelectionManager
              isLoading={isCreatingBC}
              onTopicDataSet={onTopicDataSet}
            />
          }
        />
        <Route path={`set-container-data/:id/*`} element={<DrawerWrapper />} />
      </Routes>
    </>
  );
};

export default CreateBrandedContainerFromTopicPage;

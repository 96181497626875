import * as React from 'react';
import { StyledPromotionItem } from './promotion-item.styles';
import { MutableRefObject, useCallback, useRef } from 'react';

export type PromotionItemCard = {
  imageUrl: string;
  name: string;
  key: string;
  cardTitle: string;
  disabled?: boolean;
};

type PromotionItemProps = PromotionItemCard & {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const PromotionItem = ({
  name,
  cardTitle,
  imageUrl,
  onChange,
  disabled,
}: PromotionItemProps) => {
  const checkboxRef = useRef<HTMLInputElement>();

  const handleContainerClick = useCallback(() => {
    if (checkboxRef.current) {
      checkboxRef.current.click();
    }
  }, [checkboxRef]);

  return (
    <StyledPromotionItem
      $disabled={disabled}
      className="promotion-item"
      role="button"
      onClick={handleContainerClick}
    >
      <img src={imageUrl} alt={cardTitle} draggable={false} />
      <span>{cardTitle}</span>
      <input
        ref={checkboxRef as MutableRefObject<HTMLInputElement>}
        name={name}
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
      />
    </StyledPromotionItem>
  );
};

import React from 'react';
import { Container } from './drawer-actions-bar.styles';
import { Button } from 'antd';
import type { ButtonType } from 'antd/lib/button';

type Action<T> = {
  loading?: boolean;
  icon?: React.ReactNode;
  type: ButtonType;
  onClick: (data: T[]) => Promise<void>;
  label: string;
};

export type DrawerActionsBarProps<T, K> = {
  data: K;
  description: string;
  actions: T[];
  callback?: () => void;
  disabled?: boolean;
};

export function DrawerActionsBar<T extends Action<K>, K>({
  data,
  description = '',
  actions,
  callback,
}: DrawerActionsBarProps<T, K>) {
  return (
    <Container className="edit-bottom-bar">
      <div className="left-side">
        <b style={{ verticalAlign: 'sub' }}>{description}</b>
      </div>
      <div className="right-side">
        {actions.map((action, index) => (
          <Button
            key={index}
            loading={action.loading}
            icon={action.icon}
            type={action.type}
            onClick={async () => {
              await action.onClick([data]);
              if (callback) callback();
            }}
          >
            {action.label}
          </Button>
        ))}
      </div>
    </Container>
  );
}

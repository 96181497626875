import styled from 'styled-components';

export const ActivityLogContainer = styled.div`
  display: flex;
  align-items: center;

  & > .user-round-avatar {
    margin-right: 8px;
  }
`;

export const ActivityInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DottedBorder = styled.div`
  flex: 1;
  margin: 1px 10px;
  height: 1px;
  background-image: linear-gradient(
    to right,
    #979797 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
`;

export const UpdateReasonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ActivityReason = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const ActivityDate = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

export const ActivityIssuer = styled.span`
  font-size: 12px;
`;

export const ActivityDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActivityDescription = styled.div`
  font-size: 12px;
`;

import styled from 'styled-components';

export const SearchValueContainer = styled.div`
  background-color: #e3a14a;
  font-size: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0px;
  height: 30px;
  user-select: none;
  position: relative;

  .ant-btn-icon-only {
    position: absolute;
    right: -3px;
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 11px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #e3a14a;
    color: #fff;
  }

  & .ant-select {
    width: fit-content !important;
    font-size: 10px;

    & .ant-select-arrow {
      top: 55%;
      right: 8px;
      font-size: 9px;
    }
  }

  & .ant-select-selection--single {
    padding: 0 15px;
    margin: 0;
    height: 25px;
    font-size: 10px;
    border-radius: 20px;
    width: fit-content;
  }

  & .ant-select-selection__rendered {
    line-height: 25px;
    margin: 0;
  }

  & .ant-select-selection--single .ant-select-selection__rendered {
    margin: 0px;
    margin-right: 15px;
  }

  & .value-text {
    font-weight: 500;
    font-size: 10px;
    margin: 0 35px 0 15px;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }
`;

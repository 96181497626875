import { useFiltersContext } from '../../../hooks';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePagination } from '../../../../../hooks/use-pagination';
import { LabeledValue } from 'antd/lib/select';
import isEqual from 'lodash.isequal';

export type MultiSelectHookConfig = {
  name: string;
  perPage: number;
  choices: LabeledValue[];
  initialValue?: LabeledValue[];
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => any;
  showSelectedOnBottom?: boolean;
  onChange?: (value: LabeledValue[]) => any;
};

const initial: LabeledValue[] = [];

export const useMultiSelect = ({
  name,
  perPage,
  choices: propChoices,
  initialValue = initial,
  defaultOpen,
  onOpenChange,
  showSelectedOnBottom,
  onChange,
}: MultiSelectHookConfig) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } =
    useFiltersContext();
  const decorator = getFieldDecorator(name, {
    preserve: true,
    initialValue,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value: LabeledValue[] = getFieldValue(name) ?? [];

  const selectRef = useRef<any>();

  const containerRef = useRef<HTMLElement>();
  const setContainerRef = useCallback((el: HTMLElement) => {
    containerRef.current = el;
  }, []);

  const [open, setOpen] = useState(defaultOpen);
  const [search, setSearch] = useState('');
  const [choices, setChoices] = useState(propChoices);

  const handleDeselect = useCallback(
    (deselectedValue: LabeledValue) => () => {
      const value = getFieldValue(name) as LabeledValue[];
      const newValue = value.filter((val) => val.key !== deselectedValue.key);

      setFieldsValue({
        [name]: newValue,
      });
    },
    [getFieldValue, name, setFieldsValue]
  );

  const toggleOpen: MouseEventHandler = useCallback(
    (event) => {
      if (!open) {
        setOpen(true);

        return;
      }

      const dropdownMenu = document.querySelector('.dropdown-open');

      if (dropdownMenu && dropdownMenu.contains(event.target as HTMLElement)) {
        return;
      }

      setOpen(false);
    },
    [open]
  );

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setSearch(event.target.value);
    },
    []
  );
  const onInputClick: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => event.stopPropagation(),
    []
  );

  useEffect(() => {
    if (initialValue) {
      setFieldsValue({
        [name]: initialValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  // Reset search value after closing
  useEffect(() => {
    if (!open) {
      setSearch('');
    }
  }, [open]);

  useEffect(() => {
    if (!search) {
      setChoices(propChoices);

      return;
    }

    const choices = propChoices.filter((choice) =>
      (choice.label as string).toLowerCase().includes(search.toLowerCase())
    );

    setChoices(choices);
  }, [search, propChoices]);

  useEffect(() => {
    if (onOpenChange) {
      onOpenChange(open as boolean);
    }
  }, [open, onOpenChange]);

  useEffect(() => {
    if (!containerRef.current || !open) {
      return;
    }

    const container = containerRef.current;

    const handleOutsideClick = (event: Event) => {
      const dropdownMenu = document.querySelector('.dropdown-open');
      const target = event.target as HTMLElement;

      if (
        container.contains(target) ||
        (dropdownMenu && dropdownMenu.contains(target))
      ) {
        return;
      }

      setOpen(false);
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpen(false);
      }
    };

    document.body.addEventListener('click', handleOutsideClick);
    document.body.addEventListener('keyup', handleKeyPress);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
      document.body.removeEventListener('keyup', handleKeyPress);
    };
  }, [containerRef, open]);

  useEffect(() => {
    const container = containerRef.current;

    if (!container || open) {
      return;
    }

    const select = container.querySelector('.ant-select');

    if (select) {
      select.classList.remove('ant-select-focused');
    }
  }, [open, containerRef]);

  const nonSelectedChoices = showSelectedOnBottom
    ? choices.filter((choice) => !value.find((val) => val.key === choice.key))
    : choices;

  const selectedChoices = choices.filter((choice) =>
    value.find((val) => val.key === choice.key)
  );

  const pagination = usePagination<LabeledValue>({
    items: nonSelectedChoices,
    perPage,
  });

  const resetValue: any = useCallback(() => {
    setFieldsValue({
      [name]: initialValue,
    });
  }, [name, setFieldsValue, initialValue]);

  const showResetButton = useMemo(() => {
    if (!initialValue.length) {
      return value.length > 0;
    }

    return !isEqual(value, initialValue);
  }, [value, initialValue]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return {
    pagination,
    selectedChoices,
    nonSelectedChoices,
    onInputClick,
    onInputChange,
    setContainerRef,
    decorator,
    handleDeselect,
    open,
    toggleOpen,
    setOpen,
    value,
    search,
    resetValue,
    selectRef,
    showResetButton,
    setSearch,
  };
};

export type MultiSelectHook = ReturnType<typeof useMultiSelect>;

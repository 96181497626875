export const displayTicketsAvailability = (
  available?: number | null,
  total?: number | null
) => {
  if ((available || available === 0) && (total || total === 0)) {
    return `${available}/${total}`;
  }

  return 'No data';
};

import React from 'react';
import { Select } from 'antd';
import { Brand } from 'yggdrasil-shared/domain/dictionary';
import { BrandsDocument } from '../../../resolver.types';
import { useQuery } from '@apollo/client';
import { StyledDropdown } from './brand-dropdown.styles';

type BrandDropdownProps = {
  value: Brand;
  onChange: (brand: Brand) => void;
};

export const BrandDropdown = ({ value, onChange }: BrandDropdownProps) => {
  const { data, loading } = useQuery(BrandsDocument);

  const handleOnChange = (shortcut: unknown) => {
    const foundBrand = data?.brands.find(
      (brand: Brand) => brand.shortcut === shortcut
    );

    if (foundBrand) {
      onChange(foundBrand);
    }
  };

  return (
    <StyledDropdown
      $color={value.color}
      loading={loading}
      value={value.shortcut}
      onChange={handleOnChange}
      className="brand-dropdown"
      dropdownMatchSelectWidth={false}
    >
      {data?.brands.map((brand: Brand) => (
        <Select.Option key={brand.name} value={brand.shortcut}>
          {brand.shortcut}
        </Select.Option>
      ))}
    </StyledDropdown>
  );
};
